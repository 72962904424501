import isEqual from "lodash/isEqual";
import clone from "../helpers/clone";
import mergeDeep from "../helpers/mergeDeep";
import update from "immutability-helper";
import addMonths from "date-fns/addMonths";
import { haystackMatchesSearchObject } from "./search";

function removeEmptyStrings(x) {
    if (!Array.isArray(x)) {
        return x;
    }
    return x.filter(y => y != "");
}

function reports(state = {}, action) {
    switch (action.type) {
        case "CLEAR_SELECTED_DATASOURCE":
            return {};
        case "SET_REPORT_BEGIN_DATE":
            if (!action.beginDate) {
                return state;
            }
            // Actually seeing if it changed is deceptively difficult due to bugs lurking
            // in our application, so just say it's always changed.

            // We're not consistent about the time storage.. sometimes it's a javascript Date object, sometimes it's a
            // string.  It has to do with the way the date is sent to C# and comes back.
            // There is a timezone bug in there as well, because we don't carry timezone information everywhere.

            return update(state, {
                [action.reportId]: {
                    BeginTime: { $set: action.beginDate },
                    hasUnsavedChanges: { $set: true },
                },
            });
        case "SET_REPORT_END_DATE": {
            if (!action.endDate) {
                return state;
            }
            return update(state, {
                [action.reportId]: {
                    EndTime: { $set: action.endDate },
                    hasUnsavedChanges: { $set: true },
                },
            });
        }
        case "SET_REPORT_PIVOT_CONFIG": {
            let newState = update(state, {
                [action.reportId]: {
                    PivotConfig: { $set: action.pivotConfig },
                },
            });

            let pivotChanged = false;
            const fields = ["rows", "cols", "vals", "rendererName", "aggregatorName"];
            for (const field of fields) {
                let currentFieldValue = state[action.reportId].PivotConfig
                    ? state[action.reportId].PivotConfig[field]
                    : null;
                let newFieldValue = action.pivotConfig ? action.pivotConfig[field] : null;

                currentFieldValue = removeEmptyStrings(currentFieldValue);
                newFieldValue = removeEmptyStrings(newFieldValue);

                if (!currentFieldValue || !newFieldValue || !isEqual(currentFieldValue, newFieldValue)) {
                    pivotChanged = true;
                    break;
                }
            }

            if (pivotChanged) {
                newState = update(newState, {
                    [action.reportId]: {
                        hasUnsavedChanges: { $set: true },
                    },
                });
            }
            return newState;
        }
        case "ASSIGN_NEW_REPORT_ID": {
            const newState = clone(state);
            newState[action.newReportId] = clone(newState[0]);
            delete newState[0];
            return newState;
        }
        case "NEW_SINGLE_VIEW_REPORT": {
            const newState = clone(state);
            newState[0] = {
                IsActive: true,
                BeginTime: addMonths(new Date(), -1),
                EndTime: new Date(),
                IsDeploymentReport: false,
                ReportType: "Single View",
                IsGlobalReport: false,
            };
            return newState;
        }
        case "NEW_COUNT_REPORT": {
            const newState = clone(state);
            newState[0] = {
                IsActive: true,
                BeginTime: addMonths(new Date(), -1),
                EndTime: new Date(),
                IsDeploymentReport: false,
                ReportType: "Audience",
                IsGlobalReport: false,
            };
            return newState;
        }
        case "NEW_FLOW_EXPORTREPORT_LOG_REPORT": {
            const newState = clone(state);
            newState[0] = {
                IsActive: true,
                BeginTime: addMonths(new Date(), -1),
                EndTime: new Date(),
                IsDeploymentReport: false,
                ReportType: "Flow Export Report Log",
                IsGlobalReport: false,
            };
            return newState;
        }
        case "NEW_FLOW_LOG_REPORT": {
            const newState = clone(state);
            newState[0] = {
                IsActive: true,
                BeginTime: addMonths(new Date(), -1),
                EndTime: new Date(),
                IsDeploymentReport: false,
                ReportType: "Flow Log",
                IsGlobalReport: false,
            };
            return newState;
        }
        case "UPDATE_REPORTS_LIST": {
            let newState;
            const newAction = clone(action);

            if (action.mergeBehavior && action.mergeBehavior == "replace") {
                newState = action.reports;
                if (typeof state[0] === "object") {
                    newState[0] = clone(state[0]);
                }
            } else if (action.mergeBehavior && action.mergeBehavior == "merge-overwrite") {
                newState = clone(state);
                for (const reportToDelete of Object.keys(action.reports)) {
                    delete newState[reportToDelete];
                }
                newState = mergeDeep(newState, newAction.reports);
            } else {
                // Don't merge in data from server over reports that have unsaved local changes
                const stateWithUnsavedChanges = ofilter(v => v["hasUnsavedChanges"], state);
                for (const reportIdWithUnsavedChanges of Object.keys(stateWithUnsavedChanges)) {
                    delete newAction.reports[reportIdWithUnsavedChanges];
                }
                newState = mergeDeep(clone(state), newAction.reports);
            }

            if (isEqual(state, newState)) {
                return state;
            }

            return newState;
        }
        case "ARCHIVE_REPORT": {
            /* This update helper really is great, I should clean up the other actions and reducers to use it */
            return update(state, {
                [action.reportId]: {
                    IsActive: { $set: false },
                },
            });
        }
        case "REPORT_REASSIGN_ID":
            if (state == action.oldDeploymentId) {
                return action.newDeploymentId;
            }
            return state;
        case "REPORT_COPY_TO_ID_ZERO": {
            const newstate = clone(state);
            newstate[0] = clone(newstate[action.reportId]);
            delete newstate[0].Id;
            return newstate;
        }
        default:
            return state;
    }
}
export default reports;

// reportMatchesSearchString
// Wants an "report object" as first argument
export const reportMatchesSearchString = (report, searchString) => {
    if (!report || !report.ReportName) {
        return false;
    }
    return haystackMatchesSearchObject(report.ReportName, searchString);
};

import ofilter from "../helpers/ofilter";
// Wants only "state.reports" as state, also wants a userid
const archivedReportsBelongingTo = (state, userId) =>
    ofilter((v, k) => !v["IsActive"] && v["SavedBy"] == userId, state); // eslint-disable-line no-unused-vars
export { archivedReportsBelongingTo };
