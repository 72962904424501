import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowDataLoad, FlowItem, FlowDataLoadColumn, FlowErrorsByItemId } from "../types/flowTypes";
import type { FlowDataLoadColumnsByItemId } from "./flowDataLoadColumns";
import { getFlowDataLoadColumnsByFlowItemId } from "./flowDataLoadColumns";

const myGenericReducer = makeReducerFor("FLOW_DATA_LOAD", "FlowDataLoadId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowDataLoadArray = createSelector(
    state => state.flowDataLoads.byId,
    (flowDataLoadsById: {| [number]: FlowDataLoad |}): Array<FlowDataLoad> => {
        const r: Array<FlowDataLoad> = Object.values(flowDataLoadsById);
        return r;
    }
);

export type FlowDataLoadsByItemId = {
    [number]: FlowDataLoad,
};

// eslint-disable-line
export const getFlowDataLoadsByFlowItemId = createSelector(
    state => getFlowDataLoadArray(state),
    (flowDataLoads: Array<FlowDataLoad>): FlowDataLoadsByItemId =>
        flowDataLoads.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowDataLoadsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowDataLoadsByFlowItemId(state),
    (flowItems: Array<FlowItem>, flowDataLoadsByItemId: FlowDataLoadsByItemId): Array<FlowDataLoad> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const script = flowDataLoadsByItemId[itemId];
            if (script != null) {
                result.push(script);
            }
        }
        return result;
    }
);

export const hasEnabledFlowDataLoadsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowDataLoadsByFlowItemId(state),
    (flowItems: Array<FlowItem>, flowDataLoadsByItemId: FlowDataLoadsByItemId): boolean => {
        const itemIds = flowItems.filter(x => x.IsActive).map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const script = flowDataLoadsByItemId[itemId];
            if (script != null) {
                result.push(script);
            }
        }
        return result.length > 0;
    }
);

export const getFlowDataLoadByFlowItemIdFromProps = createSelector(
    (_, props) => props.flowItemId,
    state => getFlowDataLoadArray(state),
    (flowItemId: number, dataLoadArray: Array<FlowDataLoad>): FlowDataLoad =>
        dataLoadArray.find(x => x.FlowItemId == flowItemId)
);

export const getFlowDataLoadForSelectedFlowItem = createSelector(
    state => state.selected.flowItem,
    state => getFlowDataLoadArray(state),
    (flowItemId: number, dataLoadArray: Array<FlowDataLoad>): FlowDataLoad =>
        //$FlowFixMe
        dataLoadArray.find(x => x.FlowItemId == flowItemId)
);

const dataLoadToErrorsById = (
    flowDataLoads: Array<FlowDataLoad>,
    flowDataLoadColumnsById: FlowDataLoadColumnsByItemId,
    enabledFeatures: Array<string>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowDataLoad of flowDataLoads) {
        errorsById[flowDataLoad.FlowItemId] = validateFlowDataLoad(
            flowDataLoad,
            flowDataLoadColumnsById[flowDataLoad.FlowItemId],
            enabledFeatures
        );
    }
    return errorsById;
};

export const getDataLoadErrorsForSelectedFlow = createSelector(
    state => getFlowDataLoadsForSelectedFlow(state),
    state => getFlowDataLoadColumnsByFlowItemId(state),
    state => state.session.enabledFeatures,
    dataLoadToErrorsById
);

export const getDataLoadErrorsForAllFlows = createSelector(
    state => getFlowDataLoadArray(state),
    state => getFlowDataLoadColumnsByFlowItemId(state),
    state => state.session.enabledFeatures,
    dataLoadToErrorsById
);

export const getIsAnyDataLoadIncompleteForSelectedFlow = createSelector(
    state => getDataLoadErrorsForSelectedFlow(state),
    (dataLoadErrorsById: FlowErrorsByItemId): boolean => {
        const errorValues: Array<string> = Object.values(dataLoadErrorsById);
        for (const errors of errorValues) {
            if (errors.length > 0) {
                return true;
            }
        }
        return false;
    }
);

export const validateFlowDataLoad = (
    flowDataLoad: FlowDataLoad,
    flowDataLoadColumns: Array<FlowDataLoadColumn>,
    enabledFeatures: Array<string>
) => {
    const errors = [];

    if (!flowDataLoad.FlowTableToLoad) {
        errors.push("Table to load is required.");
    }
    if (
        enabledFeatures.includes("flow-dataload-destinations") &&
        (!flowDataLoad.DestinationId || flowDataLoad.DestinationId == 0)
    ) {
        errors.push("A Destination is must be selected.");
    }
    if (!flowDataLoad.ParentFolderId) {
        errors.push("A parent folder must be selected.");
    }
    if (!flowDataLoad.FolderName) {
        errors.push("Folder name is required.");
    }

    if (flowDataLoad.FlowTableToLoad) {
        if (!flowDataLoadColumns || flowDataLoadColumns.length <= 0) {
            errors.push("Selected table doesn't have any column.");
        } else {
            //shouldn't happen but validating anyway
            //there can be a default column with empty name because it is filled with the folder name input
            if (flowDataLoadColumns.find(x => !x.ColumnName)) {
                errors.push("Table contain invalid column names.");
            }

            if (flowDataLoadColumns.filter(x => x.JoinColumn != "" && x.JoinColumn != null).length <= 0) {
                errors.push("At least one join column must be selected.");
            }

            const folderName = flowDataLoad.FolderName || "";
            if (
                flowDataLoadColumns.filter(
                    x => !x.IsDefault && x.ColumnName && x.ColumnName.toLowerCase() == folderName.toLowerCase()
                ).length > 0
            ) {
                errors.push("The folder name cannot be the same as one of the column names in the table.");
            }
        }
    }
    return errors;
};
