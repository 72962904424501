import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";

import type {
    FlowItem,
    FlowOffload,
    FlowOffloadColumn,
    FlowErrorsByItemId,
    FlowOffloadDetail,
} from "../types/flowTypes";
import type { FlowOffloadColumnsByItemId } from "./flowOffloadColumns";
import { getFlowOffloadColumnsByFlowItemId } from "./flowOffloadColumns";

const myGenericReducer = makeReducerFor("FLOW_OFFLOAD", "FlowOffloadId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

export type FlowOffloadsByItemId = {
    [number]: FlowOffload,
};
// export type FlowOffloads = {
//     [number]: FlowOffload,
// };

export const getFlowOffloadArray = createSelector(
    state => state.flowOffloads.byId,
    (flowOffloadsById: {| [number]: FlowOffload |}): Array<FlowOffload> => {
        const r: Array<FlowOffload> = Object.values(flowOffloadsById);
        return r;
    }
);

// eslint-disable-line
export const getFlowOffloadsByFlowItemId = createSelector(
    state => getFlowOffloadArray(state),
    (flowOffloads: Array<FlowOffload>): FlowOffloadsByItemId =>
        flowOffloads.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowOffloadsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowOffloadsByFlowItemId(state),
    (flowItems: Array<FlowItem>, flowOffloadsByItemId: FlowOffloadsByItemId): Array<FlowOffload> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        let result = [];
        for (const itemId of itemIds) {
            let script = flowOffloadsByItemId[itemId];
            if (script != null) {
                result.push(script);
            }
        }
        return result;
    }
);

export const getFlowOffloadDetailsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowOffloadsByFlowItemId(state),
    (flowItems: Array<FlowItem>, flowOffloadsByItemId: FlowOffloadsByItemId): Array<FlowOffloadDetail> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const itemNames = flowItems.map(fi => fi.FlowItemName);
        let result = [];
        let i = 0;
        for (const itemId of itemIds) {
            let script = flowOffloadsByItemId[itemId];
            const newFlowItemName = itemNames[i];

            if (script != null) {
                script["FlowItemName"] = newFlowItemName;
                result.push(script);
            }
            i++;
        }
        return result;
    }
);

export const getFlowOffloadForSelectedFlowItem = createSelector(
    state => state.selected.flowItem,
    state => getFlowOffloadsByFlowItemId(state),
    (flowItemId: number, flowOffloadsByItemId: FlowOffloadsByItemId): FlowOffload => flowOffloadsByItemId[flowItemId]
);

const offloadToErrorsById = (
    flowOffloads: Array<FlowOffload>,
    flowOffloadColumnsById: FlowOffloadColumnsByItemId
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowOffload of flowOffloads) {
        errorsById[flowOffload.FlowItemId] = validateFlowOffload(
            flowOffload,
            flowOffloadColumnsById[flowOffload.FlowItemId]
        );
    }
    return errorsById;
};

export const getOffloadErrorsForSelectedFlow = createSelector(
    state => getFlowOffloadsForSelectedFlow(state),
    state => getFlowOffloadColumnsByFlowItemId(state),
    offloadToErrorsById
);

export const getOffloadErrorsForAllFlows = createSelector(
    state => getFlowOffloadArray(state),
    state => getFlowOffloadColumnsByFlowItemId(state),
    offloadToErrorsById
);

export const getIsAnyOffloadIncompleteForSelectedFlow = createSelector(
    state => getOffloadErrorsForSelectedFlow(state),
    (offloadErrorsById: FlowErrorsByItemId): boolean => {
        const errorValues: Array<string> = Object.values(offloadErrorsById);
        for (const errors of errorValues) {
            if (errors.length > 0) {
                return true;
            }
        }
        return false;
    }
);

export const validateFlowOffload = (flowOffload: FlowOffload, flowOffloadColumns: Array<FlowOffloadColumn>) => {
    const errors = [];

    if (!flowOffload.TableName) {
        errors.push("Table name is required.");
    }

    if (!flowOffload.DestinationId) {
        errors.push("Destination is required.");
    }

    if (!flowOffload.FileName) {
        errors.push("File name is required.");
    }

    if (!flowOffload.IsFixedFile && (!flowOffload.FileExtension || !flowOffload.FileDelimiter)) {
        errors.push("File extension and delimiter are required.");
    }

    if (
        !flowOffload.AutoRefreshColums &&
        flowOffload.TableName &&
        (!flowOffloadColumns || flowOffloadColumns.length <= 0)
    ) {
        errors.push("Columns haven't been loaded.");
    } else if (flowOffload.TableName) {
        if (flowOffloadColumns && flowOffloadColumns.filter(x => x.DeployColumn).length <= 0) {
            errors.push("At least one column must be included for distribution.");
        }
        if (
            flowOffload.IsFixedFile &&
            flowOffloadColumns &&
            flowOffloadColumns.filter(x => x.DeployColumn && (!x.ColumnLength || x.ColumnLength < 1)).length > 0
        ) {
            errors.push("Column Size is required for all included columns.");
        }
    }
    return errors;
};

export const getSelectedFlowHasOffload = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    (flowItems: Array<FlowItem>): boolean => {
        const offloadsHavingResults = flowItems.filter(f => f.FlowItemType == "offload");
        return offloadsHavingResults.length > 0;
    }
);
