import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import h from "../../../../helpers";

// components
import {
    Paper,
    TextField,
    Button,
    TextareaAutosize,
    FormLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    InputLabel,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Divider,
    Tooltip,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import BouncingDots from "../../../loading/BouncingDots";
import IconSwitch from "../../../material-components/Misc/IconSwitch";
import FlowControlInputFiles from "./FlowControlInputFiles";

// reducers
import { getSelectedFlowPermissions, getFlowItemsForSelectedFlow } from "../../../../reducers/flowItems";
import {
    getFlowExternalServiceForSelectedFlowItem,
    getFilteredInputFileLocations,
} from "../../../../reducers/flowExternalServices";
import { getFlowExternalServiceInputsForSelectedFlowItem } from "../../../../reducers/flowExternalServiceInputs";
import { getFlowRelationsForSelectedFlow } from "../../../../reducers/flowRelations";

// actions
import { GetS3FileInfo, ResetS3Data } from "../../../../actions/awsActions";
import { showModal } from "../../../../actions/actionCreators";
import {
    updateMultipleAttribute,
    invalidateItemAndChildren,
    newFlowExternalServiceInput,
    deleteFlowExternalServiceInput,
} from "../../../../actions/flowActions";
import { requestFlowItemResultTables } from "../../../../actions/flowItemEditActions";

// types
import { IAppState } from "../../../../types/stores";
import {
    FlowExternalServiceParameter,
    FlowItem,
    FlowPermissions,
    FlowRelation,
    SnowflakeSelectedColumn,
} from "../../../../types/stores/flowTypes";
import { LayoutTypes } from "../../../layouts-external-service/LayoutBuilder";
import FlowControlLayoutPicker from "./FlowControlLayoutPicker";
import NumericalTextField from "../../../material-components/Misc/NumericalTextField";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { SortableHandle } from "react-sortable-hoc";
import { Autocomplete } from "@material-ui/lab";
import { IExternalServiceParameter } from "../../../../types/stores/flowControlData";
import { getFlowExternalServiceParametersForSelectedFlowItem } from "../../../../reducers/flowExternalServiceParameters";
import { BrowseFileIntegrations } from "../../../../helpers/constants";

const DragHandle = SortableHandle(() => <i className="material-icons sort-draggable grabbable">drag_handle</i>);

const SortableItem = SortableElement(({ colIndex, disabled, col, externalService }) => {
    const dispatch = useDispatch();

    return (
        <div key={colIndex} className="row" style={{ display: "flex", zIndex: 9999 }}>
            <div style={{ float: "left", paddingLeft: "10px" }}>
                <label> {<DragHandle />}</label>
            </div>
            <div style={{ width: "50%", marginTop: "10px" }}>{col.ColumnName}</div>
            <div style={{ marginTop: "5px" }}>
                <FormControlLabel
                    style={{ margin: "0 0 0 5px" }}
                    control={
                        <IconSwitch
                            id={"column-id-" + colIndex}
                            name={"column-name-" + colIndex}
                            onChange={e => {
                                const newColumns = [...externalService.InputTableColumns];

                                newColumns[colIndex].Include = e.target.checked;
                                dispatch(
                                    updateMultipleAttribute(
                                        "flowExternalServices",
                                        externalService.FlowExternalServiceId,
                                        {
                                            InputTableColumns: newColumns,
                                        }
                                    )
                                );
                            }}
                            checked={col.Include}
                            disabled={disabled}
                        />
                    }
                    label={
                        <Tooltip title="Column should be included on the input.">
                            <div style={{ fontSize: "12px" }}>
                                Include Column&nbsp;
                                <i className={"material-icons"} style={{ fontSize: "12px" }}>
                                    info
                                </i>
                            </div>
                        </Tooltip>
                    }
                />
            </div>
        </div>
    );
});

const SortableList = SortableContainer(({ externalService, permissions }) => (
    <div>
        {externalService.InputTableColumns.map((col, index) => (
            <SortableItem
                index={index}
                key={index}
                disabled={!permissions || !permissions.canEdit}
                colIndex={index}
                col={col}
                externalService={externalService}
            />
        ))}
    </div>
));

export enum BrowseFileLocations {
    S3 = "S3",
    PLEX5 = "PLEX5",
    SF = "SNOWFLAKE",
    SERVER = "Server",
}

const bouncingLayout = {
    marginTop: "-390px",
    marginLeft: "-350px",
};

const bouncingNoLayout = {
    marginTop: "40px",
    marginLeft: "-350px",
};

const FlowControlInput: React.FC = () => {
    const propertyName = "InputLayoutId";
    const dispatch = useDispatch();

    // State
    const [fileLocationId, setFileLocationId] = useState(0);
    const [selectedFile, setSelectedFile] = useState("");
    const [filePreview, setFilePreview] = useState("");
    const [isInputRelativePosition, setIsInputRelativePosition] = useState(false);
    const [canRetainInput, setCanRetainInput] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [databases, setDatabases] = useState<Array<string>>();
    const [selectedDatabase, setSelectedDatabase] = useState("");
    const [schemas, setSchemas] = useState<Array<string>>([]);
    const [selectedSchema, setSelectedSchema] = useState("");
    const [tables, setTables] = useState<Array<string>>([]);
    const [selectedTable, setSelectedTable] = useState("");
    const [isS3Input, setIsS3Input] = useState(false);
    const [isServerInput, setIsServerInput] = useState(false);
    const [isSnowflakeInput, setIsSnowflakeInput] = useState(false);
    const [isPLEX5Input, setIsPLEX5Input] = useState(false);
    const [previewColumns, setPreviewColumns] = useState<Array<string>>([]);
    const [previewRows, setPreviewRows] = useState<Array<any>>([]);
    const [includeAllColumns, setIncludeAllColumns] = useState(true);
    const [hasRestrictedUser, setHasRestrictedUser] = useState(false);
    const [isParentNodeScript, setIsParentNodeScript] = useState(false);
    const [parentTableName, setParentTableName] = useState<string>("");
    const [parentTables, setParentTables] = useState<Array<any>>([]);
    const [parentFlowItemId, setParentFlowItemId] = useState<number>(0);
    const [isTestMode, setIsTestMode] = useState(false);
    const [testLimitValue, setTestLimitValue] = useState(0);

    // Redux
    const flowExternalService = useSelector((state: IAppState) => getFlowExternalServiceForSelectedFlowItem(state));
    const flowExternalServiceInputs = useSelector((state: IAppState) =>
        getFlowExternalServiceInputsForSelectedFlowItem(state)
    );
    const flowPermissions = useSelector<IAppState, FlowPermissions>(state => getSelectedFlowPermissions(state));
    const awsIsDownloading = useSelector<IAppState, boolean>(state => state.aws.isDownloading);
    const awsFileName = useSelector<IAppState, string>(state => state.aws.fileName);
    const awsFilePreview = useSelector<IAppState, string>(state => state.aws.filePreview || "");
    const awsError = useSelector((state: IAppState) => state.aws.error);
    const externalInputFileLocation = useSelector((state: IAppState) => getFilteredInputFileLocations(state));
    const layouts = useSelector((state: IAppState) => state.externalServiceLayoutData.externalServiceLayouts);
    const layout = layouts.find(l => l.LayoutId == flowExternalService[propertyName]);
    const enabledFeatures = useSelector((state: IAppState) => state.session.enabledFeatures);
    const roles = useSelector((state: IAppState) => state.session.roles);
    const isSuperAdmin = roles.includes("Super Admin");
    const flowRelations = useSelector<IAppState, Array<FlowRelation>>(state => getFlowRelationsForSelectedFlow(state));
    const flowItems = useSelector<IAppState, Array<FlowItem>>(state => getFlowItemsForSelectedFlow(state));
    const resultTables = useSelector((state: IAppState) => state.flowItemResultTables.resultTables);
    const flowServiceParameters = useSelector<IAppState, Array<FlowExternalServiceParameter>>(state =>
        getFlowExternalServiceParametersForSelectedFlowItem(state)
    );
    const testSwitchParam = useSelector<IAppState, IExternalServiceParameter | undefined>(state =>
        state.flowControlData.externalServiceParameters.find(x => x.ExternalParameter.ParameterName == "Test_Switch")
    );
    const testLimitValueParam = useSelector<IAppState, IExternalServiceParameter | undefined>(state =>
        state.flowControlData.externalServiceParameters.find(
            x => x.ExternalParameter.ParameterName == "Test_Limit_Value"
        )
    );

    // Input locations
    const s3FileLocation = externalInputFileLocation.find(x => x.FileLocationName == BrowseFileLocations.S3);
    const snowflakeLocation = externalInputFileLocation.find(x => x.FileLocationName == BrowseFileLocations.SF);
    const serverFileLocation = externalInputFileLocation.find(x => x.FileLocationName == BrowseFileLocations.SERVER);
    const plex5FileLocation = externalInputFileLocation.find(x => x.FileLocationName == BrowseFileLocations.PLEX5);

    // Features
    const isMultiInputFeature = enabledFeatures.includes("flow-control-multi-input");
    const autopopulateSnowflateQuantityFeature = enabledFeatures.includes("auto-populate-snowflake-table-quantity");
    const isServerFileImportFeature = enabledFeatures.includes("flow-control-server-input");
    const isFlowcontrolSnowflakeInputFeature = enabledFeatures.includes("flowcontrol-snowflake-input");

    const handleFocus = event => event.target.select();

    // Setup flags base on features and remove Input locations in case there aren't the features
    useEffect(() => {
        setCanRetainInput(enabledFeatures.includes("flow-control-retain-input") && isSuperAdmin);

        // Force to use Snowflake Input Table if parent node is Script
        if (isFlowcontrolSnowflakeInputFeature) {
            const parentFlowRelation = flowRelations.find(x => x.ChildFlowItemId == flowExternalService.FlowItemId);
            if (
                parentFlowRelation &&
                flowItems.some(x => x.FlowItemId == parentFlowRelation.ParentFlowItemId && x.FlowItemType == "script")
            ) {
                setIsParentNodeScript(true);
                setParentTableName(flowExternalService.InputTableName);

                if (parentFlowItemId != parentFlowRelation.ParentFlowItemId) {
                    setParentFlowItemId(parentFlowRelation.ParentFlowItemId);
                    dispatch(requestFlowItemResultTables(parentFlowRelation.ParentFlowItemId));
                }

                handleFileLocation(snowflakeLocation?.FileLocationId);
            } else {
                setIsParentNodeScript(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, enabledFeatures, isSuperAdmin, externalInputFileLocation, flowRelations]);

    // Setup Input locations flags
    useEffect(() => {
        if (externalInputFileLocation.length > 0) {
            switch (flowExternalService.FileLocationId) {
                case s3FileLocation?.FileLocationId: // S3
                    setIsS3Input(true);

                    setIsServerInput(false);
                    setIsSnowflakeInput(false);
                    setIsPLEX5Input(false);

                    break;
                case serverFileLocation?.FileLocationId: // SERVER
                    setIsServerInput(isServerFileImportFeature);

                    setIsS3Input(false);
                    setIsSnowflakeInput(false);
                    setIsPLEX5Input(false);

                    break;
                case snowflakeLocation?.FileLocationId: //SNOWFLAKE
                    setIsSnowflakeInput(isFlowcontrolSnowflakeInputFeature);

                    setIsS3Input(false);
                    setIsServerInput(false);
                    setIsPLEX5Input(false);

                    if (isFlowcontrolSnowflakeInputFeature && !isParentNodeScript) {
                        getDatabases();
                    }

                    break;
                case plex5FileLocation?.FileLocationId: // PLEX5
                    setIsPLEX5Input(true);

                    setIsS3Input(false);
                    setIsServerInput(false);
                    setIsSnowflakeInput(false);

                    break;
                default: // Anything selected
                    setIsS3Input(false);
                    setIsServerInput(false);
                    setIsSnowflakeInput(false);
                    setIsPLEX5Input(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalInputFileLocation, flowExternalService.FileLocationId]);

    // Get tables created by Script node as parent flow item
    useEffect(() => {
        if (isParentNodeScript && resultTables && resultTables[parentFlowItemId]) {
            setParentTables(resultTables[parentFlowItemId]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, resultTables]);

    useEffect(() => {
        setIsInputRelativePosition(layout?.RelativePositions ?? false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, layout]);

    // Clean Snowflake selected information when the input location isn't Snowflake
    useEffect(() => {
        if (snowflakeLocation?.FileLocationId != flowExternalService.FileLocationId) {
            if (
                (flowExternalService.InputTableName != null && flowExternalService.InputTableName != "") ||
                (flowExternalService.InputTableColumns != null && flowExternalService.InputTableColumns.length > 0)
            ) {
                cleanSnowflakeDataSelected();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowExternalService.FileLocationId]);

    const cleanSnowflakeDataSelected = () => {
        setSelectedDatabase("");
        setSelectedSchema("");
        setSelectedTable("");
        dispatch(
            updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                InputTableName: null,
                InputTableColumns: null,
            })
        );
    };

    // Get the file preview
    useEffect(() => {
        if (flowExternalService) {
            if (flowExternalService.FileName) {
                setSelectedFile(flowExternalService.FileName);

                // S3
                if (isS3Input) {
                    if (filePreview) {
                        if (flowExternalService.FileName == awsFileName && filePreview != awsFilePreview) {
                            dispatch(GetS3FileInfo(flowExternalService.FileName));
                        }
                    } else if (flowExternalService.FileName == awsFileName) {
                        setFilePreview(awsFilePreview);
                        dispatch(ResetS3Data());
                    } else if (!awsIsDownloading) {
                        dispatch(GetS3FileInfo(flowExternalService.FileName));
                    }
                }
            } else {
                setSelectedFile("");
            }
        }
    }, [
        dispatch,
        flowExternalService,
        filePreview,
        isS3Input,
        awsIsDownloading,
        awsFilePreview,
        awsFileName,
        fileLocationId,
        selectedFile,
    ]);

    // Getting preview table data when there isn't parent node script
    useEffect(() => {
        if (!isParentNodeScript) {
            if (selectedDatabase != "" && selectedSchema != "" && selectedTable != "" && hasRestrictedUser) {
                getPreview();
            } else {
                setPreviewColumns([]);
                setPreviewRows([]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTable, hasRestrictedUser]);

    // Getting preview table data when there is parent node script
    useEffect(() => {
        if (isParentNodeScript) {
            if (
                parentTableName &&
                parentTableName != "" &&
                flowExternalService.InputTableName &&
                flowExternalService.InputTableName != ""
            ) {
                getPreview();
            } else {
                setPreviewColumns([]);
                setPreviewRows([]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentTableName]);

    const createColumnsJSON = () => {
        let newColumns: Array<SnowflakeSelectedColumn> = [];
        previewColumns.forEach((col, index) => {
            newColumns.push({
                ColumnName: col.trim(),
                Include: true,
                Order: index + 1,
            });
        });
        dispatch(
            updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                InputTableColumns: newColumns,
            })
        );
    };

    useEffect(() => {
        if (previewColumns.length > 0 && !flowExternalService.InputTableColumns) {
            createColumnsJSON();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previewColumns]);

    useEffect(() => {
        if (!isParentNodeScript && flowExternalService.InputTableName && flowExternalService.InputTableName != "") {
            const tableParts = flowExternalService.InputTableName?.split(".");
            if (tableParts.length > 0) {
                if (tableParts[0].length > 0) {
                    setSelectedDatabase(tableParts[0].trim());
                }
                if (tableParts[1] && tableParts[1].length > 0) {
                    setSelectedSchema(tableParts[1].trim());
                }
                if (tableParts[2] && tableParts[2].length > 0) {
                    setSelectedTable(tableParts[2].trim());
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowExternalService.InputTableName, isParentNodeScript]);

    useEffect(() => {
        if (!isParentNodeScript && selectedDatabase != "" && hasRestrictedUser) {
            getSchemas(selectedDatabase);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDatabase, hasRestrictedUser]);

    useEffect(() => {
        if (!isParentNodeScript && selectedSchema != "" && hasRestrictedUser) {
            getTables(selectedSchema);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSchema, hasRestrictedUser]);

    useEffect(() => {
        if (
            !isParentNodeScript &&
            databases &&
            !databases.some(x => x == flowExternalService.InputTableName?.split(".")[0]?.trim())
        ) {
            cleanSnowflakeDataSelected();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [databases]);

    // Manage TEST Mode service parameter
    useEffect(() => {
        if (flowServiceParameters && testSwitchParam && testLimitValueParam) {
            const testSwitchParamValue = flowServiceParameters?.find(
                x => x.ParameterId == testSwitchParam?.ParameterId
            )?.Value;

            if (testSwitchParamValue == "TEST") {
                setIsTestMode(true);

                const testLimitValueParamValue = parseInt(
                    flowServiceParameters?.find(x => x.ParameterId == testLimitValueParam?.ParameterId)?.Value ?? "0"
                );
                setTestLimitValue(testLimitValueParamValue);
            } else {
                setIsTestMode(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowServiceParameters, testSwitchParam, testLimitValueParam]);

    useEffect(() => {
        if (isS3Input) {
            setIsLoading(awsIsDownloading);
        } else if (isServerInput) {
            setIsLoading(awsIsDownloading);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [awsIsDownloading]);

    const getDatabases = () => {
        setIsLoading(true);

        fetch(`/FlowControl/AvailableSnowflakeDatabases`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { success: boolean; databases: Array<string> };
                if (response.success) {
                    setDatabases(response.databases);
                }
                setHasRestrictedUser(response.success);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                h.error("Error getting available Snowflake databases.", error);
            });
    };

    const getSchemas = database => {
        setIsLoading(true);

        fetch(`/FlowControl/AvailableSnowflakeSchemas?database=${database}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { schemas: Array<string> };
                setSchemas(response.schemas);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                h.error("Error getting available Snowflake database schemas.", error);
            });
    };

    const getTables = schema => {
        setIsLoading(true);

        fetch(`/FlowControl/AvailableSnowflakeTables?database=${selectedDatabase}&schema=${schema}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { tables: Array<string> };
                setTables(response.tables);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                h.error("Error getting available Snowflake database tables.", error);
            });
    };

    const getPreview = () => {
        setIsLoading(true);

        const tableName = isParentNodeScript
            ? flowExternalService.InputTableName
            : selectedDatabase + "." + selectedSchema + "." + selectedTable;

        fetch(`/FlowControl/SnowflakeTablePreview?tableName=${tableName}`, {
            method: "GET",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { columns: Array<string>; rows: Array<any>; quantity: number };
                setPreviewColumns(response.columns || []);
                setPreviewRows(response.rows || []);

                if (autopopulateSnowflateQuantityFeature) {
                    handleQuantity(response.quantity || 0);
                }

                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                h.error("Error getting Snowflake table preview.", error);
            });
    };

    const renderPreviewTable = () => {
        if (previewColumns.length == 0 || previewRows.length == 0) {
            return null;
        }

        return (
            <Table stickyHeader style={{ marginTop: 0 }}>
                <TableHead>
                    <TableRow>
                        {previewColumns.map((x, index) => (
                            <TableCell key={index} style={{ fontSize: 12, padding: 5 }}>
                                {x}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {previewRows.map((r, index) => (
                        <TableRow key={index}>
                            {previewColumns.map(c => (
                                <TableCell style={{ fontSize: 12, padding: 5 }} key={c + index}>
                                    {r[c]}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    };

    const handleModalFile = (_source, file, filePreview, fileQuantity, nasServerId: number = 0) => {
        handleFileName(isServerInput ? file : file.name);
        setFilePreview(filePreview);

        if (isS3Input || isServerInput) {
            handleQuantity(fileQuantity);
        }

        if (isServerInput) {
            handleNasServerId(nasServerId);
        }
    };

    const removeSelectedFile = () => {
        dispatch(
            updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                FileName: null,
                InputQuantity: 0,
            })
        );

        flowExternalServiceInputs.forEach(x => {
            dispatch(deleteFlowExternalServiceInput(x.InputId));
        });

        setFilePreview("");
        dispatch(ResetS3Data());

        dispatch(invalidateItemAndChildren(flowExternalService.FlowItemId));
    };

    const buildFullTable = (database, schema, table) => {
        if (database == "") {
            return null;
        }

        let fullName = database;
        if (schema != "") {
            fullName += "." + schema;
            if (table != "") {
                fullName += "." + table;
            }
        }
        return fullName;
    };

    const handleFileLocation = newValue => {
        setFileLocationId(newValue);
        dispatch(
            updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                FileLocationId: newValue,
            })
        );

        flowExternalServiceInputs.forEach(x => {
            dispatch(deleteFlowExternalServiceInput(x.InputId));
        });

        if (!isMultiInputFeature) {
            handleFileName("");
            setFilePreview("");
            handleQuantity(0);
        }

        if (isS3Input) {
            handleDeleteS3(false);
        }

        dispatch(invalidateItemAndChildren(flowExternalService.FlowItemId));
    };

    const handleFileName = (newValue: string) => {
        if (isMultiInputFeature) {
            // Keep Multi-input table in-sync
            const serviceInput = flowExternalServiceInputs.find(
                x => x.FlowItemId == flowExternalService.FlowItemId && x.InputNumber == 1
            );
            if (serviceInput) {
                dispatch(
                    updateMultipleAttribute("flowExternalServiceInputs", serviceInput.InputId, {
                        InputName: newValue,
                    })
                );
            } else if (isPLEX5Input || isS3Input || isServerInput) {
                dispatch(
                    newFlowExternalServiceInput(
                        flowExternalService.FlowItemId,
                        1,
                        newValue,
                        flowExternalService.InputQuantity || 0
                    )
                );
            }
        } else {
            setSelectedFile(newValue);
            dispatch(
                updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                    FileName: newValue,
                })
            );
        }

        dispatch(invalidateItemAndChildren(flowExternalService.FlowItemId));
    };

    const handleNasServerId = (nasServerId: number) => {
        if (isMultiInputFeature) {
            // Keep Multi-input table in-sync
            const serviceInput = flowExternalServiceInputs.find(
                x => x.FlowItemId == flowExternalService.FlowItemId && x.InputNumber == 1
            );
            if (serviceInput) {
                dispatch(
                    updateMultipleAttribute("flowExternalServiceInputs", serviceInput.InputId, {
                        NasServerId: nasServerId,
                    })
                );
            } else {
                dispatch(
                    newFlowExternalServiceInput(
                        flowExternalService.FlowItemId,
                        1,
                        flowExternalService.FileName,
                        flowExternalService.InputQuantity || 0,
                        nasServerId
                    )
                );
            }
        } else {
            dispatch(
                updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                    NasServerId: nasServerId,
                })
            );
        }

        dispatch(invalidateItemAndChildren(flowExternalService.FlowItemId));
    };

    const handleQuantity = (newValue: string | number) => {
        let quantity = newValue == "" ? 0 : parseInt(newValue.toString());
        if (quantity < 0) {
            quantity = 0;
        }

        dispatch(
            updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                InputQuantity: quantity,
            })
        );

        if (isMultiInputFeature) {
            // Keep Multi-input table in-sync
            const serviceInput = flowExternalServiceInputs.find(
                x => x.FlowItemId == flowExternalService.FlowItemId && x.InputNumber == 1
            );
            if (serviceInput) {
                dispatch(
                    updateMultipleAttribute("flowExternalServiceInputs", serviceInput.InputId, {
                        InputQuantity: quantity,
                    })
                );
            } else if (isPLEX5Input || isS3Input || isServerInput) {
                dispatch(
                    newFlowExternalServiceInput(
                        flowExternalService.FlowItemId,
                        1,
                        flowExternalService.FileName,
                        quantity
                    )
                );
            }
        }

        dispatch(invalidateItemAndChildren(flowExternalService.FlowItemId));
    };

    const handleInputHeaderRow = value => {
        dispatch(
            updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                InputHeaderRow: value,
            })
        );

        dispatch(invalidateItemAndChildren(flowExternalService.FlowItemId));
    };

    const handleRetainInput = value => {
        dispatch(
            updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                RetainInputFile: value,
            })
        );

        dispatch(invalidateItemAndChildren(flowExternalService.FlowItemId));
    };

    const handleDeleteS3 = value => {
        dispatch(
            updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                DeleteS3Input: value,
            })
        );

        dispatch(invalidateItemAndChildren(flowExternalService.FlowItemId));
    };

    const handleDatabase = value => {
        setSelectedDatabase(value);
        setSelectedSchema("");
        setSchemas([]);
        setSelectedTable("");
        setTables([]);
        dispatch(
            updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                InputTableColumns: null,
                InputTableName: buildFullTable(value, "", ""),
            })
        );
    };

    const handleSchema = value => {
        setSelectedSchema(value);
        setSelectedTable("");
        dispatch(
            updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                InputTableColumns: null,
                InputTableName: buildFullTable(selectedDatabase, value, ""),
            })
        );
    };

    const handleSnowflakeTableNameChange = value => {
        setSelectedTable(value);
        handleQuantity(0);

        dispatch(
            updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                InputTableColumns: null,
                InputTableName: buildFullTable(selectedDatabase, selectedSchema, value),
            })
        );
    };

    const handleSnowflakeParentTableNameChange = value => {
        setParentTableName(value);
        let inputTableName = null;
        const tableInfo = parentTables.find(x => x.TableName == value);

        if (tableInfo) {
            inputTableName = buildFullTable(tableInfo.Db, tableInfo.Schema, value);
        } else {
            const tableInfoSplitted = value.split(".");

            if (tableInfoSplitted.length == 3) {
                inputTableName = buildFullTable(tableInfoSplitted[0], tableInfoSplitted[1], tableInfoSplitted[2]);
            }
        }

        if (flowExternalService.InputTableName != inputTableName) {
            dispatch(
                updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                    InputTableColumns: null,
                    InputTableName: inputTableName,
                })
            );
        }
    };

    const handleIncludeAll = value => {
        const newColumns = [...flowExternalService.InputTableColumns];

        newColumns.forEach(col => {
            col.Include = value;
        });
        dispatch(
            updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                InputTableColumns: newColumns,
            })
        );
        setIncludeAllColumns(value);
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newColumns = [...flowExternalService.InputTableColumns];

        let updateColumns = arrayMoveImmutable(newColumns, oldIndex, newIndex);
        updateColumns.forEach((col, index) => {
            col.Order = index + 1;
        });

        dispatch(
            updateMultipleAttribute("flowExternalServices", flowExternalService.FlowExternalServiceId, {
                InputTableColumns: updateColumns,
            })
        );
    };

    return (
        <>
            <div className="flow-edit-body" style={{ marginTop: "0px" }}>
                <Paper style={{ padding: "20px" }}>
                    <FormLabel>File Location</FormLabel>
                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                        <div style={{ width: "20%" }}>
                            <Select
                                id={`external-file-location`}
                                style={{ width: "100%" }}
                                onChange={e => handleFileLocation(e.target.value)}
                                value={
                                    flowExternalService.FileLocationId <= 0
                                        ? fileLocationId <= 0
                                            ? ""
                                            : fileLocationId
                                        : flowExternalService.FileLocationId
                                }
                                disabled={isParentNodeScript}
                            >
                                {externalInputFileLocation.length == 0 ? (
                                    <small style={{ margin: "6px 16px" }}>Loading File Locations...</small>
                                ) : null}
                                {externalInputFileLocation.map(option => (
                                    <MenuItem key={option.FileLocationId} value={option.FileLocationId}>
                                        {option.FileLocationDescription}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        {isS3Input && (
                            <div style={{ marginLeft: "20px" }}>
                                <FormControlLabel
                                    checked={flowExternalService.DeleteS3Input}
                                    control={
                                        <Checkbox
                                            id="deleteS3-switch"
                                            onChange={() => handleDeleteS3(!flowExternalService.DeleteS3Input)}
                                            color="primary"
                                        />
                                    }
                                    label="Delete file from AWS after deployment"
                                    labelPlacement="end"
                                />
                            </div>
                        )}
                        {!isLoading && isSnowflakeInput && !isParentNodeScript && !hasRestrictedUser && (
                            <div style={{ marginLeft: "20px" }}>
                                <Alert severity="warning" style={{ padding: "0px 16px" }}>
                                    Company Restricted User is not configured.
                                </Alert>
                            </div>
                        )}
                    </div>
                    {flowExternalService && flowExternalService.FileLocationId > 0 && (
                        <>
                            {isSnowflakeInput ? (
                                <>
                                    {isLoading && (
                                        <div
                                            className="disabled-overlay"
                                            style={layout ? bouncingLayout : bouncingNoLayout}
                                        >
                                            <BouncingDots />
                                        </div>
                                    )}
                                    <div>
                                        {isParentNodeScript ? (
                                            <div style={{ marginTop: "20px", width: "50%" }}>
                                                <Autocomplete
                                                    freeSolo
                                                    id="flow-control-parent-table-name"
                                                    value={parentTableName}
                                                    fullWidth
                                                    options={parentTables.map(option => option.TableName)}
                                                    onInputChange={(_, value) =>
                                                        handleSnowflakeParentTableNameChange(value)
                                                    }
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            label="Table Name"
                                                            fullWidth
                                                            InputProps={{ ...params.InputProps }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <div style={{ marginTop: "20px", width: "50%" }}>
                                                    <InputLabel htmlFor="snowflake-database">Database</InputLabel>
                                                    <Select
                                                        id="snowflake-database"
                                                        style={{ width: "100%" }}
                                                        onChange={e => {
                                                            handleDatabase(e.target.value as string);
                                                        }}
                                                        value={selectedDatabase}
                                                        disabled={isLoading || !hasRestrictedUser}
                                                    >
                                                        {databases?.map((option, index) => (
                                                            <MenuItem key={index} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div style={{ marginTop: "20px", width: "50%" }}>
                                                    <InputLabel htmlFor="snowflake-schema">Schema</InputLabel>
                                                    <Select
                                                        id="snowflake-schema"
                                                        style={{ width: "100%" }}
                                                        onChange={e => {
                                                            handleSchema(e.target.value as string);
                                                        }}
                                                        value={selectedSchema}
                                                        disabled={!selectedDatabase || isLoading || !hasRestrictedUser}
                                                    >
                                                        {schemas.map((option, index) => (
                                                            <MenuItem key={index} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div style={{ marginTop: "20px", width: "50%" }}>
                                                    <InputLabel htmlFor="snowflake-table">Table Name</InputLabel>
                                                    <Select
                                                        id="snowflake-table"
                                                        style={{ width: "100%" }}
                                                        onChange={e =>
                                                            handleSnowflakeTableNameChange(e.target.value as string)
                                                        }
                                                        value={selectedTable}
                                                        disabled={!selectedSchema || isLoading || !hasRestrictedUser}
                                                    >
                                                        {tables.map((option, index) => (
                                                            <MenuItem key={index} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </>
                                        )}
                                        {!isLoading && previewRows.length > 0 && (
                                            <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                                                <div>
                                                    <FormLabel>Table Preview</FormLabel>
                                                </div>
                                                <div style={{ height: "200px", overflow: "auto" }}>
                                                    {renderPreviewTable()}
                                                </div>
                                            </div>
                                        )}
                                        {!isLoading &&
                                            ((isParentNodeScript && parentTableName != "") ||
                                                (!isParentNodeScript && selectedTable != "" && hasRestrictedUser)) && (
                                                <div style={{ marginBottom: "30px" }}>
                                                    <div className="flow-edit-header">
                                                        <FormLabel>Select Columns</FormLabel>
                                                    </div>
                                                    <div>
                                                        <Divider style={{ margin: "10px auto" }} />
                                                        <div
                                                            className="row"
                                                            style={{ display: "flex", justifyContent: "start" }}
                                                        >
                                                            <div
                                                                style={{
                                                                    marginLeft: "55px",
                                                                    marginTop: "5px",
                                                                    width: "50%",
                                                                }}
                                                            >
                                                                Column Name
                                                            </div>
                                                            <div>
                                                                <FormControlLabel
                                                                    style={{ margin: "0 0 0 5px" }}
                                                                    control={
                                                                        <IconSwitch
                                                                            id="include-columns"
                                                                            name="includeColumns"
                                                                            onChange={e =>
                                                                                handleIncludeAll(e.target.checked)
                                                                            }
                                                                            checked={includeAllColumns}
                                                                            disabled={!flowPermissions.canEdit}
                                                                        />
                                                                    }
                                                                    label={
                                                                        <Tooltip title="Columns should be included in the distributed file.">
                                                                            <div style={{ fontSize: "12px" }}>
                                                                                Include Columns&nbsp;
                                                                                <i
                                                                                    className={"material-icons"}
                                                                                    style={{ fontSize: "12px" }}
                                                                                >
                                                                                    info
                                                                                </i>
                                                                            </div>
                                                                        </Tooltip>
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <Divider style={{ margin: "10px auto" }} />
                                                        {!isLoading && flowExternalService.InputTableColumns && (
                                                            <section
                                                                id="sortable-list-container"
                                                                className="scrollyrolly sortable-list-container"
                                                            >
                                                                <SortableList
                                                                    key={flowExternalService.FlowExternalServiceId}
                                                                    externalService={flowExternalService}
                                                                    permissions={flowPermissions}
                                                                    onSortEnd={onSortEnd}
                                                                    axis="y"
                                                                    lockAxis="y"
                                                                    getContainer={() =>
                                                                        document.getElementById(
                                                                            "sortable-list-container"
                                                                        )!
                                                                    }
                                                                />
                                                            </section>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    {isMultiInputFeature ? (
                                        <div>
                                            <FlowControlInputFiles
                                                fileLocationName={
                                                    isS3Input
                                                        ? BrowseFileLocations.S3
                                                        : isServerInput
                                                        ? BrowseFileLocations.SERVER
                                                        : isPLEX5Input
                                                        ? BrowseFileLocations.PLEX5
                                                        : ""
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    marginTop: "20px",
                                                }}
                                            >
                                                <FormLabel>Input File</FormLabel>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <TextField
                                                    id="file-name"
                                                    type="text"
                                                    style={{ width: "65%" }}
                                                    value={selectedFile ? selectedFile : ""}
                                                    onChange={e => handleFileName(e.target.value)}
                                                    inputProps={
                                                        isS3Input || isServerInput
                                                            ? { readOnly: true }
                                                            : { maxLength: 44 }
                                                    }
                                                    helperText=""
                                                />
                                                {(isS3Input || isServerInput) && (
                                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                                        <Button
                                                            id="add-file"
                                                            variant="contained"
                                                            color="secondary"
                                                            className={"edit-button"}
                                                            startIcon={<i className="material-icons">add</i>}
                                                            onClick={() =>
                                                                dispatch(
                                                                    showModal("BROWSE_FILES", {
                                                                        handleModalFile,
                                                                        requestor: "flow",
                                                                        browseFileAllowed: isS3Input
                                                                            ? BrowseFileIntegrations.S3
                                                                            : BrowseFileIntegrations.SERVER,
                                                                    })
                                                                )
                                                            }
                                                            disabled={!flowPermissions.canEdit || isLoading}
                                                        >
                                                            Add File
                                                        </Button>
                                                        <Button
                                                            id="remove-file"
                                                            variant="contained"
                                                            color="secondary"
                                                            style={{ backgroundColor: "#cf4e4e" }}
                                                            className={"edit-button"}
                                                            startIcon={<i className="material-icons">remove</i>}
                                                            onClick={removeSelectedFile}
                                                            disabled={
                                                                !flowPermissions.canEdit || !selectedFile || isLoading
                                                            }
                                                        >
                                                            Remove File
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                            {(isS3Input || isServerInput) && (
                                                <div>
                                                    <div style={{ marginTop: "20px" }}>
                                                        <FormLabel>File Preview</FormLabel>
                                                        {isLoading && filePreview == "" ? (
                                                            <div
                                                                style={{
                                                                    marginTop: "20px",
                                                                    width: "100%",
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                <BouncingDots />
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {awsError && (
                                                                    <Alert
                                                                        severity="warning"
                                                                        style={{ marginBottom: "10px" }}
                                                                    >
                                                                        {awsError}
                                                                    </Alert>
                                                                )}
                                                                <TextareaAutosize
                                                                    className="display-preview"
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "130px",
                                                                        resize: "none",
                                                                        overflow: "auto",
                                                                    }}
                                                                    value={filePreview}
                                                                    disabled
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                            {(isSnowflakeInput || !isMultiInputFeature) && (
                                <div style={{ margin: "20px 0px 20px 0px" }}>
                                    <FormLabel>Input Quantity {isTestMode ? <i>(TEST Mode)</i> : null}</FormLabel>
                                    <div>
                                        <NumericalTextField
                                            value={
                                                isTestMode
                                                    ? testLimitValue
                                                    : flowExternalService && flowExternalService.InputQuantity
                                                    ? flowExternalService.InputQuantity
                                                    : ""
                                            }
                                            onBlur={e => handleQuantity(e.target.value)}
                                            onFocus={handleFocus}
                                            style={{ width: "20%", resize: "none", fontSize: "15px" }}
                                            id="input-qty"
                                            disabled={
                                                isTestMode || (isSnowflakeInput && autopopulateSnowflateQuantityFeature)
                                            }
                                            helperText="File input quantity"
                                        />
                                    </div>
                                </div>
                            )}
                            {isInputRelativePosition && !isSnowflakeInput && (
                                <div>
                                    <FormControlLabel
                                        checked={flowExternalService.InputHeaderRow}
                                        control={
                                            <Checkbox
                                                id="hasHeaderRow-switch"
                                                onChange={() =>
                                                    handleInputHeaderRow(!flowExternalService.InputHeaderRow)
                                                }
                                                color="primary"
                                            />
                                        }
                                        label="Has a Header Row"
                                        labelPlacement="end"
                                    />
                                </div>
                            )}
                            {canRetainInput && (
                                <div>
                                    <FormControlLabel
                                        checked={flowExternalService.RetainInputFile}
                                        control={
                                            <Checkbox
                                                id="retainInput-switch"
                                                onChange={() => handleRetainInput(!flowExternalService.RetainInputFile)}
                                                color="primary"
                                            />
                                        }
                                        label="Do Not Delete Input File (skips file/table copy on reruns)"
                                        labelPlacement="end"
                                    />
                                </div>
                            )}
                        </>
                    )}
                </Paper>
                <FlowControlLayoutPicker layoutType={LayoutTypes.Input} isTable={isSnowflakeInput} />
            </div>
        </>
    );
};

export default FlowControlInput;
