import { IAccountManageStore } from "../types/stores/accountManage";

export const UPDATING_PASSWORD = "UPDATING_PASSWORD";
export const UPDATING_PASSWORD_SUCCESS = "UPDATING_PASSWORD_SUCCESS";

type UpdatingPasswordAction = {
    type: typeof UPDATING_PASSWORD;
    isSaving: boolean;
};

type UpdatingPasswordSuccessAction = {
    type: typeof UPDATING_PASSWORD_SUCCESS;
    errors: Array<string>;
};

type ActionType = UpdatingPasswordAction | UpdatingPasswordSuccessAction;

function accountManage(
    state: IAccountManageStore = {
        passwordIsSaving: false,
        passwordErrors: [],
    },
    action: ActionType
): IAccountManageStore {
    switch (action.type) {
        case UPDATING_PASSWORD:
            return Object.assign({}, state, {
                passwordIsSaving: action.isSaving,
            });
        case UPDATING_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                passwordIsSaving: false,
                passwordErrors: action.errors,
            });
        default:
            return state;
    }
}

export default accountManage;
