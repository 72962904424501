import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowDataLoadColumn, FlowItem } from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_DATA_LOAD_COLUMN", "FlowDataLoadColumnId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowDataLoadColumnsArray = createSelector(
    state => state.flowDataLoadColumns.byId,
    (flowDataLoadColumnsById: {| [number]: FlowDataLoadColumn |}): Array<FlowDataLoadColumn> => {
        const r: Array<FlowDataLoadColumn> = Object.values(flowDataLoadColumnsById);
        return r;
    }
);

export type FlowDataLoadColumnsByItemId = {
    [number]: Array<FlowDataLoadColumn>,
};
// eslint-disable-line
export const getFlowDataLoadColumnsByFlowItemId = createSelector(
    state => getFlowDataLoadColumnsArray(state),
    (flowDataLoadColumns: Array<FlowDataLoadColumn>): FlowDataLoadColumnsByItemId =>
        flowDataLoadColumns.reduce((acc, row) => {
            if (!acc[row.FlowItemId]) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowDataLoadColumnsForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowDataLoadColumnsByFlowItemId(state),
    (
        selectedFlow: number,
        flowItems: Array<FlowItem>,
        flowDataLoadColumnsByItemId: FlowDataLoadColumnsByItemId
    ): Array<FlowDataLoadColumn> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        let result = [];
        for (const itemId of itemIds) {
            const script = flowDataLoadColumnsByItemId[itemId];
            if (script != null) {
                result = result.concat(script);
            }
        }
        return result;
    }
);

export const getFlowDataLoadColumnsByFlowItemIdFromProps = createSelector(
    state => getFlowDataLoadColumnsByFlowItemId(state),
    (state, props) => props.flowItemId,
    (dataLoadColumnsByFlowItemId: FlowDataLoadColumnsByItemId, flowItemId: number): Array<FlowDataLoadColumn> =>
        dataLoadColumnsByFlowItemId[flowItemId]
);

export const getFlowDataLoadColumnsForSelectedFlowItem = createSelector(
    state => getFlowDataLoadColumnsByFlowItemId(state),
    state => state.selected.flowItem,
    (dataLoadColumnsByFlowItemId: FlowDataLoadColumnsByItemId, flowItemId: number): Array<FlowDataLoadColumn> =>
        dataLoadColumnsByFlowItemId[flowItemId]
);
