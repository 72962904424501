import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow, getFlowItemsArray } from "./flowItems";
import type {
    FlowItem,
    FlowSplit,
    FlowEmpty,
    FlowErrorsByItemId,
    FlowSplitAndEmpty,
    FlowRelation,
} from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_SPLIT", "FlowSplitId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { getFlowEmptiesArray } from "./flowEmpties";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export type FlowSplitsByItemId = {
    [number]: Array<FlowSplit>,
};

export type FlowSplitsAndFlowEmpties = {
    [number]: Array<FlowSplitAndEmpty>,
};

export const getFlowSplitsArray = createSelector(
    state => state.flowSplits.byId,
    (flowSplitsById: {| [number]: FlowSplit |}): Array<FlowSplit> => {
        const r: Array<FlowSplit> = Object.values(flowSplitsById);
        return r;
    }
);

export const getFlowSplitsByFlowItemId = createSelector(
    state => getFlowSplitsArray(state),
    (flowSplits: Array<FlowSplit>): FlowSplitsByItemId =>
        flowSplits.reduce((acc, row) => {
            if (acc[row.FlowItemId] == null) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowSplitsAndEmptiesByFlowItemId = createSelector(
    state => getFlowSplitsArray(state),
    state => getFlowEmptiesArray(state),
    (flowSplits: Array<FlowSplit>, flowEmpties: Array<FlowEmpty>): FlowSplitsAndFlowEmpties =>
        flowSplits.reduce((acc, row) => {
            if (acc[row.FlowItemId] == null) {
                acc[row.FlowItemId] = [];
            }

            let emptyItem = null;
            const emptyItems = flowEmpties.filter(x => x.FlowItemId == row.ChildFlowItemId);
            if (emptyItems.length > 0) {
                emptyItem = emptyItems[0];
            }

            const newRow = Object.assign({}, row, { FlowEmpty: emptyItem });
            acc[row.FlowItemId].push(newRow);
            return acc;
        }, {})
);

export const getFlowSplitsForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowSplitsByFlowItemId(state),
    (selectedFlow: number, flowItems: Array<FlowItem>, flowSplitsByItemId: FlowSplitsByItemId): Array<FlowSplit> => {
        let result = [];
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const splits = flowSplitsByItemId[itemId];
            if (splits != null) {
                result = result.concat(splits);
            }
        }
        return result;
    }
);

const flowSplitsAndEmpties = (
    flowItems: Array<FlowItem>,
    flowSplitEmptiesByItemId: FlowSplitsAndFlowEmpties
): Array<FlowSplitAndEmpty> => {
    let result = [];
    const itemIds = flowItems.map(fi => fi.FlowItemId);
    for (const itemId of itemIds) {
        const splits = flowSplitEmptiesByItemId[itemId];
        if (splits != null) {
            result = result.concat(splits);
        }
    }
    return result;
};

export const getFlowSplitsAndEmptiesForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowSplitsAndEmptiesByFlowItemId(state),
    flowSplitsAndEmpties
);

export const getFlowSplitsAndEmptiesForAllFlows = createSelector(
    state => getFlowItemsArray(state),
    state => getFlowSplitsAndEmptiesByFlowItemId(state),
    flowSplitsAndEmpties
);

import { getFlowRelationsForSelectedFlow, getFlowRelationsForAllFlows } from "./flowRelations";

const splitsToErrorsById = (
    flowSplitEmpties: Array<FlowSplitAndEmpty>,
    flowItems: Array<FlowItem>,
    flowRelations: Array<FlowRelation>
): FlowErrorsByItemId => {
    const errors = {};
    const items = flowItems.filter(fi => fi.FlowItemType.toLowerCase() == "split");
    const itemIds = items.map(fi => fi.FlowItemId);
    for (const itemId of itemIds) {
        const theseSplits = flowSplitEmpties.filter(x => x.FlowItemId == itemId);
        const flowItem = items.find(x => x.FlowItemId == itemId);
        errors[itemId] = validateFlowSplits(flowItem, theseSplits, flowRelations);
    }
    return errors;
};

export const getSplitErrorsForSelectedFlow = createSelector(
    state => getFlowSplitsAndEmptiesForSelectedFlow(state),
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    splitsToErrorsById
);

export const getSplitErrorsForAllFlows = createSelector(
    state => getFlowSplitsAndEmptiesForAllFlows(state),
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowRelationsForAllFlows(state),
    splitsToErrorsById
);

//////////////////// HELPERS //////////////////////////////

// ***** Must pass an array of all FlowSplit rows belonging to a particular itemId. *****
// This way we can tell if percentages don't add up to 100%, etc.
const validateFlowSplits = (
    flowItem: FlowItem,
    flowSplits: Array<FlowSplitAndEmpty>,
    flowRelations: Array<FlowRelation>
): Array<string> => {
    const errors = [];
    let qtyMissing = false;
    if (flowSplits.length == 0) {
        errors.push("Must have at least one split assigned.");
        return errors; // Return early, can't find other errors
    }

    let needsParent = false;
    flowSplits.forEach(x => {
        const splitRelations = flowRelations.filter(z => z.ParentFlowItemId == 0 && z.ChildFlowItemId == x.FlowItemId);
        const allRelations = flowRelations.filter(z => z.ChildFlowItemId == x.FlowItemId);
        if ((splitRelations.length > 0 || allRelations.length == 0) && !needsParent) {
            errors.push("Split items must have a parent item assigned.");
            needsParent = true;
        }

        if (!qtyMissing && !x.IsBalance) {
            // Relative
            if (x.AbsoluteOrRelative == "relative" && x.RelativePercentRows == 0) {
                qtyMissing = true;
                errors.push("All Split items must have a percent assigned.");
            }
            // Absolute
            if (x.AbsoluteOrRelative == "absolute" && x.AbsoluteNumRows == 0) {
                qtyMissing = true;
                errors.push("All Split items must have a quantity assigned.");
            }
        }
    });

    if (flowItem && !flowItem.IsRandom && !flowItem.SortByFieldsJSON) {
        errors.push("When sorting by fields, a field must be added to a Split item.");
    } else if (flowItem && !flowItem.IsRandom && flowItem.SortByFieldsJSON && flowItem.SortByFieldsJSON.length == 0) {
        errors.push("When sorting by fields, a field must be added to a Split item.");
    } else if (
        flowItem &&
        !flowItem.IsRandom &&
        flowItem.SortByFieldsJSON &&
        (flowItem.SortByFieldsJSON == "null" || flowItem.SortByFieldsJSON == "[]")
    ) {
        errors.push("When sorting by fields, a field must be added to a Split item.");
    }

    // At least one flowSplit exists
    if (flowSplits[0].AbsoluteOrRelative == "relative") {
        // Assuming:  If one is relative, then they're all relative.
        // Look for relative percents summing above 100%.
        const sumPercent: any = flowSplits
            .map(x => x.RelativePercentRows)
            .reduce((acc, x) => (parseFloat(acc) + parseFloat(x)).toFixed(2), 0);
        if (sumPercent > 100) {
            errors.push("Amounts may not exceed 100%");
        }
    }

    const flowEmpties: Array<FlowEmpty> = flowSplits.map(x => x.FlowEmpty).filter(x => x != null);
    if (flowEmpties.length != flowSplits.length) {
        errors.push("Split Error 928"); // Empties are missing
    }

    const uniqueCriteria = [...new Set(flowEmpties.map(x => x.FlowEmptyCriteria))];

    if (flowEmpties.filter(x => x.FlowEmptyCriteria == "" || x.FlowEmptyCriteria == null).length > 0) {
        errors.push("Some splits are missing labels.");
    } else if (uniqueCriteria.length != flowEmpties.length) {
        errors.push("Some splits have duplicate labels.");
    }
    return errors;
};
