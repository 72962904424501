import { IGetDonorStore, MakeAWishActions } from "../types/stores/makeAWish";
import clone from "../helpers/clone";
const defaultState: IGetDonorStore = {
    donorsData: [],
    clientsData: [],
    editDonorsData: [],
    editDonorsHistoryData: {},
    addressTypes: [],
    memberCodes: [],
    originCodes: [],
    serviceCenters: [],
    acknowledgmentFlags: [],
    formCodes: [],
    appealCodes: [],
    userPermissions: [],
    permissions: undefined,
    giftSummary: "",
    addressSummary: "",
    auditRecordsCount: 0,
    auditRecords: [],
    auditRecord: {},
    batchesData: [],
    batchData: {},
    batchDonorData: {},
    deleteBatch: false,
    deleteAudit: false,
    promotionData: {},
    donationData: {},
    linkedDonationData: {},
    unusedInfoCodes: [],
    usedInfoCodes: [],
    unusedCampaignCodes: [],
    usedCampaignCodes: [],
    unusedFinanceCodes: [],
    usedFinanceCodes: [],
    otherAddresses: [],
    mailOnlySuppress: "",
    newBatchNumber: "",
    searchMessage: "",
    isLoading: false,
    isCodesLoading: false,
    isReportsLoading: false,
    reports: [],
    isAllGiftsLoading: false,
    allGifts: [],
    tributeTypes: [],
    giftTypes: [],
    batchCategories: [],
    isDonationsLoading: false,
    isPromotionsLoading: false,
    serviceCenterZip: undefined,
};

export const GET_DONOR = "GET_DONOR";
export const SET_SEARCH_MESSAGE = "SET_SEARCH_MESSAGE";
export const RESET_DONOR = "RESET_DONOR";
export const SET_DONOR_IS_LOADING = "SET_DONOR_IS_LOADING";
export const GET_EDIT_DONOR = "GET_EDIT_DONOR";
export const UPDATE_COMMENTS = "UPDATE_COMMENTS";
export const GET_EDIT_DONOR_HISTORY = "GET_EDIT_DONOR_HISTORY";
export const UPDATED_EDIT_DONOR = "UPDATED_EDIT_DONOR";
export const GET_ADDRESS_TYPE = "GET_ADDRESS_TYPE";
export const GET_MEMBER_CODE = "GET_MEMBER_CODE";
export const GET_ORIGIN_CODE = "GET_ORIGIN_CODE";
export const GET_SERVICE_CENTER = "GET_SERVICE_CENTER";
export const GET_ACKNOWLEDGMENT_FLAG = "GET_ACKNOWLEDGMENT_FLAG";
export const GET_FORM_CODE = "GET_FORM_CODE";
export const GET_APPEAL_CODE = "GET_APPEAL_CODE";
export const GET_GIFT_SUMMARY = "GET_GIFT_SUMMARY";
export const GET_ADDRESS_SUMMARY = "GET_ADDRESS_SUMMARY";
export const GET_AUDIT_RECORDS_COUNT = "GET_AUDIT_RECORDS_COUNT";
export const GET_AUDIT_RECORDS = "GET_AUDIT_RECORDS";
export const GET_AUDIT_RECORD = "GET_AUDIT_RECORD";
export const GET_USER_BATCHES = "GET_USER_BATCHES";
export const GET_CURRENT_BATCH = "GET_CURRENT_BATCH";
export const ADD_AUDIT_RECORD = "ADD_AUDIT_RECORD";
export const UPDATE_AUDIT_RECORD = "UPDATE_AUDIT_RECORD";
export const GET_BATCH_DONOR = "GET_BATCH_DONOR";
export const DELETE_BATCH_RECORD = "DELETE_BATCH_RECORD";
export const DELETE_AUDIT_RECORD = "DELETE_AUDIT_RECORD";
export const GET_USED_INFO_CODES = "GET_USED_INFO_CODES";
export const GET_PROMOTION_DATA = "GET_PROMOTION_DATA";
export const GET_DONATION_DATA = "GET_DONATION_DATA";
export const GET_LINKED_DONATION_DATA = "GET_LINKED_DONATION_DATA";
export const GET_UNUSED_INFO_CODES = "GET_UNUSED_INFO_CODES";
export const GET_USED_CAMPAIGN_CODES = "GET_USED_CAMPAIGN_CODES";
export const GET_UNUSED_CAMPAIGN_CODES = "GET_UNUSED_CAMPAIGN_CODES";
export const GET_USED_FINANCE_CODES = "GET_USED_FINANCE_CODES";
export const GET_UNUSED_FINANCE_CODES = "GET_UNUSED_FINANCE_CODES";
export const SET_CODES_LOADING = "SET_CODES_LOADING";
export const SET_PROMOTIONS_LOADING = "SET_PROMOTIONS_LOADING";
export const SET_DONATIONS_LOADING = "SET_DONATIONS_LOADING";
export const DELETE_DONATION_RECORD = "DELETE_DONATION_RECORD";
export const SET_MAIL_ONLY_SUPPRESS = "SET_MAIL_ONLY_SUPPRESS";
export const GET_OTHER_ADDRESSES = "GET_OTHER_ADDRESSES";
export const ADD_HEADER_RECORD = "ADD_HEADER_RECORD";
export const UPDATE_HEADER_RECORD = "UPDATE_HEADER_RECORD";
export const SET_REPORTS_LOADING = "SET_REPORTS_LOADING";
export const SET_REPORTS = "SET_REPORTS";
export const SET_USER_PERMISSION = "SET_USER_PERMISSION";
export const SET_ALL_USER_PERMISSIONS = "SET_ALL_USER_PERMISSIONS";
export const SET_ALL_GIFTS_LOADING = "SET_ALL_GIFTS_LOADING";
export const SET_ALL_GIFTS = "SET_ALL_GIFTS";
export const UPDATED_AUDITABLE_BATCH = "UPDATED_AUDITABLE_BATCH";
export const GET_TRIBUTE_TYPE = "GET_TRIBUTE_TYPE";
export const GET_GIFT_TYPE = "GET_GIFT_TYPE";
export const GET_BATCH_CATEGORIES = "GET_BATCH_CATEGORIES";
export const SET_SERVICE_CENTER_ZIP = "SET_SERVICE_CENTER_ZIP";

function makeAWishData(state = defaultState, action: MakeAWishActions): IGetDonorStore {
    switch (action.type) {
        case GET_DONOR:
            return Object.assign({}, state, {
                clientsData: action.donorsData,
            });
        case SET_SEARCH_MESSAGE:
            return Object.assign({}, state, {
                searchMessage: action.searchMessage,
            });
        case RESET_DONOR:
            return Object.assign({}, state, {
                clientsData: [],
            });
        case SET_DONOR_IS_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case GET_EDIT_DONOR:
            return Object.assign({}, state, {
                editDonorsData: action.editDonorsData,
            });
        case GET_ADDRESS_TYPE:
            return Object.assign({}, state, {
                addressTypes: action.addressType,
            });
        case GET_MEMBER_CODE:
            return Object.assign({}, state, {
                memberCodes: action.memberCode,
            });
        case UPDATE_COMMENTS: {
            //TODO doesnt seem to be updating ui
            const newState = clone(state);
            newState.editDonorsHistoryData.Comments = action.comments;
            return Object.assign({}, state, {
                editDonorsHistoryData: newState.editDonorsHistoryData,
            });
        }
        case GET_EDIT_DONOR_HISTORY:
            return Object.assign({}, state, {
                editDonorsHistoryData: action.editDonorsHistoryData,
            });
        case GET_ORIGIN_CODE:
            return Object.assign({}, state, {
                originCodes: action.originCode,
            });
        case GET_SERVICE_CENTER:
            return Object.assign({}, state, {
                serviceCenters: action.serviceCenter,
            });
        case GET_ACKNOWLEDGMENT_FLAG:
            return Object.assign({}, state, {
                acknowledgmentFlags: action.acknowledgmentFlags,
            });
        case GET_FORM_CODE:
            return Object.assign({}, state, {
                formCodes: action.formCodes,
            });
        case GET_APPEAL_CODE:
            return Object.assign({}, state, {
                appealCodes: action.appealCodes,
            });
        case GET_GIFT_SUMMARY:
            return Object.assign({}, state, {
                giftSummary: action.giftSummary,
            });
        case GET_ADDRESS_SUMMARY:
            return Object.assign({}, state, {
                addressSummary: action.addressSummary,
            });
        case GET_AUDIT_RECORDS_COUNT:
            return Object.assign({}, state, {
                auditRecordsCount: action.auditRecordsCount,
            });
        case GET_AUDIT_RECORDS:
            return Object.assign({}, state, {
                auditRecords: action.auditRecords,
            });
        case GET_AUDIT_RECORD:
            return Object.assign({}, state, {
                auditRecord: action.auditRecord,
            });
        case GET_USER_BATCHES:
            return Object.assign({}, state, {
                batchesData: action.batchesData,
            });
        case GET_CURRENT_BATCH:
            return Object.assign({}, state, {
                batchData: action.batchData,
            });
        case GET_BATCH_DONOR:
            return Object.assign({}, state, {
                batchDonorData: action.batchDonorData,
            });
        case DELETE_BATCH_RECORD:
            return Object.assign({}, state, {
                deleteBatch: action.deleteBatch,
            });
        case DELETE_AUDIT_RECORD:
            return Object.assign({}, state, {
                deleteAudit: action.deleteAudit,
            });
        case SET_CODES_LOADING:
            return Object.assign({}, state, {
                isCodesLoading: action.isCodesLoading,
            });
        case SET_PROMOTIONS_LOADING:
            return Object.assign({}, state, {
                isPromotionsLoading: action.isPromotionsLoading,
            });
        case SET_DONATIONS_LOADING:
            return Object.assign({}, state, {
                isDonationsLoading: action.isDonationsLoading,
            });
        case GET_USED_INFO_CODES:
            return Object.assign({}, state, {
                usedInfoCodes: action.usedInfoCodes,
            });
        case GET_PROMOTION_DATA:
            return Object.assign({}, state, {
                promotionData: action.promotionData,
            });
        case GET_DONATION_DATA:
            return Object.assign({}, state, {
                donationData: action.donationData,
            });
        case GET_LINKED_DONATION_DATA:
            return Object.assign({}, state, {
                linkedDonationData: action.linkedDonationData,
            });
        case GET_UNUSED_INFO_CODES:
            return Object.assign({}, state, {
                unusedInfoCodes: action.unusedInfoCodes,
            });
        case GET_USED_CAMPAIGN_CODES:
            return Object.assign({}, state, {
                usedCampaignCodes: action.usedCampaignCodes,
            });
        case GET_UNUSED_CAMPAIGN_CODES:
            return Object.assign({}, state, {
                unusedCampaignCodes: action.unusedCampaignCodes,
            });
        case SET_MAIL_ONLY_SUPPRESS:
            return Object.assign({}, state, {
                mailOnlySuppress: action.mailOnlySuppress,
            });
        case GET_USED_FINANCE_CODES:
            return Object.assign({}, state, {
                usedFinanceCodes: action.usedFinanceCodes,
            });
        case GET_UNUSED_FINANCE_CODES:
            return Object.assign({}, state, {
                unusedFinanceCodes: action.unusedFinanceCodes,
            });
        case GET_OTHER_ADDRESSES:
            return Object.assign({}, state, {
                otherAddresses: action.otherAddresses,
            });
        case ADD_HEADER_RECORD:
            return Object.assign({}, state, {
                newBatchNumber: action.newBatchNumber,
            });
        case UPDATE_HEADER_RECORD:
            return Object.assign({}, state, {});
        case SET_REPORTS_LOADING:
            return Object.assign({}, state, {
                isReportsLoading: action.isReportsLoading,
            });
        case SET_REPORTS:
            return Object.assign({}, state, {
                reports: action.reports,
            });
        case SET_USER_PERMISSION:
            return Object.assign({}, state, {
                permissions: action.permission,
            });
        case SET_ALL_USER_PERMISSIONS:
            return Object.assign({}, state, {
                userPermissions: action.userPermissions,
            });
        case SET_ALL_GIFTS_LOADING:
            return Object.assign({}, state, {
                isAllGiftsLoading: action.isAllGiftsLoading,
            });
        case SET_ALL_GIFTS:
            return Object.assign({}, state, {
                allGifts: action.allGifts,
            });
        case UPDATED_AUDITABLE_BATCH:
            return Object.assign({}, state, {});
        case GET_TRIBUTE_TYPE:
            return Object.assign({}, state, {
                tributeTypes: action.tributeType,
            });
        case GET_GIFT_TYPE:
            return Object.assign({}, state, {
                giftTypes: action.giftType,
            });
        case GET_BATCH_CATEGORIES:
            return Object.assign({}, state, {
                batchCategories: action.batchCategories,
            });
        case SET_SERVICE_CENTER_ZIP:
            return Object.assign({}, state, {
                serviceCenterZip: action.serviceCenterZip,
            });
        default:
            return state;
    }
}
export default makeAWishData;
