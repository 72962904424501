import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Paper,
    FormLabel,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    Icon,
    Tooltip,
    IconButton,
} from "@material-ui/core";
import TextFieldForTable from "../material-components/Misc/TextFieldForTable";
import CircularLoading from "../loading/CircularLoading";
import MaterialTable, { Column } from "@material-table/core";
import NumericalTextField from "../material-components/Misc/NumericalTextField";

// actions
import { setHasUnsavedLayoutChanges } from "../../actions/flowActions";
import { requestExternalServiceLayoutData, requestAddExternalServiceLayout } from "../../actions/flowControlActions";
import { showModal } from "../../actions/actionCreators";

// reducers
import { getSelectedFlowPermissions } from "../../reducers/flowItems";
import {
    getFlowExternalServiceForSelectedFlowItem,
    getFlowItemExternalServiceDefeaultFields,
    getClientFieldAliasForSelectedFlowItem,
} from "../../reducers/flowExternalServices";
import { getFlowExternalServiceParametersForSelectedFlowItem } from "../../reducers/flowExternalServiceParameters";
import { getFlowExternalServiceHardcodesForSelectedFlowItem } from "../../reducers/flowExternalServiceHardcodes";

// types
import { IAppState } from "../../types/stores/index";
import { IExternalServiceLayout, IExternalServiceLayoutField } from "../../types/stores/externalServiceLayoutData";
import { IEnrichmentField } from "../../types/stores/umbrellaData";
import { getServiceFieldData, getItemParameterValue } from "../../helpers/flowControlHelper";
import { ISaveExternalServiceLayoutData } from "../../types/stores/flowControlData";
import { FlowExternalServiceHardcode } from "../../types/stores/flowTypes";
import { qualifiers } from "../../helpers/constants";

export enum LayoutTypes {
    Input = 1,
    Output = 2,
}

// layout empty value
export const defaultLayout: IExternalServiceLayout = {
    LayoutId: 0,
    CompanyId: 0,
    LayoutType: LayoutTypes.Input,
    RelativePositions: true,
    Delimiter: null,
    RecordSize: 0,
    LayoutName: "",
    Qualifier: "",
};

interface IProps {
    layoutType?: number;
    flowExternalServiceId?: number;
    onCancel?: () => void;
    isTable?: boolean;
}
const LayoutBuilder: React.FC<IProps> = ({ layoutType, flowExternalServiceId, onCancel, isTable }: IProps) => {
    const dispatch = useDispatch();

    // State
    const [localLayout, setLocalLayout] = useState({
        ...defaultLayout,
        LayoutType: layoutType ? layoutType : LayoutTypes.Input,
    });
    const [layoutFields, setLayoutFields] = useState<Array<IExternalServiceLayoutField>>([]);
    const [addFieldType, setAddFieldType] = useState("standard");
    const [selectedServiceField, setSelectedServiceField] = useState(0);
    const [selectedCustomField, setSelectedCustomField] = useState(-1);
    const [addFieldName, setAddFieldName] = useState("");
    const [customFieldName, setCustomFieldName] = useState("");
    const [addFieldAlias, setAddFieldAlias] = useState("");
    const [addPosition, setAddPosition] = useState("");
    const [addLength, setAddLength] = useState("");
    const [addEncryption, setAddEncryption] = useState("NONE");
    const [fieldErrors, setFieldErrors] = useState<Array<string>>([]);
    const [layoutErrors, setLayoutErrors] = useState<Array<string>>([]);
    const [currentServiceFields, setCurrentServiceFields] = useState<Array<IEnrichmentField>>([]);
    const [localDelimiter, setLocalDelimiter] = useState("");
    const [customDelimiter, setCustomDelimiter] = useState("");
    const [isfieldNameEmpty, setIsfieldNameEmpty] = useState(false);
    const [addHardcodes, setAddHardcodes] = useState("false");
    const [hardcodePlacement, setHardcodePlacement] = useState("");
    const [selectedQualifier, setSelectedQualifier] = useState("");

    // Redux
    const companyId = useSelector((state: IAppState) => state.session.companyId!);
    const layouts = useSelector((state: IAppState) => state.externalServiceLayoutData.externalServiceLayouts);
    const serviceFields = useSelector((state: IAppState) => state.externalServiceLayoutData.externalServiceFields);
    const layoutIsSaving = useSelector((state: IAppState) => state.externalServiceLayoutData.layoutIsSaving);
    const layoutIsSavingAsNew = useSelector((state: IAppState) => state.externalServiceLayoutData.layoutIsSavingAsNew);
    const selectedLayout = useSelector((state: IAppState) => state.externalServiceLayoutData.selectedLayout);
    const allLayoutFields = useSelector(
        (state: IAppState) => state.externalServiceLayoutData.externalServiceLayoutFields
    );
    const flowPermissions = useSelector((state: IAppState) => getSelectedFlowPermissions(state));
    const awsIsDownloading = useSelector((state: IAppState) => state.aws.isDownloading);
    const importedLayoutFields = useSelector((state: IAppState) => state.externalServiceLayoutData.importedFields);
    const importedLayoutIsFixed = useSelector((state: IAppState) => state.externalServiceLayoutData.importedIsFixed);
    const flowExternalService = useSelector((state: IAppState) => getFlowExternalServiceForSelectedFlowItem(state));
    const flowExternalServiceParameters = useSelector((state: IAppState) =>
        getFlowExternalServiceParametersForSelectedFlowItem(state)
    );
    const externalServiceParameters = useSelector(
        (state: IAppState) => state.flowControlData.externalServiceParameters
    );
    const currentFieldsUE = useSelector((state: IAppState) => state.umbrellaData.currEnrichmentFields || []);
    const selectedFieldsUE = useSelector((state: IAppState) => state.umbrellaData.selectedFieldsItem || []);
    const fileDelimiters = useSelector((state: IAppState) => state.session.fileDelimiters);
    const listExternalParameters = useSelector((state: IAppState) => state.flowControlData.externalParameters);
    const externalServices = useSelector((state: IAppState) => state.flowControlData.externalServices);
    const flowExternalServiceHardcodes = useSelector<IAppState, Array<FlowExternalServiceHardcode>>(state =>
        getFlowExternalServiceHardcodesForSelectedFlowItem(state)
    );
    const itemServiceDefaultFields = useSelector((state: IAppState) => getFlowItemExternalServiceDefeaultFields(state));
    const itemClientFieldAlias = useSelector((state: IAppState) => getClientFieldAliasForSelectedFlowItem(state));
    const layout = layouts.find(l => l.LayoutId == selectedLayout);

    //getting Internal Matchback id
    const idInternalMatchback = listExternalParameters.find(
        parameter => parameter.ParameterName == "matchback_type"
    )?.ParameterId;

    //getting Internal Table Name Length id
    const idTableNameLength = listExternalParameters.find(
        parameter => parameter.ParameterName == "tableCodeOverride"
    )?.ParameterId;

    //getting Internal Matchback chechbox value
    const internalMatchback = flowExternalServiceParameters.filter(
        parameter => parameter.ParameterId == idInternalMatchback
    )[0];

    //getting flow extenal service parameters for Table Name Length
    const flowExternalServiceParameterTableName = flowExternalServiceParameters.filter(
        parameter => parameter.ParameterId == idTableNameLength
    );

    //getting Internal Matchback value
    const tableNameLength = flowExternalServiceParameterTableName[0];

    //getting Field Name Type value
    const enabledFeatures = useSelector((state: IAppState) => state.session.enabledFeatures || []);
    const altUEFieldName =
        enabledFeatures.includes("flow-control-field-name-type") &&
        getItemParameterValue(listExternalParameters, flowExternalServiceParameters, "output_field_names") == "true";

    // Features
    const fcQualifiersFeature = enabledFeatures.includes("flow-control-qualifiers");

    useEffect(() => {
        if (!layouts || !serviceFields) {
            dispatch(requestExternalServiceLayoutData(companyId));
        }
    }, [dispatch, layouts, serviceFields, companyId]);

    useEffect(() => {
        if (layout) {
            setLocalLayout({ ...layout });
            const layoutFields = [...allLayoutFields.filter(f => f.LayoutId == layout.LayoutId)];
            setFinalLayoutFields(layoutFields);
        } else {
            setLocalLayout({
                ...defaultLayout,
                LayoutType: typeof layoutType != "undefined" && layoutType != 0 ? layoutType : LayoutTypes.Input,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layout, allLayoutFields, layoutType]);

    useEffect(() => {
        if (importedLayoutFields && importedLayoutFields.length > 0) {
            setLocalLayout({
                ...defaultLayout,
                LayoutType: typeof layoutType != "undefined" && layoutType != 0 ? layoutType : LayoutTypes.Input,
                RelativePositions: !importedLayoutIsFixed,
            });
            const importFields = importedLayoutFields.map(x => x.Field);
            setFinalLayoutFields(importFields);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importedLayoutFields, layoutType, importedLayoutIsFixed]);

    // Set LocalDelimiter and CustomDelimiter
    useEffect(() => {
        if (localLayout.RelativePositions && localLayout.Delimiter != null && localLayout.Delimiter.length > 0) {
            if (fileDelimiters.filter(x => x.Delimiter == localLayout.Delimiter).length > 0) {
                setLocalDelimiter(localLayout.Delimiter);
                setCustomDelimiter("");
            } else {
                setLocalDelimiter("custom");
                setCustomDelimiter(localLayout.Delimiter);
            }
        } else {
            setLocalDelimiter(isTable ? "," : "");
        }
    }, [localLayout, fileDelimiters, isTable]);

    // Set SelectedQualifier
    useEffect(() => {
        if (localLayout.RelativePositions) {
            setSelectedQualifier(layout ? layout.Qualifier : localLayout.Qualifier ?? "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localLayout.RelativePositions]);

    const loadAllUEFields = useCallback(
        (fields: Array<IEnrichmentField>) => {
            const serviceFields = getServiceFieldData(
                externalServiceParameters,
                flowExternalServiceParameters,
                fields,
                [],
                externalServices,
                tableNameLength?.Value
            );
            setCurrentServiceFields(serviceFields);
        },
        [externalServiceParameters, flowExternalServiceParameters, externalServices, tableNameLength?.Value]
    );

    useEffect(() => {
        if (layoutType == 2 && currentFieldsUE.length > 0) {
            loadAllUEFields(currentFieldsUE);
        }
    }, [loadAllUEFields, layoutType, currentFieldsUE]);

    const addServiceFields = () => {
        let position = 0;
        let length = 50;
        let fields = [...layoutFields];
        const fieldsCurrentLayout = [...allLayoutFields.filter(f => f.LayoutId == layout?.LayoutId)];
        if (layoutFields?.length === 0 && fieldsCurrentLayout?.length > 0) {
            const sortedFields = getSortedFields(fieldsCurrentLayout);
            fields = [...sortedFields.map(x => ({ ...x, id: x.LayoutFieldId }))]; //all positions
            const pos = fields.map(x => x.FieldPosition);
            position = Math.max(...pos);
        }
        if (internalMatchback?.Value == "true") {
            const serviceFields = getServiceFieldData(
                externalServiceParameters,
                flowExternalServiceParameters,
                selectedFieldsUE,
                itemServiceDefaultFields,
                externalServices,
                tableNameLength?.Value
            );
            serviceFields.forEach(field => {
                if (
                    !fieldsCurrentLayout.find(currField => currField.FieldName == field.FieldName) &&
                    !fieldsCurrentLayout.find(currField => currField.FieldName == field.FieldId.toString())
                ) {
                    position++;
                    const name =
                        field.FieldId == 0
                            ? field.FieldName
                            : field.FieldId.toString() != field.FieldName && !isNaN(parseInt(field.FieldName))
                            ? field.FieldName
                            : field.FieldId.toString();
                    let newField: IExternalServiceLayoutField = {
                        // setting the new id as a negative value so that fields can be updated
                        id: isNaN(parseInt(field.FieldName))
                            ? field.FieldId == 0
                                ? position
                                : field.FieldId
                            : parseInt(field.FieldName),
                        LayoutFieldId: isNaN(parseInt(field.FieldName)) ? field.FieldId : parseInt(field.FieldName),
                        LayoutId: 0,
                        ServiceFieldId: 0,
                        FieldName: name,
                        ShortName:
                            field.FieldId == 0
                                ? field.FieldName.substring(0, 19)
                                : field.FieldId.toString().substring(0, 19),
                        FieldPosition: position,
                        FieldLength: field.Length == 0 ? length : field.Length,
                        EncryptionType: "NONE",
                        IsHardcode: false,
                        FieldAlias: findClientAlias(0, field.FieldId == 0 ? field.FieldName : field.FieldId.toString()),
                        AlternateName: null,
                    };
                    fields.push(newField);
                }
            });

            setCurrentServiceFields(serviceFields);
            setLayoutFields(fields);
            setAddFieldType("standard");
            setSelectedServiceField(0);
            setAddFieldName("");
            setAddPosition("");
            setAddLength("");
            setAddEncryption("NONE");
            setFieldErrors([]);
        }
    };

    useEffect(() => {
        if (layoutType == 2 && !layout) {
            addServiceFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutType, layout]);

    const setDelimitedFixedValue = (value: string) => {
        const newLocalLayout = layout ? { ...layout } : { ...localLayout };
        if (value == "delimited") {
            newLocalLayout.RecordSize = 0;
        } else {
            newLocalLayout.Delimiter = "";
        }
        newLocalLayout.RelativePositions = value == "delimited";

        // Reset Alias on Fixed Output layouts
        if (newLocalLayout.LayoutType == LayoutTypes.Output && !newLocalLayout.RelativePositions) {
            let newFields = [...layoutFields];
            newFields.map(x => (x.FieldAlias = null));
            setLayoutFields(newFields);
        }

        setLocalLayout(newLocalLayout);
    };

    const handleEncryptionChanged = value => setAddEncryption(value);

    const resetAddField = () => {
        setAddFieldType("standard");
        setSelectedServiceField(0);
        setAddFieldName("");
        setAddPosition("");
        setAddLength("");
        setAddEncryption("NONE");
        setFieldErrors([]);
        setAddFieldAlias("");
    };

    const resetPage = () => {
        setLocalLayout({ ...defaultLayout });
        setLayoutFields([]);
        resetAddField();
        dispatch(setHasUnsavedLayoutChanges(false));
    };

    const FieldPositionLabel = localLayout.RelativePositions ? "Column Number" : "Position";

    const addField = () => {
        // Validate
        let errors: string[] = [];
        const findPos = layoutFields.filter(x => x.FieldPosition == parseInt(addPosition));
        if (findPos.length > 0) {
            errors.push(`${FieldPositionLabel} ${addPosition} already assigned in the layout.`);
        }
        const aliasError = fieldAliasError(addFieldAlias);
        if (aliasError && aliasError != "") {
            errors.push(aliasError);
        }
        if (errors.length > 0) {
            setFieldErrors(errors);
            return;
        }

        // min value from layout fields LayoutFieldId
        const min = layoutFields.length > 0 ? Math.min(...layoutFields.map(f => f.LayoutFieldId)) : 0;
        const newField: IExternalServiceLayoutField = {
            // setting the new id as a negative value so that fields can be updated
            id: min - 1,
            LayoutFieldId: min - 1,
            LayoutId: 0,
            ServiceFieldId: addFieldType == "standard" ? selectedServiceField : 0,
            FieldName:
                addFieldType == "custom"
                    ? customFieldName == ""
                        ? addFieldName.trimStart().trimEnd()
                        : customFieldName
                    : null,
            ShortName:
                addFieldType == "custom"
                    ? customFieldName == ""
                        ? addFieldName.trimStart().trimEnd()
                        : customFieldName
                    : null,
            FieldPosition: parseInt(addPosition),
            FieldLength: parseInt(addLength),
            EncryptionType: addEncryption,
            IsHardcode: false,
            FieldAlias: addFieldAlias.trim() == "" ? null : addFieldAlias,
            AlternateName: null,
        };

        let fields = [...layoutFields];
        fields.push(newField);
        setLayoutFields(fields);

        setAddFieldType("standard");
        setSelectedServiceField(0);
        setSelectedCustomField(-1);
        setAddFieldName("");
        setAddFieldAlias("");
        setAddPosition("");
        setAddLength("");
        setAddEncryption("NONE");
        setFieldErrors([]);
    };

    const addFieldDisabled = () => {
        if (addFieldType == "standard" && selectedServiceField == 0) {
            return true;
        }
        if (addFieldType == "custom" && addFieldName.trim() == "" && customFieldName == "") {
            return true;
        }
        if (addPosition == "") {
            return true;
        }
        if (addLength == "") {
            return true;
        }
        return false;
    };

    const renderFieldsDropdown = (
        showLabel: boolean,
        selectedFieldId: number,
        onChange: (newValue: number) => void
    ) => {
        const usedFields = layoutFields.map(f => f.ServiceFieldId);

        const options = serviceFields.map(x => (
            <MenuItem key={x.ServiceFieldId} value={x.ServiceFieldId} disabled={usedFields.includes(x.ServiceFieldId)}>
                {x.DisplayName}
            </MenuItem>
        ));

        return (
            <FormControl>
                {showLabel && <InputLabel id="field-select-label">Field</InputLabel>}
                <Select
                    id="field-select"
                    onChange={e => onChange(parseInt(e.target.value as string))}
                    value={selectedFieldId > 0 ? selectedFieldId : ""}
                    style={{ minWidth: "275px" }}
                    fullWidth
                >
                    {options}
                </Select>
            </FormControl>
        );
    };

    const handleOnChangeCustomDropdown = (newValue: number) => {
        const inputLayoutId = flowExternalService.InputLayoutId;
        const customFields = allLayoutFields.filter(
            field => field.LayoutId == inputLayoutId && field.FieldName != null
        );
        const customField = customFields.find(field => field.FieldPosition == newValue);
        //all positions
        const pos = layoutFields.map(x => x.FieldPosition);

        let newLength = customField?.FieldLength || 0;
        let newName = customField?.FieldName || "";
        let newPos = Math.max(...pos) + 1;

        setSelectedCustomField(newValue);
        setCustomFieldName(newName);
        setAddLength(newLength.toString());
        setAddPosition(newPos.toString());
        setAddFieldName("");
        setAddFieldAlias(findClientAlias(0, newName) ?? "");
    };

    const handleOnchangeCustomField = (newValue: string) => {
        setAddFieldName(newValue);
        setSelectedCustomField(-1);
        setCustomFieldName("");
        setAddLength("");
        setAddPosition("");
        setAddFieldAlias(findClientAlias(0, newValue) ?? "");
    };

    const renderCustomFieldsDropdown = (selectedFieldId: number) => {
        const usedFields = layoutFields.map(f => f.FieldName);
        const inputLayoutId = flowExternalService.InputLayoutId;
        let customFields = allLayoutFields.filter(field => field.LayoutId == inputLayoutId && field.FieldName != null);
        let customOptions;

        if (customFields.length > 0) {
            customOptions = customFields.map(x => (
                <MenuItem key={x.FieldPosition} value={x.FieldPosition} disabled={usedFields.includes(x.FieldName)}>
                    {x.FieldName}
                </MenuItem>
            ));
        } else {
            customOptions = (
                <MenuItem key={"empty"} value={""} disabled>
                    {"No Custom Fields to Display"}
                </MenuItem>
            );
        }

        return (
            <FormControl style={{ marginLeft: "20px", width: "50%" }}>
                <InputLabel id="field-select-custom">Custom Field</InputLabel>
                <Select
                    id="field-select-custom"
                    onChange={e => handleOnChangeCustomDropdown(parseInt(e.target.value as string))}
                    value={selectedFieldId}
                    style={{ width: "250px" }}
                    fullWidth
                >
                    {customOptions}
                </Select>
            </FormControl>
        );
    };

    const validateFields = (): Array<string> => {
        const errors: Array<string> = [];
        let customFieldsWithEmptyName = false;
        let fieldsWithRepeatedPositions = false;
        let fieldsWithNoLength = false;

        for (const field of layoutFields) {
            if (!customFieldsWithEmptyName && field.LayoutFieldId == 0 && field.FieldName == "") {
                customFieldsWithEmptyName = true;
            }

            if (
                !fieldsWithRepeatedPositions &&
                layoutFields.filter(
                    x => x.LayoutFieldId != field.LayoutFieldId && x.FieldPosition == field.FieldPosition
                ).length > 0
            ) {
                fieldsWithRepeatedPositions = true;
            }

            if (!fieldsWithNoLength && !field.FieldLength) {
                fieldsWithNoLength = true;
            }
        }

        if (customFieldsWithEmptyName) {
            errors.push("Field name is required for custom fields.");
        }

        if (fieldsWithRepeatedPositions) {
            errors.push("There are fields with duplicated column numbers.");
        }

        if (fieldsWithNoLength) {
            errors.push("Field length is required for all fields.");
        }
        return errors;
    };

    const save = (isSaveAsNew: boolean = false) => {
        // Validate
        let errors: string[] = [];

        if (localLayout.LayoutName == "") {
            errors.push("Layout name is required.");
        }

        if (
            layouts.some(
                e =>
                    e.LayoutName.trim() === localLayout.LayoutName.trim() &&
                    (isSaveAsNew || e.LayoutId != localLayout.LayoutId)
            )
        ) {
            errors.push("Layout name already exists.");
        }

        const layout = { ...localLayout };

        // Check Delimiter
        if (localLayout.RelativePositions && (localDelimiter == null || localDelimiter.trim() == "")) {
            errors.push("Delimiter is required.");
        } else if (localDelimiter == "custom" && (customDelimiter == null || customDelimiter.trim() == "")) {
            errors.push("Custom delimiter is required.");
        } else {
            layout.Delimiter = localDelimiter == "custom" ? customDelimiter : localDelimiter;
        }

        // Check Qualifier
        if (fcQualifiersFeature && layout.RelativePositions) {
            layout.Qualifier = selectedQualifier;
        } else {
            layout.Qualifier = "";
        }

        setLocalLayout(layout);

        // Check Record Size
        if (!localLayout.RelativePositions && (localLayout.RecordSize == null || localLayout.RecordSize <= 0)) {
            errors.push("Record size is required.");
        }

        // Check Fields Count
        if (layoutFields.length == 0) {
            errors.push("At least 1 Field is required.");
        }

        const validateFieldPosition = layoutFields.find(lay => !lay.FieldPosition || lay.FieldPosition < 1);

        if (validateFieldPosition) {
            errors.push(`${FieldPositionLabel} cannot be set to 0.`);
        }

        if (isfieldNameEmpty) {
            errors.push("There are fields with blank name.");
        }

        if (isTable && layoutType == LayoutTypes.Input) {
            const columns = flowExternalService.InputTableColumns.filter(x => x.Include).length;
            if (layoutFields.length != columns) {
                errors.push("Number of layout fields must match included table columns.");
            }
        }

        const fieldErrors = validateFields();
        if (fieldErrors.length > 0) {
            errors = errors.concat(fieldErrors);
        }

        const aliasErrors = allAliasErrors();
        if (aliasErrors.length > 0) {
            errors = errors.concat(aliasErrors);
        }

        if (errors.length > 0) {
            setLayoutErrors(errors);
            return;
        } else {
            setLayoutErrors([]);
        }

        // Save
        if (layout.CompanyId == 0) {
            layout.CompanyId = companyId;
        }

        if (isSaveAsNew) layout.LayoutId = 0;
        const saveLayout: ISaveExternalServiceLayoutData = {
            Layout: layout,
            Fields: finalizeOutputLayout(layoutFields),
            flowExternalServiceId,
        };
        dispatch(requestAddExternalServiceLayout(saveLayout, isSaveAsNew, flowExternalService.WFClientCode || ""));
        resetPage();
    };

    const fieldAliasError = (alias: string): string => {
        if (!enabledFeatures.includes("flow-control-custom-headers") || !alias || alias == "") {
            return "";
        }

        let invalid = false;
        const defaultMessage =
            "Invalid Alias '__ALIAS_NAME__':  can only contain AlphaNumerics, (_) Underscore, cannot start with a Number or contain the Custom Delimiter";

        // // alphanumeric and _ only
        if (alias.match(/[^a-zA-Z0-9_]/g)) {
            invalid = true;
        }
        // cannot use custom delimiter
        if (localDelimiter == "custom" && customDelimiter != "" && alias.includes(customDelimiter)) {
            invalid = true;
        }
        // cannot start with a number
        if (alias.match(/^\d/)) {
            invalid = true;
        }

        if (invalid) {
            return defaultMessage.replace("__ALIAS_NAME__", alias);
        } else {
            return "";
        }
    };

    const allAliasErrors = (): Array<string> => {
        let errors: Array<string> = [];
        layoutFields.forEach(field => {
            const error = fieldAliasError(field.FieldAlias ?? "");
            if (error != "") {
                errors.push(error);
            }
        });
        return errors;
    };

    const handleFieldIdChanged = (layoutFieldId: number, newValue: number) => {
        const newLayoutFields = [...layoutFields];
        const field = newLayoutFields.find(x => x.LayoutFieldId == layoutFieldId);
        if (field) {
            field.ServiceFieldId = newValue;
        }
        setLayoutFields(newLayoutFields);
    };

    const handleAddFieldIdChanged = (newValue: number) => {
        setSelectedServiceField(newValue);
        setAddFieldAlias(findClientAlias(newValue, "") ?? "");
    };

    const handleCustomFieldNameChange = (layoutFieldId: number, newValue: string) => {
        const newLayoutFields = [...layoutFields];
        const field = newLayoutFields.find(x => x.LayoutFieldId == layoutFieldId);
        if (field) {
            field.FieldName = newValue.trim();
            field.ShortName = newValue.trim();
        }
        if (field!.FieldName != "") {
            setLayoutFields(newLayoutFields);
            setIsfieldNameEmpty(false);
        } else {
            setIsfieldNameEmpty(true);
        }
    };

    const determineLayoutFieldName = (fieldName: string, display: boolean, forceAlternateName: boolean = false) => {
        const itemDefaultFields = itemServiceDefaultFields.map(x => x.FieldName);
        if (!isNaN(parseInt(fieldName || ""))) {
            fieldName =
                (altUEFieldName || forceAlternateName) && !itemDefaultFields.includes(fieldName || "")
                    ? currentServiceFields.find(x => x.FieldId == parseInt(fieldName || ""))?.AlternateName ||
                      currentFieldsUE.find(y => y.FieldId == parseInt(fieldName || ""))?.AlternateName ||
                      selectedFieldsUE.find(y => y.FieldId == parseInt(fieldName || ""))?.AlternateName ||
                      ""
                    : display
                    ? currentServiceFields.find(x => x.FieldId == parseInt(fieldName || ""))?.FieldName ||
                      currentFieldsUE.find(y => y.FieldId == parseInt(fieldName || ""))?.FieldName ||
                      selectedFieldsUE.find(y => y.FieldId == parseInt(fieldName || ""))?.FieldName ||
                      ""
                    : fieldName;
        }

        return fieldName;
    };

    const finalizeOutputLayout = (layoutFields: Array<IExternalServiceLayoutField>) => {
        let finalFields = [...layoutFields];
        finalFields.forEach(field => {
            field.AlternateName = determineLayoutFieldName(field.FieldName || "", false, true);
        });
        return finalFields;
    };

    const renderFieldName = (row: IExternalServiceLayoutField) => {
        let fieldName = row.FieldName;
        let fieldShortName = row.ShortName;
        if (!isNaN(parseInt(fieldName || ""))) {
            let name = determineLayoutFieldName(fieldName || "", true);

            let shortName =
                currentServiceFields.find(x => x.FieldId == parseInt(fieldShortName || ""))?.FieldName ||
                currentFieldsUE.find(y => y.FieldId == parseInt(fieldShortName || ""))?.FieldName ||
                selectedFieldsUE.find(y => y.FieldId == parseInt(fieldShortName || ""))?.FieldName ||
                "";
            fieldName = name != "" ? name : shortName != "" ? "Table Name " + shortName : row.FieldName;
        }
        if (row.ServiceFieldId > 0) {
            return renderFieldsDropdown(false, row.ServiceFieldId, val => handleFieldIdChanged(row.LayoutFieldId, val));
        } else {
            return (
                <TextField
                    id={`field-name-${row.id}`}
                    type="text"
                    defaultValue={fieldName ?? ""}
                    inputProps={{ maxLength: "150" }}
                    style={{ width: "275px" }}
                    onChange={e => handleCustomFieldNameChange(row.LayoutFieldId, e.target.value)}
                    required
                    error={fieldName!.trim() === ""}
                    helperText={fieldName!.trim() === "" ? "Field name is required" : ""}
                    onWheel={event => {
                        const eventTarget = event.target as HTMLInputElement;
                        return eventTarget.blur();
                    }}
                    fullWidth
                />
            );
        }
    };

    const renderEditableNumberField = (row: IExternalServiceLayoutField, property: string) => (
        <NumericalTextField
            id={`${property}-${row.id}`}
            key={`${property}-${row.id}`}
            inputProps={{ maxLength: "4" }}
            value={row[property] ?? 0}
            helperText={row[property] < 1 ? "* Value cannot be set to 0" : undefined}
            onBlur={e => {
                const newValue = e.target.value;
                const index = layoutFields.findIndex(x => x.id == row.id);
                let newLayoutFields = [...layoutFields];

                if (newValue == "" || parseInt(newValue) < 0) {
                    newLayoutFields[index][property] = 0;
                } else if (isNaN(parseInt(newValue))) {
                    newLayoutFields[index][property] = layoutFields[index][property];
                } else if (parseInt(newValue) > 9999) {
                    newLayoutFields[index][property] = 9999;
                } else {
                    newLayoutFields[index][property] = parseInt(newValue);
                }

                setLayoutFields(newLayoutFields);
            }}
            onWheel={event => {
                const eventTarget = event.target as HTMLInputElement;
                return eventTarget.blur();
            }}
        />
    );

    const handleFieldEncryptionChanged = (layoutFieldId: number, newValue: string) => {
        const newLayoutFields = [...layoutFields];
        const field = newLayoutFields.find(x => x.LayoutFieldId == layoutFieldId);
        if (field) {
            field.EncryptionType = newValue;
        }
        setLayoutFields(newLayoutFields);
    };

    const renderFieldEncryption = (row: IExternalServiceLayoutField) => (
        <Select
            id={`field-encrypt-${row.id}`}
            onChange={e => handleFieldEncryptionChanged(row.LayoutFieldId, e.target.value as string)}
            value={row.EncryptionType}
            fullWidth
        >
            <MenuItem key={1} value="NONE">
                None
            </MenuItem>
            <MenuItem key={2} value="SCRAMBLE">
                Scramble
            </MenuItem>
            <MenuItem key={3} value="STANDARD">
                Standard
            </MenuItem>
        </Select>
    );

    const handleDeleteField = (id: number) => {
        let newLayoutFields = [...layoutFields];
        newLayoutFields = newLayoutFields.filter(f => f.id != id);
        setLayoutFields(newLayoutFields);
    };

    const handleAddFieldType = (type: string) => {
        if (type == "standard") {
            setSelectedCustomField(-1);
            setCustomFieldName("");
            setAddFieldName("");
        } else {
            setSelectedServiceField(0);
        }
        setAddFieldAlias("");
        setAddFieldType(type);
    };

    const renderDeleteOption = (row: IExternalServiceLayoutField) => (
        <Tooltip title="Delete">
            <IconButton id={`delete-${row.id}`} color="primary" onClick={() => handleDeleteField(row.id)}>
                <i className="material-icons" style={{ height: "20px", width: "20px", fontSize: "20px" }}>
                    delete
                </i>
            </IconButton>
        </Tooltip>
    );

    const renderQualifierOption = () => {
        const options = qualifiers?.map((qualifier, index) => (
            <MenuItem key={index} value={qualifier.value}>
                {qualifier.label}
            </MenuItem>
        ));

        return (
            <FormControl>
                <InputLabel id="quanlifier-label" shrink>
                    Field Qualifier - {localDelimiter}VALUE{localDelimiter}
                </InputLabel>
                <Select
                    id="field-qualifier"
                    required
                    displayEmpty
                    value={selectedQualifier}
                    onChange={e => setSelectedQualifier(e.target.value as string)}
                    disabled={!localLayout?.RelativePositions}
                    style={{ width: "175px" }}
                >
                    {options}
                </Select>
            </FormControl>
        );
    };

    const renderDelimiterOption = () => {
        const options = fileDelimiters.map(x => (
            <MenuItem key={x.DelimiterId} value={x.Delimiter}>
                {x.DelimiterName}
            </MenuItem>
        ));
        options.push(
            <MenuItem key={999} value="custom">
                Custom Delimiter
            </MenuItem>
        );

        return (
            <FormControl>
                <InputLabel id="delimiter-select-label">Delimiter</InputLabel>
                <Select
                    id="delimiter-select"
                    onChange={e => {
                        setLocalDelimiter(e.target.value as string);
                        setCustomDelimiter("");
                    }}
                    value={localDelimiter}
                    style={{ width: "175px" }}
                    disabled={isTable}
                >
                    {options}
                </Select>
            </FormControl>
        );
    };

    const nameSearch = (term: string, row: IExternalServiceLayoutField) => {
        let fieldName = "";
        if (row.ServiceFieldId > 0) {
            const field = serviceFields.find(f => f.ServiceFieldId == row.ServiceFieldId);
            if (field) {
                fieldName = `${field.DisplayName} ${field.FieldName}`;
            }
        } else {
            fieldName = `${row.FieldName} ${row.ShortName}`;
        }

        return fieldName.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    };

    const updateFieldAlias = (id: number, alias: string) => {
        let updateFields = [...layoutFields];
        const index = updateFields.findIndex(x => x.id == id);
        if (index >= 0) {
            updateFields[index].FieldAlias = alias;
            setLayoutFields(updateFields);
        }
    };

    const findClientAlias = (serviceFieldId: number, textName: string): string | null => {
        if (!enabledFeatures.includes("flow-control-custom-headers")) {
            return null;
        }

        let alias: string | null = null;
        if (serviceFieldId > 0) {
            const standard = itemClientFieldAlias.filter(x => x.ServiceFieldId == serviceFieldId);
            if (standard.length == 1) {
                alias = standard[0].FieldAlias;
            }
        }
        if (textName.trim().length > 0) {
            const custom = itemClientFieldAlias.filter(x => x.FieldName == textName.trim());
            if (custom.length == 1) {
                alias = custom[0].FieldAlias;
            }
        }
        return alias;
    };

    const renderFieldAliasAdd = () => (
        <div>
            <TextField
                id={`add-field-alias-text`}
                label={"Alias"}
                type="text"
                value={addFieldAlias}
                inputProps={{ maxLength: "30" }}
                onChange={e => setAddFieldAlias(e.target.value)}
                style={{ width: "275px" }}
                onWheel={event => {
                    const eventTarget = event.target as HTMLInputElement;
                    return eventTarget.blur();
                }}
            />
        </div>
    );

    const renderFieldAliasEdit = (row: IExternalServiceLayoutField) => (
        <div>
            <TextFieldForTable
                id={`edit-alias-text-${row.LayoutFieldId}`}
                key={`edit-alias-text-${row.LayoutFieldId}`}
                type="text"
                value={row.FieldAlias ?? ""}
                inputProps={{ maxLength: "30" }}
                onChange={e => updateFieldAlias(row.id, e.target.value)}
                style={{ width: "275px" }}
                disabled={row.IsHardcode}
                onWheel={event => {
                    const eventTarget = event.target as HTMLInputElement;
                    return eventTarget.blur();
                }}
            />
        </div>
    );

    const getColumns: Array<Column<IExternalServiceLayoutField>> = [
        {
            title: "Field Name",
            render: renderFieldName,
            customFilterAndSearch: nameSearch,
        },
        {
            title: "Alias",
            field: "FieldAlias",
            render: renderFieldAliasEdit,
            hidden:
                !localLayout.RelativePositions ||
                localLayout.LayoutType == LayoutTypes.Input ||
                !enabledFeatures.includes("flow-control-custom-headers"),
        },
        {
            title: FieldPositionLabel,
            field: "FieldPosition",
            render: rowData => renderEditableNumberField(rowData, "FieldPosition"),
        },
        {
            title: "Field Length",
            field: "FieldLength",
            render: rowData => renderEditableNumberField(rowData, "FieldLength"),
        },
        {
            title: "Encryption",
            field: "EncryptionType",
            render: renderFieldEncryption,
        },
        {
            title: "Actions",
            render: renderDeleteOption,
        },
    ];

    const getSortedFields = (layoutFields: Array<IExternalServiceLayoutField>) =>
        layoutFields.sort((a, b) => a.FieldPosition - b.FieldPosition);

    const setFinalLayoutFields = (layoutFields: Array<IExternalServiceLayoutField>) => {
        layoutFields.forEach(x => {
            x.FieldAlias = findClientAlias(x.ServiceFieldId, x.FieldName ?? "");
        });
        const sortedFields = getSortedFields(layoutFields);
        setLayoutFields(sortedFields.map(x => ({ ...x, id: x.LayoutFieldId })));
    };

    const handleAddHardcodes = (value: string) => {
        setAddHardcodes(value);
        setHardcodePlacement(value == "false" ? "" : hardcodePlacement);
        updateHardcodesInLayout(value == "true", "");
    };

    const handleLayoutPlacement = (value: string) => {
        setHardcodePlacement(value);
        updateHardcodesInLayout(addHardcodes == "true", value);
    };

    const updateHardcodesInLayout = (add: boolean, placement: string) => {
        let newLayoutFields = [...layoutFields];

        // Remove harcodes
        newLayoutFields = newLayoutFields.filter(x => !x.IsHardcode);

        // Add
        if (add && placement != "") {
            if (placement == "start") {
                let startFields: Array<IExternalServiceLayoutField> = [];
                flowExternalServiceHardcodes.forEach(value => {
                    startFields.push({
                        id: 0,
                        LayoutFieldId: 0,
                        LayoutId: localLayout.LayoutId,
                        ServiceFieldId: 0,
                        FieldName: value.HardcodeName,
                        ShortName: value.HardcodeName.substring(0, 19),
                        FieldPosition: 0,
                        FieldLength: value.HardcodeValue.length,
                        EncryptionType: "NONE",
                        IsHardcode: true,
                        FieldAlias: null,
                        AlternateName: null,
                    });
                });

                newLayoutFields = startFields.concat(newLayoutFields);
            } else if (placement == "end") {
                flowExternalServiceHardcodes.forEach(value => {
                    newLayoutFields.push({
                        id: 0,
                        LayoutFieldId: 0,
                        LayoutId: localLayout.LayoutId,
                        ServiceFieldId: 0,
                        FieldName: value.HardcodeName,
                        ShortName: value.HardcodeName.substring(0, 19),
                        FieldPosition: 0,
                        FieldLength: value.HardcodeValue.length,
                        EncryptionType: "NONE",
                        IsHardcode: true,
                        FieldAlias: null,
                        AlternateName: null,
                    });
                });
            }
        }

        // Update Positions
        let position = 1;
        let prevLength = 0;
        newLayoutFields.forEach((field, index) => {
            if (localLayout.RelativePositions) {
                field.FieldPosition = index + 1;
            } else {
                field.FieldPosition = position += prevLength;
                prevLength = field.FieldLength;
            }
        });

        setLayoutFields(newLayoutFields);
    };

    return (
        <Paper style={{ padding: "20px" }}>
            <FormLabel>Layout Builder</FormLabel>
            <div className="service-layout-builder" style={{ marginTop: "20px", width: "100%" }}>
                {layoutErrors.length > 0 && (
                    <div
                        className="alert-warning-original flow-item-warnings"
                        style={{ marginTop: "10px", padding: "5px" }}
                    >
                        {layoutErrors.map((err, index) => (
                            <div key={index}>
                                <Icon style={{ marginRight: "10px", position: "relative" }}>error_outline</Icon>
                                {err}
                            </div>
                        ))}
                    </div>
                )}
                {(layoutIsSaving || layoutIsSavingAsNew) && (
                    <div
                        className="disabled-overlay"
                        style={{
                            margin: "auto",
                            padding: "200px",
                            height: "max-content",
                        }}
                    />
                )}
                <div style={{ padding: "10px" }}>
                    <p>Layout Details</p>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <FormControl>
                            <RadioGroup
                                row
                                name="type"
                                value={localLayout.LayoutType}
                                onChange={e => setLocalLayout({ ...localLayout, LayoutType: parseInt(e.target.value) })}
                            >
                                <div style={{ fontSize: "16px" }}>
                                    <FormControlLabel
                                        value={LayoutTypes.Input}
                                        control={<Radio color="primary" />}
                                        label="Input"
                                        disabled={layoutType ? true : false}
                                    />
                                    <FormControlLabel
                                        value={LayoutTypes.Output}
                                        control={<Radio color="primary" />}
                                        label="Output"
                                        disabled={layoutType ? true : false}
                                    />
                                </div>
                            </RadioGroup>
                        </FormControl>
                        <Button
                            id="save-layout"
                            variant="contained"
                            color="primary"
                            className={"edit-button"}
                            style={{ minWidth: "fit-content" }}
                            startIcon={<i className="material-icons">save</i>}
                            onClick={() => save(false)}
                            disabled={layoutIsSaving || layoutIsSavingAsNew}
                        >
                            <div className="btn-saving-context" style={{ padding: "0px" }}>
                                {layoutIsSaving ? <CircularLoading size="x-small" color="#FFF" saved={false} /> : null}
                                <span>{layoutIsSaving ? "Saving..." : "Save Layout"}</span>
                            </div>
                        </Button>
                        {selectedLayout > 0 ? (
                            <Button
                                id="save-layout-as-new"
                                variant="contained"
                                color="secondary"
                                className={"edit-button"}
                                style={{ minWidth: "fit-content" }}
                                startIcon={<i className="material-icons">save</i>}
                                onClick={() => save(true)}
                                disabled={layoutIsSaving || layoutIsSavingAsNew}
                            >
                                <div className="btn-saving-context" style={{ padding: "0px" }}>
                                    {layoutIsSavingAsNew ? (
                                        <CircularLoading size="x-small" color="#FFF" saved={false} />
                                    ) : null}
                                    <span>{layoutIsSavingAsNew ? "Saving..." : "Save Layout As New"}</span>
                                </div>
                            </Button>
                        ) : (
                            <Button
                                id="import"
                                variant="contained"
                                color="secondary"
                                className={"edit-button"}
                                style={{ minWidth: "fit-content" }}
                                startIcon={<i className="material-icons">input</i>}
                                onClick={() => dispatch(showModal("FLOW_CONTROL_LAYOUT_IMPORT"))}
                                disabled={!flowPermissions.canEdit}
                            >
                                Import
                            </Button>
                        )}

                        <Button
                            id="cancel"
                            variant="contained"
                            color="secondary"
                            className={"edit-button"}
                            style={{ backgroundColor: "#cf4e4e", padding: "0px 20px", minWidth: "fit-content" }}
                            startIcon={<i className="material-icons">cancel</i>}
                            onClick={() => (onCancel ? onCancel() : null)}
                            disabled={!flowPermissions.canEdit || awsIsDownloading}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <TextField
                            id="layout-name"
                            label="Name"
                            type="text"
                            value={localLayout.LayoutName ?? ""}
                            inputProps={{ maxLength: "150" }}
                            fullWidth
                            onChange={e => setLocalLayout({ ...localLayout, LayoutName: e.target.value })}
                            onWheel={event => {
                                const eventTarget = event.target as HTMLInputElement;
                                return eventTarget.blur();
                            }}
                        />
                    </div>
                    <div className="m-t" style={{ display: "flex", justifyContent: "flex-start" }}>
                        <div className="m-t m-r-md">
                            <FormControl>
                                <RadioGroup
                                    row
                                    name="delimited"
                                    value={localLayout.RelativePositions ? "delimited" : "fixed"}
                                    onChange={e => setDelimitedFixedValue(e.target.value)}
                                >
                                    <div style={{ fontSize: "16px" }}>
                                        <FormControlLabel
                                            value="delimited"
                                            control={<Radio color="primary" />}
                                            label="Delimited"
                                            disabled={isTable}
                                        />
                                        <FormControlLabel
                                            value={"fixed"}
                                            control={<Radio color="primary" />}
                                            label="Fixed Width"
                                            disabled={isTable}
                                        />
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {localLayout.RelativePositions ? (
                            <div style={{ display: "flex" }}>
                                <div className="m-r-md">
                                    {renderDelimiterOption()}
                                    {localDelimiter == "custom" && (
                                        <TextField
                                            id="delimiter-char"
                                            label="Custom"
                                            type="text"
                                            value={customDelimiter}
                                            inputProps={{ maxLength: "5" }}
                                            onChange={e => setCustomDelimiter(e.target.value)}
                                            style={{ width: "100px" }}
                                            onWheel={event => {
                                                const eventTarget = event.target as HTMLInputElement;
                                                return eventTarget.blur();
                                            }}
                                        />
                                    )}
                                </div>
                                {fcQualifiersFeature ? renderQualifierOption() : null}
                            </div>
                        ) : (
                            <div className="m-l">
                                <NumericalTextField
                                    id="record-size"
                                    key="record-size"
                                    label="Record Size"
                                    inputProps={{ maxLength: "4" }}
                                    style={{ width: "100px" }}
                                    value={localLayout.RecordSize ?? 0}
                                    onChange={e => {
                                        const newValue = e.target.value;
                                        let newLocalLayout = { ...localLayout };

                                        if (newValue != "" && isNaN(parseInt(newValue))) {
                                            newLocalLayout["RecordSize"] = localLayout["RecordSize"];
                                        } else {
                                            newLocalLayout["RecordSize"] = parseInt(newValue);
                                        }

                                        setLocalLayout(newLocalLayout);
                                    }}
                                    onBlur={e => {
                                        const newValue = e.target.value;
                                        let newLocalLayout = { ...localLayout };

                                        if (newValue === "" || parseInt(newValue) < 0) {
                                            newLocalLayout["RecordSize"] = 0;
                                        } else if (isNaN(parseInt(newValue))) {
                                            newLocalLayout["RecordSize"] = localLayout["RecordSize"];
                                        } else if (parseInt(newValue) > 9999) {
                                            newLocalLayout["RecordSize"] = 9999;
                                        } else {
                                            newLocalLayout["RecordSize"] = parseInt(newValue);
                                        }

                                        setLocalLayout(newLocalLayout);
                                    }}
                                    onWheel={event => {
                                        const eventTarget = event.target as HTMLInputElement;
                                        return eventTarget.blur();
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {!layout && layoutType == LayoutTypes.Output && flowExternalServiceHardcodes.length > 0 && (
                    <div className="m-t-5" style={{ padding: "10px" }}>
                        <p>Add Constants</p>
                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                            <div className="m-t m-r-md">
                                <FormControl>
                                    <RadioGroup
                                        row
                                        name="add-hardcodes"
                                        value={addHardcodes}
                                        onChange={e => handleAddHardcodes(e.target.value)}
                                    >
                                        <div style={{ fontSize: "16px" }}>
                                            <FormControlLabel
                                                value="true"
                                                control={<Radio color="primary" />}
                                                label="Yes"
                                            />
                                            <FormControlLabel
                                                value={"false"}
                                                control={<Radio color="primary" />}
                                                label="No"
                                            />
                                        </div>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            {addHardcodes === "true" && (
                                <div>
                                    <FormControl
                                        className={
                                            hardcodePlacement == "" ? "alert-warning-original flow-item-warnings" : ""
                                        }
                                    >
                                        <InputLabel id="hardcode-select-label">Layout Placement</InputLabel>
                                        <Select
                                            id="placement-select"
                                            onChange={e => handleLayoutPlacement(e.target.value as string)}
                                            value={hardcodePlacement}
                                            style={{ width: "175px" }}
                                        >
                                            <MenuItem key="start" value="start">
                                                Beginning
                                            </MenuItem>
                                            <MenuItem key="end" value="end">
                                                End
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className="m-t-5" style={{ padding: "10px" }}>
                    <p>Add Field</p>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <FormControl>
                                <RadioGroup
                                    row
                                    name="field-type"
                                    value={addFieldType}
                                    onChange={e => handleAddFieldType(e.target.value)}
                                >
                                    <div style={{ fontSize: "16px" }}>
                                        <FormControlLabel
                                            value="standard"
                                            control={<Radio color="primary" />}
                                            label="Standard"
                                        />
                                        <FormControlLabel
                                            value={"custom"}
                                            control={<Radio color="primary" />}
                                            label="Custom"
                                        />
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div>
                            <Button
                                id="add-field"
                                variant="contained"
                                style={{
                                    fontSize: "12px",
                                    backgroundColor: !addFieldDisabled() ? "#1e88e5" : "",
                                    color: "#fff",
                                    marginTop: "-15px",
                                }}
                                startIcon={<i className="material-icons">add</i>}
                                onClick={addField}
                                disabled={addFieldDisabled()}
                            >
                                Add Field
                            </Button>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {addFieldType == "standard" ? (
                            <div>
                                {renderFieldsDropdown(true, selectedServiceField, val => handleAddFieldIdChanged(val))}
                            </div>
                        ) : (
                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                <TextField
                                    id="custom-name"
                                    label="Custom Name"
                                    type="text"
                                    value={addFieldName ?? ""}
                                    inputProps={{ maxLength: "150" }}
                                    onChange={e => handleOnchangeCustomField(e.target.value)}
                                    style={{ width: "275px" }}
                                    required
                                    error={addFieldName.trim() === ""}
                                    helperText={
                                        addFieldName.trim() === ""
                                            ? localLayout.LayoutType == 1
                                                ? "Field name is required"
                                                : "Select column name from drop down"
                                            : ""
                                    }
                                    onWheel={event => {
                                        const eventTarget = event.target as HTMLInputElement;
                                        return eventTarget.blur();
                                    }}
                                />
                                {layoutType == 2 ? renderCustomFieldsDropdown(selectedCustomField) : null}
                            </div>
                        )}
                        {localLayout.RelativePositions &&
                            localLayout.LayoutType == LayoutTypes.Output &&
                            enabledFeatures.includes("flow-control-custom-headers") &&
                            renderFieldAliasAdd()}
                        <div className="m-l">
                            <NumericalTextField
                                id={FieldPositionLabel + "input"}
                                label={FieldPositionLabel}
                                value={addPosition ?? ""}
                                inputProps={{ maxLength: "4" }}
                                onBlur={e => {
                                    let value = parseInt(e.target.value);
                                    if (value < 0) {
                                        value = 0;
                                    }
                                    setAddPosition(value.toString());
                                }}
                                style={{ width: "150px" }}
                                onWheel={event => {
                                    const eventTarget = event.target as HTMLInputElement;
                                    return eventTarget.blur();
                                }}
                            />
                        </div>
                        <div className="m-l">
                            <NumericalTextField
                                id="field-length"
                                label="Length"
                                value={addLength ?? ""}
                                inputProps={{ maxLength: "4" }}
                                onBlur={e => {
                                    let value = parseInt(e.target.value);
                                    if (value < 0) {
                                        value = 0;
                                    }
                                    setAddLength(value.toString());
                                }}
                                style={{ width: "100px" }}
                                onWheel={event => {
                                    const eventTarget = event.target as HTMLInputElement;
                                    return eventTarget.blur();
                                }}
                            />
                        </div>
                        <div className="m-l">
                            <FormControl>
                                <InputLabel id="encrypt-label">Encryption</InputLabel>
                                <Select
                                    id="field-encrypt"
                                    onChange={e => handleEncryptionChanged(e.target.value)}
                                    value={addEncryption}
                                    style={{ width: "150px" }}
                                >
                                    <MenuItem key={1} value="NONE">
                                        None
                                    </MenuItem>
                                    <MenuItem key={2} value="SCRAMBLE">
                                        Scramble
                                    </MenuItem>
                                    <MenuItem key={3} value="STANDARD">
                                        Standard
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {fieldErrors.length > 0 && (
                        <div
                            className="alert-warning-original flow-item-warnings"
                            style={{ marginTop: "10px", padding: "5px" }}
                        >
                            {fieldErrors.map((err, index) => (
                                <div key={index}>
                                    <Icon style={{ marginRight: "10px", position: "relative" }}>error_outline</Icon>
                                    {err}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="m-t-5" style={{ padding: "10px" }}>
                    <div>
                        <MaterialTable
                            key={"Table-Builder"}
                            title="Layout Fields"
                            columns={getColumns}
                            data={layoutFields}
                            options={{
                                rowStyle: {
                                    fontSize: 12,
                                    padding: "2px",
                                },
                                headerStyle: { fontSize: 12, zIndex: 0 },
                                pageSize: 10,
                                pageSizeOptions: [10, 25, 50],
                            }}
                        />
                    </div>
                </div>
            </div>
        </Paper>
    );
};

export default LayoutBuilder;
