import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowCannedReport, FlowItem, FlowErrorsByItemId, FlowRelation } from "../types/flowTypes";
import { getFlowRelationsForSelectedFlow, getFlowRelationsForAllFlows } from "./flowRelations";
const myGenericReducer = makeReducerFor("FLOW_CANNED_REPORT", "FlowCannedReportId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowCannedReportsArray = createSelector(
    state => state.flowCannedReports.byId,
    (flowCannedReportsById: {| [number]: FlowCannedReport |}): Array<FlowCannedReport> => {
        const r: Array<FlowCannedReport> = Object.values(flowCannedReportsById);
        return r;
    }
);

export type FlowCannedReportsByItemId = {
    [number]: FlowCannedReport,
};

export const getFlowCannedReportsByFlowItemId = createSelector(
    state => getFlowCannedReportsArray(state),
    (flowCannedReports: Array<FlowCannedReport>): FlowCannedReportsByItemId =>
        flowCannedReports.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowCannedReportsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowCannedReportsByFlowItemId(state),
    (flowItems: Array<FlowItem>, flowCannedReportsByItemId: FlowCannedReportsByItemId): Array<FlowCannedReport> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        let result = [];
        for (const itemId of itemIds) {
            const cannedReport = flowCannedReportsByItemId[itemId];
            if (cannedReport != null) {
                result.push(cannedReport);
            }
        }
        return result;
    }
);

export const getSelectedFlowCannedItems = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    (flowItems: Array<FlowItem>): Array<FlowItem> => {
        const allCannedItems = flowItems.filter(f => f.FlowItemType == "cannedreport");
        return allCannedItems;
    }
);

export const getExecutedCannedReports = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    (flowItems: Array<FlowItem>): Array<FlowItem> => {
        const cannedHavingResults = flowItems.filter(f => f.FlowItemType == "cannedreport" && f.HasResultTable);
        return cannedHavingResults;
    }
);

const cannedReportsToErrorsById = (
    flowCannedReports: Array<FlowCannedReport>,
    flowRelations: Array<FlowRelation> //,
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowCannedReport of flowCannedReports) {
        errorsById[flowCannedReport.FlowItemId] = validateFlowCannedReport(flowCannedReport, flowRelations);
    }
    return errorsById;
};

export const getFlowCannedReportErrorsForSelectedFlow = createSelector(
    state => getFlowCannedReportsForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    cannedReportsToErrorsById
);
export const getFlowCannedReportErrorsForAllFlows = createSelector(
    state => getFlowCannedReportsArray(state),
    state => getFlowRelationsForAllFlows(state),
    cannedReportsToErrorsById
);

//////////////////// HELPERS //////////////////////////////

export const validateFlowCannedReport = (
    flowCannedReport: FlowCannedReport,
    flowRelations: Array<FlowRelation>
): Array<string> => {
    const errors = [];
    const exportRelations = flowRelations.filter(
        z => z.ParentFlowItemId != 0 && z.ChildFlowItemId == flowCannedReport.FlowItemId
    );
    const allRelations = flowRelations.filter(z => z.ChildFlowItemId == flowCannedReport.FlowItemId);
    if (exportRelations.length == 0 || allRelations.length == 0) {
        errors.push("Canned Report items must have a parent assigned.");
    }

    if (!flowCannedReport.DatabaseIn || !flowCannedReport.SchemaIn || !flowCannedReport.TableIn) {
        errors.push("Table to load is required.");
    }

    if (flowCannedReport.StoredProcedureID == null || flowCannedReport.StoredProcedureID == 0) {
        errors.push("Canned Report items must select a report to execute.");
    }
    if (
        flowCannedReport.DimensionFieldKeys == "" ||
        flowCannedReport.DimensionFieldKeys == "[]" ||
        flowCannedReport.DimensionFieldKeys == "{}"
    ) {
        errors.push("Criteria cannot be blank.");
    }

    return errors;
};
