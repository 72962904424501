import { Dispatch } from "redux";
import { FlowExpression, FlowExpressionConstraint } from "../types/stores/flowTypes";
import { addThing } from "./flowActions";

export const newFlowExpression =
    (flowItemId: number) =>
    (dispatch: Dispatch): void => {
        const thing: FlowExpression = {
            FlowExpressionId: 0,
            ExpressionName: null,
            ExpressionQuery: "",
            ExpressionResult: null,
            IsValid: null,
            InvalidReason: null,
            FlowItemId: flowItemId,
        };

        dispatch(addThing("flowExpressions", thing));
    };

export const newFlowExpressionConstraint =
    (flowItemId: number) =>
    (dispatch: Dispatch): void => {
        const thing: FlowExpressionConstraint = {
            FlowExpressionConstraintId: 0,
            ChildFlowItemId: 0,
            ConstraintCondition: null,
            ConstraintStatus: false,
            ConstraintValue: null,
            FlowItemId: flowItemId,
        };

        dispatch(addThing("flowExpressionConstraints", thing));
    };
