import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowScriptResultsHistory, FlowItem } from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_SCRIPT_RESULTS_HISTORY", "FlowScriptResultId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowScriptResultsHistoriesArray = createSelector(
    state => state.flowScriptResultsHistory.byId,
    (FlowScriptResultsHistoriesById: {| [number]: FlowScriptResultsHistory |}): Array<FlowScriptResultsHistory> => {
        const r: Array<FlowScriptResultsHistory> = Object.values(FlowScriptResultsHistoriesById);
        return r;
    }
);

export type FlowScriptResultsHistoryByItemId = {
    [number]: Array<FlowScriptResultsHistory>,
};
// eslint-disable-line
export const getFlowScriptResultsHistoriesByFlowItemId = createSelector(
    state => getFlowScriptResultsHistoriesArray(state),
    (FlowScriptResultsHistories: Array<FlowScriptResultsHistory>): FlowScriptResultsHistoryByItemId =>
        FlowScriptResultsHistories.reduce((acc, row) => {
            if (!acc[row.FlowItemId]) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowScriptResultsHistoriesForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowScriptResultsHistoriesByFlowItemId(state),
    (
        selectedFlow: number,
        flowItems: Array<FlowItem>,
        flowScriptResultsHistoriesByItemId: FlowScriptResultsHistoryByItemId
    ): Array<FlowScriptResultsHistory> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const script = flowScriptResultsHistoriesByItemId[itemId];
            if (script != null) {
                result.concat(script);
            }
        }
        return result;
    }
);

export const getFlowScriptResultsHistoryByFlowItemIdFromProps = createSelector(
    state => getFlowScriptResultsHistoriesByFlowItemId(state),
    state => state.selected.flowItem,
    (
        flowScriptResultsHistoryByFlowItemId: FlowScriptResultsHistoryByItemId,
        flowItemId: number
    ): Array<FlowScriptResultsHistory> => flowScriptResultsHistoryByFlowItemId[flowItemId]
);
