export enum ScheduleType {
    Recurring = 0,
    OneTime = 1,
    NumberOfOccurrences = 2,
    EndDate = 3,
    TableRefresh = 4,
    ExperianAssetRefresh = 5,
    TimeFrequency = 6,
}

export enum ScheduleTimeType {
    Day = 1,
    Week = 2,
    Month = 3,
}
