import React from "react";

type Props = {
    size: string;
    saved?: boolean;
    color?: string;
};

const CircularLoading: React.FC<Props> = ({ size, saved, color }: Props) => {
    let px = "30px";
    switch (size) {
        case "large":
            px = "65px";
            break;
        case "medium":
            px = "40px";
            break;
        case "small":
            px = "20px";
            break;
        case "x-small":
            px = "12px";
            break;
    }

    return (
        <div className="loading-spinner-container" style={{ marginRight: "8px" }}>
            <div className="checkmark draw" style={{ display: saved ? "block" : "none" }} />
            {!saved && (
                <svg className="spinner" width={px} height={px} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle
                        className="path"
                        stroke={color != "" && color != null ? color : "#03A9F4"}
                        fill="none"
                        strokeWidth="6"
                        strokeLinecap="round"
                        cx="33"
                        cy="33"
                        r="30"
                    />
                </svg>
            )}
        </div>
    );
};

export default CircularLoading;
