import update from "immutability-helper";
import { IExternalServiceLayoutData, IActionType } from "../types/stores/externalServiceLayoutData";

export const SET_SERVICE_LAYOUT_DATA = "SET_SERVICE_LAYOUT_DATA";
export const SET_SERVICE_LAYOUT_SAVING = "SET_SERVICE_LAYOUT_SAVING";
export const SET_SERVICE_LAYOUT_SAVING_AS_NEW = "SET_SERVICE_LAYOUT_SAVING_AS_NEW";
export const SET_SERVICE_SELECTED_LAYOUT = "SET_SERVICE_SELECTED_LAYOUT";
export const SET_IMPORT_LAYOUT_FIELDS = "SET_IMPORT_LAYOUT_FIELDS";
export const SET_IMPORT_LAYOUT_IS_FIXED = "SET_IMPORT_LAYOUT_IS_FIXED";
export const SET_EXTERNAL_SERVICE_FIELDS_USED = "SET_EXTERNAL_SERVICE_FIELDS_USED";
export const SET_EXPORT_LOCKED_OUT = "SET_EXPORT_LOCKED_OUT";

const defaultState: IExternalServiceLayoutData = {
    externalServiceFields: [],
    externalServiceLayouts: [],
    externalServiceLayoutFields: [],
    externalServiceClientFieldAlias: [],
    layoutIsSaving: false,
    layoutIsSavingAsNew: false,
    selectedLayout: 0,
    importedFields: [],
    importedIsFixed: false,
    externalServiceFieldsUsed: [],
    isLockedOut: false,
};

function externalServiceLayoutData(state = defaultState, action: IActionType): IExternalServiceLayoutData {
    switch (action.type) {
        case SET_SERVICE_LAYOUT_DATA: {
            const newState: IExternalServiceLayoutData = {
                externalServiceFields: action.externalServiceFields,
                externalServiceLayouts: action.externalServiceLayouts,
                externalServiceLayoutFields: action.externalServiceLayoutFields,
                externalServiceClientFieldAlias: action.externalServiceClientFieldAlias,
                layoutIsSaving: false,
                layoutIsSavingAsNew: false,
                selectedLayout: state.selectedLayout,
                importedFields: [],
                importedIsFixed: false,
                externalServiceFieldsUsed: state.externalServiceFieldsUsed,
                isLockedOut: false,
            };

            return newState;
        }

        case SET_SERVICE_LAYOUT_SAVING: {
            return update(state, {
                layoutIsSaving: { $set: action.layoutIsSaving },
            });
        }

        case SET_SERVICE_LAYOUT_SAVING_AS_NEW: {
            return update(state, {
                layoutIsSavingAsNew: { $set: action.layoutIsSavingAsNew },
            });
        }

        case SET_SERVICE_SELECTED_LAYOUT: {
            return {
                ...state,
                selectedLayout: action.selectedLayout,
            };
        }

        case SET_IMPORT_LAYOUT_FIELDS: {
            return update(state, {
                importedFields: { $set: action.importedFields },
            });
        }

        case SET_IMPORT_LAYOUT_IS_FIXED: {
            return update(state, {
                importedIsFixed: { $set: action.importedIsFixed },
            });
        }

        case SET_EXTERNAL_SERVICE_FIELDS_USED: {
            return update(state, {
                externalServiceFieldsUsed: { $set: action.externalServiceFieldsUsed },
            });
        }

        case SET_EXPORT_LOCKED_OUT: {
            return update(state, {
                isLockedOut: { $set: action.isLockedOut },
            });
        }

        default:
            return state;
    }
}
export default externalServiceLayoutData;
