import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowItem, FlowDescription, FlowErrorsByItemId } from "../types/stores/flowTypes";
import { IFlowDescriptionStore } from "../types/stores/flowDescription";

const myGenericReducer = makeReducerFor("FLOW_DESCRIPTION", "FlowDescriptionId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { IAppState } from "../types/stores";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const myReducer = (state = {}, action: any): IFlowDescriptionStore =>
    subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

export type FlowDescriptionById = {
    [key: number]: FlowDescription;
};

export type FlowDescriptionByFlowItemId = {
    [key: number]: FlowDescription;
};

export const getFlowDescriptionsArray = createSelector(
    (state: IAppState) => state.flowDescriptions.byId,
    (flowDescriptionById: FlowDescriptionById): Array<FlowDescription> => {
        const r: Array<FlowDescription> = Object.values(flowDescriptionById);
        return r;
    }
);

// eslint-disable-line
export const getFlowDescriptionsByFlowItemId = createSelector(
    (state: IAppState) => getFlowDescriptionsArray(state),
    (flowDescription: Array<FlowDescription>): FlowDescriptionByFlowItemId =>
        flowDescription.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowDescriptionForSelectedFlowItem = createSelector(
    (state: IAppState) => state.selected.flowItem,
    (state: IAppState) => getFlowDescriptionsByFlowItemId(state),
    (selectedFlowItem: number, descriptionsByFlowItemId: FlowDescriptionByFlowItemId): FlowDescription =>
        descriptionsByFlowItemId[selectedFlowItem]
);

export const getFlowDescriptionsForSelectedFlow = createSelector(
    (state: IAppState) => getFlowItemsForSelectedFlow(state),
    (state: IAppState) => getFlowDescriptionsByFlowItemId(state),
    (flowItems: Array<FlowItem>, flowDescriptionsByFlowItemId: FlowDescriptionByFlowItemId): Array<FlowDescription> => {
        let result: Array<FlowDescription> = [];
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const description = flowDescriptionsByFlowItemId[itemId];
            if (description != null) {
                result.push(description);
            }
        }
        return result;
    }
);

const flowDescriptionToErrorsById = (flowDescriptions: Array<FlowDescription>): FlowErrorsByItemId => {
    const errorsById: FlowErrorsByItemId = {};
    for (const description of flowDescriptions) {
        errorsById[description.FlowItemId] = validateFlowDescription(description);
    }
    return errorsById;
};

export const getFlowDescriptionErrorsForSelectedFlow = createSelector(
    (state: IAppState) => getFlowDescriptionsForSelectedFlow(state),
    flowDescriptionToErrorsById
);

export const getFlowDescriptionErrorsForAllFlows = createSelector(
    (state: IAppState) => getFlowDescriptionsArray(state),
    flowDescriptionToErrorsById
);

const validateFlowDescription = (description: FlowDescription): Array<string> => {
    const errors: Array<string> = [];

    if (!description.Description || description.Description.trim() == "") {
        errors.push("Description is required.");
    }
    return errors;
};
