import { IWikiSettingsStore, WikiSettingsActions } from "../types/stores/wiki";

import clone from "../helpers/clone";

export const SET_LOADING_PAGE = "SET_LOADING_PAGE";
export const SET_SAVING_PAGE = "SET_SAVING_PAGE";
export const SET_WIKI_PAGES = "SET_WIKI_PAGES";
export const SET_LOADING_IMAGES = "SET_LOADING_IMAGES";
export const SET_WIKI_IMAGES = "SET_WIKI_IMAGES";
export const SET_WIKI_LOADING_PREVIEW = "SET_WIKI_LOADING_PREVIEW";
export const SET_WIKI_PREVIEW = "SET_WIKI_PREVIEW";
export const UPDATE_WIKI_PAGE_PROPERTY = "UPDATE_WIKI_PAGE_PROPERTY";

const initialState: IWikiSettingsStore = {
    pages: [],
    isLoading: false,
    isSaving: false,
    images: [],
    isLoadingImages: false,
    isLoadingPreview: false,
    selectedImagePreview: "",
};

function wikiSettings(state = initialState, action: WikiSettingsActions): IWikiSettingsStore {
    switch (action.type) {
        case SET_WIKI_PAGES:
            return Object.assign({}, state, {
                pages: action.pages,
            });
        case SET_LOADING_PAGE:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case SET_SAVING_PAGE:
            return Object.assign({}, state, {
                isSaving: action.isSaving,
            });
        case SET_LOADING_IMAGES:
            return Object.assign({}, state, {
                isLoadingImages: action.isLoading,
            });
        case SET_WIKI_IMAGES:
            return Object.assign({}, state, {
                images: action.images,
            });
        case UPDATE_WIKI_PAGE_PROPERTY: {
            const newPages = clone(state.pages);
            const pageIndex = newPages.findIndex(x => x.Id == action.Id);
            if (pageIndex == -1) {
                return state;
            }
            newPages[pageIndex][action.property] = action.newValue;
            return Object.assign({}, state, {
                pages: newPages,
            });
        }
        case SET_WIKI_LOADING_PREVIEW:
            return Object.assign({}, state, {
                isLoadingPreview: action.isLoadingPreview,
            });
        case SET_WIKI_PREVIEW:
            return Object.assign({}, state, {
                selectedImagePreview: action.selectedImagePreview,
            });
        default:
            return state;
    }
}
export default wikiSettings;
