import { createSelector } from "reselect";
import { IFieldLabelsStore, FieldLabelsActions } from "../types/stores/fieldLabels";
import { IAppState } from "../types/stores/index";

export const SET_FIELD_LABELS_FIELDS = "SET_FIELD_LABELS_FIELDS";
export const SET_FIELD_LABEL_TYPES = "SET_FIELD_LABEL_TYPES";
export const SET_LOADING_FIELD_LABELS = "SET_LOADING_FIELD_LABELS";
export const SET_SAVE_COMPLETED_FIELD_LABELS = "SET_SAVE_COMPLETED_FIELD_LABELS";
export const SET_IS_SAVING_FIELD_LABELS = "SET_IS_SAVING_FIELD_LABELS";

function fieldLabels(
    state: IFieldLabelsStore = {
        labels: [],
        labelTypes: [],
        isLoading: false,
        isSaving: false,
        saveCompleted: false,
    },
    action: FieldLabelsActions
): IFieldLabelsStore {
    switch (action.type) {
        case SET_FIELD_LABELS_FIELDS:
            return Object.assign({}, state, {
                labels: action.labels,
            });
        case SET_FIELD_LABEL_TYPES:
            return Object.assign({}, state, {
                labelTypes: action.labelTypes,
            });
        case SET_LOADING_FIELD_LABELS:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case SET_SAVE_COMPLETED_FIELD_LABELS:
            return Object.assign({}, state, {
                saveCompleted: action.saveCompleted,
            });
        case SET_IS_SAVING_FIELD_LABELS:
            return Object.assign({}, state, {
                isSaving: action.isSaving,
            });
        default:
            return state;
    }
}

export default fieldLabels;

// Get Approval Labels
export const getNeedsApprovalLabelIds = createSelector(
    (state: IAppState) => state.fieldLabels.labels,
    (state: IAppState) => state.fieldLabels.labelTypes,
    (labels, labelTypes) => {
        const approvalTypes = labelTypes.filter(x => x.LabelTypeName == "NEEDS_APPROVAL").map(x => x.LabelTypeId);
        return labels.filter(x => approvalTypes.includes(x.FieldLabelTypeId!)).map(x => x.FieldLabelId);
    }
);

export const getOACLabelIds = createSelector(
    (state: IAppState) => state.fieldLabels.labels,
    (state: IAppState) => state.fieldLabels.labelTypes,
    (labels, labelTypes) => {
        const approvalTypes = labelTypes
            .filter(x => x.LabelTypeName == "NEEDS_APPROVAL_INSIGHTS_RESTRICTED")
            .map(x => x.LabelTypeId);
        return labels.filter(x => approvalTypes.includes(x.FieldLabelTypeId!)).map(x => x.FieldLabelId);
    }
);
