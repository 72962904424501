import { TableauList, ITableauStore } from "../types/stores/tableau";

export const SET_TABLEAU_LIST = "SET_TABLEAU_LIST";
export const SET_TABLEAU_DISPLAY = "SET_TABLEAU_DISPLAY";
export const SET_TABLEAU_LOADING = "SET_TABLEAU_LOADING";
export const SET_TABLEAU_HOME_PAGE = "SET_TABLEAU_HOME_PAGE";
export const SET_TABLEAU_HISTORY = "SET_TABLEAU_HISTORY";
export const ADD_TO_TABLEAU_HISTORY = "ADD_TO_TABLEAU_HISTORY";

type ISET_TABLEAU_LIST = typeof SET_TABLEAU_LIST;
type ISET_TABLEAU_DISPLAY = typeof SET_TABLEAU_DISPLAY;
type ISET_TABLEAU_LOADING = typeof SET_TABLEAU_LOADING;
type ISET_TABLEAU_HOME_PAGE = typeof SET_TABLEAU_HOME_PAGE;
type IADD_TO_TABLEAU_HISTORY = typeof ADD_TO_TABLEAU_HISTORY;
type ISET_TABLEAU_HISTORY = typeof SET_TABLEAU_HISTORY;

export type ISetTableauList = {
    type: ISET_TABLEAU_LIST;
    tableauList: Array<TableauList>;
};

export type ISetTableauDisplay = {
    type: ISET_TABLEAU_DISPLAY;
    trustedTicket: string;
    ticketUrl: string;
};

export type ISetTableauLoading = {
    type: ISET_TABLEAU_LOADING;
    isLoading: boolean;
};

export type ISetTableauHomePage = {
    type: ISET_TABLEAU_HOME_PAGE;
    isHomePage: boolean;
};

export type IAddToTableauHistory = {
    type: IADD_TO_TABLEAU_HISTORY;
    item: TableauList;
};

export type ISetTableauHistory = {
    type: ISET_TABLEAU_HISTORY;
    history: Array<TableauList>;
};

type ActionType =
    | ISetTableauList
    | ISetTableauDisplay
    | ISetTableauLoading
    | ISetTableauHomePage
    | ISetTableauHistory
    | IAddToTableauHistory;

const defaultState: ITableauStore = {
    tableauList: null,
    trustedTicket: "",
    ticketUrl: "",
    isLoading: false,
    isHomePage: false,
    tableauHistory: [],
};

function tableau(state = defaultState, action: ActionType): ITableauStore {
    switch (action.type) {
        case SET_TABLEAU_LIST:
            return { ...state, tableauList: action.tableauList };
        case SET_TABLEAU_DISPLAY:
            return {
                ...state,
                trustedTicket: action.trustedTicket,
                ticketUrl: action.ticketUrl,
            };
        case SET_TABLEAU_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case SET_TABLEAU_HOME_PAGE:
            return {
                ...state,
                isHomePage: action.isHomePage,
            };
        case ADD_TO_TABLEAU_HISTORY: {
            const newHistory = [...state.tableauHistory];
            newHistory.push(action.item);
            return { ...state, tableauHistory: newHistory };
        }
        case SET_TABLEAU_HISTORY: {
            return { ...state, tableauHistory: action.history };
        }
        default:
            return state;
    }
}

export default tableau;
