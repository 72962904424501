import React from "react";
// Redux + AC
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as flowActions from "../../../actions/flowActions";
import * as actionCreators from "../../../actions/actionCreators";
import * as fieldTreeActions from "../../../actions/fieldTreeActions";
import * as clientVariableActions from "../../../actions/clientVariableActions";
import { Link } from "react-router-dom";
import { editUrlForFlowItem } from "../../../reducers/flowItems";
// Components and helpers
import GenericConfirmModal from "../../modals/GenericConfirmModal";
import FieldPicker from "../../tree-field/FieldPicker";
import FlowItemQAToggle from "./Shared/FlowItemQAToggle";
import { addCommas, renderFlowItemType } from "../../../helpers/typedHelpers";
import { makeGetFlowSVDedupe } from "../item/FlowSVDedupeEdit";
import FlowSVDedupeEdit from "./FlowSVDedupeEdit";
import { arrayMoveImmutable } from "array-move";
import OfferDedupeToggle from "./FlowOfferItems/OfferDedupeToggle";
import OfferTemplateButton from "./FlowOfferItems/OfferTemplateButton";
import OfferCodeImport from "./FlowOfferItems/OfferCodeImport";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FieldListEntryText from "../../tree-field/FieldListEntryText";

// Reselects
import { createSelector } from "reselect";
import { getFlowOfferMergesByFlowItemId } from "../../../reducers/flowOfferMerges";
import { makeGetPermissionsItemFromProps, getSelectedFlowPermissions } from "../../../reducers/flowItems";
import { getClientVariablesWithValuesForSelectedFlow } from "../../../reducers/clientVariables";
import { getFlowItemClientVariablesForSelectedFlow } from "../../../reducers/flowItemClientVariables";
import { getFlowRelationsForSelectedFlow } from "../../../reducers/flowRelations";
import { getSVDedupeErrorsForSelectedFlow } from "../../../reducers/flowSVDedupes";

// Types
import { VariableValueType, ClientVariableWithValue } from "../../../types/stores/vars";
import type { FlowOfferMergesByItemId } from "../../../reducers/flowOfferMerges";
import type { MapStateToProps } from "react-redux";
import type {
    FlowItem,
    FlowOfferMerge,
    FlowAndItemPermissions,
    FlowItemClientVariable,
    FlowItemClientVariableD,
    UpdateAttribute,
    FlowPermissions,
    FlowRelation,
    FlowSVDedupeD,
    FlowErrorsByItemId,
} from "../../../types/flowTypes";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import MaterialTooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import NumericalTextField from "../../material-components/Misc/NumericalTextField";

type Props = {
    // Passed In
    flowItemId: number,
    // Redux
    flow: any,
    flowPermissions: FlowPermissions,
    selectedFlow: number,
    selectedFlowItem: number,
    flowItems: { [number]: FlowItem },
    flowRelations: Array<FlowRelation>,
    offerCodes: Array<ClientVariableWithValue>,
    flowItemClientVariables: Array<FlowItemClientVariable>,
    flowOfferMerges: Array<FlowOfferMerge>,
    permissions: FlowAndItemPermissions,
    fields: Object,
    variableScope: string,
    flowSVDedupe: ?FlowSVDedupeD,
    flowSVDedupeErrors: ?FlowErrorsByItemId,
    // AC
    updateAttribute: UpdateAttribute,
    newFlowItemClientVariable: (
        variableId: number,
        flowId: number,
        flowItemId: number,
        childFlowItemId: number,
        variableValue: VariableValueType
    ) => void,
    deleteFlowItemClientVariable: (id: number) => void,
    requestClientVariables: () => void,
    deleteFlowOfferMerge: (id: number) => void,
    invalidateItemAndChildren: (flowItemId: number) => void,
    goEditFlowItem: (FlowItemId: number) => void,
    flowItemSetClientVariable: (
        flowId: number,
        flowItemId: number,
        variableId: number,
        variableValue: VariableValueType
    ) => void,
    showModal: (string, any) => void,
    newFlowSVDedupe: (flowItemId: number) => void,
    deleteFlowSVDedupe: (id: number) => void,
    setHasUnsavedChanges: (flowItemId: number) => void,
    updateFlowOfferMerge: (flowOfferMergeId: number, flowOfferPriority: number) => void,
    flowItemSetOfferCode: (
        flowId: number,
        flowItemId: number,
        variableId: number,
        isVisible: boolean,
        sortOrder: ?number
    ) => void,
    setSelectedField: (fieldKey: ?number) => void,
};

type State = {
    sortBy: string,
    sortDirection: string,
    tabKey: ?number,
    hasDedupe: boolean,
    hasDedupeErrors: boolean,
    sortedParentFlowItems: Array<any>,
    stringFieldOfferId: ?number,
    stringFieldParent: ?number,
};

class FlowOfferMergeEdit extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: "ItemName",
            sortDirection: "ASC",
            tabKey: 1,
            hasDedupe: false,
            hasDedupeErrors: false,
            sortedParentFlowItems: [],
            stringFieldOfferId: 0,
            stringFieldParent: 0,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps != this.props) {
            this.setDedupeErrors();
            this.setState({ hasDedupe: this.props.flowSVDedupe != null });
            if (this.props.flowSVDedupe != null) {
                this.resetPriorities();
                this.setState({ sortBy: "FlowOfferPriority", sortDirection: "ASC" });
            }
        }
    }

    resetPriorities = () => {
        const { flowOfferMerges, updateFlowOfferMerge } = this.props;

        // Set New items to bottom priority
        let maxPri = Math.max(...flowOfferMerges.map(x => x.FlowOfferPriority));
        for (const flowOfferMerge of flowOfferMerges) {
            if (flowOfferMerge.FlowOfferPriority <= 0) {
                flowOfferMerge.FlowOfferPriority = ++maxPri;
                updateFlowOfferMerge(flowOfferMerge.FlowOfferMergeId, maxPri);
            }
        }

        // Resort and reassign to account for deleted items
        this.sortOffersByPriority();
        let newPri = 1;
        for (const flowOfferMerge of flowOfferMerges) {
            // Update state if value changed
            if (flowOfferMerge.FlowOfferPriority != newPri) {
                updateFlowOfferMerge(flowOfferMerge.FlowOfferMergeId, newPri);
            }
            newPri++;
        }
    };

    resetOfferCodeSortOrder = () => {
        const { offerCodes, flowItemSetOfferCode, selectedFlow, selectedFlowItem } = this.props;

        // Set New items to last
        const sortedOffers = [...offerCodes];
        let maxOrder = Math.max(...offerCodes.map(x => x.SortOrder || 0));
        sortedOffers.forEach((value, index) => {
            if (sortedOffers[index].IsVisible && sortedOffers[index].SortOrder == null) {
                maxOrder++;
                sortedOffers[index].SortOrder = maxOrder;
            } else if (!sortedOffers[index].IsVisible) {
                sortedOffers[index].SortOrder = null;
            }
        });

        // Sort and reassign
        sortedOffers.sort((a, b) => {
            if (a["SortOrder"] == null && b["SortOrder"] != null) {
                return -1;
            }
            if (a["SortOrder"] != null && b["SortOrder"] == null) {
                return 1;
            }
            if (a["SortOrder"] == null && b["SortOrder"] == null) {
                return 0;
            }
            if (a["SortOrder"] != null && b["SortOrder"] != null && a["SortOrder"] > b["SortOrder"]) {
                return 1;
            }
            if (a["SortOrder"] != null && b["SortOrder"] != null && a["SortOrder"] < b["SortOrder"]) {
                return -1;
            }
            return 0;
        });

        let newSort = 1;
        sortedOffers.forEach((value, index) => {
            flowItemSetOfferCode(
                selectedFlow,
                selectedFlowItem,
                sortedOffers[index].Id,
                sortedOffers[index].IsVisible,
                sortedOffers[index].IsVisible ? newSort : null
            );
            if (sortedOffers[index].IsVisible) {
                newSort++;
            }
        });
    };

    sortOffersByPriority = () => {
        const { flowOfferMerges } = this.props;
        flowOfferMerges.sort((a, b) => {
            if (a["FlowOfferPriority"] > b["FlowOfferPriority"]) {
                return 1;
            }
            if (a["FlowOfferPriority"] < b["FlowOfferPriority"]) {
                return -1;
            }
            return 0;
        });
    };

    handleTabSelect = tabKey => this.setState({ tabKey });

    editVisibility = () => {
        const { showModal, variableScope, offerCodes } = this.props;

        // Set Initial Sort Order for Offers
        this.resetOfferCodeSortOrder();

        const clientVariablesWithValues = offerCodes;
        const modalProps = {
            variableScope,
            clientVariablesWithValues,
        };
        showModal("FLOW_VISIBLE_VARIABLES", modalProps);
    };

    bulkAssignModal = e => {
        const { showModal, offerCodes } = this.props;
        const offerCodeId = e.currentTarget.id;
        if (!offerCodeId) {
            return;
        }
        const selectedOffer = offerCodes.find(x => x.Id == offerCodeId);
        if (!selectedOffer) {
            return;
        }
        if (
            selectedOffer.VariableKind == "string" ||
            selectedOffer.VariableKind == "field" ||
            selectedOffer.VariableKind == "stringField"
        ) {
            showModal("FLOW_OFFER_CODE_VALUE", {
                variableId: selectedOffer.Id,
                variableKind: selectedOffer.VariableKind,
                variableName: selectedOffer.VariableName,
                assignValues: this.bulkAssignValues,
                regex: selectedOffer.Regex,
                dropdownValues: selectedOffer.DropdownValues,
            });
        } else {
            return;
        }
    };

    bulkAssignValues = (id, kind, bulkValue) => {
        const {
            flowOfferMerges,
            flowItemClientVariables,
            updateAttribute,
            newFlowItemClientVariable,
            selectedFlow,
            setSelectedField,
            flowItemId,
        } = this.props;
        flowOfferMerges.forEach((value, index) => {
            const foundItemClientVariable = flowItemClientVariables.find(
                x => x.VariableId == id && x.FlowItemId == flowOfferMerges[index].ParentFlowItemId
            );
            let newValueObject: VariableValueType = {
                Kind: kind,
                ValueString: kind == "string" ? bulkValue : null,
                ValueDate: null,
                FieldId: kind == "field" ? bulkValue : null,
            };
            if (kind == "stringField") {
                newValueObject = {
                    Kind: kind,
                    ValueString: bulkValue.split("+").shift(),
                    ValueDate: null,
                    FieldId: parseInt(bulkValue.split("+").pop()),
                };
            }
            if (foundItemClientVariable) {
                updateAttribute(
                    "flowItemClientVariables",
                    foundItemClientVariable.FlowItemClientVariableId,
                    "VariableValue",
                    newValueObject,
                    true
                );
            } else {
                newFlowItemClientVariable(
                    id,
                    selectedFlow,
                    flowOfferMerges[index].ParentFlowItemId,
                    flowItemId,
                    newValueObject
                );
            }
        });

        // Clear selected field
        if (kind == "field" || kind == "stringField") {
            setSelectedField(null);
        }
    };

    downloadExcelLink = flowItemId => {
        const { hasDedupe } = this.state;
        const excelLink = `/FlowItemReports/OfferMergeReport?flowItemId=${flowItemId}&hasDedupe=${hasDedupe.toString()}`;
        window.location = excelLink;
    };

    filterOfferCodes = () => {
        const { offerCodes } = this.props;
        const visibleOffers = offerCodes.filter(x => x.IsVisible);
        visibleOffers.sort((a, b) => {
            if (a["SortOrder"] == null || b["SortOrder"] == null) {
                return 0;
            }
            if (a["SortOrder"] > b["SortOrder"]) {
                return 1;
            }
            if (a["SortOrder"] < b["SortOrder"]) {
                return -1;
            }
            return 0;
        });

        return visibleOffers;
    };

    invalidate = () => {
        const { flowItemId, invalidateItemAndChildren } = this.props;
        invalidateItemAndChildren(flowItemId);
    };

    delete = (id: number) => {
        const { deleteFlowOfferMerge } = this.props;
        deleteFlowOfferMerge(id);
        this.invalidate();
    };

    onVariableChange = (
        flowItemClientVariable: ?FlowItemClientVariableD,
        variableId: number,
        parentFlowItemId: number,
        variableValue: VariableValueType
    ) => {
        const {
            selectedFlow,
            updateAttribute,
            newFlowItemClientVariable,
            deleteFlowItemClientVariable,
            flowItemClientVariables,
            flowItemId,
        } = this.props;

        if (flowItemClientVariable) {
            if (variableValue) {
                if (variableValue.ValueString != "" || variableValue.FieldId) {
                    updateAttribute(
                        "flowItemClientVariables",
                        flowItemClientVariable.FlowItemClientVariableId,
                        "VariableValue",
                        variableValue,
                        true
                    );
                } else {
                    deleteFlowItemClientVariable(flowItemClientVariable.FlowItemClientVariableId);
                }
            } else {
                deleteFlowItemClientVariable(flowItemClientVariable.FlowItemClientVariableId);
            }
        } else if (
            flowItemClientVariables.filter(
                x => x.VariableId == variableId && x.FlowItemId == parentFlowItemId && x.ChildFlowItemId == flowItemId
            ).length == 0
        ) {
            newFlowItemClientVariable(variableId, selectedFlow, parentFlowItemId, flowItemId, variableValue);
        }

        this.invalidate();
    };

    onPriorityChange = (val, Id) => {
        const { flowOfferMerges, updateFlowOfferMerge } = this.props;
        const row = flowOfferMerges.find(x => x.FlowOfferMergeId == Id);
        if (!row || !val) {
            return;
        }

        const intNewPri = parseInt(val);
        this.sortOffersByPriority();
        const changedOffer = flowOfferMerges.find(x => x.FlowOfferMergeId == Id);
        if (!changedOffer) {
            return;
        }
        const oldIndex = flowOfferMerges.indexOf(changedOffer);
        const newIndex = intNewPri - 1;
        let updatedMerges = arrayMoveImmutable(flowOfferMerges, oldIndex, newIndex);
        updatedMerges.forEach((value, index) => {
            const newPri = index + 1;
            const idToUpdate = updatedMerges[index]["FlowOfferMergeId"];
            updatedMerges[index].FlowOfferPriority = newPri;
            updateFlowOfferMerge(idToUpdate, newPri);
        });

        this.setState({ sortedParentFlowItems: updatedMerges });

        this.invalidate();
        this.forceUpdate();
    };

    getRows = (arrayToPopulate, tableId, row) => {
        const tableElement = document.querySelector(tableId);
        if (tableElement instanceof HTMLTableElement) {
            const numCols = tableElement && tableElement.rows ? tableElement.rows[0].cells.length : 0;
            let col = 0;
            if (row < 0) {
                return;
            }

            for (col = 0; col < numCols; ++col) {
                if (tableElement.rows[row].cells.length > col) {
                    if (tableElement.rows[row].cells[col].className == "editable-string-td") {
                        arrayToPopulate.push(tableElement.rows[row].cells[col]);
                    }
                }
            }
        }
    };

    getColumns = (arrayToPopulate, tableId, col) => {
        const tableElement = document.querySelector(tableId);
        if (tableElement instanceof HTMLTableElement) {
            const numRows = tableElement && tableElement.rows ? tableElement.rows.length : 0;
            let row = 0;

            if (col < 0) {
                return;
            }

            for (row = 0; row < numRows; ++row) {
                if (tableElement.rows[row].cells.length > col) {
                    if (tableElement.rows[row].cells[col].className == "editable-string-td") {
                        arrayToPopulate.push(tableElement.rows[row].cells[col]);
                    }
                }
            }
        }
    };

    handleKeyPress = e => {
        let editableStringArray = [];
        const table = document.querySelector("#offer-code-tables");
        const isEnterKey = e.key == "Enter";
        const isTabKey = e.key == "Tab";

        if ((isEnterKey || isTabKey) && table instanceof HTMLTableElement) {
            if (isEnterKey) {
                for (let i = 0; i < table.rows[0].cells.length; ++i) {
                    this.getColumns(editableStringArray, "#offer-code-tables", i);
                }
            } else if (isTabKey) {
                for (let i = 0; i < table.rows.length; ++i) {
                    this.getRows(editableStringArray, "#offer-code-tables", i);
                }
            }

            e.preventDefault(); // prevent default event for tab or enter
            this.navTable(e, editableStringArray);
        }
    };

    navTable = (e, editableStringArray) => {
        const useShiftKey = e.shiftKey;
        const identifier = e.target.id;
        let nextChild = null;

        if (!useShiftKey) {
            nextChild =
                editableStringArray[
                    editableStringArray.findIndex(el => {
                        if (el && el.firstChild) {
                            const input = el.firstChild.querySelector(".MuiInputBase-input");
                            if (input instanceof HTMLInputElement) {
                                return input.id == identifier;
                            }
                        }
                    }) + 1
                ];

            if (nextChild && nextChild.firstChild) {
                const nextInput = nextChild.firstChild.querySelector(".MuiInputBase-input");
                if (nextInput && nextInput instanceof HTMLInputElement) {
                    nextInput.focus();
                    nextInput.select();
                }
            }
        } else {
            nextChild =
                editableStringArray[
                    editableStringArray.findIndex(el => {
                        if (el && el.firstChild) {
                            const input = el.firstChild.querySelector(".MuiInputBase-input");
                            if (input instanceof HTMLInputElement) {
                                return input.id == identifier;
                            }
                        }
                    }) - 1
                ];

            if (nextChild && nextChild.firstChild && nextChild.firstChild instanceof HTMLInputElement) {
                const nextInput = nextChild.firstChild.querySelector(".MuiInputBase-input");
                if (nextInput && nextInput instanceof HTMLInputElement) {
                    nextInput.focus();
                    nextInput.select();
                }
            }
        }
    };

    renderEditableString = (
        flowItemClientVariable,
        variableId,
        parentFlowItemId,
        valueString,
        editableIdentifier,
        regex,
        dropdownValues
    ) => {
        const {
            permissions: {
                item: { canEdit },
            },
            flow,
        } = this.props;

        if (dropdownValues) {
            const dropdownArr = dropdownValues.split("|");
            if (dropdownArr.length > 0) {
                return (
                    <Select
                        id="master-select"
                        value={valueString || ""}
                        onChange={e => {
                            const newValueObject: VariableValueType = {
                                Kind: "string",
                                ValueString: e.target.value,
                                ValueDate: null,
                                FieldId: null,
                            };

                            this.onVariableChange(flowItemClientVariable, variableId, parentFlowItemId, newValueObject);
                        }}
                        style={{ fontSize: 16, width: "100%" }}
                    >
                        {dropdownArr.map(x => (
                            <MenuItem key={x} value={x}>
                                {x}
                            </MenuItem>
                        ))}
                    </Select>
                );
            }
        }
        let sequenceStr = false;
        if (valueString.startsWith("{")) {
            sequenceStr = true;
        }
        // set error state for regex values
        const reg = new RegExp(regex);
        const isError = Boolean(valueString && regex && !reg.test(valueString));
        const helperText = isError ? "Value doesn't match " + regex : "";

        return (
            <TextField
                key={editableIdentifier}
                value={valueString || ""}
                id={editableIdentifier}
                disabled={!canEdit || (flow && flow.IsLocked) || sequenceStr}
                error={isError}
                onKeyDown={e => {
                    this.handleKeyPress(e);
                }}
                onChange={e => {
                    const newValue = e.target.value;

                    const newValueObject: VariableValueType = {
                        Kind: "string",
                        ValueString: newValue,
                        ValueDate: null,
                        FieldId: null,
                    };
                    this.onVariableChange(flowItemClientVariable, variableId, parentFlowItemId, newValueObject);
                }}
                helperText={helperText}
            />
        );
    };

    renderEditableField = (flowItemClientVariable, variableId, flowItemId, fieldId) => {
        const {
            permissions: {
                item: { canEdit },
            },
            flow,
        } = this.props;
        return (
            <FieldPicker
                value={fieldId}
                disabled={!canEdit || (flow && flow.IsLocked)}
                onChange={newFieldId => {
                    const newValueObject: VariableValueType = {
                        Kind: "field",
                        ValueString: null,
                        ValueDate: null,
                        FieldId: newFieldId,
                    };
                    this.onVariableChange(flowItemClientVariable, variableId, flowItemId, newValueObject);
                }}
            />
        );
    };

    renderEditableStringField = (id, flowItemClientVariable, parentFlowItemId) => {
        const { showModal, offerCodes } = this.props;
        this.setState({ stringFieldParent: parentFlowItemId });
        if (flowItemClientVariable != null) {
            this.setState({ stringFieldOfferId: flowItemClientVariable.FlowItemClientVariableId });
        }
        const selectedOffer = offerCodes.find(x => x.Id == id);
        if (!selectedOffer) {
            return;
        }
        showModal("FLOW_OFFER_CODE_VALUE", {
            variableId: selectedOffer.Id,
            variableKind: selectedOffer.VariableKind,
            variableName: selectedOffer.VariableName,
            assignValues: this.assignStringFieldValues,
            regex: selectedOffer.Regex,
            dropdownValues: selectedOffer.DropdownValues,
        });
    };

    assignStringFieldValues = (id, kind, stringFieldValue) => {
        const { updateAttribute, newFlowItemClientVariable, selectedFlow, setSelectedField, flowItemId } = this.props;
        const { stringFieldOfferId, stringFieldParent } = this.state;
        const newValueObject: VariableValueType = {
            Kind: kind,
            ValueString: stringFieldValue.split("+").shift(),
            ValueDate: null,
            FieldId: parseInt(stringFieldValue.split("+").pop()),
        };
        if (stringFieldOfferId != 0) {
            updateAttribute("flowItemClientVariables", stringFieldOfferId, "VariableValue", newValueObject, true);
        } else {
            newFlowItemClientVariable(id, selectedFlow, stringFieldParent, flowItemId, newValueObject);
        }
        this.setState({ stringFieldOfferId: 0, stringFieldParent: 0 });
        setSelectedField(null);
    };

    clearEditableStringField(flowItemClientVariable) {
        if (flowItemClientVariable != null) {
            this.props.deleteFlowItemClientVariable(flowItemClientVariable.FlowItemClientVariableId);
        }
    }

    renderActions = flowOfferMergeId => {
        const {
            permissions: {
                item: { canEdit },
            },
            flow,
        } = this.props;

        const confirmDeleteBody = <div>{`Are you sure you want to delete this row?`}</div>;
        const confirmDeleteText = "Delete Row";

        return (
            <GenericConfirmModal
                onConfirm={() => this.delete(flowOfferMergeId)}
                body={confirmDeleteBody}
                confirmText={confirmDeleteText}
                title="Confirm Delete Merge"
                buttonText={
                    <i
                        className={`material-icons material-icon-hover-style ${
                            !canEdit || (flow && flow.IsLocked) ? "" : "clickable"
                        } close`}
                    ></i>
                }
                buttonDisabled={!canEdit || (flow && flow.IsLocked)}
                cnBtnStyle="normal-transparent-button"
            />
        );
    };

    renderOfferItems = parentFlowItemId => {
        const { flowItemClientVariables, flowOfferMerges } = this.props;
        const { sortedParentFlowItems } = this.state;

        let offerItems = [];
        let index = 1;
        for (const offerCode of this.filterOfferCodes()) {
            const foundFlowItemClientVariable = flowItemClientVariables.filter(
                x => x.FlowItemId == parentFlowItemId && x.VariableId == offerCode.Id
            );
            let flowItemClientVariable;
            if (foundFlowItemClientVariable.length > 0) {
                flowItemClientVariable = foundFlowItemClientVariable[0];
            }
            if (offerCode.VariableKind == "stringField") {
                const valueString = flowItemClientVariable ? flowItemClientVariable.VariableValue.ValueString : "";
                const fieldId = flowItemClientVariable ? flowItemClientVariable.VariableValue.FieldId : null;
                offerItems.push(
                    <td
                        style={{ minWidth: "180px" }}
                        className="editable-string-td"
                        key={"field" + parentFlowItemId + offerCode.Id}
                    >
                        {valueString + " "}
                        {fieldId != null ? <FieldListEntryText fieldKey={fieldId} /> : ""}
                        <div className="field-picker">
                            <i
                                className="material-icons clickable x-small-fab"
                                onClick={() =>
                                    this.renderEditableStringField(
                                        offerCode.Id,
                                        flowItemClientVariable,
                                        parentFlowItemId
                                    )
                                }
                            >
                                edit
                            </i>
                            <i
                                className="material-icons clickable x-small-fab"
                                onClick={() => this.clearEditableStringField(flowItemClientVariable)}
                            >
                                clear
                            </i>
                        </div>
                    </td>
                );
            }

            if (offerCode.VariableKind == "field") {
                const fieldId = flowItemClientVariable ? flowItemClientVariable.VariableValue.FieldId : null;
                const field = this.renderEditableField(flowItemClientVariable, offerCode.Id, parentFlowItemId, fieldId);
                offerItems.push(<td key={"field" + parentFlowItemId + offerCode.Id}>{field}</td>);
            } else if (offerCode.VariableKind == "string") {
                const valueString = flowItemClientVariable ? flowItemClientVariable.VariableValue.ValueString : "";

                // Only get sorted parents when priority changes, else we get the original.
                let mergeItem = null;
                if (sortedParentFlowItems.length > 0) {
                    mergeItem = sortedParentFlowItems.find(item => item.ParentFlowItemId == parentFlowItemId);
                } else {
                    mergeItem = flowOfferMerges.find(item => item.ParentFlowItemId == parentFlowItemId);
                }

                const identifier = mergeItem
                    ? `${
                          mergeItem.FlowOfferPriority && mergeItem.FlowOfferPriority > 0
                              ? mergeItem.FlowOfferPriority
                              : mergeItem.ParentFlowItemId
                      }-${index}`
                    : "";

                const inputText = this.renderEditableString(
                    flowItemClientVariable,
                    offerCode.Id,
                    parentFlowItemId,
                    valueString,
                    identifier,
                    offerCode.Regex,
                    offerCode.DropdownValues
                );
                offerItems.push(
                    <td
                        key={"text" + parentFlowItemId + offerCode.Id}
                        style={{ minWidth: "180px" }}
                        className="editable-string-td"
                    >
                        {inputText}
                    </td>
                );
                index++;
            }
        }

        return offerItems;
    };

    renderPrioritySelect = parentFlowItemId => {
        const {
            flowOfferMerges,
            permissions: {
                item: { canEdit },
            },
            flow,
        } = this.props;
        const item = flowOfferMerges.find(x => x.ParentFlowItemId == parentFlowItemId);
        if (!item) {
            return null;
        }
        return (
            <NumericalTextField
                className="material-form-control"
                disabled={!canEdit || (flow && flow.IsLocked)}
                size="small"
                value={item.FlowOfferPriority}
                onBlur={e => {
                    let value = parseInt(e.target.value);
                    if (value < 1) {
                        value = 1;
                    } else if (value.toString().length > 3) {
                        value = 999;
                    }
                    this.onPriorityChange(value, item.FlowOfferMergeId);
                }}
            />
        );
    };

    createOfferTable = () => {
        const { flowOfferMerges, flowItems, flowItemClientVariables, flowRelations } = this.props;
        let table = [];

        for (const flowOfferMerge of flowOfferMerges) {
            const parentFlowItem: ?FlowItem = flowItems[flowOfferMerge.ParentFlowItemId];

            let line = {};

            line.flowOfferMerge = flowOfferMerge;
            line.Quantity = 0;
            line.ItemName = "Item not found";
            line.ParentFlowItemId = flowOfferMerge.ParentFlowItemId;
            line.FlowOfferPriority = flowOfferMerge.FlowOfferPriority;

            if (parentFlowItem) {
                line.Quantity = parseInt(parentFlowItem.FlowItemQty);

                if (parentFlowItem.FlowItemType != "empty") {
                    const parentFlowItemType = renderFlowItemType(parentFlowItem.FlowItemType);
                    line.ItemName = parentFlowItem.FlowItemName + " - " + parentFlowItemType;
                } else {
                    line.ItemName = parentFlowItem.FlowItemName;

                    const emptyParentRelations = flowRelations
                        .filter(x => x.ChildFlowItemId == parentFlowItem.FlowItemId && x.ParentFlowItemId != 0)
                        .map(x => x.ParentFlowItemId);

                    if (emptyParentRelations.length > 0) {
                        const emptyParentFlowItemId = emptyParentRelations[0];
                        const emptyParentFlowItem: FlowItem = flowItems[emptyParentFlowItemId];
                        const emptyParentFlowItemType = renderFlowItemType(emptyParentFlowItem.FlowItemType);
                        line.ItemName += " - " + emptyParentFlowItem.FlowItemName + " - " + emptyParentFlowItemType;
                    }
                }
            }

            for (const offerCode of this.filterOfferCodes()) {
                const foundFlowItemClientVariable = flowItemClientVariables.filter(
                    x => x.FlowItemId == flowOfferMerge.ParentFlowItemId && x.VariableId == offerCode.Id
                );

                let flowItemClientVariable = null;
                if (foundFlowItemClientVariable.length > 0) {
                    flowItemClientVariable = foundFlowItemClientVariable[0];
                }

                line[offerCode.VariableName] = flowItemClientVariable;
            }

            table.push(line);
        }

        return this.sortTable(table);
    };

    setSort = e => {
        const { sortBy, sortDirection } = this.state;

        const newSortBy = e.currentTarget.dataset.sortfield || "Quantity";

        let newSortDirection = "ASC";
        if (newSortBy == sortBy && sortDirection == "ASC") {
            newSortDirection = "DESC";
        }

        this.setState({ sortBy: newSortBy, sortDirection: newSortDirection });
    };

    getVariableValue = input => {
        const { fields } = this.props;

        if (input == null) {
            return "";
        }

        let output = input ? input : "";
        if (input.VariableValue) {
            const kind = input.VariableValue.Kind;
            if (kind == "string") {
                output = input.VariableValue.ValueString;
            } else if (kind == "field") {
                output = fields[input.VariableValue.FieldId] ? fields[input.VariableValue.FieldId].text : "";
            }
        }

        return output.toString().toLowerCase();
    };

    sortTable = offerTable => {
        const { sortBy, sortDirection } = this.state;

        let sortedOfferTable = offerTable;
        if (sortBy && sortDirection) {
            sortedOfferTable = offerTable.sort((a, b) => {
                const nameA = this.getVariableValue(a[sortBy]);
                const nameB = this.getVariableValue(b[sortBy]);
                const bothNumbers = !isNaN(parseFloat(nameA)) && !isNaN(parseFloat(nameB));

                let comparison = 0;
                if (sortDirection == "ASC") {
                    if (bothNumbers) {
                        if (Number(nameA) < Number(nameB)) {
                            comparison = -1;
                        } else if (Number(nameA) > Number(nameB)) {
                            comparison = 1;
                        }
                    } else if (nameA < nameB) {
                        comparison = -1;
                    } else if (nameA > nameB) {
                        comparison = 1;
                    }

                    if (comparison == 0) {
                        comparison = a.ParentFlowItemId < b.ParentFlowItemId ? -1 : 1;
                    }

                    return comparison;
                } else if (bothNumbers) {
                    return Number(nameA) > Number(nameB) ? -1 : 1;
                } else {
                    return nameA > nameB ? -1 : 1;
                }
            });
        }
        return sortedOfferTable;
    };

    renderOfferHeader = offerCode => {
        const {
            permissions: {
                item: { canEdit },
            },
            flow,
        } = this.props;

        const header = (
            <th key={offerCode.VariableName}>
                <div style={{ display: "flex" }}>
                    <span
                        onClick={this.setSort}
                        data-sortfield={offerCode.VariableName}
                        style={{ marginRight: "10px" }}
                    >
                        {offerCode.VariableName}
                    </span>
                    {canEdit && (
                        <div
                            id={offerCode.Id}
                            onClick={this.bulkAssignModal}
                            disabled={!canEdit || (flow && flow.IsLocked)}
                        >
                            <MaterialTooltip style={{ fontSize: 14 }} title={"Assign a value to All items"}>
                                <Icon>edit</Icon>
                            </MaterialTooltip>
                        </div>
                    )}
                </div>
            </th>
        );

        return header;
    };

    editItem = id => {
        const { goEditFlowItem } = this.props;
        goEditFlowItem(id);
    };

    renderNewTable = () => {
        const { hasDedupe } = this.state;
        const { flowItemId, flowItems } = this.props;
        const currentFlowItem = flowItems[flowItemId];

        let sortedOfferTable = this.createOfferTable();

        let offerHeaders = [];
        for (const offerCode of this.filterOfferCodes()) {
            offerHeaders.push(this.renderOfferHeader(offerCode));
        }

        let tableLines = [];

        for (const line of sortedOfferTable) {
            const offerItems = this.renderOfferItems(line.flowOfferMerge.ParentFlowItemId);

            const actionsColumn = this.renderActions(line.flowOfferMerge.FlowOfferMergeId);
            const formattedQty = line.Quantity ? addCommas(line.Quantity) : "N/A";
            const prioritySelect = this.renderPrioritySelect(line.flowOfferMerge.ParentFlowItemId);
            const dedupeClass = `text-center${hasDedupe ? " headcol" : " hidden"}`;

            tableLines.push(
                <tr key={line.flowOfferMerge.FlowOfferMergeId}>
                    <td className="frozenFirstColumn" title={"Quantity: " + formattedQty}>
                        {line.ItemName ? (
                            <Link
                                style={{ color: "white" }}
                                to={editUrlForFlowItem(currentFlowItem.FlowId, line.flowOfferMerge.ParentFlowItemId)}
                                onClick={() => this.editItem(line.flowOfferMerge.ParentFlowItemId)}
                            >
                                {line.ItemName}
                            </Link>
                        ) : (
                            "Item not found"
                        )}
                    </td>
                    <td className={dedupeClass}>{prioritySelect}</td>
                    <td className="headcol text-center">{formattedQty}</td>
                    <td className={dedupeClass}>
                        {currentFlowItem && currentFlowItem.HasResultTable
                            ? line.flowOfferMerge
                                ? addCommas(line.flowOfferMerge.DupesQty)
                                : ""
                            : "N/A"}
                    </td>

                    {offerItems}
                    {<td>{actionsColumn}</td>}
                </tr>
            );
        }

        const offerTable = (
            <table id="offer-code-tables" className="">
                <thead className="flowTableHeader">
                    <tr>
                        <th onClick={this.setSort} data-sortfield="ItemName" className="frozenFirstColumn itemColumn">
                            Item
                        </th>
                        <th
                            onClick={this.setSort}
                            data-sortfield="FlowOfferPriority"
                            className={hasDedupe ? "headcol" : "hidden"}
                        >
                            Priority
                        </th>
                        <th onClick={this.setSort} data-sortfield="Quantity" className="headcol">
                            Input Quantity
                        </th>
                        <th
                            onClick={this.setSort}
                            data-softfield="DedupeQuantity"
                            className={hasDedupe ? "headcol" : "hidden"}
                        >
                            Output Quantity
                        </th>

                        {offerHeaders}
                        <th />
                    </tr>
                </thead>
                <tbody>{tableLines}</tbody>
            </table>
        );

        return offerTable;
    };

    setDedupeErrors = () => {
        const { flowItemId, flowSVDedupeErrors } = this.props;
        let hasErrors = false;
        if (flowItemId && flowSVDedupeErrors) {
            const errors = flowSVDedupeErrors[flowItemId];
            if (errors && errors.length > 0) {
                hasErrors = true;
            }
        }
        this.setState({ hasDedupeErrors: hasErrors });
    };

    render() {
        const { hasDedupe, hasDedupeErrors } = this.state;
        const { offerCodes, flowItemId, flowOfferMerges, permissions, flow } = this.props;

        if (flowItemId == null || offerCodes.length == 0 || flowOfferMerges.length == 0) {
            return null;
        }

        const newTable = this.renderNewTable();

        const visibilityButton = (
            <Button
                variant="contained"
                color="secondary"
                className={"edit-button"}
                startIcon={<i className="material-icons">edit</i>}
                onClick={this.editVisibility}
                disabled={!permissions.item.canEdit || (flow && flow.IsLocked)}
            >
                Edit Visibility
            </Button>
        );

        const templateButton = (
            <OfferTemplateButton
                flowItemId={flowItemId}
                isDisabled={!permissions.item.canCalculate || !permissions.item.canEdit || (flow && flow.IsLocked)}
            />
        );

        const tableClass = hasDedupeErrors ? "offer-table-errors" : "offer-table-full";

        const offerMergeEdit = (
            <div>
                <div className="flow-edit-header">
                    <p>Offer Settings</p>
                    <div className="flow-edit-header-actions">
                        <OfferDedupeToggle
                            flowItemId={flowItemId}
                            onChangeCallback={() => this.handleTabSelect(1)}
                            isDisabled={!permissions.item.canEdit || (flow && flow.IsLocked)}
                        />
                        <FlowItemQAToggle
                            flowItemId={flowItemId}
                            isDisabled={!permissions.item.canEdit || (flow && flow.IsLocked)}
                        />
                        <div className="row m-t" style={{ marginLeft: "50px", marginRight: "0px" }}>
                            {visibilityButton}
                        </div>
                    </div>
                </div>
                <Divider style={{ margin: "20px auto" }} />
                <div className="flow-edit-body">
                    <div className={tableClass}>{newTable}</div>

                    {hasDedupe && (
                        <FlowSVDedupeEdit
                            /* Setting 'key' is a hack to force the component to reload
                            to fix a focus issue on the Switch component */
                            key={this.state.tabKey}
                            flowItemId={flowItemId}
                            canEdit={permissions.item.canEdit || (flow && flow.IsLocked)}
                            containerHeight={{ minHeight: "200px" }}
                        />
                    )}

                    <div className="flow-edit-header">
                        <div style={{ flexGrow: 1 }}>
                            <p>Import</p>
                            <span className="helper-text">
                                Import your own file to automatically populate and configure your offer codes
                            </span>
                        </div>
                        <div className="flow-edit-header-actions">{templateButton}</div>
                    </div>
                    <Divider style={{ margin: "20px auto" }} />
                    <OfferCodeImport
                        flowItemId={flowItemId}
                        offerCodes={offerCodes}
                        flowOfferMerges={flowOfferMerges}
                        handleTabSelect={this.handleTabSelect}
                        permissions={permissions}
                    />
                </div>
            </div>
        );

        return offerMergeEdit;
    }
}

export const makeGetFlowOfferMerge = () =>
    createSelector(
        state => getFlowOfferMergesByFlowItemId(state),
        (_, props) => props.flowItemId,
        (flowOfferMergesByFlowItemId: FlowOfferMergesByItemId, flowItemId: number): Array<FlowOfferMerge> =>
            flowOfferMergesByFlowItemId[flowItemId]
    );

const variableScope = "OfferCode";
const mapStateToProps: MapStateToProps<*, *, *> = () => {
    const getFlowOfferMerge = makeGetFlowOfferMerge();
    const getPermissionsItemFromProps = makeGetPermissionsItemFromProps();
    const getFlowSVDedupe = makeGetFlowSVDedupe();
    return (state, ownProps) => ({
        flow: state.flows.byId[state.selected.flow],
        selectedFlow: state.selected.flow,
        selectedFlowItem: state.selected.flowItem,
        flowItems: state.flowItems.byId,
        flowRelations: getFlowRelationsForSelectedFlow(state),
        offerCodes: getClientVariablesWithValuesForSelectedFlow(state).filter(x => x.VariableScope == variableScope),
        flowItemClientVariables: getFlowItemClientVariablesForSelectedFlow(state), // array
        flowOfferMerges: getFlowOfferMerge(state, ownProps) || [],
        flowPermissions: getSelectedFlowPermissions(state),
        permissions: getPermissionsItemFromProps(state, ownProps),
        fields: state.fields.byId,
        variableScope,
        flowSVDedupe: getFlowSVDedupe(state, ownProps),
        flowSVDedupeErrors: getSVDedupeErrorsForSelectedFlow(state),
    });
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        Object.assign({}, actionCreators, flowActions, clientVariableActions, fieldTreeActions),
        dispatch
    );
const FlowOfferMergeEditC = connect(mapStateToProps, mapDispatchToProps)(FlowOfferMergeEdit);

export default FlowOfferMergeEditC;
