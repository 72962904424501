import { Dispatch, GetState } from "../types/types";
import { IQueueFile, IFileChunk, FILE_UPLOAD_STATUS } from "../types/stores/fileImportQueue";
import {
    ADD_TO_QUEUE,
    UPDATE_FILE_CHUNK,
    INC_ACTIVE_CONNECTIONS,
    DEC_ACTIVE_CONNECTIONS,
    UPDATE_FILE_REQUESTS_FAILED,
    DELETE_FROM_QUEUE,
} from "../types/actions/fileImportQueue";
import { IAppState } from "../types/stores";
import h from "../helpers";
import { toJson } from "../helpers/typedHelpers";
import { notifyGreen } from "./notifyActions";
import { extendSession } from "./sessionActions";

const BYTES_PER_CHUNK = 10485760; // UPDATED TO 10mb smaller chunks cause issues with FileMasterLog requests.

// setting this to 5, if we get 5 consecutive requests failed, we won't send anymore chunks
export const MAX_CONSECUTIVE_REQUESTS_FAILED = 5;

const files: { string?: File } = {};

// adds a file to the queue and triggers processNextChunk
export const queueUpFile =
    (guid: string, importName: string, file: File) =>
    (dispatch: Dispatch): void => {
        // this is the actual file, cant add it to redux so just put it in the object
        files[guid] = file;

        // this is just some metadata used by the queue
        const queueFile: IQueueFile = {
            guid,
            fileName: file.name,
            importName,
            size: file.size,
            requestsFailed: 0,
            chunks: [],
        };

        // lets add to the file queue all the chunks, splits the chunks into 50mb chunks
        const count =
            queueFile.size % BYTES_PER_CHUNK == 0
                ? queueFile.size / BYTES_PER_CHUNK
                : Math.floor(queueFile.size / BYTES_PER_CHUNK) + 1;
        const chunks: Array<IFileChunk> = [];

        // needs to start at 0!
        let chunkNumber = 0;
        while (chunkNumber < count) {
            chunks.push({
                chunkNumber: chunkNumber++,
                chunkStatus: FILE_UPLOAD_STATUS.PENDING,
            });
        }

        // adds the file to the redux state
        queueFile.chunks = chunks;
        dispatch({ type: ADD_TO_QUEUE, file: queueFile });

        // send the next chunk
        dispatch(processNextChunk());
    };

// looks for files with pending chunks to send and sends the chunks
const processNextChunk = () => (dispatch: Dispatch, getState: GetState) => {
    const state: IAppState = getState();
    const activeConnections = state.fileImportQueue.activeConnections;
    const threadLimit = state.fileImportQueue.threadLimit;

    // check the maximum simultaneous connections
    if (activeConnections < threadLimit) {
        // update the number of active connections upfront
        dispatch(incActiveConnections());

        const files = state.fileImportQueue.files;
        // get the first file that doesn't have chunks in progress
        // users complained that files doesn't upload in parallel, only one shows progress at the same time
        let fileSearch = files.find(
            x =>
                x.chunks &&
                x.requestsFailed < MAX_CONSECUTIVE_REQUESTS_FAILED &&
                // doesn't have chunks in progress
                x.chunks.filter(y => y.chunkStatus == FILE_UPLOAD_STATUS.IN_PROGRESS).length <= 0 &&
                x.chunks.filter(
                    y => y.chunkStatus == FILE_UPLOAD_STATUS.PENDING || y.chunkStatus == FILE_UPLOAD_STATUS.ERROR
                ).length > 0
        );

        // if all files in the queue have chunks in progress, then grab the first one with chunks pending
        if (!fileSearch) {
            // get the first file on the queue with chunks pending to send or that had an error
            fileSearch = files.find(
                x =>
                    x.chunks &&
                    x.requestsFailed < MAX_CONSECUTIVE_REQUESTS_FAILED &&
                    x.chunks.filter(
                        y =>
                            y.chunkStatus != FILE_UPLOAD_STATUS.COMPLETED &&
                            y.chunkStatus != FILE_UPLOAD_STATUS.IN_PROGRESS
                    ).length > 0
            );
        }

        // need to use fileSearch first, if file is not declared as a constant, typescript errors
        const file = fileSearch;
        // just initializing an empty chunk so that ts is happy
        let chunk: IFileChunk | undefined;

        if (file) {
            chunk = file.chunks.find(
                y => y.chunkStatus != FILE_UPLOAD_STATUS.COMPLETED && y.chunkStatus != FILE_UPLOAD_STATUS.IN_PROGRESS
            );
            if (chunk) {
                // calling the extend session method to try and prevent session timeouts when uploading big files
                extendSession();

                const newChunk = { ...chunk };
                newChunk.chunkStatus = FILE_UPLOAD_STATUS.IN_PROGRESS;
                dispatch(updateFileChunk(file.guid, newChunk));
                sendChunk(file.guid, newChunk, file.chunks.length)
                    .then(() => {
                        // if a previous chunk failed and this completed, update the flag
                        if (file.requestsFailed > 0) {
                            const newRequestsFailed = file.requestsFailed - 1;
                            dispatch(updateFileRequestsFailed(file.guid, newRequestsFailed));
                        }

                        // set the chunk to completed
                        newChunk.chunkStatus = FILE_UPLOAD_STATUS.COMPLETED;
                        dispatch(updateFileChunk(file.guid, newChunk));
                        // check if this was the last chunk pending to send for this file
                        dispatch(processChunkCompleted(file.guid, file.fileName));
                        // free the active connection
                        dispatch(decActiveConnections());
                        // check if there is any more files with pending chunks
                        dispatch(processNextChunk());
                    })
                    .catch(e => {
                        const newRequestsFailed = file.requestsFailed + 1;
                        // updating the number of requests failed
                        dispatch(updateFileRequestsFailed(file.guid, newRequestsFailed));
                        // update chunk status, can be retried later if hasn't reached the max retries
                        newChunk.chunkStatus = FILE_UPLOAD_STATUS.ERROR;
                        dispatch(updateFileChunk(file.guid, newChunk));
                        // if more than 5 consecutive requests failed, attempt to update the status in the server
                        if (newRequestsFailed == MAX_CONSECUTIVE_REQUESTS_FAILED) {
                            dispatch(requestImportFailed(file.guid, file.fileName));
                        }

                        // free the active connection
                        dispatch(decActiveConnections());
                        // check if there is any more files with pending chunks
                        dispatch(processNextChunk());

                        h.errorSilent(
                            `Send chunk failed for file: ${file.guid}, chunk number: ${newChunk.chunkNumber}, current status: ${newChunk.chunkStatus}`,
                            e
                        );
                    });
            }
        }

        // check if there is any more files with pending chunks
        // this doesn't wait for the sendChunk, it will trigger the parallel uploads
        // if there are active connections available and chunks pending
        dispatch(processNextChunk());

        if (!file || !chunk) {
            dispatch(decActiveConnections());
        }
    }
};

// checks if the completed chunk was the last one pending for that file and completes the upload
const processChunkCompleted = (guid: string, fileName: string) => (dispatch: Dispatch, getState: GetState) => {
    const state: IAppState = getState();
    const file = state.fileImportQueue.files.find(x => x.guid == guid);
    if (file) {
        const pendingChunks = file.chunks.filter(x => x.chunkStatus != FILE_UPLOAD_STATUS.COMPLETED);
        if (pendingChunks.length <= 0) {
            const formData = new FormData();
            formData.append("guid", guid);
            formData.append("fileName", fileName);

            fetch("/FileImport/CompleteChunkUpload", {
                credentials: "same-origin",
                method: "POST",
                body: formData,
            })
                .then(h.checkStatus)
                .then(toJson)
                .then(() => {
                    dispatch(notifyGreen("File upload is in progress."));
                })
                .catch(() => {
                    h.error(`There was an error finalizing the file upload: ${guid}.`);
                });

            dispatch(clearFileChunks(guid));
            delete files[guid];
        }
    }
};

const clearFileChunks = (guid: string) => ({
    type: DELETE_FROM_QUEUE,
    guid,
});

const updateFileChunk = (guid: string, newChunk: IFileChunk) => ({
    type: UPDATE_FILE_CHUNK,
    guid,
    newChunk,
});

const updateFileRequestsFailed = (guid: string, requestsFailed: number) => ({
    type: UPDATE_FILE_REQUESTS_FAILED,
    guid,
    requestsFailed,
});

const decActiveConnections = () => ({
    type: DEC_ACTIVE_CONNECTIONS,
});

const incActiveConnections = () => ({
    type: INC_ACTIVE_CONNECTIONS,
});

// sends the chunk to the server
const sendChunk = (guid: string, chunk: IFileChunk, totalChunks: number) =>
    new Promise<void>((resolve, reject) => {
        // file object
        const file: File = files[guid];
        // calculating start and end based on the BYTES_PER_CHUNK constant
        // first chunk number should always be 0 so that it's not skipped!
        const start = chunk.chunkNumber * BYTES_PER_CHUNK;
        const end = start + BYTES_PER_CHUNK;

        let slice = file.slice(start, end);
        const formData = new FormData();
        formData.append("file", slice);
        formData.append("guid", guid);
        formData.append("chunkCounter", `${chunk.chunkNumber + 1}`);
        formData.append("totalChunks", `${totalChunks}`);

        fetch("/FileImport/UploadFileChunk", {
            credentials: "same-origin",
            method: "POST",
            body: formData,
        })
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                if (data && data.ok) {
                    resolve();
                } else {
                    reject();
                }
            })
            .catch(() => {
                reject();
            });
    });

// request to update a file import to error, should be called when the max consecutive failed is reached.
const requestImportFailed = (guid: string, fileName: string) => (dispatch: Dispatch) => {
    // clearing the file chunks and deleting the file
    dispatch(clearFileChunks(guid));
    delete files[guid];

    const formData = new FormData();
    formData.append("guid", guid);

    fetch("/FileImport/UpdateFileImportToError", {
        credentials: "same-origin",
        method: "POST",
        body: formData,
    })
        .then(h.checkStatus)
        .then(toJson)
        .then(() => {
            h.error(`There was an error uploading the file ${fileName}, the process has stopped.`);
        })
        .catch(() => {
            h.error(`Unable to update the status of the file ${fileName} in the server, the process has stopped.`);
        });
};
