import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";

import type { FlowItem, FlowOffloadColumn } from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_OFFLOAD_COLUMN", "FlowOffloadColumnId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

export type FlowOffloadColumnsByItemId = {
    [number]: Array<FlowOffloadColumn>,
};

export const getFlowOffloadColumnArray = createSelector(
    state => state.flowOffloadColumns.byId,
    (flowOffloadColumnsById: {| [number]: FlowOffloadColumn |}): Array<FlowOffloadColumn> => {
        const r: Array<FlowOffloadColumn> = Object.values(flowOffloadColumnsById);
        return r;
    }
);

// eslint-disable-line
export const getFlowOffloadColumnsByFlowItemId = createSelector(
    state => getFlowOffloadColumnArray(state),
    (flowOffloadColums: Array<FlowOffloadColumn>): FlowOffloadColumnsByItemId =>
        flowOffloadColums.reduce((acc, row) => {
            if (!acc[row.FlowItemId]) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowOffloadColumnsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowOffloadColumnsByFlowItemId(state),
    (flowItems: Array<FlowItem>, flowOffloadColumnsByItemId: FlowOffloadColumnsByItemId): Array<FlowOffloadColumn> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        let result = [];
        for (const itemId of itemIds) {
            const script = flowOffloadColumnsByItemId[itemId];
            if (script != null) {
                result = result.concat(script);
            }
        }
        return result;
    }
);

export const getFlowOffloadColumnsForSelectedFlowItem = createSelector(
    state => state.selected.flowItem,
    state => getFlowOffloadColumnsByFlowItemId(state),
    (flowItemId: number, flowOffloadColumnsByItemId: FlowOffloadColumnsByItemId): Array<FlowOffloadColumn> =>
        flowOffloadColumnsByItemId[flowItemId] || []
);
