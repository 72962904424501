import {
    IStartRequestProgress,
    IUpdateRequestProgress,
    IEndRequestProgress,
    IFlowRelatedRequestStart,
    IFlowRelatedRequestCompleted,
    ITreeRequestStart,
    ITreeRequestCompleted,
} from "../types/stores/loading";
import {
    START_REQUEST_PROGRESS,
    UPDATE_REQUEST_PROGRESS,
    END_REQUEST_PROGRESS,
    SHOW_FLOW_LOADING,
    HIDE_FLOW_LOADING,
    START_TREE_REQUEST_PROGRESS,
    END_TREE_REQUEST_PROGRESS,
} from "../reducers/loadingStates";

export const startRequestProgress = (): IStartRequestProgress => ({
    type: START_REQUEST_PROGRESS,
});

export const updateRequestProgress = (progress: number): IUpdateRequestProgress => ({
    type: UPDATE_REQUEST_PROGRESS,
    progress,
});

export const endRequestProgress = (): IEndRequestProgress => ({
    type: END_REQUEST_PROGRESS,
});

export const flowRelatedRequestStart = (): IFlowRelatedRequestStart => ({
    type: SHOW_FLOW_LOADING,
});

export const flowRelatedRequestCompleted = (): IFlowRelatedRequestCompleted => ({
    type: HIDE_FLOW_LOADING,
});

export const treeRequestStart = (): ITreeRequestStart => ({
    type: START_TREE_REQUEST_PROGRESS,
});

export const treeRequestCompleted = (): ITreeRequestCompleted => ({
    type: END_TREE_REQUEST_PROGRESS,
});
