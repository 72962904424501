import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlowItemDescriptionByType, FlowItemDescriptionStore } from "../types/stores/flowItemDescription";

const initialState: FlowItemDescriptionStore = {
    byType: {},
    isLoading: false,
};

const slice = createSlice({
    name: "flowItemDescription",
    initialState,
    reducers: {
        flowItemDescriptionIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        updateFlowItemDescription: (state, action: PayloadAction<FlowItemDescriptionByType>) => {
            state.byType = action.payload;
        },
    },
});

export default slice.reducer;

export const { flowItemDescriptionIsLoading, updateFlowItemDescription } = slice.actions;
