import { incAjaxCount, decAjaxCount } from "./actionCreators";
import h from "../helpers";
import {
    SET_FLOW_ITEM_RESULT_TABLES,
    SET_FLOW_ITEM_TABLE_COLUMNS,
    SET_FLOW_ITEM_DATALOAD_TABLE_COLUMNS,
    SET_IS_LOADING_TABLES,
    SET_IS_LOADING_COLUMNS,
} from "../reducers/flowItemResultTables";
// import {
//     SET_CANNEDREPORT_COLUMNS,
// } from "../reducers/flowCannedReportColumns";
import { updateAttribute } from "./flowActions";
if (!top.RDX) {
    top.RDX = {};
}

const TOGGLE_FLOW_EDIT_LISTING = "TOGGLE_FLOW_EDIT_LISTING";
const TOGGLE_FLOW_EDIT_LISTING_PINNABLE = "TOGGLE_FLOW_EDIT_LISTING_PINNABLE";

// export const setCannedReportColumns = (flowItemId, columns) => ({
//     type: "SET_CANNEDREPORT_COLUMNS",
//     flowItemId,
//     columns,
// });
// top.RDX.setCannedReportColumns = (flowItemId, columns) =>
//     top.store.dispatch(setCannedReportColumns(flowItemId, columns));

export const ToggleFlowEditListing = currentValue => ({
    type: TOGGLE_FLOW_EDIT_LISTING,
    isOpen: !currentValue,
});

export const ToggleFlowEditListingPinnable = currentValue => ({
    type: TOGGLE_FLOW_EDIT_LISTING_PINNABLE,
    isPinned: !currentValue,
});

export const setFlowItemResultTables = (flowItemId, resultTables) => ({
    type: SET_FLOW_ITEM_RESULT_TABLES,
    flowItemId,
    resultTables,
});

export const requestFlowItemResultTables = flowItemId => dispatch => {
    const url = `/Flows/GetFlowItemResultTables?flowItemId=${flowItemId}`;
    dispatch(incAjaxCount());
    dispatch(setIsLoadingResultTables(true));
    fetch(url, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(setFlowItemResultTables(flowItemId, data));
            dispatch(setIsLoadingResultTables(false));
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(setIsLoadingResultTables(false));
            dispatch(decAjaxCount());
            h.error("Error loading tables.", error);
        });
};

export const requestFlowItemResultTablesForColumns = parentIemId => dispatch => {
    const url = `/Flows/GetResultTablesFromCannedReport?flowItemId=${parentIemId}`;
    dispatch(incAjaxCount());
    dispatch(setIsLoadingResultTables(true));
    fetch(url, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            let resultTable = [];
            if (data && data.length > 0) {
                const unique = [...new Set(data.map(item => item.TABLE_NAME))];
                if (unique.length > 1) {
                    unique.forEach(x => {
                        const tmpData = data.filter(y => y.TABLE_NAME == x);
                        const tableName = x;

                        dispatch({
                            type: SET_FLOW_ITEM_TABLE_COLUMNS,
                            tableName,
                            tableColumns: tmpData,
                        });
                        resultTable.push({
                            TableName: tableName,
                            Count: tmpData.length,
                        });
                    });
                } else {
                    const tableName = data[0].TABLE_NAME;

                    dispatch({
                        type: SET_FLOW_ITEM_TABLE_COLUMNS,
                        tableName,
                        tableColumns: data,
                    });
                    resultTable.push({
                        TableName: tableName,
                        Count: data.length,
                    });
                }
            }
            dispatch({
                type: SET_FLOW_ITEM_RESULT_TABLES,
                flowItemId: parentIemId,
                resultTables: resultTable,
            });
            dispatch(setIsLoadingResultTables(false));
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(setIsLoadingResultTables(false));
            dispatch(decAjaxCount());
            h.error("Error loading columns from parent node.", error);
        });
};

export const requestFlowItemTableColumns = (flowId, tableName, flowItemId) => dispatch => {
    const url = `/Flows/GetResultTableColumns?flowId=${flowId}&tableName=${tableName}`;
    dispatch(incAjaxCount());
    dispatch(setIsLoadingTableColumns(true));
    fetch(url, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data && data.columns) {
                dispatch({
                    type: SET_FLOW_ITEM_TABLE_COLUMNS,
                    tableName,
                    tableColumns: data.columns,
                });
            } else if (data.error) {
                h.error(`Error loading table columns. ${data.error}`, data.error, true);
            }

            if (flowItemId != null) {
                dispatch(updateAttribute("flowItems", flowItemId, "IsLoading", false));
            }
        })
        .catch(error => {
            if (flowItemId != null) {
                dispatch(updateAttribute("flowItems", flowItemId, "IsLoading", false));
            }
            h.error("Error loading table columns.", error);
        })
        .finally(() => {
            dispatch(setIsLoadingTableColumns(false));
            dispatch(decAjaxCount());
        });
};

export const requestFlowItemDataLoadTableColumns = (flowId, tableName) => dispatch => {
    const url = `/Flows/GetResultTableColumns?flowId=${flowId}&tableName=${tableName}`;
    dispatch(incAjaxCount());
    dispatch(setIsLoadingTableColumns(true));
    fetch(url, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data && data.columns) {
                dispatch({
                    type: SET_FLOW_ITEM_DATALOAD_TABLE_COLUMNS,
                    tableName,
                    tableColumns: data.columns,
                });
            } else if (data.error) {
                h.error(`Error loading table columns. ${data.error}`, data.error, true);
            }
        })
        .catch(error => {
            h.error("Error loading table columns.", error);
        })
        .finally(() => {
            dispatch(setIsLoadingTableColumns(false));
            dispatch(decAjaxCount());
        });
};

import { loadDataloadPartners } from "./flowActions";
export const requestDataloadDestinations = () => dispatch => {
    //updates null to [] so that other components don't do the call while this one is running!
    dispatch(loadDataloadPartners([]));
    const url = "/ClientAccess/GetMyDataloadPartners";
    dispatch(incAjaxCount());
    return fetch(url, { credentials: "same-origin", method: "POST" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(decAjaxCount());
            if (data.partners && data.partners.length > 0) {
                dispatch(loadDataloadPartners(data.partners));
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            dispatch(loadDataloadPartners(null));
            h.error("Error loading dataload partners.", error);
        });
};

export const setIsLoadingResultTables = isLoadingTables => ({
    type: SET_IS_LOADING_TABLES,
    isLoadingTables,
});

export const setIsLoadingTableColumns = isLoadingColumns => ({
    type: SET_IS_LOADING_COLUMNS,
    isLoadingColumns,
});
