import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import type { FlowItemEndpoint, FlowRelation } from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_ENDPOINT", "Id");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowItemEndpointsArray = createSelector(
    state => state.flowItemEndpoints.byId,
    (FlowItemEndpointsByItemId: {| [number]: FlowItemEndpoint |}): Array<FlowItemEndpoint> => {
        const r: Array<FlowItemEndpoint> = Object.values(FlowItemEndpointsByItemId);
        return r;
    }
);

export type FlowItemEndpointsById = {
    [number]: FlowItemEndpoint,
};

export const getFlowEndpointsForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemEndpointsArray(state),
    (selectedFlow: number, flowEndpoints: Array<FlowItemEndpoint>): Array<FlowItemEndpoint> => {
        let result = flowEndpoints.filter(x => x.FlowId == selectedFlow);
        return result;
    }
);

export const isItemEndpoint = (flowItemId: ?number, flowEndpoints: Array<FlowItemEndpoint>): boolean => {
    if (flowItemId == null || flowEndpoints == null) {
        return false;
    }
    return flowEndpoints.filter(x => x.FlowItemId == flowItemId).length > 0;
};

export const isLinkedToEndpoint = (
    flowItemId: ?number,
    flowEndpoints: Array<FlowItemEndpoint>,
    flowRelations: Array<FlowRelation>
): boolean => {
    const endpointItemIds = flowEndpoints.map(x => x.FlowItemId);
    return (
        flowRelations.filter(x => x.ParentFlowItemId == flowItemId && endpointItemIds.includes(x.ChildFlowItemId))
            .length > 0
    );
};

import { getFlowRelationsForSelectedFlow } from "./flowRelations";

export const makeIsEndpointOrLinkedToEndpoint = () =>
    createSelector(
        (_, props) => props.flowItemId,
        state => getFlowEndpointsForSelectedFlow(state),
        state => getFlowRelationsForSelectedFlow(state),
        (flowItemId: number, flowEndpoints: Array<FlowItemEndpoint>, flowRelations: Array<FlowRelation>): boolean => {
            const isEndpoint = isItemEndpoint(flowItemId, flowEndpoints);
            const isLinked = isLinkedToEndpoint(flowItemId, flowEndpoints, flowRelations);
            return isEndpoint || isLinked;
        }
    );
