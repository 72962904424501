import React, { useState, useCallback, useEffect } from "react";
import ofilter from "../../../helpers/ofilter";
import { labelCompare } from "../../../helpers/selectHelper";
import { useDispatch, useSelector } from "react-redux";
import {
    requestDestinations,
    requestSelectedLayoutObjects,
    requestLayoutList,
} from "../../../actions/exportLayoutActions2";
import {
    updateAttribute,
    invalidateItemAndChildren,
    clearFlowExportDistributionPlatforms,
    clearFlowItemSVDedupes,
    clearFlowExportOfferMerges,
    clearFlowRelationParentLabels,
    clearFlowExportDestinationVariables,
    newFlowSVDedupe,
    deleteFlowSVDedupe,
} from "../../../actions/flowActions";
import { requestLayoutFieldTree } from "../../../actions/fieldTreeActions";
import FlowItemDeployToggle from "./FlowExportItems/FlowItemDeployToggle";
import { showModal } from "../../../actions/actionCreators";
import { createSelector } from "reselect";
import { getFlowExportsByFlowItemId } from "../../../reducers/flowExports";
import { getFlowExportReportsArray } from "../../../reducers/flowExportReports";
import { getAncestorsFields, getFlowRelationsForSelectedFlow } from "../../../reducers/flowRelations";
import { getSelectedFlowAndItemPermissions } from "../../../reducers/flowItems";

import FlowExportDistributionPlatforms from "./FlowExportItems/FlowExportDistributionPlatforms";
import FlowExportOfferEdit from "./FlowExportItems/FlowExportOfferEdit";
import FlowExportVariableEdit from "./FlowExportItems/FlowExportVariableEdit";
import { makeGetFlowSVDedupe } from "./FlowSVDedupeEdit";
import { requestClientVariableGroupings } from "../../../actions/clientVariableActions";
import { DeploySettings } from "../../../types/stores/companyTable";
import { anyParentFlowItemsWithFirstPartyOnly } from "../../../reducers/flowExports";
import type { FlowExportsByItemId } from "../../../reducers/flowExports";
import { FormControl, Grid, Divider, Button, TextField, Icon, Tooltip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TradedeskDeliveryTypes from "./FlowExportItems/TradedeskDeliveryTypes";
import ActivationDestinationSchedules from "./FlowExportItems/ActivationDestinationSchedules";
import FlowExportReportsTable from "./FlowExportItems/FlowExportReportsTable";
import RestrictedFieldsTable from "./FlowExportItems/RestrictedFieldsTable";
import { IAppState } from "../../../types/stores/index";
import { FlowExport, FlowExportReport, FlowItemFieldsUsed, FlowRelation } from "../../../types/stores/flowTypes";
import { IAdvertisersByCompany } from "../../../types/stores/vars";
import ExportDestinationSquare from "./FlowExportItems/ExportDestinationSquare";
import FlowTaxonomyFieldEdit from "./FlowExportItems/FlowTaxonomyFieldEdit";
// import FlowTaxonomyFieldEdit from "./FlowExportItems/FlowTaxonomyFieldEdit";

type Props = {
    flowItemId: number;
    isModal: boolean;
    hideExtra: boolean;
};

const FlowExportEdit: React.FC<Props> = (props: Props) => {
    const dispatch = useDispatch();

    const [hasFieldRestrictions, setHasFieldRestrictions] = useState(false);
    const [fieldRestrictions, setFieldRestrictions] = useState<Array<FlowItemFieldsUsed>>([]);

    const getExportDestinations = makeGetExportDestinations();
    const getFlowExport = makeGetFlowExport();
    const getFlowExportReports = makeGetFlowExportReport();
    const getFlowSVDedupe = makeGetFlowSVDedupe();

    const PartnerList = useSelector((state: IAppState) => getExportDestinations(state));
    const destinationFieldRestrictions = useSelector((state: IAppState) => state.vars.destinationFieldRestrictions);
    const enabledFeatures = useSelector((state: IAppState) => state.session.enabledFeatures || []);
    const layouts = useSelector((state: IAppState) => state.layoutObjects.objects);
    const flowExport = useSelector((state: IAppState) => getFlowExport(state, props));
    const companyId = useSelector((state: IAppState) => state.session.companyId);
    const reports = useSelector((state: IAppState) => getFlowExportReports(state, props));
    const ancestorsFields = useSelector<IAppState, Array<FlowItemFieldsUsed>>(state => getAncestorsFields(state));
    const destinationDedupeSettings = useSelector((state: IAppState) => state.vars.destinationDedupeSettings);
    const flowSVDedupe = useSelector((state: IAppState) => getFlowSVDedupe(state, props));
    const environment = useSelector((state: IAppState) => state.session.environment);
    const isInternal = useSelector((state: IAppState) => state.session.isInternal);
    const flow = useSelector((state: IAppState) => state.flows.byId[state.selected.flow]);
    const canEdit = useSelector((state: IAppState) => getSelectedFlowAndItemPermissions(state).item.canEdit);
    const layoutFields = useSelector((state: IAppState) => state.layoutfields.byId);
    const layoutTypes = useSelector((state: IAppState) => state.vars.layoutTypes);
    const flowItemsById = useSelector((state: IAppState) => state.flowItems.byId);
    const variableGroups = useSelector((state: IAppState) => state.vars.variableGroupings || []);
    const destinationPartners = useSelector((state: IAppState) => state.vars.destinations);
    const flowRelations = useSelector<IAppState, Array<FlowRelation>>(state => getFlowRelationsForSelectedFlow(state));

    // flowItemsById[z.value].FlowItemQty
    const processLayoutChanged = useCallback(
        layoutId => {
            if (flowExport != null) {
                const layout = layouts.filter(x => x.Layout.LayoutID == layoutId)[0];

                //if the new layout is pivot, offer merges and sv dedupes are not needed any more
                if (layout && layout.Layout && layout.Layout.IsPivot) {
                    dispatch(clearFlowItemSVDedupes(flowExport.FlowItemId));
                    dispatch(clearFlowExportOfferMerges(flowExport.FlowItemId));
                } else if (layout && layout.Layout && !layout.Layout.IsPivot) {
                    //not pivot, clear the parent labels
                    dispatch(clearFlowRelationParentLabels(flowExport.FlowItemId));
                }

                dispatch(updateAttribute("flowExports", flowExport.FlowExportId, "ExportId", layoutId));
                dispatch(invalidateItemAndChildren(flowExport.FlowItemId));
                if (reports.length > 0) {
                    reports.forEach(x => {
                        dispatch(updateAttribute("flowExportReports", x.FlowExportReportId, "DimensionFieldKeys", ""));
                        dispatch(updateAttribute("flowExportReports", x.FlowExportReportId, "MeasureFieldKeys", ""));
                        dispatch(updateAttribute("flowExportReports", x.FlowExportReportId, "LayoutId", layoutId));
                    });
                }
            }
        },
        [dispatch, flowExport, reports, layouts]
    );
    useEffect(() => {
        if (!variableGroups || variableGroups.length == 0) {
            dispatch(requestClientVariableGroupings());
        }
    }, [dispatch, variableGroups]);

    const checkFieldRestrictions = useCallback(
        destinationId => {
            if (
                !destinationFieldRestrictions ||
                destinationFieldRestrictions.length == 0 ||
                !ancestorsFields ||
                ancestorsFields.length == 0 ||
                destinationFieldRestrictions.filter(x => x.DestinationId == destinationId).length == 0
            ) {
                setHasFieldRestrictions(false);
                setFieldRestrictions([]);
                dispatch(updateAttribute("flowItems", props.flowItemId, "IsValid", true));
                return;
            }

            const restrictedIds = destinationFieldRestrictions
                .filter(x => x.DestinationId == destinationId)
                .map(x => x.FieldKey);

            const fieldRestrictions = ancestorsFields.filter(x => restrictedIds.includes(x.FieldId));

            if (fieldRestrictions.length > 0) {
                setHasFieldRestrictions(true);
                setFieldRestrictions(fieldRestrictions);
                dispatch(updateAttribute("flowItems", props.flowItemId, "IsValid", false));
            } else {
                setHasFieldRestrictions(false);
                setFieldRestrictions([]);
                dispatch(updateAttribute("flowItems", props.flowItemId, "IsValid", true));
            }
        },
        [ancestorsFields, destinationFieldRestrictions, props.flowItemId, dispatch]
    );

    useEffect(() => {
        if (!props.hideExtra && (!PartnerList || PartnerList.length == 0 || !destinationFieldRestrictions)) {
            dispatch(requestDestinations());
        }
    }, [dispatch, PartnerList, destinationFieldRestrictions, props]);

    useEffect(() => {
        if (!layoutFields) {
            dispatch(requestLayoutFieldTree());
        }
    }, [dispatch, layoutFields]);

    useEffect(() => {
        if (!layoutTypes) {
            dispatch(requestLayoutList("replace"));
        }
    }, [dispatch, layoutTypes]);

    useEffect(() => {
        if (!enabledFeatures.includes("destination-company-layouts")) {
            //check if there is a new destination layout for
            const destinationId = flowExport ? flowExport.DestinationId : null;
            const exportId = flowExport ? flowExport.ExportId : null;
            const destinationLayout = (layouts || {}).find(
                x =>
                    x.Layout.LayoutID != null &&
                    x.Layout.LayoutCompanyID == destinationId &&
                    x.Layout.LayoutCompanyID != companyId &&
                    x.Layout.IsActiveDestinationLayout
            );

            let destinationLayoutId = null;
            if (destinationLayout && destinationLayout.Layout) {
                destinationLayoutId = destinationLayout.Layout.LayoutID;
            }

            //if there is a destination layout for the selected destination
            //and is not the currently saved in the export, trigger the change
            if (destinationLayoutId && exportId != destinationLayoutId) {
                processLayoutChanged(destinationLayoutId);
            }
        }
    }, [flowExport, enabledFeatures, layouts, companyId, processLayoutChanged]);

    useEffect(() => {
        if (
            flowExport &&
            flowExport.DestinationId &&
            flowExport.DestinationId > 0 &&
            destinationFieldRestrictions &&
            destinationFieldRestrictions.filter(x => x.DestinationId == flowExport.DestinationId).length > 0
        ) {
            checkFieldRestrictions(flowExport.DestinationId);
        }
    }, [flowExport, destinationFieldRestrictions, checkFieldRestrictions]);

    const layoutChanged = (_, e) => processLayoutChanged(e && e.value ? e.value : null);

    const setDefaultDedupeSetting = (destinationId: number | undefined) => {
        let setting = destinationDedupeSettings.find(x => x.CompanyID == companyId && x.DestinationID == destinationId);

        // Id = 0 always contains the current company default settings.
        if (!setting) {
            setting = destinationDedupeSettings.find(x => x.Id == 0);
        }

        // the destination default is dedupe on.
        if (setting && setting.IsDedupeDefault) {
            // dedupe was off
            if (!flowSVDedupe) {
                dispatch(newFlowSVDedupe(flowExport?.FlowItemId, setting.DefaultDedupeKey, setting.DedupeSortByFields));
                dispatch(
                    updateAttribute(
                        "flowExports",
                        flowExport?.FlowExportId,
                        "CustomDedupe",
                        setting.IsCustomDedupeDefault
                    )
                );
            } else {
                const jsonSortByFields =
                    setting.DedupeSortByFields && setting.DedupeSortByFields.length > 0
                        ? JSON.parse(setting.DedupeSortByFields)
                        : [];
                dispatch(
                    updateAttribute("flowSVDedupes", flowSVDedupe.FlowSVDedupeId, "SVField", setting.DefaultDedupeKey)
                );
                dispatch(
                    updateAttribute("flowSVDedupes", flowSVDedupe.FlowSVDedupeId, "SortByFields", jsonSortByFields)
                );
                dispatch(
                    updateAttribute(
                        "flowExports",
                        flowExport?.FlowExportId,
                        "CustomDedupe",
                        setting.IsCustomDedupeDefault,
                        true
                    )
                );
            }
        } else if (flowSVDedupe) {
            dispatch(deleteFlowSVDedupe(flowSVDedupe.FlowSVDedupeId));
        }
    };

    const setPartner = (newValue: number | undefined) => {
        const destination = (PartnerList || []).find(x => x.PartnerAccessId == newValue);

        if (
            destination?.IsIndirectDestination &&
            anyParentFlowItemsWithFirstPartyOnly(props.flowItemId, ancestorsFields, flowRelations)
        ) {
            showFirstPartyDataWarningModal();
            return;
        }

        // Updating the export to the default destination dedupe settings.
        setDefaultDedupeSetting(newValue);

        if (flowExport != null) {
            dispatch(clearFlowExportDestinationVariables(flowExport.FlowItemId, flowExport.DestinationId));
            dispatch(clearFlowExportDistributionPlatforms(flowExport.FlowItemId));

            dispatch(updateAttribute("flowExports", flowExport.FlowExportId, "DestinationId", newValue));
            // Reset DeliveryTypeId
            dispatch(updateAttribute("flowExports", flowExport.FlowExportId, "DeliveryTypeId", null));

            // set destination layout
            if (destination && destination.DestinationLayoutId) {
                //begin flow-export-pivot-labels
                dispatch(clearFlowRelationParentLabels(flowExport.FlowItemId));
                //end flow-export-pivot-labels

                dispatch(
                    updateAttribute("flowExports", flowExport.FlowExportId, "ExportId", destination.DestinationLayoutId)
                );

                dispatch(invalidateItemAndChildren(flowExport.FlowItemId));
                if (reports.length > 0) {
                    reports.forEach(x => {
                        dispatch(updateAttribute("flowExportReports", x.FlowExportReportId, "DimensionFieldKeys", ""));
                        dispatch(updateAttribute("flowExportReports", x.FlowExportReportId, "MeasureFieldKeys", ""));
                        dispatch(
                            updateAttribute(
                                "flowExportReports",
                                x.FlowExportReportId,
                                "LayoutId",
                                destination.DestinationLayoutId
                            )
                        );
                    });
                }
            } else {
                //If layout saved was a dif company destinationid and the user changes destinations, clear out the selected layout
                const theseLayouts = ofilter(
                    v => v["Layout"]["LayoutID"] != null && v["Layout"]["LayoutID"] == flowExport.ExportId,
                    layouts || {}
                );
                const selectedLayout = Object.keys(theseLayouts).map(item => ({
                    item: layouts[item],
                }));
                if (selectedLayout.length > 0 && selectedLayout[0].item.Layout.LayoutCompanyID != companyId) {
                    dispatch(updateAttribute("flowExports", flowExport.FlowExportId, "ExportId", null));

                    //begin flow-export-pivot-labels
                    //clear any pivot parent labels
                    dispatch(clearFlowRelationParentLabels(flowExport.FlowItemId));
                    //end flow-export-pivot-labels

                    //I may just delete the reports in this scenario, not sure yet
                    if (reports.length > 0) {
                        reports.forEach(x => {
                            dispatch(
                                updateAttribute("flowExportReports", x.FlowExportReportId, "DimensionFieldKeys", "")
                            );
                            dispatch(
                                updateAttribute("flowExportReports", x.FlowExportReportId, "MeasureFieldKeys", "")
                            );
                            dispatch(updateAttribute("flowExportReports", x.FlowExportReportId, "LayoutId", null));
                        });
                    }
                }
            }

            // Check if destination has field restrictions
            if (newValue && destinationFieldRestrictions) {
                const destinationId = newValue;
                checkFieldRestrictions(destinationId);
            } else {
                setHasFieldRestrictions(false);
                setFieldRestrictions([]);
                dispatch(updateAttribute("flowItems", props.flowItemId, "IsValid", true));
            }
        }
    };

    const showFirstPartyDataWarningModal = () => {
        dispatch(
            showModal("FILE_BIG_SIZE_UPLOAD_WARNING", {
                continue: () => {},
                type: "First Party Data to Indirect Destination",
            })
        );
    };

    const showLayoutModal = () => {
        const layout = layouts.filter(x => x.Layout.LayoutID == flowExport?.ExportId)[0];
        dispatch(requestSelectedLayoutObjects(layout.Layout.LayoutID));
        dispatch(showModal("FLOW_EXPORT_LAYOUT", layout));
    };

    const canShowCount = () => {
        if (flowExport) {
            let dest = flowExport.DestinationId;
            if (destinationPartners) {
                const partners = destinationPartners.filter(d => d.PartnerAccessId == dest);
                return partners[0].CanShowCount;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    const isDestinationCompanyLayouts = enabledFeatures.includes("destination-company-layouts");

    //If selected destination has IsActiveDestinationLayout and is not self company
    const selectedLayout = ofilter(
        v =>
            v["Layout"]["LayoutID"] != null &&
            v["Layout"]["LayoutCompanyID"] == (flowExport != null ? flowExport.DestinationId : null) &&
            v["Layout"]["LayoutCompanyID"] != companyId &&
            (isDestinationCompanyLayouts || v["Layout"]["IsActiveDestinationLayout"] == true),
        layouts || {}
    );

    const destinationLayout = isDestinationCompanyLayouts
        ? []
        : Object.keys(selectedLayout)
              .map(item => ({
                  label: layouts[item].Layout.LayoutName,
                  value: layouts[item].Layout.LayoutID,
                  item: layouts[item],
              }))
              .sort(labelCompare);

    let theseLayouts = ofilter(
        v =>
            v["Layout"]["LayoutID"] != null &&
            v["Layout"]["LayoutCompanyID"] == companyId &&
            v["Layout"]["LayoutActive"] == "Y",
        layouts || {}
    );

    if (flowExport?.DestinationId && PartnerList && PartnerList.length > 0 && isDestinationCompanyLayouts) {
        const destination = PartnerList.filter(x => x.PartnerAccessId == flowExport.DestinationId);
        if (destination.length == 1 && destination[0].CompanyIsDestination) {
            theseLayouts = ofilter(
                v =>
                    v["Layout"]["LayoutID"] != null &&
                    v["Layout"]["LayoutCompanyID"] == flowExport?.DestinationId &&
                    v["Layout"]["LayoutActive"] == "Y",
                layouts || {}
            );
        }
    }

    const layoutList = Object.keys(theseLayouts)
        .map(item => ({
            label: layouts[item].Layout.LayoutName,
            value: layouts[item].Layout.LayoutID,
            item: layouts[item],
        }))
        .sort((a, b) => {
            if (a.item.Layout.IsActiveDestinationLayout && !b.item.Layout.IsActiveDestinationLayout) {
                return -1;
            } else if (!a.item.Layout.IsActiveDestinationLayout && b.item.Layout.IsActiveDestinationLayout) {
                return 1;
            }

            // Else go to the 2nd criteria
            if (a.label.toUpperCase() < b.label.toUpperCase()) {
                return -1;
            } else if (a.label.toUpperCase() > b.label.toUpperCase()) {
                return 1;
            } else {
                // nothing to split them
                return 0;
            }
        });

    let filteredDestinations = PartnerList || [];
    //Only auprod and ukprod can see the grouped partners, others only the parent partners
    if (environment != "auprod" && environment != "ukprod" && PartnerList && PartnerList.length > 0) {
        filteredDestinations = PartnerList.filter(x => !x.isGrouped || x.PartnerAccessId == x.GroupCompanyID);
    }

    const destinations = filteredDestinations
        .map(item => ({
            label:
                item.VerticalName +
                " - " +
                item.CompanyName +
                (isInternal && item.FTPFolder != null ? " (" + item.FTPFolder + ")" : ""),
            value: item.PartnerAccessId,
            item,
        }))
        .sort(labelCompare);

    const layout = layouts && layouts.filter(x => x.Layout.LayoutID == flowExport?.ExportId)[0];

    const filteredLayouts = destinationLayout.length > 0 ? destinationLayout : layoutList;
    const filteredLayout = filteredLayouts.filter(x => x.value == flowExport?.ExportId);

    const thisDestination = destinations.find(x => x.value == flowExport?.DestinationId);

    const deploySetting = thisDestination && thisDestination.item.DeploySetting;
    const hasSnowflake = enabledFeatures.includes("snowflake-db");
    const hasFlowExportTemplateFields = enabledFeatures.includes("flow-export-template-fields");
    const isAdditionalTemplate =
        deploySetting == DeploySettings.DeployAdditionalFacebookTemplate && hasFlowExportTemplateFields;
    const isTradeDeskTemplate = deploySetting == DeploySettings.DeployTradedeskTemplate;
    const isPinterestTemplate = deploySetting == DeploySettings.DeployPinterestAutomationTemplate;
    const isFreewheelDriverFile = deploySetting == DeploySettings.DeployFreewheelDriverFile;
    const isMagniteDriverFile = deploySetting == DeploySettings.DeployMagniteDriverFile;
    const isTikTokTemplate = deploySetting == DeploySettings.DeployTikTokDriverFile;
    const isSnowflakeTable = deploySetting == DeploySettings.DeploySnowflakeTable && hasSnowflake;
    const isNonExternalFields = deploySetting == DeploySettings.DeployNonExternalFields;
    let splitOverwrite = false;
    if (
        isAdditionalTemplate ||
        isPinterestTemplate ||
        isTikTokTemplate ||
        isTradeDeskTemplate ||
        isFreewheelDriverFile ||
        isMagniteDriverFile ||
        isSnowflakeTable ||
        isNonExternalFields
    ) {
        splitOverwrite = false;
    } else if (layout != null) {
        splitOverwrite = layout.Layout.DeploySeparateFiles;
    }

    const isActivationDestination = Boolean(
        thisDestination &&
            filteredDestinations.find(x => thisDestination.value == x.PartnerAccessId)?.CompanyIsActivationDestination
    );

    const destinationId = flowExport?.DestinationId;
    let showRequestExportApprovalMessage = false;
    if (
        destinationId &&
        filteredDestinations &&
        filteredDestinations.filter(x => x.PartnerAccessId == destinationId && x.EnforceDeploymentRequest).length > 0
    ) {
        showRequestExportApprovalMessage = true;
    }

    const formatNumber = (val: number) => (val != null ? val.toLocaleString() : "");

    return flowExport ? (
        <div style={{ paddingBottom: "35px" }}>
            {showRequestExportApprovalMessage && (
                <div>
                    Your chosen destination will require you to request export approval. To do so, click the 3 dots menu
                    in the upper right hand corner from the main flow canvas and select &quot;Request Export
                    Approval&quot;
                </div>
            )}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="flow-edit-header" style={{ width: "33%", display: "flex", justifyContent: "start" }}>
                    <div style={{ marginTop: "20px" }}>
                        <p>Export Settings</p>
                    </div>

                    <div className="flow-edit-header-actions">
                        <FlowItemDeployToggle
                            flowItemId={flowExport.FlowItemId}
                            disabled={!canEdit || (flow && flow.IsLocked)}
                        />
                    </div>
                </div>
                <div className="flow-edit-header" style={{ fontSize: "24px", textAlign: "center" }}>
                    {props.isModal && canShowCount()
                        ? formatNumber(flowItemsById[flowExport.FlowItemId].FlowItemQty)
                        : ""}
                </div>
                <div style={{ width: "33%" }}>&nbsp;</div>
            </div>
            <Divider style={{ margin: "20px auto" }} />
            <div className="flow-edit-body">
                <Grid container justifyContent="center">
                    <Grid container spacing={2} justifyContent="center">
                        {enabledFeatures.includes("flow-export-destination-selection-new-ui") && thisDestination ? (
                            <Grid item xs={12}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <ExportDestinationSquare
                                        flowItemId={props.flowItemId}
                                        destination={thisDestination?.item}
                                        selectedDestinationId={flowExport?.DestinationId}
                                        onClick={() => setPartner(undefined)}
                                    />
                                </div>
                            </Grid>
                        ) : (
                            <Grid item xs={8}>
                                <FormControl fullWidth required>
                                    <Autocomplete
                                        id="destinationSelectionLabel"
                                        options={destinations}
                                        getOptionLabel={option => (option && option.label) || ""}
                                        getOptionSelected={(option, value) => value.value === option.value}
                                        onChange={(_, e) => setPartner(e?.value)}
                                        autoComplete
                                        value={thisDestination || null}
                                        disabled={PartnerList == null || !canEdit || (flow && flow.IsLocked)}
                                        renderInput={params => (
                                            <TextField {...params} label="Export Destination" fullWidth />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        )}

                        {(deploySetting == DeploySettings.DeployTradedeskTemplate ||
                            deploySetting == DeploySettings.DeployXandrTemplate) && (
                            <Grid item xs={8}>
                                <TradedeskDeliveryTypes
                                    flowExport={flowExport}
                                    canEdit={canEdit}
                                    deploySetting={deploySetting}
                                />
                            </Grid>
                        )}
                        {isActivationDestination && (
                            <Grid item xs={8}>
                                <ActivationDestinationSchedules flowExport={flowExport} canEdit={canEdit} />
                            </Grid>
                        )}
                        <Grid item xs={8}>
                            <div style={{ display: "flex", paddingBottom: "20px" }}>
                                <FormControl fullWidth required>
                                    <Autocomplete
                                        id="layoutSelectionLabel"
                                        options={destinationLayout.length > 0 ? destinationLayout : layoutList}
                                        getOptionLabel={option => option.label}
                                        getOptionSelected={(option, value) => value.value === option.value}
                                        onChange={layoutChanged}
                                        autoComplete
                                        value={filteredLayout.length > 0 ? filteredLayout[0] : null}
                                        disabled={
                                            destinationLayout.length > 0
                                                ? true
                                                : !canEdit || (flow && flow.IsLocked)
                                                ? true
                                                : false
                                        }
                                        renderOption={option => (
                                            <Tooltip
                                                title={
                                                    option.item.Layout.IsActiveDestinationLayout ? "Active" : "Inactive"
                                                }
                                            >
                                                <span>{option.label}</span>
                                            </Tooltip>
                                        )}
                                        renderInput={params => <TextField {...params} label="Layouts" fullWidth />}
                                    />
                                </FormControl>
                                <Button
                                    style={{ fontSize: 12 }}
                                    variant="contained"
                                    color="secondary"
                                    className={"edit-button"}
                                    onClick={showLayoutModal}
                                    disabled={!flowExport?.ExportId || !canEdit || (flow && flow.IsLocked)}
                                >
                                    View Details
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                {hasFieldRestrictions ? (
                    <div style={{ width: "100%" }}>
                        <div className="alert alert-warning-original flow-item-warnings">
                            <Icon style={{ marginRight: "10px", position: "relative", top: "2px" }}>error_outline</Icon>
                            The selected destination does not allow the use of the below fields
                        </div>
                        <RestrictedFieldsTable fieldRestrictions={fieldRestrictions} />
                    </div>
                ) : (
                    <div>
                        {layout &&
                            layout.Layout &&
                            layout.LayoutTaxonomyObjects != null &&
                            layout.LayoutTaxonomyObjects.length > 0 &&
                            !props.hideExtra && <FlowTaxonomyFieldEdit flowItemId={flowExport?.FlowItemId} />}

                        <FlowExportVariableEdit
                            flowItemId={flowExport?.FlowItemId}
                            destinationId={flowExport?.DestinationId}
                            isFreewheelOrMagniteDriverFile={isFreewheelDriverFile || isMagniteDriverFile}
                            variableGroups={variableGroups}
                        />

                        {flowExport && flowExport.DestinationId && (
                            <FlowExportDistributionPlatforms
                                destinationId={flowExport.DestinationId}
                                flowItemId={flowExport.FlowItemId}
                            />
                        )}
                        {/* <div hidden={props.isModal}> */}
                        {layout && layout.Layout && !props.hideExtra && (
                            <FlowExportOfferEdit
                                destinationId={flowExport?.DestinationId}
                                deliveryTypeId={flowExport?.DeliveryTypeId}
                                splitOverwrite={splitOverwrite}
                                flowItemId={flowExport?.FlowItemId}
                                flowExportId={flowExport?.FlowItemId}
                                layoutId={flowExport?.ExportId}
                                isAdditionalTemplate={isAdditionalTemplate}
                                isPinterestTemplate={isPinterestTemplate}
                                isTradeDeskTemplate={isTradeDeskTemplate}
                                isFreewheelDriverFile={isFreewheelDriverFile}
                                isMagniteDriverFile={isMagniteDriverFile}
                                isTikTokTemplate={isTikTokTemplate}
                                isSnowflakeTable={isSnowflakeTable}
                                isMultiExport={props.isModal}
                                deploySetting={deploySetting}
                            />
                        )}

                        <FlowExportReportsTable flowExport={flowExport} canEdit={canEdit} reports={reports} />
                    </div>
                )}
            </div>
        </div>
    ) : null;
};

type FlowExportSelector<S> = (state: IAppState, props: Props) => S;
export const makeGetFlowExport = (): FlowExportSelector<FlowExport | undefined> =>
    // @ts-ignore
    createSelector(
        (state: IAppState) => getFlowExportsByFlowItemId(state),
        (_, props: Props) => props.flowItemId,
        (flowExportsByFlowItemId: FlowExportsByItemId, flowItemId: number): FlowExport | undefined =>
            flowExportsByFlowItemId[flowItemId]
    );

type FlowExportReportSelector<S> = (state: IAppState, props: Props) => S;
const makeGetFlowExportReport = (): FlowExportReportSelector<Array<FlowExportReport>> =>
    // @ts-ignore
    createSelector(
        (state: IAppState) => getFlowExportReportsArray(state),
        (_, props: Props) => props.flowItemId,
        (reports: Array<FlowExportReport>, flowItemId: number): Array<FlowExportReport> =>
            reports.filter(x => x.ParentFlowItemId == flowItemId)
    );

type FlowExportDestinationSelector<S> = (state: IAppState) => S;
export const makeGetExportDestinations = (): FlowExportDestinationSelector<Array<IAdvertisersByCompany>> =>
    // @ts-ignore
    createSelector(
        (state: IAppState) => state.vars.destinations,
        (destinations: Array<IAdvertisersByCompany>): Array<IAdvertisersByCompany> =>
            destinations ? destinations.filter(x => x.AcceptFlag == "Y") : []
    );

export default FlowExportEdit;
