import h from "../helpers";
import { notifyBlack } from "../actions/notifyActions";
import { siteCustomization } from "../actions/actionCreators";

if (!top.RDX) {
    top.RDX = {};
}

//// ALL SESSION INFO ////
export const setSessionInfo = sessionInfo => ({
    type: "SET_SESSION",
    sessionInfo,
});
top.RDX.setSessionInfo = sessionInfo => top.store.dispatch(setSessionInfo(sessionInfo));

export const requestSessionInfo = () => dispatch =>
    fetch("/Account/GetSessionInfo", { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(setSessionInfo(data));
        });
top.RDX.requestSessionInfo = () => top.store.dispatch(requestSessionInfo());

//// SELECTED USER ////
export const setUserId = userId => ({
    type: "SET_USERID",
    userId,
});
top.RDX.setUserId = userId => top.store.dispatch(setUserId(userId));

//// SELECTED COMPANY ////
export const setCompanyId = companyId => ({
    type: "SET_COMPANYID",
    companyId,
});
top.RDX.setCompanyId = companyId => top.store.dispatch(setCompanyId(companyId));

//// FEATURES ////
export const setEnabledFeatures = (enabledFeatures = []) => ({
    type: "SET_ENABLED_FEATURES",
    enabledFeatures,
});
top.RDX.setEnabledFeatures = enabledFeatures => top.store.dispatch(setEnabledFeatures(enabledFeatures));

export const requestEnabledFeatures = () => dispatch =>
    fetch("/Main/MyFeatures", { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (Array.isArray(data) && data.length) {
                top.enabledFeatures = data;
                dispatch(setEnabledFeatures(data));
            }
        });
top.RDX.requestEnabledFeatures = () => top.store.dispatch(requestEnabledFeatures());

//// ISINTERNAL ////
// Am I logged in as an internal user? Bool
export const setIsInternal = isInternal => ({
    type: "SET_ISINTERNAL",
    isInternal,
});
top.RDX.setIsInternal = isInternal => top.store.dispatch(setIsInternal(isInternal));

export const requestIsInternal = () => dispatch =>
    fetch("/ReportsView/IsInternal", { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            const isInternal = data.result;
            dispatch(setIsInternal(isInternal));
        })
        .catch(error => {
            h.error("Error setting internal status.", error);
        });
top.RDX.requestIsInternal = () => top.store.dispatch(requestIsInternal());

/// LOGOUT ///

export const requestLogout = messageOverride => (dispatch, getState) => {
    const isOnNotAuthorizedSitePage = window.location.href.match(/account\/notauthorizedsite/);
    const { isOnSPA } = getState().vars;
    //This was causing the logout error that we keep seeing.  No clue why
    // dispatch(push(pushUrl));

    let token = "";
    if (document.getElementsByName("__RequestVerificationToken").length > 0) {
        token = document.getElementsByName("__RequestVerificationToken")[0].value;
    }
    fetch("/Account/LogOffJSON", {
        credentials: "same-origin",
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            __RequestVerificationToken: token,
        },
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data && data.success) {
                const message = messageOverride || "Logged Off.";
                dispatch(notifyBlack(message));
                top._loggedIn = false;

                if (isOnNotAuthorizedSitePage || !isOnSPA) {
                    // This is a special page that doesn't use SPA/Router, so we need to manually send them off the error page.
                    location.href = "/Account/Login/";
                }
                sessionStorage.removeItem("showPasswordExpiredNotification");
                localStorage.setItem("logged_off", "true");
                localStorage.removeItem("logged_off");

                location.href = "/Account/Login/";
            }
        })
        .catch(error => {
            h.error("Error logging off.", error);
        });
};
top.RDX.requestLogout = messageOverride => top.store.dispatch(requestLogout(messageOverride));

// Logs off without actually logging out - C# sends us this after it has destroyed our session.
export const logoutForcedByOtherSession = () => (dispatch, getState) => {
    const isOnNotAuthorizedSitePage = window.location.href.match(/account\/notauthorizedsite/);
    const { isOnSPA } = getState().vars;

    top._loggedIn = false;
    if (isOnNotAuthorizedSitePage || !isOnSPA) {
        // This is a special page that doesn't use SPA/Router, so we need to manually send them off the error page.
        location.href = "/Account/Login/";
    }

    localStorage.setItem("logged_off", "true");
    localStorage.removeItem("logged_off");
    //dispatch(requestSessionInfo());
};
top.RDX.logoutForcedByOtherSession = () => top.store.dispatch(logoutForcedByOtherSession());
import { SiteCustomizations } from "../helpers/constants";
export const logoutIfDataEngine = () => (dispatch, getState) => {
    const site = getState().session.site;
    if (site == "DataEngine") {
        const aeSite = siteCustomization(SiteCustomizations.AEname, "Audience Engine");
        const deSite = siteCustomization(SiteCustomizations.DEname, "Data Mixology Engine") + ".";

        dispatch(
            requestLogout("Logged Off.<br><br>Changing your company on " + aeSite + " signs you out of " + deSite)
        );
    }
};
top.RDX.logoutIfDataEngine = () => top.store.dispatch(logoutIfDataEngine());

export const extendSession = () => {
    try {
        if (top.AE && top.AE.sessionMonitorInstance) {
            top.AE.sessionMonitorInstance.extendSession(null, false, false);
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};

export const stayLoggedIn = () => {
    try {
        if (top.AE && top.AE.sessionMonitorInstance) {
            top.AE.sessionMonitorInstance.stayLoggedIn(true);
            top.RDX.hideModal("TIMEOUT_SESSION_WARNING");
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};

export const logOff = () => {
    try {
        if (top.AE && top.AE.sessionMonitorInstance) {
            top.RDX.hideModal("TIMEOUT_SESSION_WARNING");
            top.RDX.requestLogout();
            top.AE.sessionMonitorInstance.unload();
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};
