export const DragDropTypes = {
    FOLDER: "folder",
    FIELD: "field",
    CLIENTFIELD: "clientfield",
    GVARIABLE: "global_variable",
    PLACEHOLDER: "placeholder",
    CAMPAIGNOBJECT: "campaignlayoutobject",
    NEWFLOWITEM: "newFlowItem",
    FLOW: "flow",
    FLOWITEM: "flowItem",
    FLOWITEM_PORT: "flowItemPort",
    FLOWITEM_GHOST_PASTE: "flowItemGhostPaste",
    LAYOUT: "layout",
    LAYOUTOBJECT: "layoutObject",
    SVFIELD: "svField",
    COLUMN: "column",
};

export const SiteCustomizations = {
    AEemailLogo: "AEemailLogo",
    DEemailLogo: "DEemailLogo",
    AEadministrativeEmail: "AEadministrativeEmail",
    DEadministrativeEmail: "DEadministrativeEmail",
    OmniActivation: "Omniactivation",
    AEname: "AEname",
    DEname: "DEname",
    OPSemail: "OPSemail",
    DedupeDescription: "DedupeDescription",
    ComplianceEmail: "ComplianceEmail",
    MarketingEmail: "MarketingEmail",
    SupportEmail: "SupportEmail",
};

export const FlowIsRunning = {
    IDLE: 0,
    CLIENTSUBMITTED: 1,
    PENDING: 2,
    RUNNING: 3,
    CANCELLING: 4,
    DEPLOYING: 5, // #6191
    CANCELDEPLOYING: 6, // #6191
};

export const FlowProcessingTypes = {
    execute: 1,
    withoutQuantities: 2,
    otherUser: 4,
};

export const MoneyRegex = /^\d(\.\d{1,2})?$/;

// for any flow related, hard coded things - instead of searching in actions/reducers
export const FlowConstants = {
    allowsCopy: [
        "filter",
        "case",
        "split",
        "merge",
        "houseExpand",
        "svDedupe",
        "report",
        "export",
        "toCloud",
        "fromCloud",
        "model",
        "output",
        "singleview",
        "insights",
        "script",
        "scriptdbui",
        "offload",
        "flowControl",
        "flowExpression",
    ],
    hasQA: ["filter", "merge", "offerMerge", "export"],
};

export const FlowItemConstants = {
    typesWithFields: ["filter", "case", "svDedupe", "report", "split", "offerMerge", "export", "script", "scriptdbui"],
    typesWithLayoutObjects: ["exportreport"],
    typesWithCannedReportObjects: ["cannedreport"],
    doesNotHaveQuantity: [
        "report",
        "exportreport",
        "cannedreport",
        "singleview",
        "script",
        "scriptdbui",
        "discovery",
        "multiexport",
    ],
};

export const FlowBaseTypes = {
    Default: 0,
    Campaign: 1,
    Model: 2,
    Undecided: 99,
};

export const FieldVisibilityTypes = {
    NONE: 0,
    SELECT: 1,
    EXPORT: 2,
    DESTINATION: 3,
    REPORT: 4,
};

export const validFilters = ["filter", "split", "export", "empty", "case", "multiexport"];

export const BrowseFileIntegrations = {
    Computer: 0,
    S3: 1,
    SERVER: 2,
};

export const UEFieldParms = ["Field_List", "adult_2", "adult_3", "adult_4", "adult_5", "adult_6", "adult_7", "adult_8"];

export const qualifiers = [
    { label: "None", value: "" },
    { label: "Single Quote", value: "'" },
    { label: "Double Quote", value: '"' },
];

export const importModes = [
    { label: "New Table", value: "Submit" },
    { label: "Add Existing", value: "Append" },
    { label: "Replace Existing", value: "Replace" },
];
