import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import { getFlowItemClientVariablesArray } from "./flowItemClientVariables";

import type {
    FlowOutput,
    FlowItem,
    FlowRelation,
    FlowErrorsByItemId,
    FlowRelationParentLabel,
    FlowItemClientVariableD,
} from "../types/flowTypes";
import type { DestinationVariable } from "../types/types";
import { IClientVariable } from "../types/stores/vars";
const myGenericReducer = makeReducerFor("FLOW_OUTPUT", "FlowOutputId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowOutputsArray = createSelector(
    state => state.flowOutputs.byId,
    (flowOutputsById: {| [number]: FlowOutput |}): Array<FlowOutput> => {
        const r: Array<FlowOutput> = Object.values(flowOutputsById);
        return r;
    }
);

export type FlowOutputsByItemId = {
    [number]: FlowOutput,
};

export const getFlowOutputsByFlowItemId = createSelector(
    state => getFlowOutputsArray(state),
    (flowOutputs: Array<FlowOutput>): FlowOutputsByItemId =>
        flowOutputs.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowOutputsForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowOutputsByFlowItemId(state),
    (selectedFlow: number, flowItems: Array<FlowItem>, flowOutputsByItemId: FlowOutputsByItemId): Array<FlowOutput> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const thisExport = flowOutputsByItemId[itemId];
            if (thisExport != null) {
                result.push(thisExport);
            }
        }
        return result;
    }
);
import { getFlowRelationsForSelectedFlow, getFlowRelationsForAllFlows } from "./flowRelations";

export const allOutputParentsHaveResultsTable = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowOutputsForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    (
        selectedFlow: number,
        flowItems: Array<FlowItem>,
        flowOutputs: Array<FlowOutput>,
        flowRelations: Array<FlowRelation>
    ): boolean => {
        if (flowOutputs.length == 0) {
            return false;
        }

        let allHaveResultsTable = true;
        for (let outputItem of flowOutputs) {
            const parentRelations = flowRelations
                .filter(x => x.ChildFlowItemId == outputItem.FlowItemId && x.ParentFlowItemId != 0)
                .map(x => x.ParentFlowItemId);

            for (const parentId of parentRelations) {
                const parentFlowItemsFound = flowItems.filter(x => x.FlowItemId == parentId);
                if (parentFlowItemsFound.length > 0) {
                    const parentHasResultTable = parentFlowItemsFound[0].HasResultTable;
                    if (parentHasResultTable == false) {
                        return false;
                    }
                }
            }
        }
        return allHaveResultsTable;
    }
);

export const allOutputsHaveResultsTable = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    (flowItems: Array<FlowItem>): boolean => {
        let allHaveResultsTable = true;
        const activeOutputFlowItems = flowItems.filter(x => x.FlowItemType == "output" && x.IsActive);
        if (activeOutputFlowItems.length >= 0) {
            const exportItemsNotCalculated = activeOutputFlowItems.filter(x => x.HasResultTable == false);
            if (exportItemsNotCalculated.length > 0) {
                allHaveResultsTable = false;
            }
        }
        return allHaveResultsTable;
    }
);

export const anyOutputsNeedApproval = createSelector(
    state => state.selected.flow,
    state => getFlowOutputsForSelectedFlow(state),
    state => state.session.enabledFeatures,
    (selectedFlow: number, flowOutputs: Array<FlowOutput>): boolean => {
        if (flowOutputs.length == 0) {
            return false;
        }
        return flowOutputs.filter(x => x.IsApprovalRequired && x.MeetsMinimumThreshold).length > 0;
    }
);

export const anyOutputsMeetMinimumThreshold = createSelector(
    state => getFlowOutputsForSelectedFlow(state),
    (flowOutputs: Array<FlowOutput>): boolean => {
        if (flowOutputs.length == 0) {
            return false;
        }
        return flowOutputs.filter(x => x.MeetsMinimumThreshold).length > 0;
    }
);

export const allOutputsNeedingApproval = createSelector(
    state => state.selected.flow,
    state => getFlowOutputsForSelectedFlow(state),
    (selectedFlow: number, flowOutputs: Array<FlowOutput>): Array<FlowOutput> =>
        flowOutputs.filter(x => x.IsApprovalRequired && !x.IsCountApproved)
);

const outputsToErrorsById = (
    flowOutputs: Array<FlowOutput>,
    flowRelations: Array<FlowRelation>,
    flowExportDestinationOffers: { number: Array<IClientVariable> },
    layouts: Array<any>,
    flowItemClientVariables: Array<FlowItemClientVariableD>,
    flowRelationParentLabels: Array<FlowRelationParentLabel>,
    flowOutputDestinationVariables: { number: Array<DestinationVariable> },
    companyId: number,
    enabledFeatures: Array<string>,
    flowExports: Array<any>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowOutput of flowOutputs) {
        errorsById[flowOutput.FlowItemId] = validateFlowOutput(
            flowOutput,
            flowRelations,
            flowExportDestinationOffers,
            layouts,
            flowItemClientVariables,
            flowRelationParentLabels,
            flowOutputDestinationVariables,
            companyId,
            enabledFeatures,
            flowExports
        );
    }
    return errorsById;
};

import { getLayoutObjects } from "./exportlayoutobjects";
import { getFlowRelationsParentLabelsArray } from "./flowRelationParentLabels";
export const getOutputErrorsForSelectedFlow = createSelector(
    state => getFlowOutputsForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    state => state.flowExportObjects.destinationOffers,
    state => getLayoutObjects(state),
    state => getFlowItemClientVariablesArray(state),
    state => getFlowRelationsParentLabelsArray(state),
    state => state.flowExportObjects.destinationVariables,
    state => state.session.companyId,
    state => state.session.enabledFeatures,
    state => state.flowExports.byId,
    outputsToErrorsById
);

export const getOutputErrorsForAllFlows = createSelector(
    state => getFlowOutputsArray(state),
    state => getFlowRelationsForAllFlows(state),
    state => state.flowExportObjects.destinationOffers,
    state => getLayoutObjects(state),
    state => getFlowItemClientVariablesArray(state),
    state => getFlowRelationsParentLabelsArray(state),
    state => state.flowExportObjects.destinationVariables,
    state => state.session.companyId,
    state => state.session.enabledFeatures,
    state => state.flowExports.byId,
    outputsToErrorsById
);

//////////////////// HELPERS //////////////////////////////
export const validateFlowOutputDestinationOffers = (
    flowOutput: FlowOutput,
    flowRelations: Array<FlowRelation>,
    destinationOffers: any,
    layouts: any,
    flowItemCVs: Array<FlowItemClientVariableD>,
    enabledFeatures: Array<string>
): Array<string> => {
    const errors = [];
    const layout = layouts.filter(x => x.Layout.LayoutID == flowOutput.LayoutId)[0] || {};
    if (layout && layout.Layout && layout.Layout.DestinationLayout && layout.LayoutObjects) {
        const offersInLayout = layout.LayoutObjects.filter(x => x.ObjectType == 4).map(x => x.ObjectId);
        const requiredOffers = destinationOffers.filter(x => offersInLayout.includes(x.Id));
        if (requiredOffers.length > 0) {
            const parentRelations = flowRelations.filter(x => x.ChildFlowItemId == flowOutput.FlowItemId);
            for (const parentRelation of parentRelations) {
                const parentFlowItemCVs = flowItemCVs.filter(x => x.ChildFlowItemId == parentRelation.ChildFlowItemId);
                for (const requiredOffer of requiredOffers) {
                    const flowItemCV = parentFlowItemCVs.filter(x => x.VariableId == requiredOffer.Id)[0];

                    if (
                        (!flowItemCV ||
                            !flowItemCV.VariableValue ||
                            (flowItemCV.VariableValue.Kind == "string" && !flowItemCV.VariableValue.ValueString) ||
                            (flowItemCV.VariableValue.Kind == "string" && !flowItemCV.VariableValue.ValueString) ||
                            (flowItemCV.VariableValue.Kind == "field" && !flowItemCV.VariableValue.FieldId)) &&
                        !enabledFeatures.includes("flow-destination-level-splits-all-offers")
                    ) {
                        errors.push("Destination offer codes are required.");
                        return errors;
                    }
                }
            }
        }
    }
    return errors;
};

export const validateFlowOutputDestinationVariables = (
    flowOutput: FlowOutput,
    flowItemCVs: Array<FlowItemClientVariableD>,
    variables: Array<DestinationVariable>
): Array<string> => {
    const errors = [];
    if (variables.length > 0) {
        for (const variable of variables) {
            const thisClientVariable = flowItemCVs.filter(
                x => x.FlowItemId == flowOutput.FlowItemId && x.VariableId == variable.VariableId
            );

            if (thisClientVariable.length <= 0) {
                errors.push("Destination Variables are required.");
                return errors;
            }
        }
    }
    return errors;
};

export const validateFlowOutput = (
    flowOutput: FlowOutput,
    flowRelations: Array<FlowRelation>,
    flowOutputDestinationOffers: { number: Array<IClientVariable> },
    layouts: any,
    flowItemClientVariables: Array<FlowItemClientVariableD>,
    flowRelationParentLabels: Array<FlowRelationParentLabel>,
    flowOutputDestinationVariables: { number: Array<DestinationVariable> },
    companyId: number,
    enabledFeatures: Array<string>,
    flowExports: any
): Array<string> => {
    let errors = [];

    if (flowOutput.LayoutId == 0 || flowOutput.LayoutId == null) {
        errors.push("A layout must be selected before calculating.");
    }

    if (flowOutput.DestinationId == 0 || flowOutput.DestinationId == null) {
        errors.push("A destination must be selected before calculating.");
    }

    let flowExport = null;
    const parentRelations = flowRelations.filter(x => x.ChildFlowItemId == flowOutput.FlowItemId);

    const exports = Object.keys(flowExports).map(item => ({
        export: flowExports[item],
        value: flowExports[item].FlowItemId,
    }));
    for (const parentRelation of parentRelations) {
        const thisExport = exports.find(x => x.value == parentRelation.ParentFlowItemId);
        flowExport = thisExport != null ? thisExport.export : null;
    }
    if (flowExport != null) {
        const destinationOffers = flowOutputDestinationOffers[flowExport.DestinationId || 0] || [];

        const destinationOfferCodesErrors = validateFlowOutputDestinationOffers(
            flowOutput,
            flowRelations,
            destinationOffers,
            layouts,
            flowItemClientVariables,
            enabledFeatures
        );
        if (destinationOfferCodesErrors.length > 0) {
            errors = errors.concat(destinationOfferCodesErrors);
        }

        const destinationVariables = flowOutputDestinationVariables[flowExport.DestinationId || 0] || [];
        const destinationVariableErrors = validateFlowOutputDestinationVariables(
            flowOutput,
            flowItemClientVariables,
            destinationVariables
        );
        if (destinationVariableErrors.length > 0) {
            errors = errors.concat(destinationVariableErrors);
        }
    }

    let validLayout = false;
    layouts.forEach(x => {
        if (flowOutput.LayoutId == x.Layout.LayoutID) {
            validLayout = true;
        }
    });
    if (!validLayout) {
        //Previously saved layout was invalidated and is no longer being brought down to the client.
        //might change error to "A layout must be selected before calculating" since it appears to the user a layout is no longer selected
        errors.push("The previously selected layout is invalid and must be fixed before calculating.");
    }

    let needsParent = false;
    const exportRelations = flowRelations.filter(
        z => z.ParentFlowItemId != 0 && z.ChildFlowItemId == flowOutput.FlowItemId
    );
    const allRelations = flowRelations.filter(z => z.ChildFlowItemId == flowOutput.FlowItemId);
    if ((exportRelations.length == 0 || allRelations.length == 0) && !needsParent) {
        errors.push("Output items must have a parent assigned.");
        needsParent = true;
    }

    const flowRelationParentLabelErrors = validateFlowRelationParentLabels(
        flowOutput,
        flowRelations,
        layouts,
        flowRelationParentLabels
    );
    if (flowRelationParentLabelErrors.length > 0) {
        errors = errors.concat(flowRelationParentLabelErrors);
    }
    return errors;
};

export const validateFlowRelationParentLabels = (
    flowOutput: FlowOutput,
    flowRelations: Array<FlowRelation>,
    layouts: any,
    flowRelationParentLabels: Array<FlowRelationParentLabel>
): Array<string> => {
    const errors = [];
    const layout = layouts.filter(x => x.Layout.LayoutID == flowOutput.LayoutId)[0] || {};

    //parent labels are only for pivot layouts
    if (layout && layout.Layout && layout.Layout.IsPivot) {
        const parentRelationsIds = flowRelations
            .filter(x => x.ChildFlowItemId == flowOutput.FlowItemId)
            .map(x => x.FlowRelationId);
        const parentLabels = flowRelationParentLabels.filter(x => parentRelationsIds.includes(x.FlowRelationId));
        // if has parent labels validate, if not, may not have the feature so its ok
        if (parentLabels.length > 0) {
            for (const parentLabel of parentLabels) {
                if (parentLabel.ParentLabel.trim() == "") {
                    errors.push("Pivot column names are required for each parent of the export.");
                    return errors;
                }
                const regex = new RegExp(/[^a-zA-Z0-9_ ]/g);
                if (regex.test(parentLabel.ParentLabel.trim())) {
                    errors.push(
                        "At least one Output Column Name has an invalid character. Please remove all invalid characters."
                    );
                    return errors;
                }
                if (
                    parentLabels.filter(
                        x =>
                            x.FlowRelationParentLabelId != parentLabel.FlowRelationParentLabelId &&
                            x.ParentLabel.trim() == parentLabel.ParentLabel.trim()
                    ).length > 0
                ) {
                    errors.push("Pivot column names must be different for each parent.");
                    return errors;
                }
            }
        }
    }
    return errors;
};
