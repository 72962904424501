import { FlowControlStatusAction, IFlowControlStatusStore } from "../types/stores/flowControlData";

export const SET_FLOW_CONTROL_STATUS = "SET_FLOW_CONTROL_STATUS";

const defaultState: IFlowControlStatusStore = {
    running: [],
    completed: [],
    failed: [],
    loading: false,
};

function flowControlStatus(state = defaultState, action: FlowControlStatusAction): IFlowControlStatusStore {
    switch (action.type) {
        case SET_FLOW_CONTROL_STATUS:
            return Object.assign({}, state, {
                running: action.running,
                completed: action.completed,
                failed: action.failed,
                loading: action.loading,
            });
        default:
            return state;
    }
}
export default flowControlStatus;
