import { request } from "../helpers/httpInterceptor";
import h from "../helpers";
import { toJson } from "../helpers/typedHelpers";
import {
    SET_AWS_S3_FILE_LIST_LOADING,
    SET_AWS_S3_FILE_LIST,
    SET_AWS_S3_FILE_DOWNLOADING,
    SET_AWS_S3_FILE_LIST_ERROR,
    SET_AWS_S3_FILE_INFO,
    RESET_AWS_S3,
    SET_AWS_FLOW_CONTROL_FILES_INFO,
} from "../reducers/aws";
import { Dispatch, GetState } from "../types/types";
import { IFileInfo } from "../types/actions/fileInfo";
import { ISetAwsFlowControlFilesInfo } from "../types/actions/aws";

const accessErrors = ["Access Denied", "InvalidAccessKeyId", "SignatureDoesNotMatch"];
const accessMessage = "Access to the Amazon S3 was denied or is now invalid. Please contact your administrator.";

export const GetS3FileList =
    () =>
    (dispatch: Dispatch): void => {
        dispatch({ type: SET_AWS_S3_FILE_LIST_LOADING, isLoading: true });
        return request(
            `/api/aws/list`,
            {
                method: "GET",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                if (data.success) {
                    dispatch({ type: SET_AWS_S3_FILE_LIST, files: data.files });
                    dispatch({ type: SET_AWS_S3_FILE_LIST_LOADING, isLoading: false });
                    return;
                }

                if (data.message) {
                    dispatch({ type: SET_AWS_S3_FILE_LIST, files: [] });
                    let errorMessage =
                        "An unexpected error has occurred in  fetching your Amazon S3 files. Please contact your administrator.";

                    if (data.message && accessErrors.includes(data.message)) {
                        errorMessage = accessMessage;
                    }

                    dispatch({ type: SET_AWS_S3_FILE_LIST_ERROR, error: errorMessage });
                    dispatch({ type: SET_AWS_S3_FILE_LIST_LOADING, isLoading: false });
                    return;
                }
            })
            .catch(() => {
                dispatch({ type: SET_AWS_S3_FILE_LIST_LOADING, isLoading: false });
            });
    };

export const GetS3FileInfo =
    (fileName: string) =>
    (dispatch: Dispatch): void => {
        dispatch({ type: SET_AWS_S3_FILE_DOWNLOADING, isDownloading: true });
        return request(
            `/api/aws/file?fileName=${fileName}`,
            {
                method: "GET",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: SET_AWS_S3_FILE_INFO,
                        fileName,
                        filePreview: data.preview,
                        fileQuantity: data.fileQuantity,
                    });
                    dispatch(
                        addFileInfo({
                            fileName,
                            filePreview: data.preview,
                            fileQuantity: data.fileQuantity,
                        } as IFileInfo)
                    );
                    dispatch({ type: SET_AWS_S3_FILE_DOWNLOADING, isDownloading: false });
                    return;
                }

                if (data.message) {
                    dispatch({ type: SET_AWS_S3_FILE_INFO, fileName: null, filePreview: null, fileQuantity: null });
                    let errorMessage =
                        "An unexpected error has occurred in fetching your Amazon S3 file. Please contact your administrator.";

                    if (data.message && accessErrors.includes(data.message)) {
                        errorMessage = accessMessage;
                    }
                    if (data.message == "NoSuchKey") {
                        errorMessage = "File Not Found";
                    }

                    dispatch({ type: SET_AWS_S3_FILE_LIST_ERROR, error: errorMessage });
                    dispatch({ type: SET_AWS_S3_FILE_DOWNLOADING, isDownloading: false });
                    return;
                }
            })
            .catch(() => {
                dispatch({ type: SET_AWS_S3_FILE_DOWNLOADING, isDownloading: false });
            });
    };

export const ResetS3Data =
    () =>
    (dispatch: Dispatch): void => {
        dispatch({ type: RESET_AWS_S3 });
    };

export const SetAWSFlowControlFilesInfo = (files: Array<IFileInfo>): ISetAwsFlowControlFilesInfo => ({
    type: SET_AWS_FLOW_CONTROL_FILES_INFO,
    flowControlFilesInfo: files,
});

export const addFileInfo =
    (newFileInfo: IFileInfo) =>
    (dispatch: Dispatch, getState: GetState): void => {
        const state = getState();
        const enabledFeatures: Array<string> = state.session.enabledFeatures;

        if (enabledFeatures.includes("flow-control-multi-input")) {
            const filesInfo: Array<IFileInfo> = state.aws.flowControlFilesInfo;

            let newFilesInfo = [...filesInfo];
            let currentFileInfo = newFilesInfo.find(x => x.fileName == newFileInfo.fileName);

            if (currentFileInfo) {
                currentFileInfo.filePreview = newFileInfo.filePreview;
                currentFileInfo.fileQuantity = newFileInfo.fileQuantity;
            } else {
                newFilesInfo.push(newFileInfo);
            }

            dispatch(SetAWSFlowControlFilesInfo(newFilesInfo));
        }
    };
