import { IActivationSchedulesStore, ActivationSchedulesActions } from "../types/stores/activationSchedules";

export const GET_ACTIVATION_SCHEDULES = "GET_ACTIVATION_SCHEDULES";
export const EDIT_MAXIMUM_SEATS = "EDIT_MAXIMUM_SEATS";
export const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";
export const LOADING_SCHEDULES = "LOADING_SCHEDULES";
export const SET_COMPANY_DESTINATIONS = "SET_COMPANY_DESTINATIONS";

const initialState: IActivationSchedulesStore = {
    activationSchedules: [],
    loading: false,
};

function adminCompanies(state = initialState, action: ActivationSchedulesActions): IActivationSchedulesStore {
    switch (action.type) {
        case GET_ACTIVATION_SCHEDULES:
            return Object.assign({}, state, {
                activationSchedules: action.activationSchedules,
            });
        case LOADING_SCHEDULES:
            return Object.assign({}, state, {
                loading: action.loading,
            });
        default:
            return state;
    }
}

export default adminCompanies;
