import React, { useRef, useState, useEffect } from "react";
import { TextField, TextFieldProps } from "@material-ui/core";

function TextFieldForTable({ ...props }: TextFieldProps): JSX.Element {
    let textfieldProps: TextFieldProps = { ...props };
    let regex: RegExp;

    const [localValue, setLocalValue] = useState(props.value ? (props.value as string | number) : "");
    const prevValueRef = useRef(props.value ? (props.value as string | number) : "");

    useEffect(() => {
        if (props.value != undefined && props.value != prevValueRef.current) {
            setLocalValue(props.value as string | number);
            prevValueRef.current = props.value as string | number;
        }
    }, [props.value]);

    const onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
        const reg = new RegExp(regex);

        if (reg.test(e.target.value)) {
            setLocalValue(e.target.value);

            if (props.onBlur) {
                props.onBlur(e);
            }
        } else {
            setLocalValue(prevValueRef.current || "");
        }
    };

    const onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
        const reg = new RegExp(regex);

        if (reg.test(e.target.value)) {
            setLocalValue(e.target.value);

            if (props.onChange) {
                props.onChange(e);
            }
        }
    };

    textfieldProps.value = localValue;
    textfieldProps.onChange = e => onChange(e);
    textfieldProps.onBlur = e => onBlur(e);

    return (
        // putting type="number" as a prop will add the up/down arrows back
        <TextField {...textfieldProps} />
    );
}

export default TextFieldForTable;
