import h from "../helpers";
import { toJson } from "../helpers/typedHelpers";
import { incAjaxCount, decAjaxCount } from "./actionCreators";
import { updateAttribute, updateMultipleAttribute, newFlowClientVariable, newFlowItemOfferCode } from "./flowActions";
import type { ThunkAction, Dispatch, GetState } from "../types/types";
import type {
    FlowSetClientVariableAction,
    FlowAddClientVariableAction,
    FlowItemSetClientVariableAction,
    FlowItemAddClientVariableAction,
} from "../types/actionTypes";
import { getFlowItemClientVariablesArray } from "../reducers/flowItemClientVariables";
import { getFlowItemOfferCodesArray } from "../reducers/flowItemOfferCodes";
import { deleteFlowItemClientVariable, deleteFlowItemOfferCode } from "./flowActions";
import {
    ILoadVariablesAction,
    INormalizedClientVariableData,
    IClientVariable,
    ClientVariableKind,
    VariableValueType,
    IVariableDataType,
    IVariableSubType,
    IVariableGrouping,
    ISetVariableGroupings,
} from "../types/stores/vars";
import { normalize, schema } from "normalizr";
import {
    ISetLoadingDestinationOrder,
    ISetVariableDataTypes,
    ISetVariableDataTypesFull,
    IVariableType,
} from "../types/stores/vars";
import { IAppState } from "../types/stores/index";

export const loadClientVariables = (normalizedData: INormalizedClientVariableData): ILoadVariablesAction => ({
    type: "LOAD_CLIENT_VARIABLES",
    normalizedData,
});

export const requestClientVariableGroupings =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(incAjaxCount());
        fetch("/clientvariable/GetVariableGroupings", { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                const response = data as unknown as { groupings: Array<IVariableGrouping> };
                dispatch(loadVariableGroupings(response.groupings));
                dispatch(decAjaxCount());
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error loading client variables.", error);
            });
    };

export const loadVariableGroupings = (variableGroupings: Array<IVariableGrouping>): ISetVariableGroupings => ({
    type: "SET_VARIABLE_GROUPINGS",
    variableGroupings,
});

export const requestClientVariableDataTypes =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(incAjaxCount());
        fetch("/clientvariable/GetVariableTypes", { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                const response = data as unknown as { variableTypes: Array<IVariableType> };
                dispatch(loadDataTypes(response.variableTypes));
                dispatch(decAjaxCount());
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error loading client variables.", error);
            });
    };

export const requestClientVariableDataTypesFull =
    () =>
    (dispatch: Dispatch): void => {
        const url = "/clientvariable/GetVariableTypesFull";
        dispatch(incAjaxCount());
        fetch(url, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                interface ITempData {
                    variableDataTypes: Array<IVariableDataType>;
                    variableSubTypesArray: Array<Array<{ Key: string; Value: any }>>;
                }
                const response = data as ITempData;
                if (response.variableSubTypesArray) {
                    const transformedArray = response.variableSubTypesArray.map(
                        (subTypesArray: Array<{ Key: string; Value: any }>) => {
                            const transformedObject = {
                                SubTypeId: subTypesArray.find(obj => obj.Key === "SubTypeId")?.Value || 0,
                                VariableTypeId: subTypesArray.find(obj => obj.Key === "VariableTypeId")?.Value || 0,
                                Regex: subTypesArray.find(obj => obj.Key === "Regex")?.Value || "",
                                Description: subTypesArray.find(obj => obj.Key === "Description")?.Value || "",
                                CompanyId: subTypesArray.find(obj => obj.Key === "CompanyId")?.Value || 0,
                                DropdownValues: subTypesArray.find(obj => obj.Key === "DropdownValues")?.Value || "",
                                Error: "",
                                VariableTypeName:
                                    subTypesArray.find(obj => obj.Key === "VariableTypeName")?.Value || "",
                            };
                            return transformedObject;
                        }
                    );
                    dispatch(loadDataTypesFull(response.variableDataTypes, transformedArray));
                    dispatch(decAjaxCount());
                }
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error loading client variables.", error);
            });
    };

export const loadDataTypes = (variableTypes: Array<IVariableType>): ISetVariableDataTypes => ({
    type: "SET_VARIABLE_DATA_TYPES",
    variableTypes,
});

export const loadDataTypesFull = (
    variableDataTypes: Array<IVariableDataType>,
    variableSubTypes: Array<IVariableSubType>
): ISetVariableDataTypesFull => ({
    type: "SET_VARIABLE_DATA_TYPES_FULL",
    variableDataTypes,
    variableSubTypes,
});

export const requestClientVariables = (): ThunkAction => (dispatch: Dispatch) => {
    const url = "/clientvariable/list";
    dispatch(incAjaxCount());
    fetch(url, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            const variableArray = data as unknown as Array<IClientVariable>;
            const normalizedData = normalizeVariableData(variableArray);
            dispatch(loadClientVariables(normalizedData));
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error loading client variables.", error);
        });
};

export const requestDeleteClientVariable =
    (id: number): ThunkAction =>
    (dispatch: Dispatch) => {
        const deleteUrl =
            "/ClientVariable/Delete?" +
            h.serialize({
                variableId: id,
            });

        dispatch(incAjaxCount());
        fetch(deleteUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                const response = data as unknown as { result: boolean; error: boolean };
                dispatch(decAjaxCount());
                if (response.error) {
                    h.error(response.error);
                } else {
                    dispatch(requestClientVariables());
                    dispatch(offerCodeCleanup(id));
                }
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error deleting client variable", error);
            });
    };

export const requestDeleteClientGrouping =
    (id: number): ThunkAction =>
    (dispatch: Dispatch) => {
        const deleteUrl =
            "/ClientVariable/DeleteGrouping?" +
            h.serialize({
                id,
            });

        dispatch(incAjaxCount());
        fetch(deleteUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                const response = data as unknown as { result: boolean; error: boolean };
                dispatch(decAjaxCount());
                if (response.error) {
                    h.error(response.error);
                } else {
                    dispatch(requestClientVariableGroupings());
                }
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error deleting client variable grouping", error);
            });
    };

const offerCodeCleanup = (id: number) => (dispatch: Dispatch, getState: GetState) => {
    const state: IAppState = getState();
    const clientVariables = state.clientVariables ? state.clientVariables.byId : null;
    if (!clientVariables) {
        return;
    }
    const variable = clientVariables[id];
    if (!variable || variable.VariableScope == "OfferCode") {
        return;
    }

    const flowVariables = getFlowItemClientVariablesArray(state);
    if (flowVariables) {
        const variablesToDelete = flowVariables.filter(x => x.VariableId == id);
        for (const variable of variablesToDelete) {
            dispatch(deleteFlowItemClientVariable(variable.FlowItemClientVariableId));
        }
    }

    const flowOffers = getFlowItemOfferCodesArray(state);
    if (flowOffers) {
        const offersToDelete = flowOffers.filter(x => x.VariableId == id);
        for (const offer of offersToDelete) {
            dispatch(deleteFlowItemOfferCode(offer.FlowItemOfferCodeId));
        }
    }
};

const normalizeVariableData = (variableData: Array<IClientVariable>): INormalizedClientVariableData => {
    const variable = new schema.Entity("variables", {}, { idAttribute: "Id" });
    const mySchema = {
        variables: [variable],
    };
    return normalize({ variables: variableData }, mySchema);
};

export const requestAddClientVariable =
    (
        variableName: string,
        variableKind: ClientVariableKind,
        variableScope: string,
        variableSubTypeId: number,
        variableGroupId: number | null,
        onAddCallback: () => void,
        onErrorCallback: () => void
    ) =>
    (dispatch: Dispatch): void => {
        dispatch(incAjaxCount());
        const saveUrl =
            "/ClientVariable/Add?" +
            h.serialize({
                variableName,
                variableKind,
                variableScope,
                variableSubTypeId,
                variableGroupId,
            });
        fetch(saveUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(toJson)
            .then(() => {
                dispatch(decAjaxCount());
                dispatch(requestClientVariables());
                if (typeof onAddCallback == "function") {
                    onAddCallback();
                    //onAddCallback(data.id);
                }
            })
            .catch(error => {
                dispatch(decAjaxCount());
                if (typeof onErrorCallback == "function") {
                    onErrorCallback();
                }
                h.error("Error adding client variable", error);
            });
    };

export const requestAddClientVariableGrouping =
    (name: string, description: string, onAddCallback: () => void, onErrorCallback: () => void) =>
    (dispatch: Dispatch): void => {
        dispatch(incAjaxCount());
        const saveUrl =
            "/ClientVariable/AddGrouping?" +
            h.serialize({
                name,
                description,
            });
        fetch(saveUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(toJson)
            .then(() => {
                dispatch(decAjaxCount());
                dispatch(requestClientVariableGroupings());
                if (typeof onAddCallback == "function") {
                    onAddCallback();
                    //onAddCallback(data.id);
                }
            })
            .catch(error => {
                dispatch(decAjaxCount());
                if (typeof onErrorCallback == "function") {
                    onErrorCallback();
                }
                h.error("Error adding client variable groupings", error);
            });
    };

export const updateDestinationOrder =
    (currentOrderNumber: number, currentPositionId: number, newOrderNumber: number, previousPositionId: number) =>
    (dispatch: Dispatch): void => {
        dispatch(incAjaxCount());
        dispatch(setloadingListDestinationOrder(true));
        const saveUrl =
            "/ClientVariable/UpdateOrder?" +
            h.serialize({
                currentOrderNumber,
                currentPositionId,
                newOrderNumber,
                previousPositionId,
            });
        fetch(saveUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(toJson)
            .then(() => {
                dispatch(decAjaxCount());
                dispatch(requestClientVariables());
                dispatch(setloadingListDestinationOrder(false));
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error updating client variable", error);
            });
    };

export const flowSetClientVariable =
    (flowId: number, variableId: number, variableValue: VariableValueType, isVisible: boolean) =>
    (dispatch: Dispatch, getState: GetState): void => {
        const state = getState();

        // Look for the flowClientVariables matching
        const ccvById = state.flowClientVariables.byId;
        let ccvId: string | null = null; // Null if we don't find the flowClientVariableId, otherwise we'll put the found ID here

        const allCcvIds = Object.keys(ccvById);
        for (const id of allCcvIds) {
            if (ccvById[id].VariableId != variableId || ccvById[id].FlowId != flowId) {
                // Not a match
                continue;
            }
            // Found it
            ccvId = id;
            break;
        }

        if (ccvId == null) {
            dispatch(newFlowClientVariable(variableId, flowId, variableValue, isVisible));
        } else {
            const updatesToMake = { VariableValue: variableValue, IsVisible: isVisible };
            dispatch(updateMultipleAttribute("flowClientVariables", ccvId, updatesToMake, true));
        }
        dispatch(updateAttribute("flows", flowId, "hasUnsavedChanges", true));
    };

export const flowItemSetOfferCode =
    (flowId: number, flowItemId: number, variableId: number, isVisible: boolean, sortOrder: number | null) =>
    (dispatch: Dispatch, getState: GetState): void => {
        const state = getState();
        // Look for the flowItemOfferCodes matching
        const ccvById = state.flowItemOfferCodes.byId;
        let ccvId: string | null = null; // Null if we don't find the flowClientVariableId, otherwise we'll put the found ID here

        const allCcvIds = Object.keys(ccvById);
        for (const id of allCcvIds) {
            if (ccvById[id].VariableId != variableId || ccvById[id].FlowItemId != flowItemId) {
                // Not a match
                continue;
            }
            // Found it
            ccvId = id;
            break;
        }

        if (ccvId == null) {
            dispatch(newFlowItemOfferCode(flowItemId, variableId, isVisible, sortOrder));
        } else {
            dispatch(
                updateMultipleAttribute("flowItemOfferCodes", ccvId, { IsVisible: isVisible, SortOrder: sortOrder })
            );
        }
        dispatch(updateAttribute("flows", flowId, "hasUnsavedChanges", true));
    };

export const flowItemSetClientVariable =
    (flowId: number, flowItemId: number, variableId: number, variableValue: VariableValueType) =>
    (dispatch: Dispatch, getState: GetState): void => {
        const state = getState();

        // Look for the flowClientVariables matching
        const ccvById = state.flowItemClientVariables.byId;
        let ccvId: string | null = null; // Null if we don't find the flowItemClientVariableId, otherwise we'll put the found ID here

        const allCcvIds = Object.keys(ccvById);
        for (const id of allCcvIds) {
            if (
                ccvById[id].VariableId != variableId ||
                ccvById[id].FlowItemId != flowItemId ||
                ccvById[id].FlowId != flowId
            ) {
                // Not a match
                continue;
            }
            // Found it
            ccvId = id;
            break;
        }

        if (ccvId == null) {
            const newId =
                Math.min.apply(
                    null,
                    allCcvIds.concat(["0"]).map(x => parseInt(x))
                ) - 1;
            dispatch(_flowItemAddClientVariable(newId, flowId, flowItemId, variableId, variableValue));
        } else {
            dispatch(_flowItemSetClientVariable(parseInt(ccvId), variableValue, flowId, flowItemId));
        }
    };

export const setloadingListDestinationOrder = (loadingListDestinationOrder: boolean): ISetLoadingDestinationOrder => ({
    type: "SET_LOADING_DESTINATION_ORDER",
    loadingListDestinationOrder,
});

export const _flowSetClientVariable = (
    flowClientVariableId: number,
    variableValue: VariableValueType,
    flowId: number
): FlowSetClientVariableAction => ({
    type: "FLOW_SET_CLIENT_VARIABLE",
    flowId,
    flowClientVariableId,
    variableValue,
    meta: { doNotBatch: true },
});

export const _flowItemSetClientVariable = (
    flowItemClientVariableId: number,
    variableValue: VariableValueType,
    flowId: number,
    flowItemId: number
): FlowItemSetClientVariableAction => ({
    type: "FLOW_ITEM_SET_CLIENT_VARIABLE",
    flowId,
    flowItemId,
    flowItemClientVariableId,
    variableValue,
    meta: { doNotBatch: true },
});

export const _flowAddClientVariable = (
    newId: number,
    flowId: number,
    variableId: number,
    variableValue: VariableValueType
): FlowAddClientVariableAction => ({
    type: "FLOW_ADD_CLIENT_VARIABLE",
    newId,
    flowId,
    variableId,
    variableValue,
    meta: { doNotBatch: true },
});

export const _flowItemAddClientVariable = (
    newId: number,
    flowId: number,
    flowItemId: number,
    variableId: number,
    variableValue: VariableValueType
): FlowItemAddClientVariableAction => ({
    type: "FLOW_ITEM_ADD_CLIENT_VARIABLE",
    newId,
    flowId,
    flowItemId,
    variableId,
    variableValue,
    meta: { doNotBatch: true },
});
