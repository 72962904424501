import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowItem, FlowExternalServiceHardcode } from "../types/stores/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_EXTERNAL_SERVICE_HARDCODE", "HardcodeId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { IAppState } from "../types/stores";
import { IFlowExternalServiceHardcodeStore } from "../types/stores/flowExternalServiceHardcode";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const myReducer = (state = {}, action: any): IFlowExternalServiceHardcodeStore =>
    subItemReducer(myGenericReducer(state, action), action);

export default myReducer;

export type FlowExternalServiceHardcodeById = {
    [key: number]: FlowExternalServiceHardcode;
};
export type FlowExternalServiceHardcodeByItemId = {
    [key: number]: Array<FlowExternalServiceHardcode>;
};

export const getFlowExternalServiceHardcodesArray = createSelector(
    (state: IAppState) => state.flowExternalServiceHardcodes.byId,
    (flowExternalServiceHardcodesById: FlowExternalServiceHardcodeById): Array<FlowExternalServiceHardcode> => {
        const r: Array<FlowExternalServiceHardcode> = Object.values(flowExternalServiceHardcodesById);
        return r;
    }
);

// eslint-disable-line
export const getFlowExternalServiceHardcodesByFlowItemId = createSelector(
    (state: IAppState) => getFlowExternalServiceHardcodesArray(state),
    (flowExternalServiceHardcodes: Array<FlowExternalServiceHardcode>): FlowExternalServiceHardcodeByItemId =>
        flowExternalServiceHardcodes.reduce((acc, row) => {
            if (!acc[row.FlowItemId]) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowExternalServiceHardcodesForSelectedFlow = createSelector(
    (state: IAppState) => getFlowItemsForSelectedFlow(state),
    (state: IAppState) => getFlowExternalServiceHardcodesByFlowItemId(state),
    (
        flowItems: Array<FlowItem>,
        flowExternalServiceHardcodesByItemId: FlowExternalServiceHardcodeByItemId
    ): Array<FlowExternalServiceHardcode> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        let result: Array<FlowExternalServiceHardcode> = [];
        for (const itemId of itemIds) {
            const params = flowExternalServiceHardcodesByItemId[itemId];
            if (params != null) {
                result = result.concat(params);
            }
        }
        return result;
    }
);

//@ts-ignore
export const getFlowExternalServiceHardcodesForSelectedFlowItem = createSelector(
    (state: IAppState) => state.selected.flowItem,
    (state: IAppState) => getFlowExternalServiceHardcodesByFlowItemId(state),
    (flowItemId, flowExternalServiceHardcodesByItemId): Array<FlowExternalServiceHardcode> =>
        flowExternalServiceHardcodesByItemId[flowItemId] || []
);
