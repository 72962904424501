if (!top.RDX) {
    top.RDX = {};
}

export const setSelectedCannedReport = Id => ({
    type: "SET_SELECTED_CANNEDREPORT",
    Id,
});
top.RDX.setSelectedCannedReport = Id => top.store.dispatch(setSelectedCannedReport(Id));

export const clearSelectedCannedReport = () => ({
    type: "CLEAR_SELECTED_CANNEDREPORT",
});
top.RDX.clearSelectedCannedReport = () => top.store.dispatch(clearSelectedCannedReport());
