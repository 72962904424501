import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow, getFlowItemsArray } from "./flowItems";
import type {
    FlowItem,
    FlowExternalService,
    FlowExternalServiceParameter,
    FlowExternalServiceInput,
    FlowExternalServiceHardcode,
    FlowErrorsByItemId,
    FlowDeploymentLog,
    FlowRelation,
} from "../types/stores/flowTypes";
import type {
    IExternalFileLocation,
    IExternalParameter,
    IExternalServiceDefaultFields,
} from "../types/stores/flowControlData";

const myGenericReducer = makeReducerFor("FLOW_EXTERNAL_SERVICE", "FlowExternalServiceId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { IAppState } from "../types/stores";
import {
    FlowExternalServiceParameterByItemId,
    getFlowExternalServiceParametersByFlowItemId,
} from "./flowExternalServiceParameters";
import {
    FlowExternalServiceInputByItemId,
    getFlowExternalServiceInputsByFlowItemId,
} from "./flowExternalServiceInputs";
import {
    FlowExternalServiceHardcodeByItemId,
    getFlowExternalServiceHardcodesByFlowItemId,
} from "./flowExternalServiceHardcodes";
import { IFlowExternalServiceStore } from "../types/stores/flowExternalService";
import {
    IExternalServiceLayout,
    IExternalServiceLayoutField,
    IExternalServiceField,
    IExternalServiceClientFieldAlias,
} from "../types/stores/externalServiceLayoutData";
import { DeploySettings } from "../types/stores/companyTable";
import { BrowseFileLocations } from "../components/flows/item/FlowControlItems/FlowControlInput";
import { getFlowRelationsForSelectedFlow } from "./flowRelations";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const myReducer = (state = {}, action: any): IFlowExternalServiceStore =>
    subItemReducer(myGenericReducer(state, action), action);

export default myReducer;

export type FlowExternalServiceByItemId = {
    [key: number]: FlowExternalService;
};

export const getFlowExternalServicesArray = createSelector(
    (state: IAppState) => state.flowExternalServices.byId,
    (flowExternalServiceById: FlowExternalServiceByItemId): Array<FlowExternalService> => {
        const r: Array<FlowExternalService> = Object.values(flowExternalServiceById);
        return r;
    }
);

// eslint-disable-line
export const getFlowExternalServicesByFlowItemId = createSelector(
    (state: IAppState) => getFlowExternalServicesArray(state),
    (flowExternalServices: Array<FlowExternalService>): FlowExternalServiceByItemId =>
        flowExternalServices.reduce((acc, row) => {
            if (!acc[row.FlowItemId]) {
                acc[row.FlowItemId] = row;
            }
            return acc;
        }, {})
);

export const getFlowExternalServicesForSelectedFlow = createSelector(
    (state: IAppState) => getFlowItemsForSelectedFlow(state),
    (state: IAppState) => getFlowExternalServicesByFlowItemId(state),
    (
        flowItems: Array<FlowItem>,
        flowExternalServicesByItemId: FlowExternalServiceByItemId
    ): Array<FlowExternalService> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        let result: Array<FlowExternalService> = [];
        for (const itemId of itemIds) {
            const externalService = flowExternalServicesByItemId[itemId];
            if (externalService) {
                result.push(externalService);
            }
        }
        return result;
    }
);

export const getFlowExternalServicesForAllFlows = createSelector(
    (state: IAppState) => getFlowItemsArray(state),
    (state: IAppState) => getFlowExternalServicesByFlowItemId(state),
    (
        flowItems: Array<FlowItem>,
        flowExternalServicesByItemId: FlowExternalServiceByItemId
    ): Array<FlowExternalService> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        let result: Array<FlowExternalService> = [];
        for (const itemId of itemIds) {
            const externalService = flowExternalServicesByItemId[itemId];
            if (externalService) {
                result.push(externalService);
            }
        }
        return result;
    }
);

//@ts-expect-error
export const getFlowExternalServiceForSelectedFlowItem = createSelector(
    (state: IAppState) => state.selected.flowItem,
    state => getFlowExternalServicesByFlowItemId(state),
    (flowItemId: number, flowExternalServicesByItemId: FlowExternalServiceByItemId): FlowExternalService =>
        flowExternalServicesByItemId[flowItemId]
);

export const getSelectedFlowHasActiveExternalServices = createSelector(
    (state: IAppState) => getFlowItemsForSelectedFlow(state),
    (flowItems: Array<FlowItem>): boolean =>
        flowItems.filter(x => x.FlowItemType == "flowControl" && x.IsActive).length > 0
);

export const getExternalServiceDeployAllowed = createSelector(
    (state: IAppState) => getFlowItemsForSelectedFlow(state),
    (state: IAppState) => state.selected.flow,
    (state: IAppState) => state.flowDeploymentQueue.activeList,
    (flowItems: Array<FlowItem>, flow: number, activeList: Array<FlowDeploymentLog>): boolean => {
        const flowControlItems = flowItems.filter(x => x.FlowItemType == "flowControl" && x.IsActive && !x.IsRunning);
        // there aren't any active flow control nodes
        if (flowControlItems.length <= 0) {
            return false;
        }

        // Check any pending flow controls
        let deployingFC = false;
        if (flow && activeList) {
            deployingFC = activeList.filter(x => x.FlowId == flow).length > 0;
        }

        // all the flow control items have result tables (not real tables, but is set to true after a successful run)
        if (flowControlItems.filter(x => !x.HasResultTable).length > 0) {
            return false;
        }
        return !deployingFC;
    }
);

export const getFlowItemExternalServiceDefeaultFields = createSelector(
    (state: IAppState) => getFlowExternalServiceForSelectedFlowItem(state),
    (state: IAppState) => state.flowControlData.externalServiceDefaultFields,
    (
        flowExternalService: FlowExternalService,
        externalServiceDefaultFields: Array<IExternalServiceDefaultFields>
    ): Array<IExternalServiceDefaultFields> => {
        let serviceIds = new Array<number>();

        if (flowExternalService) {
            const { ServicesJSON } = flowExternalService;
            serviceIds = ServicesJSON.map(x => x.ServiceId);
            serviceIds.push(0);
        }

        return externalServiceDefaultFields.filter(x => serviceIds.includes(x.ServiceFieldId));
    }
);

export const getClientFieldAliasForSelectedFlowItem = createSelector(
    (state: IAppState) => getFlowExternalServiceForSelectedFlowItem(state),
    (state: IAppState) => state.externalServiceLayoutData.externalServiceClientFieldAlias,
    (
        flowExternalService: FlowExternalService,
        externalServiceFieldAlias: Array<IExternalServiceClientFieldAlias>
    ): Array<IExternalServiceClientFieldAlias> => {
        if (!flowExternalService?.WFClientCode) {
            return [];
        } else {
            return externalServiceFieldAlias.filter(x => x.WFClientCode == flowExternalService.WFClientCode);
        }
    }
);

export const getFilteredInputFileLocations = createSelector(
    (state: IAppState) => state.flowControlData.externalInputFileLocation,
    (state: IAppState) => state.session.roles,
    (state: IAppState) => state.session.isInternal || false,
    (state: IAppState) => state.session.enabledFeatures,
    (
        fileLocations: Array<IExternalFileLocation>,
        roles: Array<string>,
        isInternal: boolean,
        enabledFeatures: Array<string>
    ): Array<IExternalFileLocation> => {
        let filteredLocations = [...fileLocations];
        const isSuperAdmin = roles.includes("Super Admin");
        const isExperianAdmin = roles.includes("Experian Admin");

        // Remove SERVER input when feature is off
        if (
            !enabledFeatures.includes("flow-control-server-input") ||
            (!isSuperAdmin && !isExperianAdmin) ||
            !isInternal
        ) {
            const sfIndex = filteredLocations.findIndex(x => x.FileLocationName == BrowseFileLocations.SERVER);
            if (sfIndex >= 0) {
                filteredLocations.splice(sfIndex, 1);
            }
        }

        // Remove Snowflake input when feature is off
        if (!enabledFeatures.includes("flowcontrol-snowflake-input")) {
            const sfIndex = filteredLocations.findIndex(x => x.FileLocationName == BrowseFileLocations.SF);
            if (sfIndex >= 0) {
                filteredLocations.splice(sfIndex, 1);
            }
        }

        return filteredLocations;
    }
);

const flowExternalServiceToErrorsById = (
    flowExternalServices: Array<FlowExternalService>,
    flowExternalServiceParametersByItemId: FlowExternalServiceParameterByItemId,
    externalParameters: Array<IExternalParameter>,
    layouts: Array<IExternalServiceLayout>,
    layoutFields: Array<IExternalServiceLayoutField>,
    ueServiceId: number,
    fileLocations: Array<IExternalFileLocation>,
    flowRelations: Array<FlowRelation>,
    flowItems: Array<FlowItem>,
    flowExternalServiceInputsByItemId: FlowExternalServiceInputByItemId,
    multiInputFeature: boolean,
    flowExternalServiceHardcodesByItemId: FlowExternalServiceHardcodeByItemId,
    externalServiceFields: Array<IExternalServiceField>,
    hardcodeFeature: boolean,
    mm2EncryptFeature: boolean
): FlowErrorsByItemId => {
    const errorsById: FlowErrorsByItemId = {};
    for (const service of flowExternalServices) {
        errorsById[service.FlowItemId] = ValidateFlowExternalService(
            service,
            flowExternalServiceParametersByItemId[service.FlowItemId],
            externalParameters,
            layouts,
            layoutFields,
            ueServiceId,
            fileLocations,
            flowRelations,
            flowItems,
            flowExternalServiceInputsByItemId[service.FlowItemId],
            multiInputFeature,
            flowExternalServiceHardcodesByItemId[service.FlowItemId],
            externalServiceFields,
            hardcodeFeature,
            mm2EncryptFeature
        );
    }
    return errorsById;
};

export const getFlowExternalServiceErrorsForSelectedFlow = createSelector(
    (state: IAppState) => getFlowExternalServicesForSelectedFlow(state),
    (state: IAppState) => getFlowExternalServiceParametersByFlowItemId(state),
    (state: IAppState) => state.flowControlData.externalParameters,
    (state: IAppState) => state.externalServiceLayoutData.externalServiceLayouts,
    (state: IAppState) => state.externalServiceLayoutData.externalServiceLayoutFields,
    (state: IAppState) => state.session.ueServiceId,
    (state: IAppState) => getFilteredInputFileLocations(state),
    (state: IAppState) => getFlowRelationsForSelectedFlow(state),
    (state: IAppState) => getFlowItemsForSelectedFlow(state),
    (state: IAppState) => getFlowExternalServiceInputsByFlowItemId(state),
    (state: IAppState) => state.session.enabledFeatures.includes("flow-control-multi-input"),
    (state: IAppState) => getFlowExternalServiceHardcodesByFlowItemId(state),
    (state: IAppState) => state.externalServiceLayoutData.externalServiceFields,
    (state: IAppState) => state.session.enabledFeatures.includes("flow-control-constant-insert"),
    (state: IAppState) => state.session.enabledFeatures.includes("flow-control-mm2-encrypt"),
    flowExternalServiceToErrorsById
);

export const getFlowExternalServiceErrorsForAllFlows = createSelector(
    (state: IAppState) => getFlowExternalServicesForAllFlows(state),
    (state: IAppState) => getFlowExternalServiceParametersByFlowItemId(state),
    (state: IAppState) => state.flowControlData.externalParameters,
    (state: IAppState) => state.externalServiceLayoutData.externalServiceLayouts,
    (state: IAppState) => state.externalServiceLayoutData.externalServiceLayoutFields,
    (state: IAppState) => state.session.ueServiceId,
    (state: IAppState) => getFilteredInputFileLocations(state),
    (state: IAppState) => getFlowRelationsForSelectedFlow(state),
    (state: IAppState) => getFlowItemsForSelectedFlow(state),
    (state: IAppState) => getFlowExternalServiceInputsByFlowItemId(state),
    (state: IAppState) => state.session.enabledFeatures.includes("flow-control-multi-input"),
    (state: IAppState) => getFlowExternalServiceHardcodesByFlowItemId(state),
    (state: IAppState) => state.externalServiceLayoutData.externalServiceFields,
    (state: IAppState) => state.session.enabledFeatures.includes("flow-control-constant-insert"),
    (state: IAppState) => state.session.enabledFeatures.includes("flow-control-mm2-encrypt"),
    flowExternalServiceToErrorsById
);

const ValidateFlowExternalService = (
    flowExternalService: FlowExternalService,
    flowExternalServiceParameters: Array<FlowExternalServiceParameter>,
    externalParameters: Array<IExternalParameter>,
    layouts: Array<IExternalServiceLayout>,
    layoutFields: Array<IExternalServiceLayoutField>,
    ueServiceId: number,
    fileLocations: Array<IExternalFileLocation>,
    flowRelations: Array<FlowRelation>,
    flowItems: Array<FlowItem>,
    flowExternalServiceInputs: Array<FlowExternalServiceInput>,
    multiInputFeature: boolean,
    flowExternalServiceHardcodes: Array<FlowExternalServiceHardcode>,
    externalServiceFields: Array<IExternalServiceField>,
    hardcodeFeature: boolean,
    mm2EncryptFeature: boolean
): Array<string> => {
    const errors: Array<string> = [];
    const inputLayout = layouts.find(x => x.LayoutId == flowExternalService.InputLayoutId);
    const outputLayout = layouts.find(x => x.LayoutId == flowExternalService.OutputLayoutId);
    const location = fileLocations.find(x => x.FileLocationId == flowExternalService.FileLocationId);
    let isTestMode = false;

    if (flowExternalServiceParameters) {
        const testSwitchParam = externalParameters.find(x => x.ParameterName == "Test_Switch")?.ParameterId;
        isTestMode = flowExternalServiceParameters.some(x => x.ParameterId == testSwitchParam && x.Value == "TEST");
    }

    if (
        flowExternalService &&
        (typeof flowExternalService.WFJobNumber == "undefined" || !flowExternalService.WFJobNumber) &&
        (typeof flowExternalService.WFBillingDivCode == "undefined" || !flowExternalService.WFBillingDivCode)
    ) {
        errors.push("No Job number or Billing Div/Code has been selected.");
    }

    if (
        flowExternalService &&
        (typeof flowExternalService.WFJobNumber == "undefined" || !flowExternalService.WFJobNumber) &&
        (typeof flowExternalService.WFIFRSCode == "undefined" || !flowExternalService.WFIFRSCode)
    ) {
        errors.push("No IFRS Category has been selected.");
    }

    if (
        flowExternalService &&
        (typeof flowExternalService.WFClientCode == "undefined" || !flowExternalService.WFClientCode) &&
        (typeof flowExternalService.WFClientKey == "undefined" || !flowExternalService.WFClientKey)
    ) {
        errors.push("No WorkFlow Client Code has been selected.");
    }

    if (flowExternalService.FileLocationId > 0 && location) {
        // Snowflake Input Table
        if (location.FileLocationName == BrowseFileLocations.SF) {
            const tableParts = flowExternalService.InputTableName?.split(".") || [];
            switch (tableParts.length) {
                case 0:
                    errors.push("No Input Database, Schema and Table Name have been selected.");
                    break;
                case 1:
                    errors.push("No Input Schema and Table Name have been selected.");
                    break;
                case 2:
                    errors.push("No Input Table Name has been selected.");
                    break;
            }

            if (tableParts.length == 3) {
                const inputColumns = flowExternalService.InputTableColumns
                    ? flowExternalService.InputTableColumns.filter(x => x.Include)
                    : [];

                if (tableParts.length == 3 && inputColumns.length == 0) {
                    errors.push("No Input Table Columns have been selected.");
                }

                if (inputLayout != null) {
                    if (!inputLayout.RelativePositions || inputLayout.Delimiter != ",") {
                        errors.push("Snowflake Table Input Layout must be comma delimited.");
                    }

                    if (
                        tableParts.length == 3 &&
                        inputColumns.length != layoutFields.filter(x => x.LayoutId == inputLayout.LayoutId).length
                    ) {
                        errors.push("Number of layout fields must match included table columns.");
                    }
                }
            }

            if (
                !isTestMode &&
                (!flowExternalService.InputQuantity || flowExternalService.InputQuantity <= 0) &&
                multiInputFeature
            ) {
                errors.push("No Input Quantity has been entered.");
            }
        } else {
            if (flowExternalServiceInputs && multiInputFeature) {
                if (
                    flowExternalServiceInputs.length == 0 ||
                    (flowExternalServiceInputs.length == 1 &&
                        flowExternalServiceInputs[0].InputName == "" &&
                        flowExternalServiceInputs[0].InputQuantity == 0)
                ) {
                    errors.push("No Input Files have been selected.");
                } else {
                    let noNameError = false;
                    let noQtyError = false;
                    flowExternalServiceInputs.forEach(x => {
                        if (x.InputName == "" && !noNameError) {
                            noNameError = true;
                            errors.push("Input File Name Missing.");
                        }
                        if (x.InputQuantity == 0 && !noQtyError && !isTestMode) {
                            noQtyError = true;
                            errors.push("Input File Quantity Missing.");
                        }
                    });
                }

                // Check for duplicated Input files
                const filteredInputs = flowExternalServiceInputs.filter(x => x.InputName != "");
                const dedupedFiles = [...new Set(filteredInputs.map(x => x.InputName))];
                if (dedupedFiles.length != filteredInputs.length) {
                    const fileNames = filteredInputs.map(x => x.InputName);
                    const dupes = [...new Set(fileNames.filter((file, index) => fileNames.indexOf(file) !== index))];
                    dupes.forEach(file => {
                        errors.push("Input File Name is duplicated: " + file);
                    });
                }
            } else if (!flowExternalService.FileName || flowExternalService.FileName.length <= 0) {
                errors.push("No Input File has been selected.");
            }

            // Force to use Snowflake Input Table if parent node is Script
            const parentFlowItem = flowRelations.find(x => x.ChildFlowItemId == flowExternalService.FlowItemId);
            if (
                parentFlowItem &&
                flowItems.some(x => x.FlowItemId == parentFlowItem.ParentFlowItemId && x.FlowItemType == "script")
            ) {
                errors.push("The input should be Snowflake Table.");
            }
        }
    } else {
        errors.push("No Input File Location has been selected.");
    }

    if (
        !isTestMode &&
        (!flowExternalService.InputQuantity || flowExternalService.InputQuantity <= 0) &&
        !multiInputFeature
    ) {
        errors.push("No Input Quantity has been entered.");
    }

    if (flowExternalService && (!flowExternalService.InputLayoutId || flowExternalService.InputLayoutId == 0)) {
        errors.push("No Input Layout has been selected.");
    }

    if (!flowExternalService.ServicesJSON || flowExternalService.ServicesJSON.length <= 0) {
        errors.push("No Services have been selected.");
    }

    if (
        (mm2EncryptFeature &&
            flowExternalService.ServicesJSON.filter(x => x.ServiceName == "match_maker").length > 0) ||
        (!mm2EncryptFeature && flowExternalService.ServicesJSON.filter(x => x.ServiceName == "mm_2.0").length > 0)
    ) {
        errors.push("Service Match Maker 2.0 specs must be updated.");
    }

    if (flowExternalService.ServicesJSON.filter(x => !x.ServiceId).length > 0) {
        errors.push("Services added are missing required information.");
    }

    if (
        typeof flowExternalService.WFClientKey !== "undefined" &&
        typeof flowExternalService.WFJobClientKey !== "undefined"
    ) {
        if (flowExternalService.WFClientKey != flowExternalService.WFJobClientKey) {
            errors.push("Job number does not belong to the client code chosen.");
        }
    }

    if (typeof flowExternalService.WFJobClientKey !== "undefined") {
        if (typeof flowExternalService.WFJobNumber === "undefined") {
            errors.push("Job number does not belong to the client code chosen.");
        }
    }

    if (flowExternalService.ServicesJSON.filter(x => x.ServiceName == "match_maker").length > 0) {
        if (flowExternalServiceParameters) {
            const addressMatchTypeParameterId = externalParameters.find(
                x => x.ParameterName == "Address_Match_Types"
            )?.ParameterId;
            const noValues = flowExternalServiceParameters.filter(
                x => x.ParameterId == addressMatchTypeParameterId && x.Value == ""
            ).length;
            if (noValues > 0) {
                errors.push("Please select address hash type.");
            }
        }
    }

    if (flowExternalServiceParameters) {
        const noValues = flowExternalServiceParameters.filter(x => !x.Value);
        if (noValues.length > 0) {
            const requiredParameters = externalParameters.filter(x => x.Required).map(x => x.ParameterId);
            if (noValues.filter(x => requiredParameters.includes(x.ParameterId)).length > 0) {
                errors.push("Service Parameters are mising required values.");
            }
        }
    }

    if (inputLayout != null) {
        if (inputLayout.RelativePositions && (inputLayout.Delimiter == null || inputLayout.Delimiter == "")) {
            errors.push("Input Layout requires a delimiter.");
        } else if (!inputLayout.RelativePositions && (inputLayout.RecordSize == null || inputLayout.RecordSize <= 0)) {
            errors.push("Input Layout requires a record size.");
        }
    }

    if (outputLayout != null) {
        if (outputLayout.RelativePositions && (outputLayout.Delimiter == null || outputLayout.Delimiter == "")) {
            errors.push("Output Layout requires a delimiter.");
        } else if (
            !outputLayout.RelativePositions &&
            (outputLayout.RecordSize == null || outputLayout.RecordSize <= 0)
        ) {
            errors.push("Output Layout requires a record size.");
        }
    }

    const ueService = (flowExternalService.ServicesJSON || []).find(x => x.ServiceId == ueServiceId);
    if (ueService) {
        const fieldsFailingCompliance = flowExternalService.FieldsFailingCompliance || "";
        if (fieldsFailingCompliance.trim().length > 0) {
            errors.push(
                "Universal Enrinchment selected fields do not pass compliance. Please remove the fields failing or refresh compliance."
            );
        } else if (!flowExternalService.UECompliancePassed) {
            errors.push("Universal Enrichment fields compliance needs to be refreshed.");
        }
    }

    if (
        !flowExternalService.DestinationId ||
        (flowExternalService.DestinationId == null && flowExternalService.OutputLocationId == 3)
    ) {
        errors.push("No Output Destination has been selected.");
    }

    if (flowExternalService && flowExternalService.DeploySettingId == DeploySettings.DeploySnowflakeTable) {
        if (!flowExternalService.OutputLayoutId || flowExternalService.OutputLayoutId == 0) {
            errors.push("No Output Layout has been selected.");
        }

        if (flowExternalService.OutputLayoutId > 0 && !outputLayout?.RelativePositions) {
            errors.push("Only delimited files are allow to deploy to Snowflake.");
        }

        if (flowExternalService.SnowflakeSettingsId == 0) {
            errors.push("No Snowflake Setting has been selected.");
        }

        if (!flowExternalService.SnowflakeTable || flowExternalService.SnowflakeTable == "") {
            errors.push("No Snowflake Table has been entered.");
        }
    } else if (
        flowExternalService &&
        (!flowExternalService.OutputFileName || flowExternalService.OutputFileName.length <= 0)
    ) {
        errors.push("Output file name is required.");
    }

    if (hardcodeFeature && flowExternalServiceHardcodes) {
        const reservedNames = externalServiceFields.map(x => x.FieldName);
        const hardcodeNames = flowExternalServiceHardcodes.map(x => x.HardcodeName);
        const invalid = hardcodeNames.filter(x => reservedNames.includes(x));
        invalid.forEach(field => {
            errors.push("Constant Insert Field Name cannot be a reserved Keyword: " + field);
        });

        if (flowExternalServiceHardcodes.filter(x => x.HardcodeName == "").length > 0) {
            errors.push("Constant Insert Field Name is required.");
        }
        if (flowExternalServiceHardcodes.filter(x => x.HardcodeValue == "").length > 0) {
            errors.push("Constant Insert Field Value is required.");
        }
    }

    const layoutHardcodes = layoutFields.filter(x => x.LayoutId == outputLayout?.LayoutId && x.IsHardcode);
    if (hardcodeFeature && outputLayout?.LayoutId && layoutHardcodes.length > 0) {
        layoutHardcodes.forEach(field => {
            if (!flowExternalServiceHardcodes) {
                errors.push("Output Layout Field '" + field.FieldName + "' is missing from Constant Inserts.");
            } else if (flowExternalServiceHardcodes.filter(x => x.HardcodeName == field.FieldName).length == 0) {
                errors.push("Output Layout Field '" + field.FieldName + "' is missing from Constant Inserts.");
            }
        });
    }

    return errors;
};
