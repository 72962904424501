import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow, getFlowItemsArray } from "./flowItems";
import { getFlowItemClientVariablesArray } from "./flowItemClientVariables";
import { checkIfIndirectDestinationHas1POnlyParent, getAncestorFieldIdsForFlowItemId } from "../helpers/flowItems";
import { fieldsHaveNeedsApprovalLabels } from "../helpers/needsApproval";
import type {
    FlowExport,
    FlowItem,
    FlowRelation,
    FlowErrorsByItemId,
    FlowExportDistributionPlatform,
    FlowExportDestinationPartner,
    FlowItemClientVariableD,
    FlowRelationParentLabel,
    FlowOfferMerge,
    FlowSegmentSplit,
    FlowSegmentSplitOffer,
    FlowItemFieldsUsed,
} from "../types/flowTypes";
import { IClientVariable, IFlowItemLayoutError } from "../types/stores/vars";
import { allOutputsHaveResultsTable } from "./flowOutputs";
import { getSelectedFlowPermissions } from "./flowItems";
import { getAncestorsFields, getAncestorsFlowItemIds } from "./flowRelations";
import { getFlowOfferMergesArray, getFlowOfferMergesForSelectedFlow } from "./flowOfferMerges";
import {
    allSingleViewsHaveResultsTable,
    anySingleViewsNeedApproval,
    getFlowSingleViewsForSelectedFlow,
} from "./flowSingleViews";
import { getFlowSegmentSplitOffersForSelectedFlow, getFlowSegmentSplitOfferArray } from "./flowSegmentSplitOffers";
import type { DestinationVariable } from "../types/types";
import fileNameTemplates from "../helpers/fileNameTemplates";

const myGenericReducer = makeReducerFor("FLOW_EXPORT", "FlowExportId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowExportsArray = createSelector(
    state => state.flowExports.byId,
    (flowExportsById: {| [number]: FlowExport |}): Array<FlowExport> => {
        const r: Array<FlowExport> = Object.values(flowExportsById);
        return r;
    }
);

export type FlowExportsByItemId = {
    [number]: FlowExport,
};

export const getFlowExportsByFlowItemId = createSelector(
    state => getFlowExportsArray(state),
    (flowExports: Array<FlowExport>): FlowExportsByItemId =>
        flowExports.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getIsDeployAllow = (state: any, isFlowItem: boolean) => {
    if (isFlowItem) {
        // If not simple flow
        const seletectedFlow = state.flows.byId[state.selected.flow];
        if (seletectedFlow == null || !seletectedFlow.FlowBaseType > 0) {
            return false;
        }

        // If not export item
        const selectedFlowItem = state.flowItems.byId[state.selected.flowItem];
        if (
            selectedFlowItem == null ||
            (selectedFlowItem.FlowItemType.toLowerCase() != "export" &&
                selectedFlowItem.FlowItemType.toLowerCase() != "multiexport")
        ) {
            return false;
        }
    }

    // If no exports
    const hasExports = getFlowExportsForSelectedFlow(state).length > 0;
    if (!hasExports) {
        return false;
    }

    // Permissions
    const flowPermissions = getSelectedFlowPermissions(state);
    let canEdit = false;
    let canCalculate = false;
    if (flowPermissions != null) {
        canEdit = flowPermissions.canEdit;
        canCalculate = flowPermissions.canCalculate;
    }

    // Check any pending exports
    let hasPendingExportsToDeploy = false;
    if (state.selected.flow && state.flowDeploymentQueue.activeList) {
        hasPendingExportsToDeploy =
            state.flowDeploymentQueue.activeList.filter(
                x => x.FlowId == state.selected.flow && x.WorkflowStatusName != "Processing"
            ).length > 0;
    }

    return (
        canCalculate &&
        canEdit &&
        !hasPendingExportsToDeploy &&
        allExportsHaveResultsTable(state) && // exportsWithResultTable
        allOutputsHaveResultsTable(state) && // outputsWithResultTable
        !getIsShowCountApproval(state) && // isShowCountApproval
        !anyExportsNeedApproval(state) && // exportsNeedApproval
        anyExportsMeetMinimumThreshold(state) // exportsMeetMinimum
    );
};

export const getIsShowCountApproval = (state: any) => {
    let canEdit = false;
    let canCalculate = false;
    const hasExports = getFlowExportsForSelectedFlow(state).length > 0;
    const exportsWithResultTable = allExportsHaveResultsTable(state);
    const flowPermissions = getSelectedFlowPermissions(state);

    const hasSingleViews = getFlowSingleViewsForSelectedFlow(state).length > 0;
    const singleViewsNeedApproval = anySingleViewsNeedApproval(state);
    const singleViewsWithResultTable = allSingleViewsHaveResultsTable(state);
    const exportsNeedApproval = anyExportsNeedApproval(state);

    if (flowPermissions != null) {
        canEdit = flowPermissions.canEdit;
        canCalculate = flowPermissions.canCalculate;
    }

    if (
        (!hasExports && !hasSingleViews) ||
        !canCalculate ||
        !canEdit ||
        (!singleViewsWithResultTable && !exportsWithResultTable)
    ) {
        return false;
    }

    const singleViewsReady = !hasSingleViews || (singleViewsNeedApproval && singleViewsWithResultTable);
    const exportsReady = !hasExports || (exportsNeedApproval && exportsWithResultTable);

    return singleViewsReady && exportsReady;
};

export const getIsAnyDestinationEnforcingDeploymentRequest = (state: any) => {
    const flowExports = getFlowExportsForSelectedFlow(state);

    if (flowExports && flowExports.length > 0) {
        const destinationIds = flowExports.map(x => x.DestinationId || 0);
        const destinations = state.vars.destinations;
        if (
            !destinations ||
            destinations.length <= 0 ||
            destinations.filter(x => destinationIds.includes(x.PartnerAccessId) && x.EnforceDeploymentRequest).length >
                0
        ) {
            return true;
        }
    }
    return false;
};

export const getFlowExportsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowExportsByFlowItemId(state),
    (flowItems: Array<FlowItem>, flowExportsByItemId: FlowExportsByItemId): Array<FlowExport> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const thisExport = flowExportsByItemId[itemId];
            if (thisExport != null) {
                result.push(thisExport);
            }
        }
        return result;
    }
);
import { getFlowRelationsForSelectedFlow, getFlowRelationsForAllFlows } from "./flowRelations";
import { getFlowExportDistributionPlatformsArray } from "./flowExportDistributionPlatforms";
import isValidEmailAddress from "../helpers/emailValidation";
import { MoneyRegex } from "../helpers/constants";

export const allExportParentsHaveResultsTable = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowExportsForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    (flowItems: Array<FlowItem>, flowExports: Array<FlowExport>, flowRelations: Array<FlowRelation>): boolean => {
        if (flowExports.length == 0) {
            return false;
        }

        let allHaveResultsTable = true;
        for (let exportItem of flowExports) {
            const parentRelations = flowRelations
                .filter(x => x.ChildFlowItemId == exportItem.FlowItemId && x.ParentFlowItemId != 0)
                .map(x => x.ParentFlowItemId);

            for (const parentId of parentRelations) {
                const parentFlowItemsFound = flowItems.filter(x => x.FlowItemId == parentId);
                if (parentFlowItemsFound.length > 0) {
                    const parentHasResultTable = parentFlowItemsFound[0].HasResultTable;
                    if (parentHasResultTable == false) {
                        return false;
                    }
                }
            }
        }
        return allHaveResultsTable;
    }
);

export const allExportsHaveResultsTable = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowExportsForSelectedFlow(state),
    (flowItems: Array<FlowItem>, flowExports: Array<FlowExport>): boolean => {
        if (flowExports.length == 0) {
            return false;
        }

        const flowExportsWithDeploy = flowExports.filter(x => x.ShouldDeploy);
        if (flowExportsWithDeploy.length == 0) {
            return false;
        }

        const exportDeployIds = flowExportsWithDeploy.map(x => x.FlowItemId);
        const inactiveExportDeploys = flowItems.filter(
            x => exportDeployIds.includes(x.FlowItemId) && x.IsActive == false
        );
        if (inactiveExportDeploys.length >= flowExportsWithDeploy.length) {
            return false;
        }

        let allHaveResultsTable = true;
        const exportFlowItems = flowItems.filter(x => x.FlowItemType == "export");
        if (exportFlowItems.length == 0) {
            allHaveResultsTable = false; // don't have any Export item
        } else {
            const exportItemsNotCalculated = exportFlowItems.filter(x => x.HasResultTable == false);
            if (exportItemsNotCalculated.length > inactiveExportDeploys.length) {
                allHaveResultsTable = false;
            }
        }
        return allHaveResultsTable;
    }
);

export const anyExportsNeedApproval = createSelector(
    state => getFlowExportsForSelectedFlow(state),
    (flowExports: Array<FlowExport>): boolean => {
        if (flowExports.length == 0) {
            return false;
        }
        return flowExports.filter(x => x.IsApprovalRequired && x.MeetsMinimumThreshold).length > 0;
    }
);

export const anyExportsMeetMinimumThreshold = createSelector(
    state => getFlowExportsForSelectedFlow(state),
    (flowExports: Array<FlowExport>): boolean => {
        if (flowExports.length == 0) {
            return false;
        }
        return flowExports.filter(x => x.MeetsMinimumThreshold).length > 0;
    }
);

export const allExportsNeedingApproval = createSelector(
    state => getFlowExportsForSelectedFlow(state),
    (flowExports: Array<FlowExport>): Array<FlowExport> =>
        flowExports.filter(x => x.IsApprovalRequired && !x.IsCountApproved)
);

export const getFlowExportsHaveTapadTradeDesk = createSelector(
    state => getFlowExportsForSelectedFlow(state),
    state => state.vars.destinations,
    (flowExports: Array<FlowExport>, destinations: Array<any>): boolean => {
        let exportDestinationIds = flowExports.map(x => x.DestinationId);
        return (
            destinations &&
            destinations.filter(
                x =>
                    exportDestinationIds.includes(x.PartnerAccessId) &&
                    x.DeploySetting == DeploySettings.DeployTradedeskTemplate
            ).length > 0
        );
    }
);

export const getFlowExportsHaveMagniteDriverFile = createSelector(
    state => getFlowExportsForSelectedFlow(state),
    state => state.vars.destinations,
    (flowExports: Array<FlowExport>, destinations: Array<any>): boolean => {
        let exportDestinationIds = flowExports.map(x => x.DestinationId);
        return (
            destinations &&
            destinations.filter(
                x =>
                    exportDestinationIds.includes(x.PartnerAccessId) &&
                    x.DeploySetting == DeploySettings.DeployMagniteDriverFile
            ).length > 0
        );
    }
);

export const anyParentFlowItemsWithFirstPartyOnly = (
    flowItemId: number,
    ancestorFields: Array<FlowItemFieldsUsed>,
    flowRelations: Array<FlowRelation>
) => {
    if (ancestorFields && flowRelations) {
        const parents: Array<number> = [];
        getAncestorsFlowItemIds(flowRelations, flowItemId, parents);
        let parentAncestorFields = ancestorFields.filter(x => parents.includes(x.FlowItemId));

        return checkIfIndirectDestinationHas1POnlyParent(parentAncestorFields);
    }

    return false;
};

export const anyExportsRequireFieldApproval = (state: any) => {
    // Needed data
    const flowExports = getFlowExportsForSelectedFlow(state);

    const flowRelations = getFlowRelationsForSelectedFlow(state);
    const flowFilters = getFlowFiltersForSelectedFlow(state);
    const flowCases = getFlowCasesForSelectedFlow(state);
    const needsApprovalLabelIds = getNeedsApprovalLabelIds(state);
    const enabledFieldLabels = state.fieldsByCompany.enabledFieldLabels;
    const isInternal = state.session.isInternal;

    // No validation needed
    if (!flowExports || (flowExports && flowExports.length == 0) || isInternal) {
        return false;
    }

    for (const item of flowExports) {
        // using a different approach, will loop only through this item parents instead of all flow items for the selected flow
        const ancestorFieldIds = getAncestorFieldIdsForFlowItemId(
            item.FlowItemId,
            flowRelations,
            flowFilters,
            flowCases
        );
        const ancestorHasNeedsApprovalFields = fieldsHaveNeedsApprovalLabels(
            ancestorFieldIds,
            needsApprovalLabelIds,
            enabledFieldLabels,
            isInternal
        );
        if (ancestorHasNeedsApprovalFields) {
            return true;
        }
    }

    return false;
};

const exportsToErrorsById = (
    flowExports: Array<FlowExport>,
    flowRelations: Array<FlowRelation>,
    //flow-export-distribution-platforms
    flowExportDistributionPlatforms: Array<FlowExportDistributionPlatform>,
    flowExportDestinationPartners: { number: Array<FlowExportDestinationPartner> },
    flowExportDestinationOffers: { number: Array<IClientVariable> },
    layouts: Array<any>,
    flowItemClientVariables: Array<FlowItemClientVariableD>,
    flowRelationParentLabels: Array<FlowRelationParentLabel>,
    flowExportDestinationVariables: { number: Array<DestinationVariable> },
    destinationFieldRestrictions: any,
    getAncestorsFields: any,
    flowOfferMerges: Array<FlowOfferMerge>,
    flowSegmentSplitOffers: Array<FlowSegmentSplitOffer>,
    flowItems: Array<FlowItem>,
    flowSegmentSplits: Array<FlowSegmentSplit>,
    destinations: Array<any>,
    tapadDeliveryTypes: Array<any>,
    flowItemLayoutErrors: Array<IFlowItemLayoutError>,
    enabledFeatures: Array<any>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowExport of flowExports) {
        errorsById[flowExport.FlowItemId] = validateFlowExport(
            flowExport,
            flowRelations,
            flowExportDistributionPlatforms,
            flowExportDestinationPartners,
            flowExportDestinationOffers,
            layouts,
            flowItemClientVariables,
            flowRelationParentLabels,
            flowExportDestinationVariables,
            destinationFieldRestrictions,
            getAncestorsFields,
            flowOfferMerges,
            flowSegmentSplitOffers,
            flowItems,
            flowSegmentSplits,
            destinations,
            tapadDeliveryTypes,
            flowItemLayoutErrors,
            enabledFeatures
        );
    }
    return errorsById;
};

import { getLayoutObjects } from "./exportlayoutobjects";
import { getFlowRelationsParentLabelsArray } from "./flowRelationParentLabels";
import { getNeedsApprovalLabelIds } from "./fieldLabels";
import { getFlowFiltersForSelectedFlow } from "./flowFilters";
import { getFlowCasesForSelectedFlow } from "./flowCases";
import { getFlowSegmentSplitsForSelectedFlow, getFlowSegmentSplitArray } from "./flowSegmentSplits";
import { DeploySettings } from "../types/stores/companyTable";
export const getExportErrorsForSelectedFlow = createSelector(
    state => getFlowExportsForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    //flow-export-distribution-platforms
    state => getFlowExportDistributionPlatformsArray(state),
    state => state.flowExportObjects.destinationPartners,
    state => state.flowExportObjects.destinationOffers,
    state => getLayoutObjects(state),
    state => getFlowItemClientVariablesArray(state),
    state => getFlowRelationsParentLabelsArray(state),
    state => state.flowExportObjects.destinationVariables,
    state => state.vars.destinationFieldRestrictions,
    state => getAncestorsFields(state),
    state => getFlowOfferMergesForSelectedFlow(state),
    state => getFlowSegmentSplitOffersForSelectedFlow(state),
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowSegmentSplitsForSelectedFlow(state),
    state => state.vars.destinations,
    state => state.vars.tapadDeliveryTypes,
    state => state.vars.flowItemLayoutErrors,
    state => state.session.enabledFeatures,
    exportsToErrorsById
);

export const getExportErrorsForAllFlows = createSelector(
    state => getFlowExportsArray(state),
    state => getFlowRelationsForAllFlows(state),
    //flow-export-distribution-platforms
    state => getFlowExportDistributionPlatformsArray(state),
    state => state.flowExportObjects.destinationPartners,
    state => state.flowExportObjects.destinationOffers,
    state => getLayoutObjects(state),
    state => getFlowItemClientVariablesArray(state),
    state => getFlowRelationsParentLabelsArray(state),
    state => state.flowExportObjects.destinationVariables,
    state => state.vars.destinationFieldRestrictions,
    state => getAncestorsFields(state),
    state => getFlowOfferMergesArray(state),
    state => getFlowSegmentSplitOfferArray(state),
    state => getFlowItemsArray(state),
    state => getFlowSegmentSplitArray(state),
    state => state.vars.destinations,
    state => state.vars.tapadDeliveryTypes,
    state => state.vars.flowItemLayoutErrors,
    state => state.session.enabledFeatures,
    exportsToErrorsById
);

//////////////////// HELPERS //////////////////////////////

export const validateFlowExport = (
    flowExport: FlowExport,
    flowRelations: Array<FlowRelation>,
    allFlowExportDistributionPlatforms: Array<FlowExportDistributionPlatform>,
    flowExportDestinationPartners: { number: Array<FlowExportDestinationPartner> },
    flowExportDestinationOffers: { number: Array<IClientVariable> },
    layouts: any,
    flowItemClientVariables: Array<FlowItemClientVariableD>,
    flowRelationParentLabels: Array<FlowRelationParentLabel>,
    flowExportDestinationVariables: { number: Array<DestinationVariable> },
    destinationFieldRestrictions: any,
    getAncestorsFields: any,
    flowOfferMerges: Array<FlowOfferMerge>,
    flowSegmentSplitOffers: Array<FlowSegmentSplitOffer>,
    flowItems: Array<FlowItem>,
    flowSegmentSplits: Array<FlowSegmentSplit>,
    destinations: Array<any>,
    tapadDeliveryTypes: Array<any>,
    flowItemLayoutErrors: Array<IFlowItemLayoutError>,
    enabledFeatures: Array<any>
): Array<string> => {
    let errors = [];
    if (flowExport.ExportId == 0 || flowExport.ExportId == null) {
        errors.push("A layout must be selected before calculating.");
    }

    if (flowExport.DestinationId == 0 || flowExport.DestinationId == null) {
        errors.push("A destination must be selected before calculating.");
    }

    // Get selected destination
    // If destination is Tapad, check to see if tapad delivery type exists
    const thisDestination = destinations.find(x => x.PartnerAccessId == flowExport.DestinationId);

    if (thisDestination && thisDestination.IsIndirectDestination) {
        const ancestorFields = getAncestorsFields;

        if (anyParentFlowItemsWithFirstPartyOnly(flowExport.FlowItemId, ancestorFields, flowRelations)) {
            errors.push("Current destination has 1P only data connected.");
        }
    }

    if (thisDestination && thisDestination.DeploySetting == DeploySettings.DeployTradedeskTemplate) {
        if (tapadDeliveryTypes.length == 0) {
            errors.push("Current company is missing Tapad delivery type information. Please see your Experian admin.");
        } else {
            const selectedDeliveryType = tapadDeliveryTypes.find(
                x => x.TapadDeliveryTypeId == flowExport.DeliveryTypeId
            );
            if (!selectedDeliveryType) {
                errors.push("Tapad Activation Template must be selected before calculating.");
            }
        }
    }

    let validLayout = false;
    layouts.forEach(x => {
        if (flowExport.ExportId == x.Layout.LayoutID && x.Layout.LayoutActive == "Y") {
            validLayout = true;
        }
    });
    if (!validLayout) {
        //Previously saved layout was invalidated and is no longer being brought down to the client.
        //might change error to "A layout must be selected before calculating" since it appears to the user a layout is no longer selected
        errors.push("The previously selected layout is invalid and must be fixed before calculating.");
    } else if (enabledFeatures.includes("export-unknown-field-validation")) {
        if (
            flowItemLayoutErrors.filter(x => x.FlowItemId == flowExport.FlowItemId && x.LayoutId == flowExport.ExportId)
                .length > 0
        ) {
            errors.push("Export Layout has fields that are no longer available.");
        }
    }

    const exportRelations = flowRelations.filter(
        z => z.ParentFlowItemId != 0 && z.ChildFlowItemId == flowExport.FlowItemId
    );
    const allRelations = flowRelations.filter(z => z.ChildFlowItemId == flowExport.FlowItemId);
    if (exportRelations.length == 0 || allRelations.length == 0) {
        errors.push("Export items must have a parent assigned.");
    }

    const destinationPartners = flowExportDestinationPartners[flowExport.DestinationId || 0];
    //flow-export-distribution-platforms
    const flowExportDistributionPlatforms = allFlowExportDistributionPlatforms.filter(
        x => x.FlowItemId == flowExport.FlowItemId
    );

    if (
        destinationPartners &&
        destinationPartners.length > 0 &&
        (!flowExportDistributionPlatforms || flowExportDistributionPlatforms.length <= 0)
    ) {
        errors.push("At least one destination platform must be added.");
    }
    const distributionPlatformsErrors = validateFlowExportDistributionPlatforms(flowExportDistributionPlatforms);
    if (distributionPlatformsErrors && distributionPlatformsErrors.length > 0) {
        errors = errors.concat(distributionPlatformsErrors);
    }
    const destinationOffers = flowExportDestinationOffers[flowExport.DestinationId || 0] || [];
    const destinationOfferCodesErrors = validateFlowExportDestinationOffers(
        flowExport,
        flowRelations,
        destinationOffers,
        layouts,
        enabledFeatures,
        flowItemClientVariables,
        flowItems
    );
    if (destinationOfferCodesErrors.length > 0) {
        errors = errors.concat(destinationOfferCodesErrors);
    }

    const splitOfferCodeDropDownErrors = validateSplitOfferCodeDropDown(
        flowExport,
        destinationOffers,
        layouts,
        enabledFeatures,
        flowSegmentSplits
    );

    if (splitOfferCodeDropDownErrors.length > 0) {
        errors = errors.concat(splitOfferCodeDropDownErrors);
    }

    const splitOfferSegmentsErrors = validateSplitOfferSegments(
        flowExport,
        flowItems,
        enabledFeatures,
        flowRelations,
        flowSegmentSplits
    );

    if (splitOfferSegmentsErrors.length > 0) {
        errors = errors.concat(splitOfferSegmentsErrors);
    }

    const audienceFileNameErrors = validateAudienceFileName(flowExport, enabledFeatures);
    if (audienceFileNameErrors.length > 0) {
        errors = errors.concat(audienceFileNameErrors);
    }

    const destinationVariables = flowExportDestinationVariables[flowExport.DestinationId || 0] || [];
    const destinationVariableErrors = validateFlowExportDestinationVariables(
        flowExport,
        flowItemClientVariables,
        destinationVariables
    );
    if (destinationVariableErrors.length > 0) {
        errors = errors.concat(destinationVariableErrors);
    }

    const flowRelationParentLabelErrors = validateFlowRelationParentLabels(
        flowExport,
        flowRelations,
        layouts,
        flowRelationParentLabels
    );
    if (flowRelationParentLabelErrors.length > 0) {
        errors = errors.concat(flowRelationParentLabelErrors);
    }
    const restrictedFields = destinationFieldRestrictions || [];

    if (restrictedFields.length > 0 && flowExport != null && flowExport.DestinationId != null) {
        let hasRestrictedFields = false;
        const ancestorFields = getAncestorsFields;
        ancestorFields.forEach(ancestorsField => {
            if (
                restrictedFields.filter(
                    invalidFields =>
                        invalidFields.FieldKey == ancestorsField.FieldId &&
                        invalidFields.DestinationId == flowExport.DestinationId
                ).length != 0
            ) {
                hasRestrictedFields = true;
            }
        });
        if (hasRestrictedFields) {
            errors.push("Export has restricted fields");
        }
    }

    const exportOfferMerges = flowOfferMerges ? flowOfferMerges.filter(x => x.FlowItemId == flowExport.FlowItemId) : [];
    if (exportOfferMerges.length == 0 && !errors.includes("Export items must have a parent assigned.")) {
        errors.push("Export missing Offer Merge metadata.");
    }

    const splitErrors = validateFlowSegmentSplits(
        flowExport,
        flowItems,
        exportOfferMerges,
        flowSegmentSplits,
        flowSegmentSplitOffers
    );
    if (splitErrors.length > 0) {
        errors = errors.concat(splitErrors);
    }

    if (
        thisDestination &&
        (thisDestination.DeploySetting == DeploySettings.DeployTradedeskTemplate ||
            thisDestination.DeploySetting == DeploySettings.DeployAdditionalFacebookTemplate ||
            thisDestination.DeploySetting == DeploySettings.DeployPinterestAutomationTemplate ||
            thisDestination.DeploySetting == DeploySettings.DeployFreewheelDriverFile ||
            thisDestination.DeploySetting == DeploySettings.DeployMagniteDriverFile ||
            thisDestination.DeploySetting == DeploySettings.DeployTikTokDriverFile)
    ) {
        const qtyErrors = validateExportParentQuantities(flowExport, flowItems, flowRelations, exportOfferMerges);
        if (qtyErrors.length > 0) {
            errors = errors.concat(qtyErrors);
        }
    }

    const hasNoSnowflakeSetting = flowExport.SnowflakeTable == "" || flowExport.SnowflakeSettingsId == 0;

    if (
        thisDestination &&
        thisDestination.DeploySetting == DeploySettings.DeploySnowflakeTable &&
        hasNoSnowflakeSetting
    ) {
        errors = errors.concat("Snowflake settings missing.");
    }

    return errors;
};

//code added for flow-export-distribution-platforms
export const validateFlowExportDistributionPlatforms = (
    flowExportDistributionPlatforms: Array<FlowExportDistributionPlatform>
): Array<string> => {
    const errors = [];
    if (flowExportDistributionPlatforms.length == 0) {
        return errors;
    }

    for (const distributionPlatform of flowExportDistributionPlatforms) {
        const cpm = distributionPlatform.CPM != null ? distributionPlatform.CPM.toString() : "";
        if (
            distributionPlatform.PartnerId == 0 ||
            distributionPlatform.ContactName == "" ||
            distributionPlatform.ContactEmail == "" ||
            distributionPlatform.DestinationID == "" ||
            cpm == ""
        ) {
            errors.push("Distribution platforms missing required information.");
            return errors;
        }
        if (!isValidEmailAddress(distributionPlatform.ContactEmail) || !MoneyRegex.test(cpm)) {
            errors.push("Distribution platforms invalid information.");
            return errors;
        }
    }
    return errors;
};

export const getCommonGroupings = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowExportsForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    state => state.flowExportObjects.destinationOffers,
    state => getLayoutObjects(state),
    state => state.flowExportObjects.destinationVariables,
    (state, props) => props.flowItemId,
    state => getFlowItemClientVariablesArray(state),
    (
        flowItems: Array<FlowItem>,
        flowExports: Array<FlowExport>,
        flowRelations: Array<FlowRelation>,
        flowExportDestinationOffers: { number: Array<IClientVariable> },
        layouts: Array<any>,
        flowExportDestinationVariables: any,
        flowItemId: number
    ): Array<IClientVariable> => {
        let theseGroups = [];
        const parentRelations = flowRelations.filter(x => x.ChildFlowItemId == flowItemId);
        const childRelations = flowRelations.filter(x => x.ParentFlowItemId == flowItemId);
        for (const relation of childRelations) {
            let thisExport = flowExports.filter(x => x.FlowItemId == relation.ChildFlowItemId);
            let layout = layouts.filter(x => x.Layout.LayoutID == thisExport[0].ExportId)[0] || {};
            const theseOffers = flowExportDestinationOffers[thisExport[0].DestinationId];
            const theseVariables = flowExportDestinationVariables[thisExport[0].DestinationId];
            if (layout != null && layout.LayoutObjects != null && theseOffers != null && theseOffers != undefined) {
                const offersInLayout = layout.LayoutObjects.filter(x => x.ObjectType == 4);
                const theseOffersWithFlowItemId = theseOffers.map(item => ({
                    ...item,
                    FlowItemId: relation.ChildFlowItemId,
                }));
                offersInLayout.map(z => {
                    for (let index = 0; index < parentRelations.length; index++) {
                        const offer = theseOffersWithFlowItemId.filter(x => x.Id == z.ObjectId)[0];
                        if (offer) {
                            theseGroups.push(offer);
                        }
                    }
                });
            }

            // Is this needed?
            if (theseVariables != null && theseVariables.length > 0) {
                theseVariables.map(zx => {
                    if (zx.VariableGroupId != null) {
                        const variablesToAddToGroup = { ...zx, FlowItemId: relation.ChildFlowItemId };
                        theseGroups.push(variablesToAddToGroup);
                    }
                });
            }
        }

        return theseGroups;
    }
);

export const validateFlowExportDestinationOffers = (
    flowExport: FlowExport,
    flowRelations: Array<FlowRelation>,
    destinationOffers: Array<IClientVariable>,
    layouts: any,
    enabledFeatures: Array<string>,
    flowItemCVs: Array<FlowItemClientVariableD>,
    flowItems: Array<FlowItem>
): Array<string> => {
    const errors = [];

    const layout = layouts.filter(x => x.Layout.LayoutID == flowExport.ExportId)[0] || {};
    if (layout && layout.Layout && layout.Layout.DestinationLayout && layout.LayoutObjects) {
        const offersInLayout = layout.LayoutObjects.filter(x => x.ObjectType == 4).map(x => x.ObjectId);
        const requiredOffers = destinationOffers.filter(x => offersInLayout.includes(x.Id));

        if (requiredOffers.length > 0) {
            // Gather Parents accounting for MDEN skip up
            const baseRelations = flowRelations
                .filter(x => x.ChildFlowItemId == flowExport.FlowItemId && x.ParentFlowItemId != 0)
                .map(x => x.ParentFlowItemId);

            let parentRelations = [];
            baseRelations.forEach(baseId => {
                const baseItem = flowItems.find(x => x.FlowItemId == baseId);

                // if parent is MDEN need to get it's parents
                if (baseItem && baseItem.FlowItemType == "multiexport") {
                    const mdenRelations = flowRelations
                        .filter(x => x.ChildFlowItemId == baseId && x.ParentFlowItemId != 0)
                        .map(x => x.ParentFlowItemId);

                    mdenRelations.forEach(mdenParent => {
                        parentRelations.push(mdenParent);
                    });
                } else {
                    parentRelations.push(baseId);
                }
            });

            let invalidFormats = [];
            let missingRequired = false;
            for (const parentRelation of parentRelations) {
                const parentFlowItemCVs = flowItemCVs.filter(
                    x => x.FlowItemId == parentRelation && x.ChildFlowItemId == flowExport.FlowItemId
                );

                for (const requiredOffer of requiredOffers) {
                    const flowItemCV = parentFlowItemCVs.filter(x => x.VariableId == requiredOffer.Id)[0];
                    if (parentFlowItemCVs.length > 0 && !flowItemCV) {
                        missingRequired = true;
                        continue;
                    }

                    if (
                        (!flowItemCV ||
                            !flowItemCV.VariableValue ||
                            (flowItemCV.VariableValue.Kind == "string" && !flowItemCV.VariableValue.ValueString) ||
                            (flowItemCV.VariableValue.Kind == "field" && !flowItemCV.VariableValue.FieldId)) &&
                        (!enabledFeatures.includes("flow-destination-level-splits-all-offers") ||
                            (enabledFeatures.includes("flow-destination-level-splits-all-offers") &&
                                !flowExport.IsSplitOn))
                    ) {
                        missingRequired = true;
                        continue;
                    }

                    // Offer Code Replacement Variables
                    if (
                        flowItemCV &&
                        flowItemCV.VariableValue &&
                        flowItemCV.VariableValue.Kind == "string" &&
                        flowItemCV.VariableValue.ValueString.includes("{") &&
                        enabledFeatures.includes("export-destination-offer-variables")
                    ) {
                        const invalids = validateOfferFormats(flowItemCV.VariableValue.ValueString);
                        if (invalids.length > 0) {
                            invalids.forEach(format => {
                                invalidFormats.push(format);
                            });
                        }
                    }
                }
            }

            if (missingRequired) {
                errors.push("Destination offer codes are required.");
            }

            if (invalidFormats.length > 0) {
                const uniques = [...new Set(invalidFormats)];
                errors.push("Destination offer codes contain invalid Formats: " + uniques.join(", "));
            }
        }
    }
    return errors;
};

const validateOfferFormats = (offerFormat: string) => {
    if (!offerFormat) return [];

    let invalidFormats = [];
    let arr = offerFormat.split("}");
    arr.forEach(x => {
        if (x.split("{")[1] != null) {
            let formatItem = "{" + x.split("{")[1] + "}";
            if (!fileNameTemplates.includes(formatItem)) {
                invalidFormats.push(formatItem);
            }
        }
    });
    return invalidFormats;
};

export const validateSplitOfferCodeDropDown = (
    flowExport: FlowExport,
    destinationOffers: Array<IClientVariable>,
    layouts: any,
    enabledFeatures: Array<string>
): Array<string> => {
    const errors = [];
    if (layouts != null && layouts.length > 0) {
        const layout = layouts.filter(x => x.Layout.LayoutID == flowExport.ExportId)[0] || {};
        if (layout != null && layout.LayoutObjects != null && layout.LayoutObjects.length > 0) {
            const offersInLayout = layout.LayoutObjects.filter(x => x.ObjectType == 4).map(x => x.ObjectId);
            const requiredOffers = destinationOffers.filter(x => offersInLayout.includes(x.Id));
            // const exportSplits = flowSegmentSplits.filter(x => x.FlowItemId == flowExport.FlowItemId);

            if (
                flowExport.IsSplitOn &&
                !enabledFeatures.includes("flow-destination-level-splits-all-offers") &&
                requiredOffers.length > 0 &&
                flowExport.SelectedOfferId == null
            ) {
                errors.push("Split Offer Code must be selected.");
            }
        }
    }
    return errors;
};

export const validateSplitOfferSegments = (
    flowExport: FlowExport,
    flowItems: Array<FlowItem>,
    enabledFeatures: Array<string>,
    flowRelations: Array<FlowRelation>,
    flowSegmentSplits: Array<FlowSegmentSplit>
): Array<string> => {
    const errors = [];
    if (flowExport.IsSplitOn && enabledFeatures.includes("flow-destination-level-splits-all-offers")) {
        const baseRelations = flowRelations
            .filter(x => x.ChildFlowItemId == flowExport.FlowItemId && x.ParentFlowItemId != 0)
            .map(x => x.ParentFlowItemId);

        let parentRelations = [];
        baseRelations.forEach(baseId => {
            const baseItem = flowItems.find(x => x.FlowItemId == baseId);

            // if parent is MDEN need to get it's parents
            if (baseItem && baseItem.FlowItemType == "multiexport") {
                const mdenRelations = flowRelations
                    .filter(x => x.ChildFlowItemId == baseId && x.ParentFlowItemId != 0)
                    .map(x => x.ParentFlowItemId);

                mdenRelations.forEach(mdenParent => {
                    parentRelations.push(mdenParent);
                });
            } else {
                parentRelations.push(baseId);
            }
        });

        parentRelations.forEach(parentid => {
            const numfofsplits = flowSegmentSplits.filter(
                x => x.FlowItemId == flowExport.FlowItemId && x.ParentFlowItemId == parentid
            );
            if (numfofsplits.length == 0) {
                const item = flowItems.find(x => x.FlowItemId == parentid);
                errors.push("Splits must be added for segment " + item.FlowItemName);
            }
        });
    }
    return errors;
};

export const validateAudienceFileName = (flowExport: FlowExport, enabledFeatures: Array<string>): Array<string> => {
    const errors = [];
    if (
        flowExport.IsSplitOn &&
        enabledFeatures.includes("flow-destination-level-splits-all-offers") &&
        !flowExport.IsSeperateFiles &&
        flowExport.AudienceFileName == ""
    ) {
        errors.push("Audience File Name is Required.");
    }
    return errors;
};

export const validateFlowExportDestinationVariables = (
    flowExport: FlowExport,
    flowItemCVs: Array<FlowItemClientVariableD>,
    variables: Array<DestinationVariable>
): Array<string> => {
    const errors = [];
    if (variables.length > 0) {
        for (const variable of variables) {
            const thisClientVariable = flowItemCVs.filter(
                x => x.FlowItemId == flowExport.FlowItemId && x.VariableId == variable.VariableId
            );

            if (thisClientVariable.length <= 0) {
                errors.push("Destination Variables are required.");
                return errors;
            }
        }
    }
    return errors;
};

export const validateFlowRelationParentLabels = (
    flowExport: FlowExport,
    flowRelations: Array<FlowRelation>,
    layouts: any,
    flowRelationParentLabels: Array<FlowRelationParentLabel>
): Array<string> => {
    const errors = [];
    const layout = layouts.filter(x => x.Layout.LayoutID == flowExport.ExportId)[0] || {};

    //parent labels are only for pivot layouts
    if (layout && layout.Layout && layout.Layout.IsPivot) {
        const parentRelationsIds = flowRelations
            .filter(x => x.ChildFlowItemId == flowExport.FlowItemId)
            .map(x => x.FlowRelationId);
        const parentLabels = flowRelationParentLabels.filter(x => parentRelationsIds.includes(x.FlowRelationId));
        // if has parent labels validate, if not, may not have the feature so its ok
        if (parentLabels.length > 0) {
            for (const parentLabel of parentLabels) {
                if (parentLabel.ParentLabel.trim() == "") {
                    errors.push("Pivot column names are required for each parent of the export.");
                    return errors;
                }

                const regex = new RegExp(/[^a-zA-Z0-9_ ]/g);

                if (regex.test(parentLabel.ParentLabel.trim())) {
                    errors.push(
                        "At least one Output Column Name has an invalid character. Please remove all invalid characters."
                    );
                    return errors;
                }

                if (
                    parentLabels.filter(
                        x =>
                            x.FlowRelationParentLabelId != parentLabel.FlowRelationParentLabelId &&
                            x.ParentLabel.trim() == parentLabel.ParentLabel.trim()
                    ).length > 0
                ) {
                    errors.push("Pivot column names must be different for each parent.");
                    return errors;
                }
            }
        }
    }
    return errors;
};

export const validateFlowSegmentSplits = (
    flowExport: FlowExport,
    flowItems: Array<FlowItem>,
    offerMerges: Array<FlowOfferMerge>,
    flowSegmentSplits: Array<FlowSegmentSplit>,
    flowSegmentSplitOffers: Array<FlowSegmentSplitOffer>
): Array<string> => {
    const errors = [];
    // split is off, no need to validate.
    if (!flowExport.IsSplitOn || !flowSegmentSplits) {
        return errors;
    }

    const relativeSplitsIncomplete: Array<FlowItem> = [];
    const absoluteSplitsIncomplete: Array<FlowItem> = [];
    const currentFlowItem = flowItems.find(x => x.FlowItemId == flowExport.FlowItemId);

    const exportSplits = flowSegmentSplits.filter(x => x.FlowItemId == flowExport.FlowItemId);
    const splitsMissingSettings = exportSplits.filter(
        x =>
            !x.SegmentName ||
            x.SegmentName == "" ||
            (x.AbsoluteOrRelative == "relative" && x.RelativePercentRows <= 0) ||
            (x.AbsoluteOrRelative == "absolute" && x.AbsoluteNumRows <= 0)
    );

    if (splitsMissingSettings.length > 0) {
        const segmentNames = [];
        for (const split of splitsMissingSettings) {
            const item = flowItems.find(x => x.FlowItemId == split.ParentFlowItemId);
            if (!segmentNames.includes(item.FlowItemName)) {
                segmentNames.push(item.FlowItemName);
            }
        }
        errors.push("Split settings are incomplete for segments: " + segmentNames.join() + ".");
    }

    const currentSplitOffers = flowSegmentSplitOffers.filter(x => x.FlowItemId == flowExport.FlowItemId);
    if (currentSplitOffers.filter(x => x.Value == "").length > 0) {
        errors.push("All Destination offer codes must be filled out for each split.");
    }

    // Validate Offer replacement variables
    let invalidFormats = [];
    currentSplitOffers.forEach(offer => {
        if (offer.Value.includes("{")) {
            const invalids = validateOfferFormats(offer.Value);
            if (invalids.length > 0) {
                invalids.forEach(format => {
                    invalidFormats.push(format);
                });
            }
        }
    });

    if (invalidFormats.length > 0) {
        const uniques = [...new Set(invalidFormats)];
        errors.push("Destination offer codes contain invalid Formats: " + uniques.join(", "));
    }

    // Validate Audience Name replacement variables
    invalidFormats = [];
    flowSegmentSplits.forEach(split => {
        if (split.SegmentName.includes("{")) {
            const invalids = validateOfferFormats(split.SegmentName);
            if (invalids.length > 0) {
                invalids.forEach(format => {
                    invalidFormats.push(format);
                });
            }
        }
    });

    if (invalidFormats.length > 0) {
        const uniques = [...new Set(invalidFormats)];
        errors.push("Audience Names contain invalid Formats: " + uniques.join(", "));
    }

    for (const offer of offerMerges) {
        const parentFlowItemId = offer.ParentFlowItemId;
        const parentSplits = exportSplits.filter(x => x.ParentFlowItemId == parentFlowItemId);
        const parentItem = flowItems.find(x => x.FlowItemId == offer.ParentFlowItemId);
        if (parentSplits.length > 0) {
            // relative method, total should be equal to 100%
            const splitMethod = parentSplits[0].AbsoluteOrRelative;
            if (splitMethod == "relative") {
                let total = 0;
                for (const split of parentSplits) {
                    total += split.RelativePercentRows;
                }
                if (parseInt(total) != 100) {
                    relativeSplitsIncomplete.push(parentItem);
                }
            } else if (currentFlowItem.HasResultTable) {
                // Only check absolute if already executed
                let total = 0;
                for (const split of parentSplits) {
                    total += split.AbsoluteNumRows;
                }

                // If total absolute is more than final quantity, show error.
                if (total > offer.FinalQty) {
                    absoluteSplitsIncomplete.push(parentItem);
                }
            }
        }
    }

    if (relativeSplitsIncomplete.length > 0) {
        errors.push(
            "Relative split amounts doesn't match 100% for segments: " +
                segmentNamesToCSV(relativeSplitsIncomplete) +
                "."
        );
    }

    if (absoluteSplitsIncomplete.length > 0) {
        errors.push(
            "Total of absolute row amounts is more than the final quantity for segments: " +
                segmentNamesToCSV(absoluteSplitsIncomplete) +
                "."
        );
    }
    return errors;
};

export const validateExportParentQuantities = (
    flowExport: FlowExport,
    flowItems: Array<FlowItem>,
    flowRelations: Array<FlowRelation>,
    offerMerges: Array<FlowOfferMerge>
) => {
    const errors = [];
    const exportItem = flowItems.find(x => x.FlowItemId == flowExport.FlowItemId);
    // get the parent flowitem ids
    const parentItemIds = flowRelations
        .filter(x => x.ChildFlowItemId == flowExport.FlowItemId && x.ParentFlowItemId != 0)
        .map(y => y.ParentFlowItemId);

    const parentItems = flowItems.filter(x => parentItemIds.includes(x.FlowItemId));

    // all parents have executed but some have 0 quantities
    const parentsWith0Qty = parentItems.filter(
        x => !x.hasUnSavedChanges && x.IsRunning == 0 && x.HasResultTable && x.FlowItemQty == 0
    );

    // offers with 0 qty
    const offersWith0Qty = offerMerges.filter(x => x.FinalQty <= 0);
    const offerParentItemIds = offersWith0Qty.map(x => x.ParentFlowItemId);

    // all parents have quantities above 0, but had 0 FinalQty
    const parentsWith0FinalQty = parentItems.filter(
        x =>
            offerParentItemIds.includes(x.FlowItemId) &&
            !x.hasUnSavedChanges &&
            x.IsRunning == 0 &&
            x.HasResultTable &&
            x.FlowItemQty > 0
    );

    if (parentsWith0Qty.length > 0) {
        errors.push("Deployment with 0 quantity segments is not allowed, please fix your segments before executing.");
    }
    // all parents have executed and have quantities above 0
    else if (exportItem.HasResultTable && parentsWith0FinalQty.length > 0) {
        errors.push("Deployment with 0 final quantity is not allowed, please update your segments before deploying.");
    }

    return errors;
};

// gets an array of flowItems and returns the item names comma separated.
const segmentNamesToCSV = (flowItems: Array<FlowItem>): string => {
    const segmentNames = [];
    for (const item of flowItems) {
        if (!segmentNames.includes(item.FlowItemName)) {
            segmentNames.push(item.FlowItemName);
        }
    }
    return segmentNames.join();
};
