import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowItem, FlowExpression, FlowExpressionConstraint, FlowErrorsByItemId } from "../types/stores/flowTypes";
import { IFlowExpressionStore } from "../types/stores/flowExpression";
import type { IndexType } from "../types/types";
import { getFlowItemChildrenIndex } from "./flowRelations";

const myGenericReducer = makeReducerFor("FLOW_EXPRESSION", "FlowExpressionId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { IAppState } from "../types/stores";
import {
    FlowExpressionConstraintsByFlowItemId,
    getFlowExpressionConstraintsByFlowItemId,
} from "./flowExpressionConstraints";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const myReducer = (state = {}, action: any): IFlowExpressionStore =>
    subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

export type FlowExpressionById = {
    [key: number]: FlowExpression;
};

export type FlowExpressionByFlowItemId = {
    [key: number]: FlowExpression;
};

export const getFlowExpressionsArray = createSelector(
    (state: IAppState) => state.flowExpressions.byId,
    (flowExpressionById: FlowExpressionById): Array<FlowExpression> => {
        const r: Array<FlowExpression> = Object.values(flowExpressionById);
        return r;
    }
);

// eslint-disable-line
export const getFlowExpressionsByFlowItemId = createSelector(
    (state: IAppState) => getFlowExpressionsArray(state),
    (flowExpressions: Array<FlowExpression>): FlowExpressionByFlowItemId =>
        flowExpressions.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowExpressionForSelectedFlowItem = createSelector(
    (state: IAppState) => state.selected.flowItem,
    (state: IAppState) => getFlowExpressionsByFlowItemId(state),
    (selectedFlowItem: number, expressionsByFlowItemId: FlowExpressionByFlowItemId): FlowExpression =>
        expressionsByFlowItemId[selectedFlowItem]
);

export const getFlowExpressionsForSelectedFlow = createSelector(
    (state: IAppState) => getFlowItemsForSelectedFlow(state),
    (state: IAppState) => getFlowExpressionsByFlowItemId(state),
    (flowItems: Array<FlowItem>, flowExpressionsByFlowItemId: FlowExpressionByFlowItemId): Array<FlowExpression> => {
        let result: Array<FlowExpression> = [];
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const expression = flowExpressionsByFlowItemId[itemId];
            if (expression != null) {
                result.push(expression);
            }
        }
        return result;
    }
);

const flowExpressionToErrorsById = (
    flowExpressions: Array<FlowExpression>,
    expressionConstraintsByFlowItemId: FlowExpressionConstraintsByFlowItemId,
    getFlowItemChildrenIndex: IndexType
): FlowErrorsByItemId => {
    const errorsById: FlowErrorsByItemId = {};
    for (const expression of flowExpressions) {
        errorsById[expression.FlowItemId] = validateFlowExpression(
            expression,
            expressionConstraintsByFlowItemId[expression.FlowItemId],
            getFlowItemChildrenIndex[expression.FlowItemId]
        );
    }
    return errorsById;
};

export const getFlowExpressionErrorsForSelectedFlow = createSelector(
    (state: IAppState) => getFlowExpressionsForSelectedFlow(state),
    (state: IAppState) => getFlowExpressionConstraintsByFlowItemId(state),
    (state: IAppState) => getFlowItemChildrenIndex(state),
    flowExpressionToErrorsById
);

export const getFlowExpressionErrorsForAllFlows = createSelector(
    (state: IAppState) => getFlowExpressionsArray(state),
    (state: IAppState) => getFlowExpressionConstraintsByFlowItemId(state),
    (state: IAppState) => getFlowItemChildrenIndex(state),
    flowExpressionToErrorsById
);

const validateFlowExpression = (
    expression: FlowExpression,
    flowExpressionConstraints: Array<FlowExpressionConstraint>,
    childFlowItemIds: Array<number>
): Array<string> => {
    const errors: Array<string> = [];

    if (!expression.ExpressionQuery || expression.ExpressionQuery.trim() == "") {
        errors.push("Expression Query is required.");
    }

    if (!flowExpressionConstraints || flowExpressionConstraints.length <= 0) {
        errors.push("At least one expression constraint must be added.");
    } else {
        if (flowExpressionConstraints.find(x => !x.ChildFlowItemId)) {
            errors.push("Child flow item is required for all constraints.");
        }
        if (flowExpressionConstraints.find(x => !x.ConstraintCondition)) {
            errors.push("Constraint condition is required for all constraints.");
        }
        if (flowExpressionConstraints.find(x => !x.ConstraintValue || x.ConstraintValue.trim() == "")) {
            errors.push("Constraint value is required for all constraints.");
        }
    }

    const areAllChildCovered = childFlowItemIds?.every(x =>
        flowExpressionConstraints?.some(y => y.ChildFlowItemId == x)
    );
    if (!areAllChildCovered) {
        errors.push("All the child nodes must be covered by at least a expression constraint.");
    }

    return errors;
};
