import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import tryParseJSON from "../helpers/tryParseJSON";
import type { FlowReport, FlowRelation, FlowItem, FlowErrorsByItemId, FlowFilter, FlowCase } from "../types/flowTypes";
import { getFlowRelationsForSelectedFlow, getFlowRelationsForAllFlows } from "./flowRelations";
import { getFlowCasesForSelectedFlow, getFlowCasesArray } from "./flowCases";
import { getFlowFiltersForSelectedFlow, getFlowFiltersArray } from "./flowFilters";
const myGenericReducer = makeReducerFor("FLOW_REPORT", "FlowReportId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { getAncestorFieldIdsForFlowItemId } from "../helpers/flowItems";
import { fieldsHaveNeedsApprovalLabels } from "../helpers/needsApproval";
import { getNeedsApprovalLabelIds } from "./fieldLabels";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowReportsArray = createSelector(
    state => state.flowReports.byId,
    (flowReportsById: {| [number]: FlowReport |}): Array<FlowReport> => {
        const r: Array<FlowReport> = Object.values(flowReportsById);
        return r;
    }
);

export type FlowReportsByItemId = {
    [number]: FlowReport,
};

export const getFlowReportsByFlowItemId = createSelector(
    state => getFlowReportsArray(state),
    (flowReports: Array<FlowReport>): FlowReportsByItemId =>
        flowReports.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowReportsForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowReportsByFlowItemId(state),
    (flowItems: Array<FlowItem>, flowReportsByItemId: FlowReportsByItemId): Array<FlowReport> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const report = flowReportsByItemId[itemId];
            if (report != null) {
                result.push(report);
            }
        }
        return result;
    }
);

export const getSelectedFlowHasReportsToExport = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    (flowItems: Array<FlowItem>): boolean => {
        const reportItemsHavingResults = flowItems.filter(
            f =>
                (f.FlowItemType == "report" || f.FlowItemType == "exportreport" || f.FlowItemType == "cannedreport") &&
                f.HasResultTable
        );
        return reportItemsHavingResults.length > 0;
    }
);

export const getSelectedFlowReports = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    (flowItems: Array<FlowItem>): Array<FlowItem> => {
        const reportItemsHavingResults = flowItems.filter(
            f =>
                (f.FlowItemType == "report" || f.FlowItemType == "exportreport" || f.FlowItemType == "cannedreport") &&
                f.HasResultTable
        );
        return reportItemsHavingResults;
    }
);

export const getSelectedFlowHasReportsToEmail = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    (flowItems: Array<FlowItem>): boolean => {
        const reportItemsHavingResults = flowItems.filter(
            f => f.FlowItemType == "report" || f.FlowItemType == "exportreport" || f.FlowItemType == "cannedreport"
        );
        return reportItemsHavingResults.length > 0;
    }
);

const reportsToErrorsById = (
    flowReports: Array<FlowReport>,
    fieldsById: Array<Object>,
    flowRelations: Array<FlowRelation>,
    needsApprovalLabelIds: Array<number>,
    flowFilters: Array<FlowFilter>,
    flowCases: Array<FlowCase>,
    enabledFieldLabels: any,
    isInternal: boolean
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowReport of flowReports) {
        errorsById[flowReport.FlowItemId] = validateFlowReport(
            flowReport,
            fieldsById,
            flowRelations,
            needsApprovalLabelIds,
            flowFilters,
            flowCases,
            enabledFieldLabels,
            isInternal
        );
    }
    return errorsById;
};

export const getReportErrorsForSelectedFlow = createSelector(
    state => getFlowReportsForSelectedFlow(state),
    state => state.fields.byId,
    state => getFlowRelationsForSelectedFlow(state),
    state => getNeedsApprovalLabelIds(state),
    state => getFlowFiltersForSelectedFlow(state),
    state => getFlowCasesForSelectedFlow(state),
    state => state.fieldsByCompany.enabledFieldLabels,
    state => state.session.isInternal,
    reportsToErrorsById
);

export const getReportErrorsForAllFlows = createSelector(
    state => getFlowReportsArray(state),
    state => state.fields.byId,
    state => getFlowRelationsForAllFlows(state),
    state => getNeedsApprovalLabelIds(state),
    state => getFlowFiltersArray(state),
    state => getFlowCasesArray(state),
    state => state.fieldsByCompany.enabledFieldLabels,
    state => state.session.isInternal,
    reportsToErrorsById
);

import { measureFieldsStringToArray, isAnyMeasureFieldInvalid } from "../helpers/crosstabHelper";

//////////////////// HELPERS //////////////////////////////

export const validateFlowReport = (
    flowReport: FlowReport,
    fieldsById: Array<Object>,
    flowRelations: Array<FlowRelation>,
    needsApprovalLabelIds: Array<number>,
    flowFilters: Array<FlowFilter>,
    flowCases: Array<FlowCase>,
    enabledFieldLabels: any,
    isInternal: boolean
): Array<string> => {
    const errors = [];
    let theseFieldIds = [];
    if (flowReport.UniverseTableKey == null || flowReport.UniverseTableKey == 0) {
        errors.push("Report items must have a table selected.");
    }
    let hasBadDimensionBands = false;
    if (flowReport.DimensionFieldKeys != null) {
        // const dimensionObjects = crosstabFieldKeysStringToArray(flowReport.DimensionFieldKeys);
        const dimensionMeasureObjects = measureFieldsStringToArray(flowReport.DimensionFieldKeys);

        if (dimensionMeasureObjects.constructor === Array) {
            dimensionMeasureObjects.forEach(x => {
                if (
                    x.windowOperator == "banding" &&
                    (x.windowOperatorOptions == null ||
                        x.windowOperatorOptions.intervals == null ||
                        x.windowOperatorOptions.intervals.length == 0)
                ) {
                    hasBadDimensionBands = true;
                }
                theseFieldIds.push(x.fieldKey);
            });
        }
    }
    if (
        flowReport.MeasureFieldKeys == null ||
        flowReport.MeasureFieldKeys == "" ||
        flowReport.MeasureFieldKeys == "[]"
    ) {
        errors.push("Report items must have at least one measure field set.");
    } else {
        const measureFields = measureFieldsStringToArray(flowReport.MeasureFieldKeys);

        let anyInvalidMeasureFields = isAnyMeasureFieldInvalid(measureFields);
        if (anyInvalidMeasureFields) {
            errors.push("One or more measure fields are currently invalid.");
        }

        measureFields.forEach(x => {
            const isDuplicated =
                measureFields.filter(y => y.fieldKey == x.fieldKey && x.aggregationOperator == y.aggregationOperator)
                    .length > 1;
            const errorMessage = "One or more measure fields are duplicated (field and aggregation).";
            if (isDuplicated && !errors.includes(errorMessage)) {
                errors.push(errorMessage);
            }
            if (
                x.windowOperator == "banding" &&
                (x.windowOperatorOptions == null ||
                    x.windowOperatorOptions.intervals == null ||
                    x.windowOperatorOptions.intervals.length == 0)
            ) {
                hasBadDimensionBands = true;
            }
            theseFieldIds.push(x.fieldKey);
        });
    }
    if (hasBadDimensionBands) {
        errors.push("One or more report items' bands have not been set.");
    }
    const criteriaErrors = validateFlowReportCriteria(flowReport, fieldsById);
    for (const error of criteriaErrors) {
        errors.push(error);
    }

    const ancestorFieldIds = getAncestorFieldIdsForFlowItemId(
        flowReport.FlowItemId,
        flowRelations,
        flowFilters,
        flowCases
    );
    const ancestorHasNeedsApprovalFields = fieldsHaveNeedsApprovalLabels(
        ancestorFieldIds,
        needsApprovalLabelIds,
        enabledFieldLabels,
        isInternal
    );
    const reportHasNeedsApprovalFields = fieldsHaveNeedsApprovalLabels(
        theseFieldIds,
        needsApprovalLabelIds,
        enabledFieldLabels,
        isInternal
    );

    if (ancestorHasNeedsApprovalFields) {
        errors.push("Report parents contain fields that require approval.");
    }

    if (reportHasNeedsApprovalFields) {
        errors.push("Report currently has fields that require approval.");
    }

    return errors;
};

import getFieldIdsFromRules from "../helpers/getFieldIdsFromRules";
export const validateFlowReportCriteria = (flowReport: FlowReport, fieldsById: Array<Object>): Array<string> => {
    const errors = [];
    //not mandatory to have custom filters
    if (flowReport.FlowReportCriteria == null) {
        return errors;
    }
    //if its set, then need to be valid
    if (flowReport.FlowReportCriteria == "") {
        errors.push("Custom filters cannot be blank.");
        return errors;
    }

    const includeObject = tryParseJSON(flowReport.FlowReportCriteria) || {};
    const rules = includeObject.rules || [];
    const filterFieldIds = getFieldIdsFromRules(rules);
    if (rules.length == 0) {
        errors.push("Custom filters must have at least one rule.");
    } else if (!includeObject.valid) {
        errors.push("Custom filters are not completely filled out.");
    } else if (!fieldsById || filterFieldIds.filter(x => !fieldsById[parseInt(x)]).length > 0) {
        errors.push("Custom filters have fields no longer available.");
    }
    return errors;
};

export const getFlowReportsNeedApprovals = () =>
    createSelector(
        (_, props) => props.id,
        state => getFlowItemsForSelectedFlow(state),
        state => getFlowRelationsForSelectedFlow(state),
        state => getNeedsApprovalLabelIds(state),
        state => getFlowFiltersForSelectedFlow(state),
        state => getFlowCasesForSelectedFlow(state),
        state => getFlowReportsForSelectedFlow(state),
        state => state.fieldsByCompany.enabledFieldLabels,
        state => state.session.isInternal,
        (
            flowItemId: number,
            flowItems: Array<FlowItem>,
            flowRelations: Array<FlowRelation>,
            needsApprovalLabelIds: Array<number>,
            flowFilters: Array<FlowFilter>,
            flowCases: Array<FlowCase>,
            flowReports: Array<FlowReport>,
            enabledFieldLabels: any,
            isInternal: boolean
        ): boolean => {
            const item = flowItems.find(x => x.FlowItemId == flowItemId);
            if (!item || (item.FlowItemType != "report" && item.FlowItemType != "exportreport")) {
                return false;
            }

            const ancestorFieldIds = getAncestorFieldIdsForFlowItemId(
                flowItemId,
                flowRelations,
                flowFilters,
                flowCases
            );

            const ancestorHasNeedsApprovalFields = fieldsHaveNeedsApprovalLabels(
                ancestorFieldIds,
                needsApprovalLabelIds,
                enabledFieldLabels,
                isInternal
            );

            let reportHasNeedsApprovalFields = false;
            if (item.FlowItemType == "report") {
                const report = flowReports.find(x => x.FlowItemId == flowItemId);

                if (report) {
                    const measureFieldIds = measureFieldsStringToArray(report.MeasureFieldKeys).map(x => x.fieldKey);
                    const measureApprovalFields = fieldsHaveNeedsApprovalLabels(
                        measureFieldIds,
                        needsApprovalLabelIds,
                        enabledFieldLabels,
                        isInternal
                    );

                    const dimensionFieldIds = measureFieldsStringToArray(report.DimensionFieldKeys).map(
                        x => x.fieldKey
                    );
                    const dimensionApprovalFields = fieldsHaveNeedsApprovalLabels(
                        dimensionFieldIds,
                        needsApprovalLabelIds,
                        enabledFieldLabels,
                        isInternal
                    );
                    reportHasNeedsApprovalFields = measureApprovalFields || dimensionApprovalFields;
                }
            }

            return ancestorHasNeedsApprovalFields || reportHasNeedsApprovalFields;
        }
    );
