import { IDiscoverySegmentStore, DiscoverySegmentActions } from "../types/stores/discoverySegment";

export const SET_LOADING_SEGMENTATIONS = "SET_LOADING_SEGMENTATIONS";
export const SET_SAVING_SEGMENTATIONS = "SET_SAVING_SEGMENTATIONS";
export const SET_SEGMENTATIONS = "SET_SEGMENTATIONS";

const initialState: IDiscoverySegmentStore = {
    isSegmentationsLoading: false,
    isSegmentationsSaving: false,
    segmentations: [],
};

function discoverySegment(state = initialState, action: DiscoverySegmentActions): IDiscoverySegmentStore {
    switch (action.type) {
        case SET_LOADING_SEGMENTATIONS:
            return Object.assign({}, state, {
                isSegmentationsLoading: action.isLoading,
            });
        case SET_SAVING_SEGMENTATIONS:
            return Object.assign({}, state, {
                isSegmentationsSaving: action.isSaving,
            });
        case SET_SEGMENTATIONS:
            return Object.assign({}, state, {
                segmentations: action.segments,
            });
        default:
            return state;
    }
}
export default discoverySegment;
