import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import type { FlowExpressionConstraint, FlowExpression } from "../types/stores/flowTypes";
import { IFlowExpressionConstraintStore } from "../types/stores/flowExpressionConstraint";

const myGenericReducer = makeReducerFor("FLOW_EXPRESSION_CONSTRAINT", "FlowExpressionConstraintId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { IAppState } from "../types/stores";
import { getFlowExpressionsForSelectedFlow } from "./flowExpressions";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const myReducer = (state = {}, action: any): IFlowExpressionConstraintStore =>
    subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

export type FlowExpressionConstraintById = {
    [key: number]: FlowExpressionConstraint;
};

export type FlowExpressionConstraintsByFlowItemId = {
    [key: number]: Array<FlowExpressionConstraint>;
};

export const getFlowExpressionConstraintsArray = createSelector(
    (state: IAppState) => state.flowExpressionConstraints.byId,
    (flowExpressionConstraintById: FlowExpressionConstraintById): Array<FlowExpressionConstraint> => {
        const r: Array<FlowExpressionConstraint> = Object.values(flowExpressionConstraintById);
        return r;
    }
);

// eslint-disable-line
export const getFlowExpressionConstraintsByFlowItemId = createSelector(
    (state: IAppState) => getFlowExpressionConstraintsArray(state),
    (flowExpressions: Array<FlowExpressionConstraint>): FlowExpressionConstraintsByFlowItemId =>
        flowExpressions.reduce((acc, row) => {
            if (!acc[row.FlowItemId]) {
                acc[row.FlowItemId] = [];
            }

            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowExpressionConstraintsForSelectedFlowItem = createSelector(
    (state: IAppState) => state.selected.flowItem,
    (state: IAppState) => getFlowExpressionConstraintsByFlowItemId(state),
    (
        selectedFlowItem: number,
        constraintsByFlowItemId: FlowExpressionConstraintsByFlowItemId
    ): Array<FlowExpressionConstraint> => constraintsByFlowItemId[selectedFlowItem] || []
);

export const getFlowExpressionsConstraintsForSelectedFlow = createSelector(
    (state: IAppState) => getFlowExpressionsForSelectedFlow(state),
    (state: IAppState) => getFlowExpressionConstraintsByFlowItemId(state),
    (
        flowExpressions: Array<FlowExpression>,
        flowExpressionsByFlowItemId: FlowExpressionConstraintsByFlowItemId
    ): Array<FlowExpressionConstraint> => {
        let result: Array<FlowExpressionConstraint> = [];
        const flowItemIds = flowExpressions.map(fi => fi.FlowItemId);
        for (const flowItemId of flowItemIds) {
            const constraints = flowExpressionsByFlowItemId[flowItemId];
            if (constraints != null) {
                result = result.concat(constraints);
            }
        }
        return result;
    }
);
