import { Dispatch } from "../types/types";
import h from "../helpers";
import { notifyGreen, notifyError } from "./notifyActions";
import { showModal } from "./actionCreators";
import {
    ISetFieldLabelsIsLoading,
    ISetFieldLabelsIsSaving,
    ISetFieldLabelsSaveCompleted,
    IFieldLabelsWithProperties,
} from "../types/stores/fieldLabels";
import { IFieldLabelType } from "../types/stores/fieldTypes";

import {
    SET_FIELD_LABELS_FIELDS,
    SET_FIELD_LABEL_TYPES,
    SET_LOADING_FIELD_LABELS,
    SET_IS_SAVING_FIELD_LABELS,
    SET_SAVE_COMPLETED_FIELD_LABELS,
} from "../reducers/fieldLabels";

export const SetFieldLabelsIsLoading = (newValue: boolean): ISetFieldLabelsIsLoading => ({
    type: SET_LOADING_FIELD_LABELS,
    isLoading: newValue,
});

export const SetFieldLabelsIsSaving = (newValue: boolean): ISetFieldLabelsIsSaving => ({
    type: SET_IS_SAVING_FIELD_LABELS,
    isSaving: newValue,
});

export const SetFieldLabelsSaveCompleted = (newValue: boolean): ISetFieldLabelsSaveCompleted => ({
    type: SET_SAVE_COMPLETED_FIELD_LABELS,
    saveCompleted: newValue,
});

export const GetFieldLabels =
    () =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(SetFieldLabelsIsLoading(true));
        return fetch("/Field/GetFieldLabelswithProperties", { credentials: "same-origin", method: "POST" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { success: boolean; results: Array<IFieldLabelsWithProperties> };
                dispatch(SetFieldLabelsIsLoading(false));

                if (response && response.success) {
                    dispatch({ type: SET_FIELD_LABELS_FIELDS, labels: response.results });
                }
            })
            .catch(error => {
                dispatch(SetFieldLabelsIsLoading(false));
                h.error("Error getting field labels.", error);
            });
    };

export const GetFieldLabelTypes =
    () =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch(SetFieldLabelsIsLoading(true));
        return fetch("/Field/GetFieldLabelTypes", { credentials: "same-origin", method: "POST" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { success: boolean; results: Array<IFieldLabelType> };
                dispatch(SetFieldLabelsIsLoading(false));

                if (response && response.success) {
                    dispatch({ type: SET_FIELD_LABEL_TYPES, labelTypes: response.results });
                }
            })
            .catch(error => {
                dispatch(SetFieldLabelsIsLoading(false));
                h.error("Error getting field label types.", error);
            });
    };

export const CallFieldLabelsActionForUpdate = (
    url: string,
    errorMessage: string,
    successMessage: string,
    dispatch: Dispatch
): Promise<void> =>
    fetch(url, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            const response = data as unknown as {
                error: boolean;
                isApprovalDataAddRequired: boolean;
                fieldLabelId: number;
                labelName: string;
            };
            if (response.error) {
                h.error(response.error);
            } else {
                if (response.isApprovalDataAddRequired) {
                    // If approval type was added or type updated to APPROVAL_FIELD, need to display modal to add approval data
                    dispatch(
                        showModal("FIELD_LABEL_APPROVAL", {
                            fieldLabelId: response.fieldLabelId,
                            labelName: response.labelName,
                            disableEscape: true,
                        })
                    );
                }

                dispatch(GetFieldLabels());
                dispatch(notifyGreen(successMessage));
            }
        })
        .catch(error => {
            h.error(errorMessage, error);
        });

export const AddFieldLabel =
    (labelName: string, labelType: number) =>
    (dispatch: Dispatch): Promise<void> => {
        const url =
            "/field/AddFieldLabel?" +
            h.serialize({
                labelName,
                labelType,
            });

        const errorMessage = "Error adding field label";
        const successMessage = "Successfully added field label.";
        return CallFieldLabelsActionForUpdate(url, errorMessage, successMessage, dispatch);
    };

export const DeleteFieldLabel =
    (id: number) =>
    (dispatch: Dispatch): Promise<void> => {
        const url =
            "/field/deleteFieldLabel?" +
            h.serialize({
                fieldLabelId: id,
            });
        const errorMessage = "Error deleting field label";
        const successMessage = "Successfully deleted field label.";
        return CallFieldLabelsActionForUpdate(url, errorMessage, successMessage, dispatch);
    };

export const EditFieldLabel =
    (fieldLabelId: number, newValue: string) =>
    (dispatch: Dispatch): Promise<void> => {
        const url =
            "/field/EditFieldLabel?" +
            h.serialize({
                fieldLabelId,
                newValue,
            });
        const errorMessage = "Error editing field label";
        const successMessage = "Successfully updated field label.";
        return CallFieldLabelsActionForUpdate(url, errorMessage, successMessage, dispatch);
    };

export const EditFieldLabelType =
    (fieldLabelId: number, newValue: number) =>
    (dispatch: Dispatch): Promise<void> => {
        const url =
            "/field/EditFieldLabelType?" +
            h.serialize({
                fieldLabelId,
                newValue,
            });
        const errorMessage = "Error editing field label type";
        const successMessage = "Successfully updated field label.";
        return CallFieldLabelsActionForUpdate(url, errorMessage, successMessage, dispatch);
    };

export const CloneFieldLabels =
    (fromCompanyId: number, toCompanyId: number, cloneOption: string) =>
    (dispatch: Dispatch): Promise<void> => {
        const url =
            "/field/CloneFieldLabels?" +
            h.serialize({
                fromCompanyId,
                toCompanyId,
                cloneOption,
            });

        const errorMessage = "There was an issue with cloning field labels.";

        return fetch(url, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { success: boolean; error: string };
                if (!response.success) {
                    h.error(response.error);
                }
            })
            .catch(error => {
                dispatch(notifyError(errorMessage));
                h.error(errorMessage, error);
            });
    };
