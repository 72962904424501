import update from "immutability-helper";
import { UmbrellaDataActions, IUmbrellaDataStore } from "../types/stores/umbrellaData";

export const SET_ENRICHMENT_FIELDS = "SET_ENRICHMENT_FIELDS";
export const SET_CURR_ENRICHMENT_FIELDS = "SET_CURR_ENRICHMENT_FIELDS";
export const SET_SELECTED_FIELDS_ITEM = "SET_SELECTED_FIELDS_ITEM";
export const SET_IS_SEARCHING = "SET_IS_SEARCHING";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_IS_VALIDATING = "SET_IS_VALIDATING";
export const SET_VALIDATED_FIELDS = "SET_VALIDATED_FIELDS";
export const SET_SELECTED_ITEMID = "SET_SELECTED_ITEMID";

const initialState: IUmbrellaDataStore = {
    enrichmentFields: [],
    currEnrichmentFields: [],
    selectedFieldsItem: [],
    isSearching: false,
    isLoading: false,
    isValidating: false,
    validatedFields: [],
    selectedItemId: 0,
};

function umbrellaData(state = initialState, action: UmbrellaDataActions): IUmbrellaDataStore {
    switch (action.type) {
        case SET_ENRICHMENT_FIELDS: {
            return update(state, {
                enrichmentFields: { $set: action.enrichmentFields },
            });
        }
        case SET_CURR_ENRICHMENT_FIELDS: {
            return update(state, {
                currEnrichmentFields: { $set: action.currEnrichmentFields },
            });
        }
        case SET_SELECTED_FIELDS_ITEM: {
            return update(state, {
                selectedFieldsItem: { $set: action.selectedFieldsItem },
            });
        }
        case SET_IS_SEARCHING: {
            return update(state, {
                isSearching: { $set: action.isSearching },
            });
        }
        case SET_IS_LOADING: {
            return update(state, {
                isLoading: { $set: action.isLoading },
            });
        }
        case SET_IS_VALIDATING: {
            return update(state, {
                isValidating: { $set: action.isValidating },
            });
        }
        case SET_VALIDATED_FIELDS: {
            return update(state, {
                validatedFields: { $set: action.validatedFields },
            });
        }
        case SET_SELECTED_ITEMID: {
            return update(state, {
                selectedItemId: { $set: action.selectedItemId },
            });
        }

        default:
            return state;
    }
}
export default umbrellaData;
