import { FileUploadSettingActions, IColumn, IFileUploadSettingStore } from "../types/stores/fileUploadSettings";

const defaultState: IFileUploadSettingStore = {
    fileUploadSetting: {
        RetentionDays: 0,
        GUID: "",
        RequestedName: "",
        ExistingTableId: null,
        Mode: "",
        FileType: "",
        Delimiter: "",
        HasFirstRowHeaders: false,
        Qualifier: "",
        HasLastRowTrailer: false,
        DeleteExistingTable: false,
        Columns: [],
        FolderId: 0,
        FolderName: "",
        NewFolderName: "",
        ParentJoinId: null,
        MasterTableKey: null,
        Dedupe: false,
        SortByFieldsJSON: "",
        IgnoreBlanks: false,
        UserId: 0,
        CompanyId: -1,
        ShowIncludeColumns: false,
        DecryptionCode: "",
    },
    isFileUploadSettingsLoading: false,
};

export const SET_FILE_UPLOAD_SETTING = "SET_FILE_UPLOAD_SETTING";
export const CLEAR_FILE_UPLOAD_SETTING = "CLEAR_FILE_UPLOAD_SETTING";
export const SET_IS_FILE_UPLOAD_SETTINGS_LOADING = "SET_IS_FILE_UPLOAD_SETTINGS_LOADING";

function distributionList(state = defaultState, action: FileUploadSettingActions): IFileUploadSettingStore {
    switch (action.type) {
        case SET_FILE_UPLOAD_SETTING: {
            const fileUploadSetting = { ...action.fileUploadSetting };
            const fileUploadSettingColumns: Array<IColumn> = JSON.parse(action.fileUploadSetting.Columns as string);
            const fileUploadSettingParsed = Object.assign({}, fileUploadSetting, {
                Columns: fileUploadSettingColumns,
            });

            return Object.assign({}, state, {
                fileUploadSetting: fileUploadSettingParsed,
            });
        }
        case CLEAR_FILE_UPLOAD_SETTING: {
            return Object.assign({}, state, {
                fileUploadSetting: defaultState,
            });
        }
        case SET_IS_FILE_UPLOAD_SETTINGS_LOADING: {
            return Object.assign({}, state, {
                isFileUploadSettingsLoading: action.isFileUploadSettingsLoading,
            });
        }
        default:
            return state;
    }
}
export default distributionList;
