import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
// import tryParseJSON from "../helpers/tryParseJSON";
import type { FlowModel, FlowItem, FlowErrorsByItemId, FlowRelation } from "../types/flowTypes";

const myGenericReducer = makeReducerFor("FLOW_MODEL", "FlowModelId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowModelsArray = createSelector(
    state => state.flowModels.byId,
    (flowModelsById: {| [number]: FlowModel |}): Array<FlowModel> => {
        const r: Array<FlowModel> = Object.values(flowModelsById);
        return r;
    }
);

export type FlowModelsByItemId = {
    [number]: FlowModel,
};

export const getFlowModelsByFlowItemId = createSelector(
    state => getFlowModelsArray(state),
    (flowModels: Array<FlowModel>): FlowModelsByItemId =>
        flowModels.reduce((acc, row) => {
            acc[row.FlowItemId] = row;
            return acc;
        }, {})
);

export const getFlowModelsForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowModelsByFlowItemId(state),
    (selectedFlow: number, flowItems: Array<FlowItem>, flowModelsByItemId: FlowModelsByItemId): Array<FlowModel> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        const result = [];
        for (const itemId of itemIds) {
            const report = flowModelsByItemId[itemId];
            if (report != null) {
                result.push(report);
            }
        }
        return result;
    }
);

export const getFlowModelsAreValidForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    (flowItems: Array<FlowItem>): boolean => {
        const modelFlowItems = flowItems.filter(x => x.FlowItemType == "model");
        return !(modelFlowItems.filter(x => !x.IsValid || x.IsRunning > 0 || x.IsError || !x.FlowItemEnd).length > 0);
    }
);

import { getFlowRelationsForSelectedFlow, getFlowRelationsForAllFlows } from "./flowRelations";

const reportsToErrorsById = (
    flowItemsById: {| [number]: FlowItem |},
    flowModels: Array<FlowModel>,
    flowRelations: Array<FlowRelation>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowModel of flowModels) {
        const flowItem = flowItemsById[flowModel.FlowItemId];
        errorsById[flowModel.FlowItemId] = validateFlowModel(flowItem, flowModel, flowRelations);
    }
    return errorsById;
};

export const getModelErrorsForSelectedFlow = createSelector(
    state => state.flowItems.byId,
    state => getFlowModelsForSelectedFlow(state),
    state => getFlowRelationsForSelectedFlow(state),
    state => state.session.enabledFeatures || [],
    reportsToErrorsById
);

export const getModelErrorsForAllFlows = createSelector(
    state => state.flowItems.byId,
    state => getFlowModelsArray(state),
    state => getFlowRelationsForAllFlows(state),
    state => state.session.enabledFeatures || [],
    reportsToErrorsById
);

//////////////////// HELPERS //////////////////////////////
export const validateFlowModel = (
    flowItem: FlowItem,
    flowModel: FlowModel,
    flowRelations: Array<FlowRelation>
): Array<string> => {
    const errors = [];

    const parentIds = flowRelations
        .filter(z => z.ParentFlowItemId != 0 && z.ChildFlowItemId == flowModel.FlowItemId)
        .map(x => x.ParentFlowItemId);
    if (flowItem.FlowItemName == "New Item") {
        errors.push("Model needs to have a custom name.");
    }
    if (parentIds.length != 1 && parentIds.length != 2) {
        errors.push("Model must have 1 or 2 parents assigned.");
    } else if (parentIds.length == 2) {
        if (flowModel.TargetFlowItemId == null || flowModel.BaseFlowItemId == null) {
            errors.push("Model must have Target and Base selected.");
        } else if (flowModel.BaseLuidQty == 0 || flowModel.TargetLuidQty == 0) {
            errors.push("There were not enough records for modeling to proceed.");
        } else if (flowModel.BaseFilename == null || flowModel.BaseFilename == "") {
            errors.push("Model must have a Base Filename.");
        }

        if (flowModel.TargetLuidQty) {
            if (flowModel.TargetLuidQty < 550) {
                errors.push("Target must have at least 550 households.");
            }
        }

        if (flowModel.BaseLuidQty && flowModel.TargetLuidQty) {
            if (flowModel.BaseLuidQty < flowModel.TargetLuidQty) {
                errors.push("Base must have more lines than Target.");
            }
        }
    }
    if (flowModel.Bundle == 0) {
        errors.push("Model must have a Bundle.");
    }

    if (flowModel.DestinationCompanyId == null || flowModel.DestinationCompanyId == 0) {
        errors.push("Model must have a Destination Company selected.");
    }

    return errors;
};
