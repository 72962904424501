export const SET_FLOW_ITEM_RESULT_TABLES = "SET_FLOW_ITEM_RESULT_TABLES";
export const SET_FLOW_ITEM_TABLE_COLUMNS = "SET_FLOW_ITEM_TABLE_COLUMNS";
export const SET_FLOW_ITEM_DATALOAD_TABLE_COLUMNS = "SET_FLOW_ITEM_DATALOAD_TABLE_COLUMNS";
export const SET_IS_LOADING_TABLES = "SET_IS_LOADING_TABLES";
export const SET_IS_LOADING_COLUMNS = "SET_IS_LOADING_COLUMNS";

const initialState = {
    resultTables: {},
    tableColumns: {},
    isLoadingTables: false,
    isLoadingColumns: false,
};

function flowItemResultTables(state = initialState, action) {
    switch (action.type) {
        case SET_FLOW_ITEM_RESULT_TABLES: {
            if (!action.flowItemId || !action.resultTables) {
                return state;
            }

            return {
                ...state,
                resultTables: { ...state.resultTables, [action.flowItemId]: action.resultTables },
            };
        }

        case SET_FLOW_ITEM_TABLE_COLUMNS: {
            if (!action.tableName || !action.tableColumns) {
                return state;
            }

            return {
                ...state,
                tableColumns: { ...state.tableColumns, [action.tableName]: action.tableColumns },
            };
        }

        case SET_FLOW_ITEM_DATALOAD_TABLE_COLUMNS: {
            if (!action.tableName || !action.tableColumns) {
                return state;
            }
            return {
                ...state,
                tableColumns: { ...state.tableColumns, [action.tableName]: action.tableColumns },
            };
        }

        case SET_IS_LOADING_TABLES: {
            return { ...state, isLoadingTables: action.isLoadingTables };
        }

        case SET_IS_LOADING_COLUMNS: {
            return { ...state, isLoadingColumns: action.isLoadingColumns };
        }
        default:
            return state;
    }
}

export default flowItemResultTables;
