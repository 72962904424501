import update from "immutability-helper";
import {
    IFlowControlDataStore,
    IExternalService,
    IExternalParameter,
    IExternalParameterValue,
    IExternalServiceParameter,
    IExternalFileLocation,
    IExternalServiceDefaultFields,
} from "../types/stores/flowControlData";

export const SET_FLOW_CONTROL_DATA = "SET_FLOW_CONTROL_DATA";
export const SET_EXTERNAL_PARAMETER_VALUES = "SET_EXTERNAL_PARAMETER_VALUES";

export type ISetFlowControlData = {
    type: typeof SET_FLOW_CONTROL_DATA;
    externalServices: Array<IExternalService>;
    externalParameters: Array<IExternalParameter>;
    externalParameterValues: Array<IExternalParameterValue>;
    externalServiceParameters: Array<IExternalServiceParameter>;
    externalFileLocation: Array<IExternalFileLocation>;
    externalInputFileLocation: Array<IExternalFileLocation>;
    externalServiceDefaultFields: Array<IExternalServiceDefaultFields>;
};

export type ISetExternalParameterValues = {
    type: typeof SET_EXTERNAL_PARAMETER_VALUES;
    externalParameterValues: Array<IExternalParameterValue>;
};

type IActionType = ISetFlowControlData | ISetExternalParameterValues;

const defaultState: IFlowControlDataStore = {
    externalServices: [],
    externalParameters: [],
    externalParameterValues: [],
    externalServiceParameters: [],
    externalFileLocation: [],
    externalInputFileLocation: [],
    externalServiceDefaultFields: [],
};

function flowControlData(state = defaultState, action: IActionType): IFlowControlDataStore {
    switch (action.type) {
        case SET_FLOW_CONTROL_DATA: {
            const newState: IFlowControlDataStore = {
                externalServices: action.externalServices,
                externalParameters: action.externalParameters,
                externalParameterValues: action.externalParameterValues,
                externalServiceParameters: action.externalServiceParameters,
                externalFileLocation: action.externalFileLocation,
                externalInputFileLocation: action.externalInputFileLocation,
                externalServiceDefaultFields: action.externalServiceDefaultFields,
            };

            return newState;
        }

        case SET_EXTERNAL_PARAMETER_VALUES: {
            return update(state, {
                externalParameterValues: { $set: action.externalParameterValues },
            });
        }

        default:
            return state;
    }
}
export default flowControlData;
