/*
    Notifications - These are ephemeral messages that appear in the top right, such as "soandso count completed."

    Main, easy to use functions:

    notifyBlack(text | react node)
    notifyGreen(text | react node)
    notifyBlue(text | react node)

    Low level, specify class names and everything: addNotificationMessage(text | react node, options)

    We have an optional grouping function which allows messages to be updated instead of displaying new ones.
    For example, imagine a flow that has 10 items complete, each one second after the last.  Instead of displaying 10
    notifications, we will display 1 notification and add the items to it as it completes.  It will time out 5 seconds
    after the last one is displayed.  See note (gp1) lower in this file.
*/
import React from "react";
import { debounce } from "lodash";
import { GetNotificationsCount } from "./userNotificationActions";
import { getUserCompanyFlowsStatus } from "./dashboardWidgetActions";
import { Dispatch } from "redux";
import {
    Notification,
    UpdatableFields,
    MessageGroupOptions,
    IDismissNotification,
    IAddNotification,
    IUpdateNotification,
} from "../types/stores/notifications";
import { DISMISS_NOTIFICATION, ADD_NOTIFICATION, UPDATE_NOTIFICATION } from "../reducers/notifications";
import { IAppState } from "../types/stores/index";

// Notifications //
if (top && !top.RDX) {
    top.RDX = {};
}

let nextNotifyId = 0;

const getCount = () => {
    top!.store.dispatch(GetNotificationsCount());
};

const getCountDebounced = debounce(getCount, 2000);

// AddNotifyMessage: This is a "low level" AC to add a notification.  It allows you to specify everything about the
// notification.  If you just want to display simple text, use something like notifyBlack() or notifyGreen() instead.
//
// (1).  You may pass "text" (simple case) or "componentName" (more complex).  If passed a componentName,
// we ignore the text and render <ComponentName notification={notification} />.
// The componentName must also be added to the lookup table in Notification.js -> renderInner.
// You can put extra information used in the render in the groupArgsList.
// This may be slightly awkward if you aren't using the grouping functionality, and perhaps a ".extra" field should be added.
export const addNotifyMessage = (text: string | React.ReactNode, options: UpdatableFields): IAddNotification => ({
    type: ADD_NOTIFICATION,
    id: nextNotifyId++,
    text, // Text of the notification
    componentName: options.componentName != null ? options.componentName : "", // Optionally, a react Component Name used to register the message.  See (1) above.
    width: options.width || "auto", // CSS Width.  Usually you want "auto".
    className: options.className || "", // Class Names to apply to the message div.
    delay: options.delay || (typeof text == "string" ? calculateDelay(text) : 6000), // How long in milliseconds to display
    groupName: options.groupName || "", // See grouping note (gp1)
    groupArgsList: options.groupArgs != null ? [options.groupArgs] : [], // See grouping note (gp1)
    variant: options.variant || "info", // snackbar variant
});

export const addNotifyMessageTop = (text: string, options: UpdatableFields): void => {
    if (text.includes("notification-count-update")) {
        switch (text) {
            case "notification-count-update-force":
                getCount();
                break;
            default: {
                getCountDebounced();
                break;
            }
        }
    } else if (text.includes("flow-running-updated")) {
        if (window.location.href.includes("dashboard") || window.location.href.includes("flow-status")) {
            top!.store.dispatch(getUserCompanyFlowsStatus());
        }
    } else if (text.includes("STATEMENT_ERROR")) {
        top!.store.dispatch(notifyError("Please, select countable columns!!!"));
    } else {
        top!.store.dispatch(addNotifyMessage(text, options));
    }
};

top!.RDX.addNotifyMessage = addNotifyMessageTop;

export const updateNotifyMessage = (id: number, updates: UpdatableFields): IUpdateNotification => ({
    type: UPDATE_NOTIFICATION,
    id,
    updates,
});

export const dismissNotifyMessage = (id: number): IDismissNotification => ({
    type: DISMISS_NOTIFICATION,
    id,
});

export const notifyBlack =
    (text: string) =>
    (dispatch: Dispatch): void => {
        const delay = typeof text == "string" ? calculateDelay(text) : 6000;
        dispatch(
            addNotifyMessage(text, {
                width: "auto",
                className: "alert-gloss alert-gloss-black",
                delay,
                variant: "info",
            })
        );
    };
top!.RDX.notifyBlack = text => top!.store.dispatch(notifyBlack(text));

export const notifyBlue =
    (text: string | React.ReactNode) =>
    (dispatch: Dispatch): void => {
        const delay = typeof text == "string" ? calculateDelay(text) : 7000;
        dispatch(
            addNotifyMessage(text, {
                width: "auto",
                className: "alert-gloss alert-gloss-info",
                delay,
                variant: "info",
            })
        );
    };

export const notifyGreen =
    (text: string) =>
    (dispatch: Dispatch): IAddNotification =>
        dispatch(
            addNotifyMessage(text, {
                width: "auto",
                className: "alert-gloss alert-gloss-success",
                delay: typeof text == "string" ? calculateDelay(text) : 7000,
                variant: "success",
            })
        );

export const notifyError =
    (text: string, keepDisplayed: boolean = false) =>
    (dispatch: Dispatch): void => {
        if (keepDisplayed) {
            dispatch(
                addNotifyMessage(text, {
                    width: "auto",
                    className: "alert-gloss alert-gloss-black",
                    variant: "error",
                })
            );
        } else {
            const delay = typeof text == "string" ? calculateDelay(text) : 7000;
            dispatch(
                addNotifyMessage(text, {
                    width: "auto",
                    className: "alert-gloss alert-gloss-black",
                    delay,
                    variant: "error",
                })
            );
        }
    };

const safeStrLen = (text: string) => text.replace(/(<([^>]+)>)/gi, "").length;
const calculateDelay = (text: string) => Math.min(Math.max(safeStrLen(text) * 62, 3000), 8000);

/* Grouping Function (gp1) - Send a notification that can be updated later in lieu of sending additional notifications.
   These types of messages must be rendered with a custom component instead of a simple text string.
   Call this function with a groupName (string) and groupArgs (object containing whatever info you want).
   If there is already a message out with the same group, we will update its args, allowing the message to update instead
   of sending a new message.

   Example:  Many flow items completing in the same few seconds will all go to one notification instead of several.
   Let's go through an example of two calls.

   (1st message) Call notifyMessageGrouping with this:
    {
        "groupName": "flowItemComplete",
        "componentName": "NotifyFlowItemFinished",
        "groupArgs": {
            "flowName": "acura",
            "flowItemName": "simple item 1",
            "qty": 1234
        }
    }
   There is no notification with groupName="flowItemComplete" yet, so we render it.  The content of the message is
   <NotifyFlowItemFinished notification={notification} />.
        -- this.props.notification.groupArgsList = [ {flowName: "acura", "flowItemName": "simple item 1", "qty": 1234 } ]
   It can use that to render the text in the notification.

   (2nd message, one second later) Call notifyMessageGrouping with this:
   {
        "groupName": "flowItemComplete",
        "componentName": "NotifyFlowItemFinished",
        "groupArgs": {
            "flowName": "acura",
            "flowItemName": "complex item credit score",
            "qty": 4567
        }
   }
   There is already a notification displaying with groupName="flowItemComplete", so we update it instead of adding a new message.
   We change the notification.groupArgsList that is being sent to the component doing the rendering, which is:
   <NotifyFlowItemFinished notification={notification} />.
   But now:
        -- this.props.notification.groupArgsList = [
            {flowName: "acura", "flowItemName": "simple item 1", "qty": 1234 },
            {flowName: "acura", "flowItemName": "complex item credit score", "qty": 4567 },
        ]
    It should use the updated props to update the message text.
*/
export const notifyMessageGrouping =
    (messageGroupOptions: MessageGroupOptions) =>
    (dispatch: Dispatch, getState: () => IAppState): void => {
        // Look in store for an active notification belonging to the same group

        const notifications: Array<Notification> = Object.values(getState().notifications);
        const notificationSameGroupShowing = notifications.filter(
            x => !x.dismissed && x.groupName == messageGroupOptions.groupName
        );

        if (notificationSameGroupShowing.length == 0) {
            // No messages with the same group - send new message
            dispatch(
                addNotifyMessage("", {
                    width: "auto",
                    className:
                        messageGroupOptions.className != null
                            ? messageGroupOptions.className
                            : "alert-gloss alert-gloss-success",
                    delay: messageGroupOptions.delay != null ? messageGroupOptions.delay : 5000,
                    groupName: messageGroupOptions.groupName,
                    groupArgs: messageGroupOptions.groupArgs,
                    componentName: messageGroupOptions.componentName,
                    variant: messageGroupOptions.variant || "info",
                })
            );
            return;
        }

        // There is a message with the same group, update it.  We always append the groupArgs to the groupArgsList.
        const notificationToUpdate = notificationSameGroupShowing[0];
        const newGroupArgs = notificationToUpdate.groupArgsList.concat(messageGroupOptions.groupArgs);

        dispatch(
            updateNotifyMessage(notificationToUpdate.id, {
                delay: notificationToUpdate.delay + 251, // Causes delay to reset
                groupArgsList: newGroupArgs, // Add new args to update message
            })
        );
    };
top!.RDX.notifyMessageGrouping = messageGroupOptions => top!.store.dispatch(notifyMessageGrouping(messageGroupOptions));
