import h from "../helpers";
import { Dispatch } from "redux";
import { request } from "../helpers/httpInterceptor";
import { debounce } from "lodash";
import { IFileMasterLogEntry } from "../types/stores/fileImportQueue";
import { IModelEntry, IStatusEntry } from "../types/stores/dashboardWidgets";
import { GET_USER_COMPANY_RUNNING_FLOWS_STATUS } from "../reducers/dashboardWidgets";
import {
    UPDATING_WIDGETS,
    GET_USER_WIDGETS,
    TOGGLE_WIDGET,
    MOVE_WIDGET,
    GET_USER_COMPANY_FLOWS_STATUS,
    GET_USER_COMPANY_SCHEDULE_STATUS,
    GET_RECENT_FLOWS,
    GET_FILE_MASTER_LOG,
    GET_MODELS,
    REMOVE_USER_COMPANY_FLOWS_STATUS,
    GET_USER_COMPANY_COMPLETED_FLOWS_STATUS,
} from "../reducers/dashboardWidgets";
import { FILES_WAITING_UPLOAD, GET_IMPORTS } from "../actions/fileImportActions";
import { GetState } from "../types/types";
import { incAjaxCount, decAjaxCount } from "./actionCreators";

if (top && !top.RDX) {
    top.RDX = {};
}

export const getUserWidgets =
    () =>
    (dispatch: Dispatch): Promise<Response> => {
        dispatch({ type: UPDATING_WIDGETS });
        return request("/Widget/GetWidgets", { credentials: "same-origin" }, dispatch)
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch({
                    type: GET_USER_WIDGETS,
                    widgets: data,
                });
            })
            .catch(error => {
                h.error("Error getting Widgets.", error);
                dispatch({
                    type: GET_USER_WIDGETS,
                    widgets: [],
                });
            });
    };

top!.RDX.getFileMasterLog = () => dispatchGetFileMasterLog(top!.store.dispatch);

const dispatchGetFileMasterLog = debounce(dispatch => {
    dispatch(getFileMasterLog(false));
}, 2000);

export const getFileMasterLog =
    (isSetLoading = true, guid: string = "") =>
    (dispatch: Dispatch, getState: GetState): Promise<void> => {
        dispatch(incAjaxCount());

        if (isSetLoading) {
            dispatch({ type: GET_FILE_MASTER_LOG, loading: true });
        }

        return fetch(`/FileImport/GetFileMasterLog${guid != "" ? "?guid=" + guid : ""}`, {
            credentials: "same-origin",
            method: "POST",
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const result = data as unknown as { files: Array<IFileMasterLogEntry> };
                dispatch({
                    type: GET_FILE_MASTER_LOG,
                    fileMasterLog: result.files,
                    loading: false,
                });

                // Add or remove files from waiting upload list to know when get Existing Import list again
                // So, trigger Existing Import list getting when there isn't any file pending to upload
                const state = getState();
                const currentFilesWaitingUpload = state.fileImport.filesWaitingUpload || [];
                if (currentFilesWaitingUpload.length > 0) {
                    const newFilesWaitingUpload = new Array<string>();

                    currentFilesWaitingUpload.forEach(file => {
                        if (
                            result.files.some(
                                x => x.FolderName == file && Number.parseInt(x.PercentComplete?.toString() ?? "") < 100
                            )
                        ) {
                            newFilesWaitingUpload.push(file);
                        }
                    });

                    dispatch({
                        type: FILES_WAITING_UPLOAD,
                        filesWaitingUpload: newFilesWaitingUpload,
                    });

                    if (newFilesWaitingUpload.length == 0) {
                        dispatch({ type: GET_IMPORTS, files: {} });
                    }
                }
            })
            .catch(error => {
                h.error("Error loading FileMasterLog.", error);
                dispatch({
                    type: GET_FILE_MASTER_LOG,
                    fileMasterLog: [],
                    loading: false,
                });
            })
            .finally(() => {
                dispatch(decAjaxCount());
            });
    };

interface IModelContainer {
    Models: Array<IModelEntry>;
    StatusResults: Array<IStatusEntry>;
}

export const getModels =
    () =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: GET_MODELS, loading: true });
        return fetch("/AudienceModel/GetModels", { credentials: "same-origin", method: "POST" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const result = data as unknown as { modelContainer?: IModelContainer };
                if (result.modelContainer) {
                    dispatch({
                        type: GET_MODELS,
                        models: result.modelContainer.Models,
                        statuses: result.modelContainer.StatusResults,
                        loading: false,
                    });
                }
            })
            .catch(error => {
                h.error("Error getting Models.", error);
                dispatch({
                    type: GET_MODELS,
                    models: [],
                    statuses: [],
                    loading: false,
                });
            });
    };

export const removeUserCompanyFlowsStatus =
    (flowIdToRemove: number) =>
    (dispatch: Dispatch): void => {
        dispatch({ type: REMOVE_USER_COMPANY_FLOWS_STATUS, flowIdToRemove });
    };

export const getUserCompanyFlowsStatus =
    () =>
    (dispatch: Dispatch): Promise<Response> => {
        dispatch({ type: GET_USER_COMPANY_FLOWS_STATUS, loading: true });
        return request("/Widget/GetUserCompanyFlowsStatus", { credentials: "same-origin" }, dispatch)
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch({
                    type: GET_USER_COMPANY_FLOWS_STATUS,
                    runningFlows: data.runningFlows || [],
                    completedFlows: data.completedFlows || [],
                    loading: false,
                });
            })
            .catch(error => {
                dispatch({
                    type: GET_USER_COMPANY_FLOWS_STATUS,
                    runningFlows: [],
                    completedFlows: [],
                    loading: false,
                });
                h.error("Error getting User Company Flows.", error);
            });
    };

export const getUserCompanyRunningFlowsStatus =
    () =>
    (dispatch: Dispatch): Promise<Response> => {
        dispatch({ type: GET_USER_COMPANY_RUNNING_FLOWS_STATUS, loading: true });
        return request("/Widget/GetUserCompanyRunningFlowsStatus", { credentials: "same-origin" }, dispatch)
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch({
                    type: GET_USER_COMPANY_RUNNING_FLOWS_STATUS,
                    runningFlows: data.runningFlows || [],
                    loading: false,
                });
            })
            .catch(error => {
                dispatch({
                    type: GET_USER_COMPANY_RUNNING_FLOWS_STATUS,
                    runningFlows: [],
                    loading: false,
                });
                h.error("Error getting User Company Running Flows.", error);
            });
    };

export const getUserCompanyCompletedFlowsStatus =
    (start: string, stop: string, daysAgo: number, year: number) =>
    (dispatch: Dispatch): Promise<Response> =>
        request(
            "/Widget/GetUserCompanyCompletedFlowsStatus",
            {
                credentials: "same-origin",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    start,
                    stop,
                    daysAgo,
                    year,
                }),
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch({
                    type: GET_USER_COMPANY_COMPLETED_FLOWS_STATUS,
                    completedFlows: data || [],
                    loading: false,
                });
            })
            .catch(error => {
                dispatch({
                    type: GET_USER_COMPANY_COMPLETED_FLOWS_STATUS,
                    completedFlows: [],
                    loading: false,
                });
                h.error("Error getting User Company Completed Flows.", error);
            });

export const getUserCompanyScheduleStatus =
    () =>
    (dispatch: Dispatch): Promise<Response> =>
        request("/Widget/GetUserCompanyScheduleStatus", { credentials: "same-origin" }, dispatch)
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (data.success) {
                    dispatch({
                        type: GET_USER_COMPANY_SCHEDULE_STATUS,
                        scheduleStatus: data.schedules || [],
                        loading: false,
                    });
                } else {
                    dispatch({ type: GET_USER_COMPANY_SCHEDULE_STATUS, loading: false });
                    h.error("Error getting User Company Schedules.");
                }
            })
            .catch(error => {
                dispatch({ type: GET_USER_COMPANY_SCHEDULE_STATUS, loading: false });
                h.error("Error getting User Company Schedules.", error);
            });

export const getRecentFlows =
    () =>
    (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: GET_RECENT_FLOWS, loading: true });
        return fetch("/Widget/GetRecentFlows", { credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch({
                    type: GET_RECENT_FLOWS,
                    recentFlows: data,
                    loading: false,
                });
            })
            .catch(error => {
                h.error("Error getting Recent Flows.", error);
                dispatch({
                    type: GET_RECENT_FLOWS,
                    recentFlows: [],
                    loading: false,
                });
            });
    };

// When a widget is toggled on or off, the toggle is saved (if on) or removed (if off) from the database
export const toggleWidget =
    (WidgetName: string, WidgetIsVisible: boolean) =>
    (dispatch: Dispatch): Promise<Response> => {
        dispatch({ type: UPDATING_WIDGETS });
        return request(
            "/Widget/SaveWidget",
            {
                credentials: "same-origin",
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({
                    WidgetName,
                    WidgetIsVisible,
                }),
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (!data.result) {
                    throw new Error("Error saving widget toggle.");
                } else {
                    dispatch({
                        type: TOGGLE_WIDGET,
                        WidgetName,
                        WidgetIsVisible,
                    });
                }
            })
            .catch(error => {
                h.error("Error saving widget toggle.", error);
            });
    };

//Save the widget index/position on the dashboard
export const moveWidget =
    (WidgetName: string, WidgetIndex: number) =>
    (dispatch: Dispatch): Promise<Response> => {
        dispatch({ type: UPDATING_WIDGETS });
        return request(
            "/Widget/MoveWidget",
            {
                credentials: "same-origin",
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({
                    WidgetName,
                    WidgetIndex,
                }),
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch({
                    type: MOVE_WIDGET,
                    widgets: data,
                });
            })
            .catch(error => {
                h.error("Error saving widget move.", error);
            });
    };
