import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow } from "./flowItems";
import type { FlowSingleView, FlowItem, FlowErrorsByItemId } from "../types/flowTypes";
import { getAncestorsFields } from "./flowRelations";
const myGenericReducer = makeReducerFor("FLOW_SINGLE_VIEW", "FlowSingleViewId");
import subItemReducer from "./_genericFlowSubItemReducer";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowSingleViewsArray = createSelector(
    state => state.flowSingleViews.byId,
    (flowSingleViewsById: {| [number]: FlowSingleView |}): Array<FlowSingleView> => {
        const r: Array<FlowSingleView> = Object.values(flowSingleViewsById);
        return r;
    }
);

// export const getActiveSingleViewItemsByVertical2 = (
//     singleViewItems: Array<SingleViewItem>,
//     verticals: Array<string>
// ) => {
//     const singleViewByVertical = {};
//     for (const vertical of verticals) {
//         singleViewByVertical[vertical] = singleViewItems.filter(x => x.VerticalName == vertical);
//     }
//     return singleViewByVertical;
// };

export type FlowSingleViewsByItemId = {
    [number]: Array<FlowSingleView>,
};

export const getFlowSingleViewsByFlowItemId = createSelector(
    state => getFlowSingleViewsArray(state),
    (flowSingleViews: Array<FlowSingleView>): FlowSingleViewsByItemId =>
        flowSingleViews.reduce((acc, row) => {
            if (acc[row.FlowItemId] == null) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

// export const getFlowSplitsForSelectedFlow = createSelector(
//     state => state.selected.flow,
//     state => getFlowItemsForSelectedFlow(state),
//     state => getFlowSplitsByFlowItemId(state),
//     (selectedFlow: number, flowItems: Array<FlowItem>, flowSplitsByItemId: FlowSplitsByItemId): Array<FlowSplit> => {
//         let result = [];
//         const itemIds = flowItems.map(fi => fi.FlowItemId);
//         for (const itemId of itemIds) {
//             const splits = flowSplitsByItemId[itemId];
//             if (splits != null) {
//                 result = result.concat(splits);
//             }
//         }
//         return result;
//     }
// );

export const getFlowSingleViewsForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowSingleViewsByFlowItemId(state),
    (
        selectedFlow: number,
        flowItems: Array<FlowItem>,
        flowSingleViewsByItemId: FlowSingleViewsByItemId
    ): Array<FlowSingleView> => {
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        let result = [];
        for (const itemId of itemIds) {
            const singleViews = flowSingleViewsByItemId[itemId];
            if (singleViews != null) {
                result = result.concat(singleViews);
            }
        }
        return result;
    }
);

export const allSingleViewsHaveResultsTable = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowSingleViewsForSelectedFlow(state),
    (selectedFlow: number, flowItems: Array<FlowItem>, flowSingleViews: Array<FlowSingleView>): boolean => {
        if (flowSingleViews.length == 0) {
            return false;
        }

        let allHaveResultsTable = true;
        const svFlowItems = flowItems.filter(x => x.FlowItemType == "singleview");
        if (svFlowItems.length == 0) {
            allHaveResultsTable = false; // don't have any SingleView items
        } else {
            const svItemsNotCalculated = svFlowItems.filter(x => x.HasResultTable == false);
            if (svItemsNotCalculated.length > 0) {
                allHaveResultsTable = false;
            }
            const svItemsDisabled = svFlowItems.filter(x => x.IsActive == false);
            if (svItemsDisabled.length > 0) {
                allHaveResultsTable = false;
            }
        }
        return allHaveResultsTable;
    }
);

export const anySingleViewsNeedApproval = createSelector(
    state => state.selected.flow,
    state => getFlowSingleViewsForSelectedFlow(state),
    (selectedFlow: number, flowSingleViews: Array<FlowSingleView>): boolean => {
        if (flowSingleViews.length == 0) {
            return false;
        }
        return flowSingleViews.filter(x => x.IsApprovalRequired == true).length > 0;
    }
);

const singleViewsToErrorsById = (
    flowSingleViews: Array<FlowSingleView>,
    flowItems: Array<FlowItem>,
    destinationFieldRestrictions: any,
    getAncestorsFields: any
): FlowErrorsByItemId => {
    const errorsById = {};
    const singleViewItems = flowItems.filter(x => x.FlowItemType == "singleview");
    const restrictedFields = destinationFieldRestrictions || [];
    if (singleViewItems.length > 0) {
        singleViewItems.forEach(item => {
            // SingleViews for the item
            const itemSingleViews = flowSingleViews.filter(x => x.FlowItemId == item.FlowItemId);
            if (itemSingleViews.length == 0 || itemSingleViews.filter(x => x.IsSelected).length == 0) {
                // Must have at least 1 destination selected
                errorsById[item.FlowItemId] = "Please select at least 1 destination.";
            }

            if (restrictedFields.length > 0) {
                let hasRestrictedFields = false;
                const ancestorFields = getAncestorsFields;
                ancestorFields.forEach(ancestorsField => {
                    if (
                        restrictedFields.filter(
                            invalidFields =>
                                invalidFields.FieldKey == ancestorsField.FieldId &&
                                invalidFields.DestinationId == item.PartnerId
                        ).length != 0
                    ) {
                        hasRestrictedFields = true;
                    }
                });
                //Shouldnt be possible...
                if (hasRestrictedFields) {
                    errorsById[item.FlowItemId] = "SingleView has restricted fields";
                }
            }
        });
    }
    return errorsById;
};

export const getSingleViewErrorsForSelectedFlow = createSelector(
    state => getFlowSingleViewsForSelectedFlow(state),
    state => getFlowItemsForSelectedFlow(state),
    state => state.vars.destinationFieldRestrictions,
    state => getAncestorsFields(state),
    singleViewsToErrorsById
);

export const getSingleViewErrorsForAllFlows = createSelector(
    state => getFlowSingleViewsArray(state),
    state => getFlowItemsForSelectedFlow(state),
    state => state.vars.destinationFieldRestrictions,
    state => getAncestorsFields(state),
    singleViewsToErrorsById
);
//////////////////// HELPERS //////////////////////////////

export const validateFlowSingleView = (FlowSingleView: FlowSingleView): Array<string> => {
    const errors = [];
    return errors;
};
